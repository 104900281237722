import React, { useState, useEffect } from "react";
import "./IntegrationChatGPTSetupHandoverSettings.scss";
import { FiInfo, FiTrash2, FiPlus, FiAlertCircle, FiSave } from "react-icons/fi";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { connect } from 'react-redux';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";

const IntegrationChatGPTSetupHandoverSettings = (props) => {
    
    let [isLoadingSavedData, setIsLoadingSavedData] = useState(false);

    useEffect(() => {
        props.getTopics();
    }, []);

    useEffect(() => {
        fetchSavedHandoverSettings();
    }, [props.match.params.orgID]);

    useEffect(() => {
        let _errorMessageData = { ...props.handoverSettingsError };
        while (_errorMessageData.criteria.length < props.handoverSettings.criteria.length) {
            _errorMessageData.criteria.push({
                criteria: "",
                topic: ""
            });
        }
        props.setHandoverSettingsError(_errorMessageData);
    }, [props.handoverSettings]);

    const fetchSavedHandoverSettings = () => {
        setIsLoadingSavedData(true);

        // TODO: GET SAVED SETTINGS API
        setTimeout(() => {
            setIsLoadingSavedData(false);
            if (!props.handoverSettingsOrigin) {
                props.setHandoverSettingsOrigin(JSON.stringify(props.handoverSettings));
            }
        }, 1000);
    }

    const onChangeText = (e, i) => {
        let _data = { ...props.handoverSettings };
        let _errorMessageData = { ...props.handoverSettingsError };
        _errorMessageData.criteria[i].criteria = "";
        _data.criteria[i].criteria = e.target.value;
        props.setHandoverSettingsError(_errorMessageData);
        props.setHandoverSettings(_data);
    }

    const onSelectTopic = (e, i) => {
        let _data = { ...props.handoverSettings };
        let _errorMessageData = { ...props.handoverSettingsError };
        _errorMessageData.criteria[i].topic = "";
        _data.criteria[i].topic = e;
        props.setHandoverSettingsError(_errorMessageData);
        props.setHandoverSettings(_data);
    }

    const toggleConfig = () => {
        let _data = { ...props.handoverSettings };
        _data.isEnable = !_data.isEnable;
        props.setHandoverSettings(_data);

        if (!_data.isEnable) {
            let _errorMessageData = { ...props.handoverSettingsError };
            _errorMessageData.criteria.map((value) => {
                value.criteria = "";
                value.topic = "";
                return null;
            });
            props.setHandoverSettingsError(_errorMessageData);
        }
    }

    const addAnotherCriteria = () => {
        let _data = { ...props.handoverSettings };
        let _errorMessageData = { ...props.handoverSettingsError };

        _errorMessageData.criteria.push({
            criteria: "",
            topic: ""
        });

        _data.criteria.push({
            criteria: "",
            topic: {
                value: "",
                label: ""
            }
        });
        props.setHandoverSettingsError(_errorMessageData);
        props.setHandoverSettings(_data);
    }

    const onDeleteCriteria = (i) => {
        let _data = { ...props.handoverSettings };
        let _errorMessageData = { ...props.handoverSettingsError };

        _errorMessageData.criteria.splice(i, 1);
        _data.criteria.splice(i, 1);

        props.setHandoverSettingsError(_errorMessageData);
        props.setHandoverSettings(_data);
    }

    const filterTopicSelection = (i) => {
        let printTopic = [];

        props.topicList.map((val) => {
            let findIndexTopic = props.handoverSettings.criteria.findIndex(_v => _v.topic.value === val.value);
            if (findIndexTopic === -1 || findIndexTopic === i) {
                printTopic.push(val);
            }
            return null;
        })

        return printTopic;
    }

    const printCriteria = (v, i) => {
        return (
            <div key={`criteria-wrap-${i}`} className={`criteria-wrapper criteria-wrapper-${i} form-content-wrapper`}>
                <label>
                    <b>Criteria</b>
                    {/* <span className={`${i !== 0 ? "right-18" : ""}`}>{v.text.length}/25 character</span> */}
                </label>
                <input
                    type="text"
                    placeholder="Insert criteria here"
                    className={` ${props.handoverSettingsError.criteria && props.handoverSettingsError.criteria.length > i && props.handoverSettingsError.criteria[i] && props.handoverSettingsError.criteria[i].criteria !== "" ? "border-red" : ""}`}
                    value={v.criteria}
                    onChange={(e) => onChangeText(e, i)}
                    disabled={!props.handoverSettings.isEnable}
                />
                {
                    (props.handoverSettingsError.criteria && props.handoverSettingsError.criteria.length > i && props.handoverSettingsError.criteria[i] && props.handoverSettingsError.criteria[i].criteria !== "") &&
                    <ErrorOrInfoComp
                        text={props.handoverSettingsError.criteria[i].criteria}
                        _className={`${props.handoverSettingsError.criteria && props.handoverSettingsError.criteria.length > i && props.handoverSettingsError.criteria[i] && props.handoverSettingsError.criteria[i].criteria !== "" ? "red-text" : ""}`}
                        icon={<FiAlertCircle />}
                    />
                }

                <label>
                    <b>Topic</b>
                </label>

                <CustomSelectOption
                    optionListProps={filterTopicSelection(i)}
                    valueDropdownProps={v.topic}
                    placeholderProps={'Select topic'}
                    onClickDropDownListOptionProps={(e) => onSelectTopic(e, i)}
                    specificClassNameProps="custom-select-criteria-topic"
                    dropdownError={""}
                    _className={props.handoverSettingsError.criteria && props.handoverSettingsError.criteria.length > i && props.handoverSettingsError.criteria[i] && props.handoverSettingsError.criteria[i].topic !== "" ? "border-red" : ""}
                    isDisabled={!props.handoverSettings.isEnable}
                    isLoading={!props.topicList || props.topicList.length === 0}
                />
                {
                    props.handoverSettingsError.criteria && props.handoverSettingsError.criteria.length > i && props.handoverSettingsError.criteria[i] && props.handoverSettingsError.criteria[i].topic !== "" &&
                    <ErrorOrInfoComp
                        text={props.handoverSettingsError.criteria && props.handoverSettingsError.criteria.length > i && props.handoverSettingsError.criteria[i] && props.handoverSettingsError.criteria[i].topic}
                        _className={`${props.handoverSettingsError.criteria[i].topic !== "" ? "red-text" : ""}`}
                        icon={<FiAlertCircle />}
                    />
                }

                {
                    (props.handoverSettings.isEnable && props.handoverSettings.criteria.length > 1) &&
                    <FiTrash2
                        className='button-delete'
                        onClick={() => onDeleteCriteria(i)}
                    />
                }
            </div>
        )
    }

    return (
        <>
            <SectionWrap
                {...props}
                orgID={props.match.params.orgID}
                withHeader
                withSideMenu
                noWrap
                dataOrigin={props.handoverSettingsOrigin}
                dataAfterChange={JSON.stringify(props.handoverSettings)}
            >

                <div className="chatgpt-setup-handover-page-wrapper">
                    <p className="top-title">
                        <span className="top-subtitle">
                            <div className="gpt-handover-switch-wrapper">
                                <label className="custom-switch-wrapper">
                                    <input
                                        type="checkbox"
                                        checked={props.handoverSettings.isEnable}
                                        onChange={() => toggleConfig()}
                                        disabled={isLoadingSavedData}
                                    />
                                    <span className="custom-switch round"></span>
                                </label>
                            </div>
                            <p>Turning this on will allow cases to be handed over to agents when messages with specific criteria are received.</p>
                        </span>
                    </p>

                    <p className="text-info-top">
                        <FiInfo />
                        Criteria helps ChatGPT define conversations that needs to be handed over to agents.
                        <br/>
                        E.g. When there is no possible answers from the knowledge base
                    </p>

                    {
                        isLoadingSavedData ?
                        <RetrieveDataLoading
                            isLoading={isLoadingSavedData}
                            errorMessage=""
                            errorCode=""
                        />
                        :
                        <>
                            {/* <ErrorOrInfoComp
                                icon={<FiInfo />}
                                _className="top-info"
                                text="Criteria helps ChatGPT define conversations that needs to be handed over to agents. E.g. When there is no possible answers from the knowledge base"
                            /> */}

                            {
                                props.handoverSettings.criteria &&
                                props.handoverSettings.criteria.map((v, i) => {
                                return (
                                    printCriteria(v, i)
                                )
                            })}
        
                            {
                                props.handoverSettings.isEnable && props.handoverSettings.criteria.length < props.topicList.length &&
                                <div>
                                    <ButtonWithLoadingOrIcon
                                        text="Add another criteria"
                                        onClickAction={() => addAnotherCriteria()}
                                        className="main-button-26 no-fill-button add-another-criteria"
                                        icon={{
                                            type: "svg",
                                            src: FiPlus
                                        }}
                                        position="left"
                                    />
                                </div>
                            }

                            <div>
                                <ButtonWithLoadingOrIcon
                                    className="no-fill-button main-button-40 button-save"
                                    text="Save Changes"
                                    icon={{
                                        type: "svg",
                                        src: FiSave
                                    }}
                                    position="right"
                                    onClickAction={() => {
                                        props.validateSaveHandoverSettings();
                                        props.setPendingTab(-1);
                                    }}
                                />
                            </div>
                        </>
                    }
                </div>
            </SectionWrap>
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationChatGPTSetupHandoverSettings)
