import React, { useState, useEffect } from 'react';
import './ChatRoomUserInfo.scss';
import { taptalk, tapCoreChatRoomManager } from '@taptalk.io/web-sdk';
import CaseService from "../../../../services/chat/caseServices";
import { MdFiberManualRecord } from 'react-icons/md';
import { FiMail, FiEdit, FiInfo, FiUser, FiClock } from 'react-icons/fi';
import { connect } from 'react-redux';
import ChatRoomCaseDetailTab from './chatRoomCaseDetailTab/ChatRoomCaseDetailTab';
import ChatRoomChatHistoryTab from './chatRoomCaseHistoryTab/ChatRoomChatHistoryTab';
import ChatRoomUserInfoTab from './chatRoomUserInfoTab/ChatRoomUserInfoTab';
import ChatRoomCaseActionHistory from './chatRoomCaseActionHistory/ChatRoomCaseActionHistory';
import ChatRoomShareMedia from './chatRoomSharedMedia/ChatRoomShareMedia';
import { doToast } from "../../../../helper/HelperGeneral";
import { TAPLIVE_MEDIUM } from "../../../../constants/taplive";
import Helper from '../../../../helper/HelperChat';
import { Modal, ModalBody } from "reactstrap";
import { setChangeContactInfo } from "../../../../redux/actions/reduxActionChangeUserAlias";
import { setCaseDetailData } from "../../../../redux/actions/reduxActionCaseDetailData";
import { setEditContactInfo } from "../../../../redux/actions/reduxActionEditContactInfo";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import mixpanel from "mixpanel-browser";
import iconReplaceAvatar from "../../../../assets/img/replace-avatar.svg";
// import { BLOCKING_REASON } from '../../../../constants/taplive';

const PREFIX_CASE = "case#";

const USER_INFO_TAB = {
    caseDetail: 1,
    userInfo: 2,
    caseHistory: 3
}

var ChatRoomUserInfo = (props) => {
    let [activeUserInfoTab, setActiveUserInfoTab] = useState(USER_INFO_TAB.caseDetail);
    let [isLoadingCaseDetailFinish, setIsLoadingCaseDetailFinish] = useState(false);
    let [prevCaseDetailData, setPrevCaseDetailData] = useState(null);
    let [userAgentData, setUserAgentData] = useState({});

    //   let [userData, setUserData] = useState(null);
    let [isShowModalEditAlias, setIsShowModalEditAlias] = useState(false);
    let [valEditAlias, setValEditAlias] = useState("");
    let [isWaitReponseEditAlias, setIsWaitReponseEditAlias] = useState(false);
    let [isShowEditAliasButton, setIsShowEditAliasButton] = useState(false);
    let [temporaryCaseDetailVal, setTemporaryCaseDetailVal] = useState(false);

    useEffect(() => {
        if (props.reloadCaseDetail.lastRun && props.reloadCaseDetail.room.roomID === props.activeRoom.roomID) {
            getCaseDetail(props.caseDetailProps.id, false, setIsLoadingCaseDetailFinish(true));
        }
    }, [props.reloadCaseDetail])

    useEffect(() => {
        if (temporaryCaseDetailVal) {
            if (temporaryCaseDetailVal.room.roomID === props.activeRoom.roomID) {
                props.setCaseDetailData(temporaryCaseDetailVal.detail);

                if (temporaryCaseDetailVal.callback) {
                    temporaryCaseDetailVal.callback();
                }
            }
        }
    }, [temporaryCaseDetailVal])

    useEffect(() => {
        if (props.caseDetailProps !== null) {
            let runCaseDetailProps = async () => {
                let _userAgentData = props.myAgentData.account;
                setUserAgentData(_userAgentData);
                setIsShowEditAliasButton(_userAgentData.id === props.caseDetailProps.agentAccountID);

                if (prevCaseDetailData === null) {
                    setPrevCaseDetailData(props.caseDetailProps);
                    getRoomData(props.caseDetailProps.userID);
                } else {
                    if (prevCaseDetailData.id !== props.caseDetailProps.id) {
                        setPrevCaseDetailData(props.caseDetailProps);
                        getRoomData(props.caseDetailProps.userID)
                    } else {
                        let _detailData = { ...props.caseDetailData };
                        _detailData.case = props.caseDetailProps;

                        // if(props.caseDetailProps.isClosed) {
                        //     _detailData.canReply = false;
                        //     _detailData.blockingReason = BLOCKING_REASON.case_closed.blockingReason;
                        //     _detailData.blockingMessage = BLOCKING_REASON.case_closed.blockingMessage;
                        // }

                        // setTemporaryCaseDetailVal({
                        //     detail: _detailData,
                        //     room: props.activeRoom,
                        //     date: new Date().valueOf()
                        // })
                    }
                }
            }

            runCaseDetailProps();
        }
    }, [props.caseDetailProps])

    useEffect(() => {
        if (props.listenerUserOnlineProps !== null) {
            let userOnlineData = props.listenerUserOnlineProps;
            if (props.caseDetailData && props.caseDetailData.user) {
                if (userOnlineData.user.xcUserID === props.caseDetailData.user.userID) {
                    let _detailData = { ...props.caseDetailData };
                    _detailData.user.isOnline = userOnlineData.isOnline;
                    // props.setCaseDetailData(_detailData);
                    setTemporaryCaseDetailVal({
                        detail: _detailData,
                        room: props.activeRoom,
                        date: new Date().valueOf()
                    })
                }
            }
        }
    }, [props.listenerUserOnlineProps])

    useEffect(() => {
        if (props.activeRoom === null) {
            setPrevCaseDetailData(null);
        }
    }, [props.activeRoom])

    useEffect(() => {
        if (props.caseDetailData === null) {
            setIsLoadingCaseDetailFinish(false);
        } else {
            if (props.caseDetailData.user) {
                setIsLoadingCaseDetailFinish(true);
            } else {
                setIsLoadingCaseDetailFinish(false);
            }
        }
    }, [props.caseDetailData])

    useEffect(() => {
        let _changeUserAlias = { ...props.changeContactInfo };

        if (_changeUserAlias.state) {
            getRoomData(_changeUserAlias.userID);
        }
    }, [props.changeContactInfo])

    //   useEffect(() => {
    //     let updateMessage = props.messageListenerUpdateMessageProps;

    //     if(updateMessage !== null && props.caseDetailData !== null) {
    //         let _detailData = props.caseDetailData;

    //         if(((updateMessage.type === MESSAGE_TYPE.CASE_UPDATE_AGENT_CHANGE) || (updateMessage.type === MESSAGE_TYPE.CASE_CLOSED)) && _detailData.userCases[PREFIX_CASE + updateMessage.data.id]) {
    //             _detailData.userCases[PREFIX_CASE + updateMessage.data.id] = updateMessage.data;

    //             props.setCaseDetailData(_detailData);
    //         }
    //     }
    //   }, [props.messageListenerUpdateMessageProps])

    let actionRunGetRoomData = () => {
        getRoomData(props.caseDetailProps.userID);
    }

    let onClickUserInfoTab = (tab) => {
        setActiveUserInfoTab(tab)
    }

    let getRoomData = async (userID) => {
        setIsLoadingCaseDetailFinish(false);

        if (props.caseDetailProps.medium === TAPLIVE_MEDIUM.launcher) {
            tapCoreChatRoomManager.getGroupChatRoom(props.activeRoom.roomID, {
                onSuccess: (response) => {
                    let participants = response.participants;
                    let findIndex = participants.findIndex(value => value.xcUserID === userID);

                    //   setUserData(participants[findIndex]);
                    getCaseDetail(props.caseDetailProps.id, participants[findIndex]);
                },

                onError: (errorCode, errorMessage) => {
                    console.log(errorCode, errorMessage);
                }
            })
        } else {
            getCaseDetail(props.caseDetailProps.id, false);
        }
    }

    let getCaseDetail = async (caseID, participant, callback = false) => {
        setIsLoadingCaseDetailFinish(false);

        CaseService.postCaseDetail(props.parentProps.match.params.orgID, { id: caseID, includeUserCases: false }, (response) => {
            let dataResult = response.dataResult;

            if (!callback) {
                if (participant) {
                    // dataResult.data.user.isOnline = participant.isOnline;
                    dataResult.data.user.isOnline = true;
                }

                // else {
                //     dataResult.data.user.isOnline = false;
                // }
            }

            if (dataResult.error.errorMessage !== "") {
                let _cases = {};

                for (let i in dataResult.data.userCases) {
                    _cases[PREFIX_CASE + dataResult.data.userCases[i].id] = dataResult.data.userCases[i];
                }

                dataResult.data.userCases = _cases;
                // props.setCaseDetailData(dataResult.data);
                setTemporaryCaseDetailVal({
                    detail: dataResult.data,
                    room: props.activeRoom,
                    date: new Date().valueOf(),
                    callback: callback
                })

                callback && callback();
            } else {
                doToast(dataResult.error.errorMessage, 'fail');
                console.log(dataResult.error.errorMessage);
            }
        });
    }

    let renderModalEditAlias = () => {
        let onChangeInputAlias = (e) => {
            setValEditAlias(e.target.value);
        }

        let submitEditAlias = () => {
            setIsWaitReponseEditAlias(true);

            let data = {
                userID: props.caseDetailData.user.userID,
                alias: valEditAlias
            }

            CaseService.postUpdateUserAlias(props.parentProps.match.params.orgID, data, (response) => {
                setIsWaitReponseEditAlias(false);
                let _dataResult = response.dataResult;

                if (response.dataResult.error.message === "") {
                    doToast(response.dataResult.data.message);

                    if (_dataResult.data.success) {
                        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                            mixpanel.track(
                                "[Action] Set Alias",
                                {
                                    userFullName: props.myAgentData.account.fullName,
                                    userID: props.myAgentData.account.id,
                                    organizationID: props.parentProps.match.params.orgID.split("-")[0]
                                }
                            );
                        }

                        toggleModalEditAlias();
                        // getRoomData(props.caseDetailProps.userID);
                        props.setChangeContactInfo({
                            state: true,
                            alias: valEditAlias,
                            userID: props.caseDetailProps.userID
                        });
                    }
                } else {
                    doToast(response.dataResult.error.message, "fail");
                }
            })
        }

        return (
            <Modal isOpen={isShowModalEditAlias} toggle={toggleModalEditAlias} className={"modal-edit-alias"}>
                <ModalBody>
                    <p className="title">
                        <b>Edit Alias</b>
                    </p>

                    <input disabled={isWaitReponseEditAlias} type="text" value={valEditAlias} onChange={(e) => onChangeInputAlias(e)} placeholder="E.g John (maximum 30 character)" maxLength="30" />

                    <ButtonWithLoadingOrIcon
                        onClickAction={toggleModalEditAlias}
                        className="no-fill-button main-button-48"
                        text="Cancel"
                    />

                    {isWaitReponseEditAlias ?
                        <ButtonWithLoadingOrIcon
                            isLoading
                            loadingColor="gray"
                            isDisabled
                            className="orange-button main-button-48"
                            text="Save Changes"
                            position="left"
                        />
                        :
                        <ButtonWithLoadingOrIcon
                            onClickAction={submitEditAlias}
                            className="orange-button main-button-48"
                            text="Save Changes"
                        />
                    }
                </ModalBody>
            </Modal>
        )
    }

    let toggleModalEditAlias = () => {
        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Button] Set Alias",
                {
                    userFullName: props.myAgentData.account.fullName,
                    userID: props.myAgentData.account.id,
                    organizationID: props.parentProps.match.params.orgID.split("-")[0]
                }
            );
        }

        setIsShowModalEditAlias(!isShowModalEditAlias);
    }

    let renderInfoName = () => {
        let text = "";

        if (props.caseDetailData) {
            if (props.caseDetailData.user.alias) {
                text = props.caseDetailData.user.alias !== "" ? `${props.caseDetailData.user.alias} (${props.caseDetailData.user.fullName})` : props.caseDetailData.user.fullName;
            } else {
                text = props.caseDetailData.case.medium === "email" ? (props.caseDetailData.user.fullName === "" ? props.caseDetailData.user.email : props.caseDetailData.user.fullName) : props.caseDetailData.user.fullName;
            }
        }

        return text;
    }

    return (
        <div className={`chat-room-user-info-wrapper ${props.isUserInfoShowProps ? 'active-chat-room-user-info-wrapper' : ''}`}>
            {renderModalEditAlias()}

            {!isLoadingCaseDetailFinish ?
                <div className="case-detail-loading-wrapper">
                    <div className="lds-ring">
                        <div /><div /><div /><div />
                    </div>

                    <b>Loading Detail</b>
                </div>
                :
                props.caseDetailData &&
                <React.Fragment>
                    <div className="user-info-name-avatar-wrapper">
                        {props.caseDetailData.user.photoURL === "" ?
                            <div className="user-info-avatar-wrapper" style={{ background: taptalk.getRandomColor(renderInfoName()) }}>
                                <b>{Helper.renderUserAvatarWord(renderInfoName())}</b>
                            </div>
                            :
                            <img
                                className="user-avatar-name"
                                src={props.caseDetailData.user.photoURL}
                                alt=""
                                onError={(e) => { e.target.onerror = null; e.target.src = iconReplaceAvatar; }}
                            />
                        }

                        <div className="user-info-name-connectivity">
                            <div className="main-user-name-outer">
                                <div className={`main-user-name ${!isShowEditAliasButton ? "hide-edit-alias" : ""}`}>
                                    <p>
                                        <b>
                                            {renderInfoName()}
                                        </b>
                                    </p>

                                    {/* {isShowEditAliasButton &&
                                        <div className="edit-alias-button" onClick={() => {
                                            toggleModalEditAlias();
                                            setValEditAlias(props.caseDetailData.user.alias);
                                        }}>
                                            <FiEdit />
                                            <b>Alias</b>
                                        </div>
                                    } */}

                                    {isShowEditAliasButton &&
                                        <div
                                            className="edit-alias-button"
                                            onClick={() => {
                                                let _editContactInfo = { ...props.editContactInfo };
                                                _editContactInfo.isShow = !_editContactInfo.isShow;
                                                props.setEditContactInfo(_editContactInfo);
                                            }}
                                        >
                                            <FiEdit />
                                            <b>Edit Contact Info</b>
                                        </div>
                                    }
                                </div>
                            </div>

                            {props.caseDetailData.case.medium === TAPLIVE_MEDIUM.launcher &&
                                (props.caseDetailData.user.isOnline ?
                                    <span className="user-info-online"><MdFiberManualRecord />ONLINE</span>
                                    :
                                    !props.caseDetailData.case.isClosed && props.caseDetailData.case.agentAccountID === userAgentData.id && props.caseDetailData.case.userEmail !== "" ?
                                        <a href={`mailto:${props.caseDetailData.case.userEmail}`}>
                                            <div className="user-info-offline"><FiMail />Send Email</div>
                                        </a>
                                        :
                                        <span className="user-info-offline-dot"><MdFiberManualRecord />OFFLINE</span>
                                )
                            }
                        </div>
                    </div>

                    <div className="user-info-tab-wrapper">
                        <div className={`user-info-tab-content ${activeUserInfoTab === USER_INFO_TAB.caseDetail ? 'active-room-list-tab' : ''}`}
                            onClick={() => onClickUserInfoTab(USER_INFO_TAB.caseDetail)}
                        >
                            <FiInfo />
                            <b>Case Details</b>
                        </div>

                        <div className={`user-info-tab-content ${activeUserInfoTab === USER_INFO_TAB.userInfo ? 'active-room-list-tab' : ''}`}
                            onClick={() => onClickUserInfoTab(USER_INFO_TAB.userInfo)}
                        >
                            <FiUser />
                            <b>Contact Info</b>
                        </div>

                        <div className={`user-info-tab-content ${activeUserInfoTab === USER_INFO_TAB.caseHistory ? 'active-room-list-tab' : ''}`}
                            onClick={() => onClickUserInfoTab(USER_INFO_TAB.caseHistory)}
                        >
                            <FiClock />
                            <b>Chat History</b>
                        </div>
                    </div>

                    <div className="user-info-tab chat-room-user-info-outer-container">
                        {activeUserInfoTab === USER_INFO_TAB.caseDetail &&
                            <ChatRoomCaseDetailTab
                                // caseDetailProps={props.caseDetailData} 
                                parentProps={props.parentProps}
                                actionRunGetRoomDataProps={actionRunGetRoomData}
                            />
                        }

                        {activeUserInfoTab === USER_INFO_TAB.userInfo &&
                            <ChatRoomUserInfoTab
                                caseDetailProps={props.caseDetailData}
                                parentProps={props.parentProps}
                                actionRunGetRoomDataProps={actionRunGetRoomData}
                            />
                        }

                        {activeUserInfoTab === USER_INFO_TAB.caseHistory &&
                            <ChatRoomChatHistoryTab
                                parentProps={props.parentProps}
                                isShowModalCaseHistoryProps={props.isShowModalCaseHistoryProps}
                                toggleCaseModalHistoryProps={props.toggleCaseModalHistoryProps}
                                isShowModalBroadcastHistoryProps={props.isShowModalBroadcastHistoryProps}
                                toggleBroadcastModalHistoryProps={props.toggleBroadcastModalHistoryProps}
                                caseDetailProps={props.caseDetailData}
                                onClickCaseHistoryProps={props.onClickCaseHistoryProps}
                                onClickBroadcastHistoryProps={props.onClickBroadcastHistoryProps}
                            />
                        }

                        <ChatRoomCaseActionHistory
                            parentProps={props.parentProps}
                        />

                        <ChatRoomShareMedia
                            parentProps={props.parentProps}
                        />
                    </div>
                </React.Fragment>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    caseDetailData: state.caseDetailData,
    reloadCaseDetail: state.reloadCaseDetail,
    editContactInfo: state.editContactInfo,
    changeContactInfo: state.changeContactInfo,
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
    setChangeContactInfo,
    setCaseDetailData,
    setEditContactInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomUserInfo);
