import React from "react";
import "./SelectBulk.scss";
import { FiSearch, FiX, FiInfo } from "react-icons/fi";
import ErrorOrInfoComp from "../errorOrInfoComp/ErrorOrInfoComp";
import ListGreyCircle from "../../../assets/img/list-circle-grey.svg";
import FiSad from "../../../assets/img/fi-sad.svg";

const SelectBulk = ({
    onChangeSearchBulk,
    onCheckAll,
    onClearAll,
    bulkList,
    onChangeSingleSelect,
    isChecked,
    searchBulk,
    isCheckAll,
    title,
    isTitleOptional,
    selectedBulk,
    removeSingleBulk,
    selectedTitle,
    icon,
    searchTitle,
    withOutSelectAll,
    errorText,
    isDisabled,
    idx = 0,
    _className,
    ...props
}) => {
    return (
        <div className={`${_className} option-select-bulk-wrapper ${isDisabled ? "option-select-bulk-wrapper-disabled" : ""}`}>
            <div className={`option-select-bulk-wrapper-50`}>
                <label>
                    <b>{title}</b>{isTitleOptional ? <span> (optional)</span> : ""}

                    {/* {selectedBulk.length > 0 &&
                        <b className="clear-bulk" onClick={onClearAll}>
                            <FiX />
                            Clear All
                        </b>
                    } */}
                </label>

                <div className={`selection-bulk-wrapper custom-checkbox ${errorText === "" ? "" : "border-red"}`}>
                    <div className="search-box-wrapper">
                        <input
                            type="text"
                            placeholder={searchTitle}
                            onChange={(e) => onChangeSearchBulk(e)}
                            value={searchBulk}
                            disabled={isDisabled}
                        />

                        <div id="search-icon">
                            <FiSearch size="20" color="#191919" />
                        </div>
                    </div>


                    <div className={`overflow-select-bulk ${bulkList.length === 0 ? "no-overflow" : ""}`}>
                        {(searchBulk === "" && !withOutSelectAll) &&
                            <div className={`select-content ${bulkList.length === selectedBulk.length ? "custom-checkbox" : "custom-checkbox-stripe"} custom-checkbox-min checkbox-all`}>
                                <input disabled={isDisabled} type="checkbox" id={`select-all-bulk-${idx}`} onChange={(e) => onCheckAll(e)} checked={isCheckAll} />
                                <label htmlFor={`select-all-bulk-${idx}`}>Select All</label>
                            </div>
                        }
                        {bulkList.length > 0 ?
                            bulkList.map((value, index) => {
                                return (
                                    <div className="select-content" key={`topic-${index}`}>
                                        <input
                                            type="checkbox"
                                            id={value.id + `-${idx}`}
                                            onChange={(e) => { onChangeSingleSelect(e, value) }}
                                            checked={isChecked(value.id)}
                                            disabled={isDisabled}
                                        />
                                        <label htmlFor={value.id + `-${idx}`}>{value.name}</label>
                                    </div>
                                )
                            })
                            :
                            <div className="select-content not-found">
                                <img src={FiSad} alt="" />
                                <br />
                                No results found. Please try a different keyword.
                            </div>
                        }
                    </div>
                </div>

                {(errorText && errorText !== "") &&
                    <ErrorOrInfoComp
                        text={errorText}
                        _className={"font-red"}
                        icon={<FiInfo />}
                    />
                }
            </div>

            <div className="option-select-bulk-wrapper-50">
                <label>
                    <b>Selected</b> <span>({selectedBulk.length})</span>

                    {selectedBulk.length > 0 &&
                        <b className="clear-bulk" onClick={onClearAll}>
                            <FiX />
                            Clear All
                        </b>
                    }
                </label>

                <div className="select-bulk-wrapper">
                    <div className="selected-bulk-container">
                        {
                            selectedBulk.length > 0 ?
                                selectedBulk.map((value, index) => {
                                    return (
                                        <div className="topic-list-content" key={`selected-bulk-${index}`}>
                                            <b>{value.name}</b>

                                            <div className="remove-bulk" onClick={() => removeSingleBulk(value)}>
                                                <FiX />
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="no-selected-bulk">
                                    <img src={icon ? icon : ListGreyCircle} alt="" />
                                    <p className="font-root-medium">{selectedTitle}</p>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectBulk;