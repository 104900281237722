import React, { useState, useEffect } from 'react';
import './ChatRoomHeader.scss';
import Helper from '../../../helper/HelperChat';
import { printRoomListName } from '../../../helper/HelperGeneral';
import { taptalk } from '@taptalk.io/web-sdk';
// import ChatRoomHeaderInfoModal from './chatRoomHeaderInfoModal/ChatRoomHeaderInfoModal';
import { connect } from 'react-redux';
import iconTrash from "../../../assets/img/icon-trash-white.svg";
import iconTrashGray from "../../../assets/img/icon-trash-gray.svg";
import iconTrashOrange from "../../../assets/img/icon-trash-orange.svg";
import iconReplaceAvatar from "../../../assets/img/replace-avatar.svg";
import { FiInfo, FiXCircle, FiCheckCircle, FiAlertTriangle, FiRefreshCcw, FiChevronDown } from 'react-icons/fi';
import { doToast, checkClickOutside } from "../../../helper/HelperGeneral";
import CaseService from '../../../services/chat/caseServices';
import PopupRemove from "../../reuseableComponent/popupRemove/PopupRemove";
import ChatRoomHandoverCaseModal from "./chatRoomHandoverCaseModal/ChatRoomHandoverCaseModal";
import { setActiveRoom } from '../../../redux/actions/reduxActionActiveRoom';
import { setActiveCasePanel } from '../../../redux/actions/reduxActionActiveCasePanel';
import { setCaseDetailData } from '../../../redux/actions/reduxActionCaseDetailData';
import { setActiveHandoverCase } from '../../../redux/actions/reduxActionActiveHandoverCase';
import { setSubmitHandoverCase } from '../../../redux/actions/reduxActionSubmitHandoverCase';
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import mixpanel from "mixpanel-browser";
// import ClosingMessageServices from "../../../services/newServices/ClosingMessageServices";
import { TAPLIVE_MEDIUM, BLOCKING_REASON, TAPLIVE_MEDIUM_LOGO } from '../../../constants/taplive';
import MarkAsJunk from '../markAsJunk/MarkAsJunk';
// import { Tooltip } from 'reactstrap';
import ButtonTooltipDropdown from '../../reuseableComponent/buttonTooltipDropdown/ButtonTooltipDropdown';

const MESSAGE_TYPE = {
  CASE_CREATED: 3005,
  CASE_CLOSED: 3001,
  CASE_REOPEN: 3002,
  CASE_RATING_BUTTON: 3003,
  CASE_RATING_BUTTON_DISABLED: 3004,
  CASE_UPDATE_AGENT_CHANGE: 3006,
  CASE_UPDATE_DETAIL_UPDATE: 3007
}

const CLOCK_STATE = {
  isOff: "off",
  isStarted: "started",
  isPaused: "paused"
}

var ChatRoomHeader = (props) => {
  let [isActiveClosingMessage, setIsActiveClosingMessage] = useState(false);
  let [isWaitingFetchClosingMessage, setIsWaitingFetchClosingMessage] = useState(false);
  let [isSendClosingMessage, setIsSendClosingMessage] = useState(false);
  let [isTyping, setIsTyping] = useState({});
  let [isTypingTimeout, setIsTypingTimeout] = useState(null);

  let [caseDetailHeader, setCaseDetailHeader] = useState(null);
  let [isLoadingResolvedCase, setIsloadingResolvedCase] = useState(false);

  let [showModalResolved, setShowModalResolved] = useState(false);

  let [showModalMarkAsJunk, setShowModalMarkAsJunk] = useState(false);
  let [showModalUnmarkAsJunk, setShowModalUnmarkAsJunk] = useState(false);
  let [isLoadingMarkCase, setIsLoadingMarkCase] = useState(false);
  let [isSetMarkAsResolved, setIsSetMarkAsResolved] = useState(false);

  let [isShowButtonTooltip, setIsShowButtonTooltip] = useState(false);

  let toggleTooltip = (show = true) => {
    setIsShowButtonTooltip(show ? !isShowButtonTooltip : show);
  }

  useEffect(() => {
    setIsWaitingFetchClosingMessage(false);
    function closeMarkCaseDropdown(event) {
      let path = event.path || (event.composedPath && event.composedPath());

      if (path) {
        if (checkClickOutside(path, "tooltip-dropdown-button")) {
          setIsShowButtonTooltip(false);
        }
      }
    }

    window.addEventListener("click", closeMarkCaseDropdown);

    return function cleanunpListener() {
      window.removeEventListener("click", closeMarkCaseDropdown)
    }
  }, [])

  useEffect(() => {
    let _changeContactInfo = props.changeContactInfo;
    let _caseDetailHeader = { ...caseDetailHeader };

    if (_changeContactInfo.state) {
      _caseDetailHeader.userAlias = _changeContactInfo.alias;
      _caseDetailHeader.userFullName = _changeContactInfo.fullname;
      _caseDetailHeader.userEmail = _changeContactInfo.email;
      _caseDetailHeader.userPhone = _changeContactInfo.phone;
      setCaseDetailHeader(_caseDetailHeader);
    }
  }, [props.changeContactInfo])

  useEffect(() => {
    setIsTyping({});
    clearTimeout(isTypingTimeout);
  }, [props.activeRoom]);

  useEffect(() => {
    if (props.listenerStartTypingProps !== null) {
      if (props.activeRoom.roomID === props.listenerStartTypingProps.roomID) {
        let _isTyping = { ...isTyping };

        _isTyping[props.listenerStartTypingProps.user.userID] = props.listenerStartTypingProps.user

        setIsTyping(_isTyping);

        clearTimeout(isTypingTimeout);

        setIsTypingTimeout(() => setTimeout(function () {
          setIsTyping({});
        }, 10000));
      }
    }
  }, [props.listenerStartTypingProps])

  useEffect(() => {
    if (props.listenerStopTypingProps !== null) {
      if (props.activeRoom.roomID === props.listenerStopTypingProps.roomID) {
        clearTimeout(isTypingTimeout);

        let _isTypingStop = { ...isTyping };

        delete _isTypingStop[props.listenerStopTypingProps.user.userID];

        // setIsTyping(isTyping => isTyping.filter(value => value.userID !== props.listenerStopTypingProps.user.userID));
        setIsTyping(_isTypingStop);
      }
    }
  }, [props.listenerStopTypingProps])

  useEffect(() => {
    let listenerNewMessage = props.messageListenerNewMessageProps === null ? null : props.messageListenerNewMessageProps;

    let setNewCaseDetail = () => {
      let _caseDetailHeader = { ...caseDetailHeader };
      let currentTaptalkRoom = { ..._caseDetailHeader }.tapTalkRoom;

      _caseDetailHeader = {};
      _caseDetailHeader = listenerNewMessage.data;
      _caseDetailHeader.tapTalkRoom = {};

      if (!listenerNewMessage.isHidden) {
        _caseDetailHeader.tapTalkRoom.lastMessage = listenerNewMessage;
        _caseDetailHeader.tapTalkRoom.unreadCount = 0;

      } else {
        _caseDetailHeader.tapTalkRoom = currentTaptalkRoom;
      }

      return _caseDetailHeader;
    }

    if (props.activeRoom !== null && caseDetailHeader !== null) {

      if (listenerNewMessage !== null) {

        if (props.activeRoom.roomID === listenerNewMessage.room.roomID) {

          //case close or update case detail
          if (listenerNewMessage.type === MESSAGE_TYPE.CASE_CLOSED || listenerNewMessage.type === MESSAGE_TYPE.CASE_UPDATE_AGENT_CHANGE || listenerNewMessage.type === MESSAGE_TYPE.CASE_UPDATE_DETAIL_UPDATE) {
            setCaseDetailHeader(setNewCaseDetail());
          }
          //case close or update case detail
        }

      }

    }
  }, [props.messageListenerNewMessageProps]);

  useEffect(() => {
    setCaseDetailHeader(props.caseDetailValProps);
  }, [props.caseDetailValProps])

  let generateIconOmnichannel = (caseDetail) => {
    return TAPLIVE_MEDIUM[caseDetail.medium] ? TAPLIVE_MEDIUM_LOGO[caseDetail.medium] : "";
  }

  let checkReplyHoursExceeded = () => {
    return props.caseDetailData
      && props.caseDetailData.blockingReason === BLOCKING_REASON.reply_hours_exceeded.blockingReason
  }

  // let goToSolveCaseClick = () => {
  //   // props.setActiveCasePanel('solved');
  //   props.setActiveRoom(null);
  // }

  let actionCaseClose = () => {
    setIsloadingResolvedCase(true);

    let caseID = props.activeRoom.xcRoomID.split(':')[1];
    let _caseDetailVal = { ...props.caseDetailData };

    let data = {
      id: parseInt(caseID),
      sendClosingMessage: (checkReplyHoursExceeded() || _caseDetailVal.case.isJunk) ? false : isSendClosingMessage
    }

    CaseService.postCloseCase(props.parentProps.match.params.orgID, data, (response) => {
      setIsloadingResolvedCase(false);
      if (response.dataResult.error.code === "") {
        _caseDetailVal.case.closedTime = new Date().valueOf();
        _caseDetailVal.case.isClosed = true;
        props.setCaseDetailData(_caseDetailVal);

        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
          mixpanel.track(
            "[Action] Mark Resolved",
            {
              userFullName: props.myAgentData.account.fullName,
              userID: props.myAgentData.account.id,
              organizationID: props.parentProps.match.params.orgID.split("-")[0]
            }
          );
        }

        setShowModalResolved(false);
        doToast(response.dataResult.data.message);
      } else {
        doToast(response.dataResult.error.message, "fail");
      }
    });
  }

  let markCase = (markAsJunk = true) => {
    setIsLoadingMarkCase(true);

    let caseID = props.activeRoom.xcRoomID.split(':')[1];
    let _caseDetailVal = { ...props.caseDetailData };

    let data = {
      id: parseInt(caseID),
      createdTime: caseDetailHeader.createdTime,
      isJunk: markAsJunk
    }

    CaseService.updateJunkStatus(props.parentProps.match.params.orgID, data, (response) => {
      setIsLoadingMarkCase(false);

      if (response.dataResult.error.code === "") {
        _caseDetailVal.case.isJunk = markAsJunk;
        props.setCaseDetailData(_caseDetailVal);
        if (isSetMarkAsResolved) {
          actionCaseClose()
        }

        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
          mixpanel.track(
            "[Action] Update Junk Status",
            {
              userFullName: props.myAgentData.account.fullName,
              userID: props.myAgentData.account.id,
              organizationID: props.parentProps.match.params.orgID.split("-")[0]
            }
          );
        }

        setShowModalMarkAsJunk(false);
        setShowModalUnmarkAsJunk(false);
        setIsSetMarkAsResolved(false);
        doToast(response.dataResult.data.message);
      } else {
        doToast(response.dataResult.error.message, "fail");
      }
    })
  }

  let markAsResolved = () => {
    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track("[Button] Mark Resolved", {
        userFullName: props.myAgentData.account.fullName,
        userID: props.myAgentData.account.id,
        organizationID: props.parentProps.match.params.orgID.split("-")[0],
      });
    }

    // setIsWaitingFetchClosingMessage(true);

    setIsActiveClosingMessage(props.caseDetailData.canSendClosingMessage);

    if (props.caseDetailData.canSendClosingMessage && !checkReplyHoursExceeded()) {
      setIsSendClosingMessage(true);
    }

    setShowModalResolved(true);

    // ClosingMessageServices.getClosingMessage(
    //   props.parentProps.match.params.orgID,
    //   (response) => {
    //     setIsWaitingFetchClosingMessage(false);
    //     let dataResult = response.dataResult;

    //     if (dataResult.error.message === "") {
    //       setIsActiveClosingMessage(dataResult.data.closingMessage.isEnabled);

    //       if (dataResult.data.closingMessage.isEnabled && !checkReplyHoursExceeded()) {
    //         setIsSendClosingMessage(true);
    //       }
    //     }

    //     setShowModalResolved(true);
    //   }
    // );
  }

  let renderHeaderName = () => {
    let text = "";
    if (caseDetailHeader) {
      if (caseDetailHeader.alias) {
        text = caseDetailHeader.userAlias !== "" ? `${caseDetailHeader.userAlias} (${caseDetailHeader.userFullName})` : caseDetailHeader.userFullName;
      } else {
        text = caseDetailHeader.medium === "email" ? (caseDetailHeader.userFullName === "" ? caseDetailHeader.userEmail : caseDetailHeader.userFullName) : caseDetailHeader.userFullName;
      }
    }

    return text;
  }

  return (
    <div
      className={`
        chat-room-header-container 
        ${(props.isUserInfoShowProps || props.salesTalkInbox.isShow) ? "chat-room-header-show-detail" : ""}
      `}
    >
      {caseDetailHeader !== null &&
        <React.Fragment>
          <ChatRoomHandoverCaseModal
            {...props}
          />

          <div className="chat-room-header-avatar-wrapper">
            {props.activeRoom !== null &&
              (props.activeRoom.imageURL.thumbnail === "" ?
                <div className="user-avatar-name"
                  style={{ background: taptalk.getRandomColor(renderHeaderName()) }}
                >
                  <b>{Helper.renderUserAvatarWord(renderHeaderName())}</b>
                </div>
                :
                <img
                  className="user-avatar-name"
                  src={props.activeRoom.imageURL.thumbnail}
                  alt=""
                  onError={(e) => { e.target.onerror = null; e.target.src = iconReplaceAvatar; }}
                />
              )
            }

            {generateIconOmnichannel(caseDetailHeader) === "" ?
              ""
              :
              <img className="chat-rooom-omnichannel-header"
                src={generateIconOmnichannel(caseDetailHeader)}
                alt=""
              />
            }
          </div>

          <div className="chat-room-identifier">
            <p>
              <b>
                {/* {caseDetailHeader.userAlias ?
                        caseDetailHeader.userAlias !== "" ? `${caseDetailHeader.userAlias} (${caseDetailHeader.userFullName})` : caseDetailHeader.userFullName
                        :
                        (caseDetailHeader.userFullName !== "" ? caseDetailHeader.userFullName : "No Name")
                    } */}

                {printRoomListName(caseDetailHeader, props.inboxConfigsRedux.showContactPhone)}
              </b>
            </p>

            {/* {roomData && */}
            {(Object.keys(isTyping).length > 0 ?
              <p className="connectivity-status is-typing">
                is typing<span className="typing-dot-one">.</span><span className="typing-dot-two">.</span><span className="typing-dot-three">.</span>
              </p>
              // props.activeRoom.type === 1 ?
              //   "Typing..."
              //   :
              //   isTyping.length > 1 ?
              //     isTyping.length + " Peoples typing..."
              //     :
              //     isTyping[0].fullname + " is typing"
              // :
              // props.activeRoom.type === 2 ?
              //   <div className="connectivity-status">
              //       {`${roomData.participants.length} Member${roomData.participants.length > 1 ? 's' : ''}`}
              //   </div>
              :
              // ${isActive ? 'status-online' : ''}
              <p className={`connectivity-status`}>
                {caseDetailHeader.topicName} (#{caseDetailHeader.stringID})
              </p>
            )
            }
          </div>

          <div className="chatroom-header-right-button-wrapper">
            {props.myAgentData.account.id === caseDetailHeader.agentAccountID &&
              caseDetailHeader.isClosed &&
              !props.startConversation.conversation &&
              !props.startConversationBa.conversation &&
              ( // TODO (question): props.myAgentData.account.id === caseDetailHeader.agentJunkAccountID
                <div
                  className="mark-as-resolved-wrapper"
                // onClick={() => setShowModalUnmarkAsJunk(caseDetailHeader.isJunk)}
                >
                  {/* {!isWaitingFetchClosingMessage ? ( */}
                  <ButtonWithLoadingOrIcon
                    className="orange-button main-button-40 button-icon"
                    icon={caseDetailHeader.isJunk ? {
                      type: "img",
                      src: iconTrash,
                    } : {
                      type: "svg",
                      src: FiAlertTriangle,
                    }}
                    text={caseDetailHeader.isJunk ? `Mark as Not Junk` : `Mark as Junk`}
                    position="left"
                    onClickAction={() =>
                      caseDetailHeader.isJunk ?
                        setShowModalUnmarkAsJunk(true)
                        :
                        setShowModalMarkAsJunk(true)
                    }
                  />
                  {/* ) : (
                    <ButtonWithLoadingOrIcon
                      className="orange-button main-button-40 button-icon"
                      text={caseDetailHeader.isJunk ? `Mark as Not Junk` : `Mark as Junk b`}
                      position="left"
                      isDisabled
                      isLoading
                      loadingColor="gray"
                      onClick={setShowModalmarkAsJunk(true)}
                    />
                  )} */}
                  {/* <button className="no-fill-button">
                            <div className="lds-ring">
                              <div /><div /><div /><div />
                            </div>
                          </button>
                        } */}
                </div>
              )}

            {props.myAgentData.account.id === caseDetailHeader.agentAccountID &&
              (props.clockState === CLOCK_STATE.isStarted || props.allowReplyOnAway) &&
              !caseDetailHeader.isClosed &&
              !props.startConversation.conversation &&
              !props.startConversationBa.conversation &&
              props.caseDetailData &&
              ( // TODO (question): props.myAgentData.account.id === caseDetailHeader.agentJunkAccountID
                <ButtonTooltipDropdown
                  icon={{
                    type: "svg",
                    src: FiChevronDown,
                  }}
                  isLoading={isWaitingFetchClosingMessage}
                  buttonText="Case Actions"
                  mainProps={props}
                  isShow={isShowButtonTooltip}
                  toggle={toggleTooltip}
                  _className="mark-as-resolved-wrapper"
                >
                  <ul>
                    <li
                      onClick={() => {
                        toggleTooltip()
                        markAsResolved()
                      }}
                    >
                      <FiCheckCircle />
                      <b>Mark as Resolved</b>
                    </li>
                    <li
                      onClick={() => {
                        toggleTooltip()
                        if (caseDetailHeader.isJunk) setShowModalUnmarkAsJunk(true)
                        else setShowModalMarkAsJunk(true)
                      }}
                      onMouseOver={() => {
                        if (document.getElementById('unmark-as-junk-icon'))
                          document.getElementById('unmark-as-junk-icon').src = iconTrashOrange;
                      }}
                      onMouseOut={() => {
                        if (document.getElementById('unmark-as-junk-icon'))
                          document.getElementById('unmark-as-junk-icon').src = iconTrashGray;
                      }}
                    >
                      {
                        caseDetailHeader.isJunk ?
                          <React.Fragment>
                            <img
                              src={iconTrashGray} alt=""
                              // onMouseOver={e => e.currentTarget.src = iconTrashOrange}
                              id="unmark-as-junk-icon"
                            />
                            <b>Mark as Not Junk</b>
                          </React.Fragment>
                          :
                          <React.Fragment>
                            <FiAlertTriangle />
                            <b>Mark as Junk</b>
                          </React.Fragment>
                      }
                    </li>
                    {props.allowHandover &&
                      <li
                        onClick={() => {
                          toggleTooltip();
                          props.setActiveHandoverCase({ show: true, topicID: false });
                        }}
                      >
                        <FiRefreshCcw />
                        <b>Handover Case</b>
                      </li>
                    }
                  </ul>
                </ButtonTooltipDropdown>
              )}

            <div className="open-detail-button" onClick={() => {
              if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                  `[Action] ${props.isUserInfoShowProps ? "Hide" : "Show"} Details`,
                  {
                    userFullName: props.myAgentData.account.fullName,
                    userID: props.myAgentData.account.id,
                    organizationID: props.parentProps.match.params.orgID.split("-")[0]
                  }
                );
              }

              props.toggleIsUserInfoShowActionProps()
            }}>
              {props.isUserInfoShowProps ?
                <React.Fragment>
                  <FiXCircle />
                  <b>Hide Details</b>
                </React.Fragment>
                :
                <React.Fragment>
                  <FiInfo />
                  <b>Details</b>
                </React.Fragment>
              }
            </div>
          </div>

          {/* <ChatRoomHeaderInfoModal 
                chatRoomDataForHeaderProps={props.chatRoomDataForHeaderProps}
                // roomDataProps={roomData}
                roomDataProps={caseDetailHeader}
                toggleRoomInfoModalprops={toggleRoomInfoModal}
                toggleRoomInfoModalActionprops={toggleRoomInfoModalAction}
            /> */}
        </React.Fragment>
      }

      <PopupRemove
        removeTitle={`Mark as resolved?`}
        removeDescription={`You are marking this case as resolved. Once done you cannot send any more messages.`}
        onClickCancel={() => setShowModalResolved(false)}
        onClickRemove={actionCaseClose}
        additionalContent={
          (
            props.caseDetailData &&
            !props.caseDetailData.case.isJunk &&
            isActiveClosingMessage &&
            !checkReplyHoursExceeded()
          ) ?
            <div
              className="set-send-closing-message-wrapper"
              style={{
                display: "flex",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 14,
                lineHeight: "21px",
                letterSpacing: "-0.2px",
                color: "rgba(25, 25, 25, 0.6)",
                padding: "0 8px 8px",
                background: "rgba(25, 25, 25, 0.05)",
                borderRadius: 8,
                marginTop: 8,
              }}
            >
              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  id="set-send-closing-message"
                  onClick={() => setIsSendClosingMessage(!isSendClosingMessage)}
                  checked={isSendClosingMessage}
                  disabled={isLoadingResolvedCase}
                  readOnly
                />
                <label htmlFor="set-send-closing-message" />
              </div>
              <p style={{ marginTop: 7 }}>Send closing message</p>
            </div>
            :
            ""
        }
        withoutBorder
        loadingButtonGray
        waitForResponse={isLoadingResolvedCase}
        showModalProps={showModalResolved}
        submitText={'Confirm'}
      />

      <MarkAsJunk
        onClickCancel={() => {
          setShowModalMarkAsJunk(false);
          setIsSetMarkAsResolved(false);
        }}
        markCase={markCase}
        isSetMarkAsResolved={isSetMarkAsResolved}
        toggleMarkAsResolved={() => setIsSetMarkAsResolved(!isSetMarkAsResolved)}
        showModal={showModalMarkAsJunk}
        isLoadingMarkCase={isLoadingMarkCase}
        hideMarkAsResolve={props.caseDetailData ? props.caseDetailData.case.isClosed : true}
      />

      <MarkAsJunk
        onClickCancel={() => {
          setShowModalUnmarkAsJunk(false);
          setIsSetMarkAsResolved(false);
        }}
        markCase={() => markCase(false)}
        isSetMarkAsResolved={isSetMarkAsResolved}
        toggleMarkAsResolved={() => setIsSetMarkAsResolved(!isSetMarkAsResolved)}
        showModal={showModalUnmarkAsJunk}
        isLoadingMarkCase={isLoadingMarkCase}
        unmarkJunk
        hideMarkAsResolve={props.caseDetailData ? props.caseDetailData.case.isClosed : true}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  changeContactInfo: state.changeContactInfo,
  startConversation: state.startConversation,
  startConversationBa: state.startConversationBa,
  clockState: state.clockState,
  allowReplyOnAway: state.allowReplyOnAway,
  caseDetailData: state.caseDetailData,
  salesTalkInbox: state.salesTalkInbox,
  allowHandover: state.allowHandover,
  inboxConfigsRedux: state.inboxConfigsRedux,
  myAgentData: state.myAgentData
});

const mapDispatchToProps = {
  setActiveCasePanel,
  setActiveRoom,
  setCaseDetailData,
  setActiveHandoverCase,
  setSubmitHandoverCase
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomHeader);