import React, { useEffect, useState, useRef } from 'react';
import './MessageUserSummary.scss';
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ReportService from '../../../services/newServices/ReportService';
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import { doToast, checkID } from '../../../helper/HelperGeneral';
import HelperDate from '../../../helper/HelperDate';
import AdvancedDateFilter from '../../reuseableComponent/advancedDateFilter/AdvancedDateFilter';
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import mixpanel from "mixpanel-browser";
import OverviewTopicReportNoData from '../overview/overviewTopicReportNoData/OverviewTopicReportNoData';
import ApexCharts from 'apexcharts';
import { FiHelpCircle } from 'react-icons/fi';
import { Tooltip } from 'reactstrap';

const MESSAGE_USER_SUMMARY_DATE_SELECTION = {
  all_time: {
      val: "all_time",
      text: "All Time"
  },
  last_7: {
      val: "last_7",
      text: "Last 7 Days",
      from: new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date(),
  },
  last_30: {
      val: "last_30",
      text: "Last 30 Days"
  },
  this_month: {
      val: "this_month",
      text: "This Month"
  },
  last_month: {
      val: "last_month",
      text: "Last Month"
  },
  custom: {
      val: "custom",
      text: "Custom"
  }
}

const USER_MESSAGES_HEATMAP = "userMessagesHeatmap";
const DAILY_MESSAGES = "dailyMessages";
const ACTIVE_USERS = "activeUsers";
const NEW_USERS = "newUsers";
const MESSAGE_USER_SUMMARY_ITEMS = [
  USER_MESSAGES_HEATMAP,
  DAILY_MESSAGES,
  ACTIVE_USERS,
  NEW_USERS,
]

const MessageUserSummary = (props) => {
  let [orgID, setOrgID] = React.useState(false);

  let [isFetchingUserMessagesHeatmap, setIsFetchingUserMessagesHeatmap] = useState(false);
  let [isFetchingDailyMessages, setIsFetchingDailyMessages] = useState(false);
  let [isFetchingActiveUsers, setIsFetchingActiveUsers] = useState(false);
  let [isFetchingNewUsers, setIsFetchingNewUsers] = useState(false);

  let [userMessagesHeatmap, setUserMessagesHeatmap] = useState(null);
  let [dailyMessages, setDailyMessages] = useState(null);
  let [activeUsers, setActiveUsers] = useState(null);
  let [newUsers, setNewUsers] = useState(null);

  let [mappedUserMessagesHeatmap, setMappedUserMessagesHeatmap] = useState([]);
  let [mappedUserMessagesVsActiveUsers, setMappedUserMessagesVsActiveUsers] = useState({});
  let [mappedUserMessagesVsBotMessages, setMappedUserMessagesVsBotMessages] = useState({});
  let [mappedActiveUsersVsNewUsers, setMappedActiveUsersVsNewUsers] = useState({});
  let [maxHeatmapValue, setMaxHeatmapValue] = useState(1);
  let maxHeatmapVal = 1; // Prevent setting lower value for useState

  let [retrieveDataError, setRetrieveDataError] = React.useState({
    code: false,
    message: false
  });
  let [dateVal, setDateVal] = useState({
    from: new Date(new Date().setDate(new Date().getDate() - 7)),
    to: new Date()
  });
  let [filterDateForCalender, setFilterDateForCalender] = useState({
    from: undefined,
    to: undefined
  });
  let [filterBy, setFilterBy] = useState({
    text: "Last 7 Days",
    val: "last_7"
  });

  let [isShowDropdownDate, setIsShowDropdownDate] = useState(false);

  let [showTooltipMessagesVsUsersUserMessages, setShowTooltipMessagesVsUsersUserMessages] = useState(false);
  let [showTooltipMessagesVsUsersActiveUsers, setShowTooltipMessagesVsUsersActiveUsers] = useState(false);
  let [showTooltipUserVsBotUserMessages, setShowTooltipUserVsBotUserMessages] = useState(false);
  let [showTooltipUserVsBotBotMessages, setShowTooltipUserVsBotBotMessages] = useState(false);
  let [showTooltipActiveVsNewActiveUsers, setShowTooltipActiveVsNewActiveUsers] = useState(false);
  let [showTooltipActiveVsNewNewUsers, setShowTooltipActiveVsNewNewUsers] = useState(false);

  let userMessagesHeatmapChart = null;
  let messagesUsersChart = null;
  let userBotMessagesChart = null;
  let activeNewUsersChart = null;

  let orangeArray = ["#FF7E00", "#FF7E00", "#FF7E00", "#FF7E00", "#FF7E00", "#FF7E00", "#FF7E00", "#FF7E00", "#FF7E00", "#FF7E00"];
  let greenArray = ["#19C700", "#19C700", "#19C700", "#19C700", "#19C700", "#19C700", "#19C700", "#19C700", "#19C700", "#19C700"];
  // let blueArray = ["#3BAEFF", "#3BAEFF", "#3BAEFF", "#3BAEFF", "#3BAEFF", "#3BAEFF", "#3BAEFF", "#3BAEFF", "#3BAEFF", "#3BAEFF"];
  // let purpleArray = ["#9C88F1", "#9C88F1", "#9C88F1", "#9C88F1", "#9C88F1", "#9C88F1", "#9C88F1", "#9C88F1", "#9C88F1", "#9C88F1"];
  
  let messagesUsersChartRef = useRef();
  let userBotMessagesChartRef = useRef();
  let activeNewUsersChartRef = useRef();

  useEffect(() => {
    doMount();
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Page] Message and User Summary",
        {
          userFullName: myAgentData.account.fullName,
          userID: myAgentData.account.id,
          organizationID: props.match.params.orgID.split("-")[0]
        }
      );
    }
  }, []);

  useEffect(() => {
    if (orgID) {
      getMessageAndUserSummary();
    }
  }, [orgID, dateVal]);

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.success) {
        doToast(props.history.location.state.message);
      }
      else {
        doToast(props.history.location.state.message, 'fail');
      }
      window.history.pushState(null, '');
    }
  }, [props.history]);

  /**
   * USER MESSAGES HEATMAP
   */

  useEffect(() => {
    if (!userMessagesHeatmap) {
      return;
    }
    
    let _mappedUserMessagesHeatmap = [];
    userMessagesHeatmap.map((dailyItem) => {
      let mappedDailyData = [];
      dailyItem.items.map((hourlyItem) => {
        mappedDailyData.push({
          x: `${hourlyItem.hour < 10 ? '0' : ''}${hourlyItem.hour}`,
          y: hourlyItem.totalMessages
        });
        if (maxHeatmapVal < hourlyItem.totalMessages) {
          maxHeatmapVal = hourlyItem.totalMessages;
          setMaxHeatmapValue(hourlyItem.totalMessages);
        }
        return null;
      });

      _mappedUserMessagesHeatmap.unshift({
        name: dailyItem.day,
        data: mappedDailyData,
      });
      return null;
    });
    setMappedUserMessagesHeatmap(_mappedUserMessagesHeatmap);
    setIsFetchingUserMessagesHeatmap(false);
  }, [userMessagesHeatmap]);

  useEffect(() => {
    if (!mappedUserMessagesHeatmap || mappedUserMessagesHeatmap.length <= 0) {
      return;
    }
    
    let userMessagesHeatmapOptions = {
      chart: {
        type: "heatmap",
        height: 300,
        toolbar: {
          show: false
        },
      },
      series: mappedUserMessagesHeatmap,
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'right',
        fontFamily: "PTRootUI",
        fontSize: "12px",
        fontWeight: "400",
        labels: {
          colors: ["#191919", "#191919", "#191919", "#191919", "#191919"],
        },
        onItemClick: {
            toggleDataSeries: false,
        },
      },
      xaxis: {
        seriesName: "Hour (24-Hour Time)",
        title: {
          text: "Hour (24-Hour Time)",
          style: {
            fontFamily: "PTRootUI",
            fontSize: "12px",
            fontWeight: "400",
            color: "#19191966",
          },
        },
        labels: {
          style: {
            fontFamily: "PTRootUI",
            fontSize: "12px",
            colors: ["#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966"]
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            fontFamily: "PTRootUI",
            fontSize: "12px",
            colors: ["#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966"]
          }
        },
      },
      plotOptions: {
        heatmap: {
          enableShades: false,
          colorScale: {
            ranges: [
              {
                from: 0,
                to: Math.ceil(maxHeatmapValue / 5) - 1,
                color: "#FFF3F4",
                name: "0",
              },
              {
                from: Math.ceil(maxHeatmapValue / 5),
                to: Math.ceil(maxHeatmapValue / 5) * 2 - 1,
                color: "#FFE7EA",
                name: "" + Math.ceil(maxHeatmapValue / 5),
              },
              {
                from: Math.ceil(maxHeatmapValue / 5) * 2,
                to: Math.ceil(maxHeatmapValue / 5) * 3 - 1,
                color: "#FFC9D0",
                name: "" + (Math.ceil(maxHeatmapValue / 5) * 2),
              },
              {
                from: Math.ceil(maxHeatmapValue / 5) * 3,
                to: Math.ceil(maxHeatmapValue / 5) * 4 - 1,
                color: "#FFACB6",
                name: "" + (Math.ceil(maxHeatmapValue / 5) * 3),
              },
              {
                from: Math.ceil(maxHeatmapValue / 5) * 4,
                to: Number.MAX_SAFE_INTEGER,
                color: "#FF8191",
                name: "" + (Math.ceil(maxHeatmapValue / 5) * 4),
              },
            ]
          }
        }
      },
      states: {
        active: {
          filter: {
            type: "none"
          }
        },
        hover: {
          filter: {
            type: "none"
          }
        },
      },
      tooltip: {
        theme: "dark",
        style: {
          fontFamily: "PTRootUI",
          fontSize: "12px",
        },
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          return (
            `<div>` +
              `<b>${mappedUserMessagesHeatmap[seriesIndex].name}, ${data.x}:00</b>` +
              `<br/>` + 
              `<p>Total: ${data.y} message${data.y > 1 ? 's' : ''}</p>` +
            `</div>`
          )
        },
      }
    }

    let elChart = document.querySelector("#user-messages-heatmap");
    if (elChart) {
      userMessagesHeatmapChart = new ApexCharts(elChart, userMessagesHeatmapOptions);
      userMessagesHeatmapChart.render();
    }
  }, [mappedUserMessagesHeatmap]);

  /**
   * USER MESSAGES VS ACTIVE USERS
   */

  useEffect(() => {
    if (!dailyMessages || !activeUsers) {
      return;
    }

    let numberOfUserMessages = [];
    let numberOfActiveUsers = [];
    let xLabels = [];
    let axisLabelColors = [];

    dailyMessages.map((dailyMessage) => {
      numberOfUserMessages.push(dailyMessage.totalUserMessages);
      xLabels.push(HelperDate.formatToString(new Date(Date.parse(dailyMessage.date, "yyyy-MM-dd")), "d MMM"));
      axisLabelColors.push("#19191966");
      return null;
    });

    activeUsers.map((activeUser) => {
      numberOfActiveUsers.push(activeUser.totalUsers);
      return null;
    });

    let _mappedUserMessagesVsActiveUsers = { ...mappedUserMessagesVsActiveUsers};
    _mappedUserMessagesVsActiveUsers.numberOfUserMessages = numberOfUserMessages;
    _mappedUserMessagesVsActiveUsers.numberOfActiveUsers = numberOfActiveUsers;
    _mappedUserMessagesVsActiveUsers.xLabels = xLabels;
    _mappedUserMessagesVsActiveUsers.axisLabelColors = axisLabelColors;
    setMappedUserMessagesVsActiveUsers(_mappedUserMessagesVsActiveUsers);
    setIsFetchingDailyMessages(false);
    setIsFetchingActiveUsers(false);
  }, [dailyMessages, activeUsers]);

  useEffect(() => {
    if (!mappedUserMessagesVsActiveUsers ||
        !mappedUserMessagesVsActiveUsers.numberOfUserMessages ||
        !mappedUserMessagesVsActiveUsers.numberOfActiveUsers
    ) {
      return;
    }
    let messagesVsUsersOptions = {
      chart: {
        type: "line",
        height: 300,
        stacked: false,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#FFF2E5", "#19C700"],
      stroke: {
        width: [1, 2],
        curve: "smooth",
        colors: ["#FF7E00", "#19C700"],
      },
      markers: {
        size: 4,
        strokeWidth: 0,
      },
      series: [
        {
          name: "User Messages",
          type: "column",
          data: mappedUserMessagesVsActiveUsers.numberOfUserMessages
        },
        {
          name: "Active Users",
          type: "line",
          data: mappedUserMessagesVsActiveUsers.numberOfActiveUsers,
        },
      ],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          size: 4,
          fillColors: ["#FF7E00 ", "#19C700"],
        },
        fontFamily: "PTRootUI",
        fontSize: "12px",
        fontWeight: "400",
        labels: {
          colors: ["#191919", "#191919"],
        },
        onItemClick: {
            toggleDataSeries: false,
        },
      },
      xaxis: {
        categories: mappedUserMessagesVsActiveUsers.xLabels,
        labels: {
          style: {
            fontFamily: "PTRootUI",
            fontSize: "12px",              
            colors: mappedUserMessagesVsActiveUsers.axisLabelColors
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          seriesName: "Number of User Messages",
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Number of User Messages",
            style: {
              fontFamily: "PTRootUI",
              fontSize: "12px",
              fontWeight: "400",
              color: "#19191966",
            }
          },
          labels: {
            style: {
              fontFamily: "PTRootUI",
              fontSize: "12px",
              colors: orangeArray,
            },
          },
        },
        {
          opposite: true,
          seriesName: "Number of Active Users",
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Number of Active Users",
            style: {
              fontFamily: "PTRootUI",
              fontSize: "12px",
              fontWeight: "400",
              // color: "#19C700",
              color: "#19191966",
            }
          },
          labels: {
            style: {
              fontFamily: "PTRootUI",
              fontSize: "12px",
              colors: greenArray,
            }
          },
        }
      ],
      plotOptions: {
        bar: {
          borderRadius: 4,
        }
      },
      states: {
        active: {
          filter: {
            type: "none"
          }
        },
        hover: {
          filter: {
            type: "none"
          }
        },
      },
      tooltip: {
        theme: "dark",
        style: {
          fontFamily: "PTRootUI",
          fontSize: "12px",
        },
        marker: {
          fillColors: ["#FF7E00 ", "#19C700"],
        }
      }
    }

    let elChart = document.querySelector("#messages-users-chart");
    if (elChart) {
      messagesUsersChart = new ApexCharts(elChart, messagesVsUsersOptions);
      messagesUsersChart.render();
    }
  }, [mappedUserMessagesVsActiveUsers]);

  /**
   * USER MESSAGES VS BOT MESSAGES
   */

  useEffect(() => {
    if (!dailyMessages) {
      return;
    }

    let numberOfUserMessages = [];
    let numberOfBotMessages = [];
    let xLabels = [];
    let axisLabelColors = [];

    dailyMessages.map((dailyMessage) => {
      numberOfUserMessages.push(dailyMessage.totalUserMessages);
      numberOfBotMessages.push(dailyMessage.totalChatbotMessages);
      xLabels.push(HelperDate.formatToString(new Date(Date.parse(dailyMessage.date, "yyyy-MM-dd")), "d MMM"));
      axisLabelColors.push("#19191966");
      return null;
    });

    let _mappedUserMessagesVsBotMessages = { ...mappedUserMessagesVsBotMessages};
    _mappedUserMessagesVsBotMessages.numberOfUserMessages = numberOfUserMessages;
    _mappedUserMessagesVsBotMessages.numberOfBotMessages = numberOfBotMessages;
    _mappedUserMessagesVsBotMessages.xLabels = xLabels;
    _mappedUserMessagesVsBotMessages.axisLabelColors = axisLabelColors;
    setMappedUserMessagesVsBotMessages(_mappedUserMessagesVsBotMessages);
    setIsFetchingDailyMessages(false);
  }, [dailyMessages]);

  useEffect(() => {
    if (!mappedUserMessagesVsBotMessages ||
        !mappedUserMessagesVsBotMessages.numberOfUserMessages ||
        !mappedUserMessagesVsBotMessages.numberOfBotMessages
    ) {
      return;
    }
    let usersVsBotsOptions = {
      chart: {
        type: "line",
        height: 300,
        stacked: false,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#FF7E00", "#9C88F1"],
      stroke: {
        width: 2,
        curve: "smooth",
        colors: ["#FF7E00", "#9C88F1"],
      },
      markers: {
        size: 4,
        strokeWidth: 0,
      },
      series: [
        {
          name: "User Messages",
          type: "line",
          data: mappedUserMessagesVsBotMessages.numberOfUserMessages
        },
        {
          name: "Bot Messages",
          type: "line",
          data: mappedUserMessagesVsBotMessages.numberOfBotMessages,
        },
      ],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          size: 4,
        },
        fontFamily: "PTRootUI",
        fontSize: "12px",
        fontWeight: "400",
        labels: {
          colors: ["#191919", "#191919"],
        },
        onItemClick: {
            toggleDataSeries: false,
        },
      },
      xaxis: {
        categories: mappedUserMessagesVsBotMessages.xLabels,
        labels: {
          style: {
            fontFamily: "PTRootUI",
            fontSize: "12px",              
            colors: mappedUserMessagesVsBotMessages.axisLabelColors
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          // seriesName: "Number of User Messages",
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
          },
          // title: {
          //   text: "Number of User Messages",
          //   style: {
          //     fontFamily: "PTRootUI",
          //     fontSize: "12px",
          //     fontWeight: "400",
          //     // color: "#19C700",
          //     color: "#19191966",
          //   }
          // },
          labels: {
            style: {
              fontFamily: "PTRootUI",
              fontSize: "12px",
              backgroundColor: "#FFF2E5",
              borderRadius: "8px",
              // colors: orangeArray,
              colors: mappedUserMessagesVsBotMessages.axisLabelColors,
            },
          },
        },
        // {
        //   opposite: true,
        //   seriesName: "Number of Bot Messages",
        //   axisTicks: {
        //     show: true
        //   },
        //   axisBorder: {
        //     show: true,
        //   },
        //   title: {
        //     text: "Number of Bot Messages",
        //     style: {
        //       fontFamily: "PTRootUI",
        //       fontSize: "12px",
        //       fontWeight: "400",
        //       // color: "#3BAEFF",
        //       color: "#19191966",
        //     }
        //   },
        //   labels: {
        //     style: {
        //       fontFamily: "PTRootUI",
        //       fontSize: "12px",
        //       colors: purpleArray,
        //     }
        //   },
        // },
      ],
      tooltip: {
        theme: "dark",
        style: {
          fontFamily: "PTRootUI",
          fontSize: "12px",
        },
      }
    }

    let elChart = document.querySelector("#user-bot-chart");
    if (elChart) {
      messagesUsersChart = new ApexCharts(elChart, usersVsBotsOptions);
      messagesUsersChart.render();
    }
  }, [mappedUserMessagesVsBotMessages]);

  /**
   * ACTIVE USERS VS NEW USERS
   */

  useEffect(() => {
    if (!activeUsers || !newUsers) {
      return;
    }

    let numberOfActiveUsers = [];
    let numberOfNewUsers = [];
    let xLabels = [];
    let axisLabelColors = [];

    activeUsers.map((activeUser) => {
      numberOfActiveUsers.push(activeUser.totalUsers);
      xLabels.push(HelperDate.formatToString(new Date(Date.parse(activeUser.date, "yyyy-MM-dd")), "d MMM"));
      axisLabelColors.push("#19191966");
      return null;
    });

    newUsers.map((newUser) => {
      numberOfNewUsers.push(newUser.totalUsers);
      return null;
    });

    let _mappedActiveUsersVsNewUsers = { ...mappedActiveUsersVsNewUsers};
    _mappedActiveUsersVsNewUsers.numberOfActiveUsers = numberOfActiveUsers;
    _mappedActiveUsersVsNewUsers.numberOfNewUsers = numberOfNewUsers;
    _mappedActiveUsersVsNewUsers.xLabels = xLabels;
    _mappedActiveUsersVsNewUsers.axisLabelColors = axisLabelColors;
    setMappedActiveUsersVsNewUsers(_mappedActiveUsersVsNewUsers);
    setIsFetchingActiveUsers(false);
    setIsFetchingNewUsers(false);
  }, [activeUsers, newUsers]);

  useEffect(() => {
    if (!mappedActiveUsersVsNewUsers ||
        !mappedActiveUsersVsNewUsers.numberOfActiveUsers ||
        !mappedActiveUsersVsNewUsers.numberOfNewUsers
    ) {
      return;
    }
    let activeVsNewOptions = {
      chart: {
        type: "line",
        height: 300,
        stacked: false,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#EBF7FF", "#19C700"],
      stroke: {
        width: [1, 2],
        curve: "smooth",
        colors: ["#3BAEFF ", "#19C700"],
      },
      markers: {
        size: 4,
        strokeWidth: 0,
      },
      series: [
        {
          name: "New Users",
          type: "column",
          data: mappedActiveUsersVsNewUsers.numberOfNewUsers
        },
        {
          name: "Active Users",
          type: "line",
          data: mappedActiveUsersVsNewUsers.numberOfActiveUsers,
        },
      ],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          size: 4,
          fillColors: ["#3BAEFF ", "#19C700"],
        },
        fontFamily: "PTRootUI",
        fontSize: "12px",
        fontWeight: "400",
        labels: {
          colors: ["#191919", "#191919"],
        },
        onItemClick: {
            toggleDataSeries: false,
        },
      },
      xaxis: {
        categories: mappedActiveUsersVsNewUsers.xLabels,
        labels: {
          style: {
            fontFamily: "PTRootUI",
            fontSize: "12px",              
            colors: mappedActiveUsersVsNewUsers.axisLabelColors
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          // seriesName: "Number of New Users",
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
          },
          // title: {
          //   text: "Number of New Users",
          //   style: {
          //     fontFamily: "PTRootUI",
          //     fontSize: "12px",
          //     fontWeight: "400",
          //     color: "#19191966",
          //   }
          // },
          labels: {
            style: {
              fontFamily: "PTRootUI",
              fontSize: "12px",
              // colors: blueArray,
              colors: mappedActiveUsersVsNewUsers.axisLabelColors,
            },
          },
        },
        // {
        //   opposite: true,
        //   seriesName: "Number of Active Users",
        //   axisTicks: {
        //     show: true
        //   },
        //   axisBorder: {
        //     show: true,
        //   },
        //   title: {
        //     text: "Number of Active Users",
        //     style: {
        //       fontFamily: "PTRootUI",
        //       fontSize: "12px",
        //       fontWeight: "400",
        //       color: "#19191966",
        //     }
        //   },
        //   labels: {
        //     style: {
        //       fontFamily: "PTRootUI",
        //       fontSize: "12px",
        //       colors: greenArray,
        //     }
        //   },
        // },
      ],
      plotOptions: {
        bar: {
          borderRadius: 4,
        }
      },
      states: {
        active: {
          filter: {
            type: "none"
          }
        },
        hover: {
          filter: {
            type: "none"
          }
        },
      },
      tooltip: {
        theme: "dark",
        style: {
          fontFamily: "PTRootUI",
          fontSize: "12px",
        },
        marker: {
          fillColors: ["#3BAEFF ", "#19C700"],
        }
      }
    }
    let elChart = document.querySelector("#active-new-chart");
    if (elChart) {
      activeNewUsersChart = new ApexCharts(elChart, activeVsNewOptions);
      activeNewUsersChart.render();
    }
  }, [mappedActiveUsersVsNewUsers]);

  const doMount = async () => {
    const paramID = checkID(props.match);

    if (paramID) {
      setOrgID(paramID);
    }
    else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/organization/members`);
        }
        else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  const toggleTooltipMessagesVsUsersUserMessages = () => {
    setShowTooltipMessagesVsUsersUserMessages(!showTooltipMessagesVsUsersUserMessages);
  }

  const toggleTooltipMessagesVsUsersActiveUsers = () => {
    setShowTooltipMessagesVsUsersActiveUsers(!showTooltipMessagesVsUsersActiveUsers);
  }

  const toggleTooltipUserVsBotUserMessages = () => {
    setShowTooltipUserVsBotUserMessages(!showTooltipUserVsBotUserMessages);
  }

  const toggleTooltipUserVsBotBotMessages = () => {
    setShowTooltipUserVsBotBotMessages(!showTooltipUserVsBotBotMessages);
  }

  const toggleTooltipActiveVsNewActiveUsers = () => {
    setShowTooltipActiveVsNewActiveUsers(!showTooltipActiveVsNewActiveUsers);
  }

  const toggleTooltipActiveVsNewNewUsers = () => {
    setShowTooltipActiveVsNewNewUsers(!showTooltipActiveVsNewNewUsers);
  }

  const getMessageAndUserSummary = () => {
    setIsFetchingUserMessagesHeatmap(true);
    setIsFetchingDailyMessages(true);
    setIsFetchingActiveUsers(true);
    setIsFetchingNewUsers(true);

    setUserMessagesHeatmap(null);
    setDailyMessages(null);
    setActiveUsers(null);
    setNewUsers(null);
    
    maxHeatmapVal = 1;
    setMaxHeatmapValue(1);

    if (userMessagesHeatmapChart) {
      userMessagesHeatmapChart.destroy();
    }
    if (messagesUsersChart) {
      messagesUsersChart.destroy();
    }
    if (userBotMessagesChart) {
      userBotMessagesChart.destroy();
    }
    if (activeNewUsersChart) {
      activeNewUsersChart.destroy();
    }

    MESSAGE_USER_SUMMARY_ITEMS.map((item, index) => {
      let data = {
        items: [item],
        startDate: HelperDate.formatToString(dateVal.from, "yyyy-MM-dd"),
        endDate: HelperDate.formatToString(dateVal.to, "yyyy-MM-dd"),
      }
      ReportService.getMessageAndUserSummary(orgID, data, (response) => {
        const dismissLoading = () => {
          if (item === USER_MESSAGES_HEATMAP) {
            setIsFetchingUserMessagesHeatmap(false);
          }
          else if (item === DAILY_MESSAGES) {
            setIsFetchingDailyMessages(false);
          }
          else if (item === ACTIVE_USERS) {
            setIsFetchingActiveUsers(false);
          }
          else if (item === NEW_USERS) {
            setIsFetchingNewUsers(false);
          }
        };
        let dataResult = response.dataResult;
        if (dataResult.error && dataResult.error.message) {
          if (dataResult.error.code !== "49901") {
            doToast(dataResult.error.message, "fail");
          }
          dismissLoading();

          let _retrieveDataError = { ...retrieveDataError };
          _retrieveDataError.message = dataResult.error.message;
          _retrieveDataError.code = dataResult.error.code;
          setRetrieveDataError(_retrieveDataError);
        }
        else if (!dataResult.data[item]) {
          doToast(`No data found for ${item}`, "fail");
          dismissLoading();
        }
        else {
          if (item === USER_MESSAGES_HEATMAP) {
            setUserMessagesHeatmap(dataResult.data[item]);
          }
          else if (item === DAILY_MESSAGES) {
            setDailyMessages(dataResult.data[item]);
          }
          else if (item === ACTIVE_USERS) {
            setActiveUsers(dataResult.data[item]);
          }
          else if (item === NEW_USERS) {
            setNewUsers(dataResult.data[item]);
          }
        }
      });
      return null;
    });
  };

  const handleMessagesUsersScroll = (scroll) => {
    userBotMessagesChartRef.current.scrollLeft = scroll.target.scrollLeft;
    activeNewUsersChartRef.current.scrollLeft = scroll.target.scrollLeft;
  }

  const handleUserBotScroll = (scroll) => {
    messagesUsersChartRef.current.scrollLeft = scroll.target.scrollLeft;
    activeNewUsersChartRef.current.scrollLeft = scroll.target.scrollLeft;
  }

  const handleActiveNewScroll = (scroll) => {
    messagesUsersChartRef.current.scrollLeft = scroll.target.scrollLeft;
    userBotMessagesChartRef.current.scrollLeft = scroll.target.scrollLeft;
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="overview-report-wrapper sectionWrap">
        <React.Fragment>
          <p className="overview-report-title">
            <b>Message & User Summary</b>
            {
              !retrieveDataError.code &&
              <AdvancedDateFilter
                toggleDropdownDate={() => setIsShowDropdownDate(!isShowDropdownDate)}
                isOpenDropdown={isShowDropdownDate}
                dateVal={dateVal}
                customDateSelection={MESSAGE_USER_SUMMARY_DATE_SELECTION}
                filterDateForCalender={filterDateForCalender}
                filterBy={filterBy}
                onClickDate={(date) => setDateVal(date)}
                onClickFilterBy={(filter) => setFilterBy(filter)}
                onClickCalendar={(date) => {
                  setFilterDateForCalender(date)
                }}
                ignoreAllTime
                isDisabled={isFetchingUserMessagesHeatmap}
                disabledDays={{
                  before: new Date(new Date().setDate(new Date().getDate() - 89)),
                  after: new Date()
                }}
                maxRange={90}
              />
            }
          </p>

          {
            <div className="overview-report-data-outer-wrapper">
              {
                (retrieveDataError.code === "49901" || (isFetchingUserMessagesHeatmap && isFetchingDailyMessages && isFetchingActiveUsers && isFetchingNewUsers))
                ?
                <RetrieveDataLoading
                  isLoading={isFetchingUserMessagesHeatmap && isFetchingDailyMessages && isFetchingActiveUsers && isFetchingNewUsers}
                  errorMessage={retrieveDataError.message}
                  errorCode={retrieveDataError.code}
                />
                :
                <div className="overview-report-data-wrapper">
                  <div className="summary-section-wrapper">
                    <b className="summary-title">User Messages Heatmap</b>
                    <p className="summary-subtitle">Showing activity for incoming messages per hour for selected date range.</p>
                    {
                      (isFetchingUserMessagesHeatmap || (retrieveDataError.code && retrieveDataError.code !== "49901")) ?
                      <RetrieveDataLoading
                        isLoading={isFetchingUserMessagesHeatmap}
                        errorMessage={retrieveDataError.message}
                        errorCode={retrieveDataError.code}
                      />
                      :
                      (mappedUserMessagesHeatmap && mappedUserMessagesHeatmap.length > 0)
                      ?
                      <div/* style={{ transform: "translateY(-32px)" }}*/>
                        <div className="heatmap-legend-wrapper">
                          <div className="heatmap-legend">
                            <div className="heatmap-legend-box color-1" />
                            <p>0</p>
                          </div>
                          <div className="heatmap-legend">
                            <div className="heatmap-legend-box color-2" />
                            <p>{Math.ceil(maxHeatmapValue / 5)}</p>
                          </div>
                          <div className="heatmap-legend">
                            <div className="heatmap-legend-box color-3" />
                            <p>{Math.ceil(maxHeatmapValue / 5) * 2}</p>
                          </div>
                          <div className="heatmap-legend">
                            <div className="heatmap-legend-box color-4" />
                            <p>{Math.ceil(maxHeatmapValue / 5) * 3}</p>
                          </div>
                          <div className="heatmap-legend">
                            <div className="heatmap-legend-box color-5" />
                            <p>{Math.ceil(maxHeatmapValue / 5) * 4}</p>
                          </div>
                        </div>
                        <div id="user-messages-heatmap" />
                      </div>
                      :
                      <OverviewTopicReportNoData noTitle _className="overview-topic-report-wrapper" />
                    }
                  </div>

                  {/* <div className={`section-separator${(mappedUserMessagesHeatmap && mappedUserMessagesHeatmap.length > 0) ? "-no-legend" : ""}`} /> */}
                  <div className="section-separator" />

                  <div className="summary-section-wrapper">
                    <b className="summary-title">User Messages vs Active Users</b>
                    {
                      (isFetchingDailyMessages || isFetchingActiveUsers || (retrieveDataError.code && retrieveDataError.code !== "49901")) ?
                      <RetrieveDataLoading
                        isLoading={isFetchingDailyMessages || isFetchingActiveUsers}
                        errorMessage={retrieveDataError.message}
                        errorCode={retrieveDataError.code}
                      />
                      :
                      (mappedUserMessagesVsActiveUsers && mappedUserMessagesVsActiveUsers.numberOfUserMessages && mappedUserMessagesVsActiveUsers.numberOfUserMessages.length > 0)
                      ?
                      <>
                        <div className="chart-legend-wrapper">
                          <div className="chart-legend-list">
                            <div className="chart-legend orange-dot"/>
                            <p>User Messages</p>
                            <FiHelpCircle id="tooltip-messages-vs-users-user-messages" className="chart-legend-tooltip-icon" alt="" />
                            <Tooltip
                              className="tooltip-chart-legend"
                              placement="bottom"
                              isOpen={showTooltipMessagesVsUsersUserMessages}
                              target="tooltip-messages-vs-users-user-messages"
                              toggle={toggleTooltipMessagesVsUsersUserMessages}
                              opacity={1.0}
                            >
                              Total number of messages<br/>sent by customers
                            </Tooltip>
                          </div>
                          <div className="chart-legend-list">
                            <div className="chart-legend green-dot"/>
                            <p>Active Users</p>
                            <FiHelpCircle id="tooltip-messages-vs-users-active-users" className="chart-legend-tooltip-icon" alt="" />
                            <Tooltip
                              className="tooltip-chart-legend"
                              placement="bottom"
                              isOpen={showTooltipMessagesVsUsersActiveUsers}
                              target="tooltip-messages-vs-users-active-users"
                              toggle={toggleTooltipMessagesVsUsersActiveUsers}
                              opacity={1.0}
                            >
                              Total number of<br/>active users
                            </Tooltip>
                          </div>
                        </div>
                        <div
                          id="messages-users-chart-wrapper"
                          style={{
                            overflowX: 
                              (mappedUserMessagesVsActiveUsers && mappedUserMessagesVsActiveUsers.numberOfUserMessages && mappedUserMessagesVsActiveUsers.numberOfUserMessages.length > 31)
                              ?
                              "scroll"
                              :
                              "hidden",
                            overflowY: "hidden",
                            // transform: "translateY(-24px)",
                          }}
                          onScroll={handleMessagesUsersScroll}
                          ref={messagesUsersChartRef}
                        >
                          <div
                            style={{
                              width: 
                                (mappedUserMessagesVsActiveUsers && mappedUserMessagesVsActiveUsers.numberOfUserMessages && mappedUserMessagesVsActiveUsers.numberOfUserMessages.length > 31)
                                ?
                                `${mappedUserMessagesVsActiveUsers.numberOfUserMessages.length * 100 + 60}px`
                                :
                                `100%`
                            }}
                          >
                            <div id="messages-users-chart" />
                          </div>
                        </div>
                      </>
                      :
                      <OverviewTopicReportNoData noTitle _className="overview-topic-report-wrapper" />
                    }
                  </div>

                  <div className="section-separator" />

                  <div className="summary-section-wrapper">
                    <b className="summary-title">User Messages vs Bot Messages</b>
                    {
                      (isFetchingDailyMessages || (retrieveDataError.code && retrieveDataError.code !== "49901")) ?
                      <RetrieveDataLoading
                        isLoading={isFetchingDailyMessages}
                        errorMessage={retrieveDataError.message}
                        errorCode={retrieveDataError.code}
                      />
                      :
                      (mappedUserMessagesVsBotMessages && mappedUserMessagesVsBotMessages.numberOfUserMessages && mappedUserMessagesVsBotMessages.numberOfUserMessages.length > 0)
                      ?
                      <>
                        <div className="chart-legend-wrapper">
                          <div className="chart-legend-list">
                            <div className="chart-legend orange-dot"/>
                            <p>User Messages</p>
                            <FiHelpCircle id="tooltip-user-vs-bot-user-messages" className="chart-legend-tooltip-icon" alt="" />
                            <Tooltip
                              className="tooltip-chart-legend"
                              placement="bottom"
                              isOpen={showTooltipUserVsBotUserMessages}
                              target="tooltip-user-vs-bot-user-messages"
                              toggle={toggleTooltipUserVsBotUserMessages}
                              opacity={1.0}
                            >
                              Number of messages<br/>sent by customers
                            </Tooltip>
                          </div>
                          <div className="chart-legend-list">
                            <div className="chart-legend purple-dot"/>
                            <p>Bot Messages</p>
                            <FiHelpCircle id="tooltip-user-vs-bot-bot-messages" className="chart-legend-tooltip-icon" alt="" />
                            <Tooltip
                              className="tooltip-chart-legend"
                              placement="bottom"
                              isOpen={showTooltipUserVsBotBotMessages}
                              target="tooltip-user-vs-bot-bot-messages"
                              toggle={toggleTooltipUserVsBotBotMessages}
                              opacity={1.0}
                            >
                              Number of messages<br/>sent by chatbots
                            </Tooltip>
                          </div>
                        </div>
                        <div 
                          id="user-bot-chart-wrapper"
                          style={{
                            overflowX: 
                              (mappedUserMessagesVsBotMessages && mappedUserMessagesVsBotMessages.numberOfUserMessages && mappedUserMessagesVsBotMessages.numberOfUserMessages.length > 31)
                              ?
                              "scroll"
                              :
                              "hidden",
                            overflowY: "hidden",
                            // transform: "translateY(-24px)",
                          }}
                          onScroll={handleUserBotScroll}
                          ref={userBotMessagesChartRef}
                        >
                          <div
                            style={{
                              width: 
                                (mappedUserMessagesVsBotMessages && mappedUserMessagesVsBotMessages.numberOfUserMessages && mappedUserMessagesVsBotMessages.numberOfUserMessages.length > 31)
                                ?
                                `${mappedUserMessagesVsBotMessages.numberOfUserMessages.length * 100 + 60}px`
                                :
                                `100%`
                            }}
                          >
                            <div id="user-bot-chart" />
                          </div>
                        </div>
                      </>
                      :
                      <OverviewTopicReportNoData noTitle _className="overview-topic-report-wrapper" />
                    }
                  </div>

                  <div className="section-separator" />

                  <div className="summary-section-wrapper">
                    <b className="summary-title">Active Users vs New Users</b>
                    {
                      (isFetchingActiveUsers || isFetchingNewUsers || (retrieveDataError.code && retrieveDataError.code !== "49901")) ?
                      <RetrieveDataLoading
                        isLoading={isFetchingActiveUsers || isFetchingNewUsers}
                        errorMessage={retrieveDataError.message}
                        errorCode={retrieveDataError.code}
                      />
                      :
                      (mappedActiveUsersVsNewUsers && mappedActiveUsersVsNewUsers.numberOfActiveUsers && mappedActiveUsersVsNewUsers.numberOfActiveUsers.length > 0)
                      ?
                      <>
                        <div className="chart-legend-wrapper">
                          <div className="chart-legend-list">
                            <div className="chart-legend green-dot"/>
                            <p>Active Users</p>
                            <FiHelpCircle id="tooltip-active-vs-new-active-users" className="chart-legend-tooltip-icon" alt="" />
                            <Tooltip
                              className="tooltip-chart-legend"
                              placement="bottom"
                              isOpen={showTooltipActiveVsNewActiveUsers}
                              target="tooltip-active-vs-new-active-users"
                              toggle={toggleTooltipActiveVsNewActiveUsers}
                              opacity={1.0}
                            >
                              Number of unique customers<br/>with chats in the selected period
                            </Tooltip>
                          </div>
                          <div className="chart-legend-list">
                            <div className="chart-legend blue-dot"/>
                            <p>New Users</p>
                            <FiHelpCircle id="tooltip-active-vs-new-new-users" className="chart-legend-tooltip-icon" alt="" />
                            <Tooltip
                              className="tooltip-chart-legend"
                              placement="bottom"
                              isOpen={showTooltipActiveVsNewNewUsers}
                              target="tooltip-active-vs-new-new-users"
                              toggle={toggleTooltipActiveVsNewNewUsers}
                              opacity={1.0}
                            >
                              Number of new contacts<br/>created in the selected period
                            </Tooltip>
                          </div>
                        </div>
                        <div 
                          id="active-new-chart-wrapper"
                          style={{
                            overflowX: 
                              (mappedActiveUsersVsNewUsers && mappedActiveUsersVsNewUsers.numberOfActiveUsers && mappedActiveUsersVsNewUsers.numberOfActiveUsers.length > 31)
                              ?
                              "scroll"
                              :
                              "hidden",
                            overflowY: "hidden",
                            // transform: "translateY(-24px)",
                          }}
                          onScroll={handleActiveNewScroll}
                          ref={activeNewUsersChartRef}
                        >
                          <div
                            style={{
                              width: 
                                (mappedActiveUsersVsNewUsers && mappedActiveUsersVsNewUsers.numberOfActiveUsers && mappedActiveUsersVsNewUsers.numberOfActiveUsers.length > 31)
                                ?
                                `${mappedActiveUsersVsNewUsers.numberOfActiveUsers.length * 100 + 60}px`
                                :
                                `100%`
                            }}
                          >
                            <div id="active-new-chart" />
                          </div>
                        </div>
                      </>
                      :
                      <OverviewTopicReportNoData noTitle _className="overview-topic-report-wrapper" />
                    }
                  </div>
                </div>
              }
            </div>
          }
        </React.Fragment>
      </div>
    </SectionWrap>
  );
};

export default MessageUserSummary;
