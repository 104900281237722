const INITIAL_STATE = false;

let reduxReducerActiveSharedMedia = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_SHARED_MEDIA':
        return action.activeSharedMedia;
      case 'CLEAR_ACTIVE_SHARED_MEDIA':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerActiveSharedMedia;