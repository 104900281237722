import React from 'react';
import './PopupTextField.scss';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ButtonWithLoadingOrIcon from '../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import ErrorOrInfoComp from '../errorOrInfoComp/ErrorOrInfoComp';
import { FiAlertCircle } from 'react-icons/fi';

let PopupTextField = (props) => {
  let {
    isOpen,
    toggle,
    className,
    title,
    titleImage,
    description,
    placeholder,
    text,
    setText,
    onTextChanged,
    textError,
    setTextError,
    buttonAction,
    characterLimit,
    leftButtonText,
    rightButtonText,
    children,
    isLoading
  } = props;
 
  const onChangeText = (e) => {
    let _text = e.target.value;
    setText(_text);

    if (characterLimit && _text.length > characterLimit) {
      setTextError("Characters exceed limit");
    }
    else {
      setTextError("");
    }

    if (onTextChanged) {
      onTextChanged(e);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={`modal-text-field ${className}`}
      >
        <ModalBody>
          <div className="form-content-wrapper text-field-wrapper">
            <label>
              <b className="modal-title">{titleImage}{title}</b>
              {
                characterLimit &&
                <span className={`input-counter grey-font ${text.length > characterLimit ? "red-text" : ""}`}>
                  {text.length}/{characterLimit} characters
                </span>
              }
            </label>
            <input
              type="text"
              className={`${textError ? "border-red" : ""}`}
              onChange={(e) => onChangeText(e)}
              value={text}
              placeholder={placeholder ? placeholder : ""}
              disabled={isLoading}
            />
            {
              textError &&
              <ErrorOrInfoComp
                text={textError}
                _className={"font-red"}
                icon={<FiAlertCircle />}
              />
            }
          </div>
          {
            description &&
            <p className="modal-description" dangerouslySetInnerHTML={{__html: description}} />
          }
        </ModalBody>

        <ModalFooter>
          <ButtonWithLoadingOrIcon 
            text={leftButtonText ? leftButtonText : "Cancel"}
            className="main-button-48 no-fill-button cancel-button"
            isDisabled={isLoading}
            onClickAction={() => {
              if (!isLoading) {
                toggle();
              }
            }}
          />

          <ButtonWithLoadingOrIcon
            text={rightButtonText ? rightButtonText : "Save Changes"}
            className="main-button-48 orange-button modal-button"
            loadingColor="gray"
            position="left"
            isLoading={isLoading}
            isDisabled={isLoading}
            onClickAction={buttonAction}
          />
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default PopupTextField;
