import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Tooltip } from 'reactstrap';
import { connect } from "react-redux";
import "./BroadcastMessageDetail.scss";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiActivity, FiClock, FiUser, FiUsers, FiDownload, FiHelpCircle, FiEdit, FiTrash2, FiMessageSquare, FiX } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
// import { BiMessageDetail } from "react-icons/bi";
import IconMessage from "../../../assets/img/sidemenu-icon/icon-messages-black.svg";
import xRed from "../../../assets/img/x-red.svg";
import delivGreen from "../../../assets/img/delivered-green.svg";
import pendingYellow from "../../../assets/img/pending-yellow.svg";
import userPurple from "../../../assets/img/user-purple.svg";
import sentOrange from "../../../assets/img/sent-orange.svg";
import doubleCheckBlue from "../../../assets/img/double-check-blue.svg";
// import IconComment from "../../../assets/img/message-bubbles/comment.svg";
import BroadcastBlack from "../../../assets/img/sidemenu-icon/new/sub/broadcast-message-black.svg";
import IconFile from "../../../assets/img/file.svg";
import IcGroup from "../../../assets/img/ic-group.svg";
import BroadcastMessageServices from "../../../services/newServices/BroadcastMessageServices";
import { TAPLIVE_MEDIUM_LOGO, TAPLIVE_STATUS, RECIPIENT_TYPE } from "../../../constants/taplive";
import { MESSAGE_TYPE } from "../../../constants/message";
import HelperDate from "../../../helper/HelperDate";
import HelperInput from "../../../helper/HelperInput";
import HelperChat from "../../../helper/HelperChat";
import ColoredInfoBox from "../../reuseableComponent/coloredInfoBox/ColoredInfoBox";
// import ModalPreviewImageOrVideo from "../../reuseableComponent/cmodalPreviewImageOrVideo/ModalPreviewImageOrVideo";
import { setPreviewImageOrVideo } from "../../../redux/actions/reduxActionPreviewImageOrVideo";
import CustomSelectOption from "../../reuseableComponent/customSelectOption/CustomSelectOption";
import Pagination from "../../reuseableComponent/pagination/Pagination";
import ImageUpload from "../../reuseableComponent/imageUpload/ImageUpload";
import PopupConfirmation from "../../reuseableComponent/popupConfirmation/PopupConfirmation";
import CustomSearchBox from "../../reuseableComponent/customSearchBox/CustomSearchBox";
import FilterStatusDropdown from "../../reuseableComponent/FilterStatusDropdown/FilterStatusDropdown";
import { doToast } from "../../../helper/HelperGeneral";
import { Scrollbars } from 'react-custom-scrollbars';

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-5px",
    width: "3px",
    borderRadius: "8px"
  }
};

let RELEASE_DATE = new Date(2021, 10, 23);

const FILTER_BC_STATUS = [
  {
    value: "pending",
    label: "Pending"
  },
  {
    value: "sent",
    label: "Sent"
  },
  {
    value: "delivered",
    label: "Delivered"
  },
  {
    value: "read",
    label: "Read"
  },
  {
    value: "replied",
    label: "Responded",
  },
  {
    value: "not_sent",
    label: "Not Sent"
  },
]

let BroadcastMessageDetail = (props) => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [searchVal, setSearchVal] = useState("");
  let [broadcastDetail, setBroadcastDetail] = useState(false);
  let [imageSrc, setImageSrc] = useState('');
  let [isShowModalImage, setIsShowModalImage] = useState(false);
  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isLoadingGetBroadcastData, setIsLoadingGetBroadcastData] = useState(true);
  let [isLoadingGetRecipients, setIsLoadingGetRecipients] = useState(true);
  let [listPageVal, setListPageVal] = useState({
    label: "20",
    value: 20,
  });
  let [pageDisplay, setPageDisplay] = useState(1);
  let [listPage, setListPage] = useState(20);
  let listPageOption = [
    {
      label: "20",
      value: 20,
    },
    {
      label: "50",
      value: 50,
    },
    {
      label: "100",
      value: 100,
    },
  ];
  let [isLoadingGenerateReport, setIsLoadingGenerateReport] = useState(false);
  let [showTooltipDelivered, setShowTooltipDelivered] = useState(false);
  let [showTooltipRead, setShowTooltipRead] = useState(false);
  let [isShowModalDelete, setIsShowModalDelete] = useState(false);
  let [isLoadingDelete, setIsLoadingDelete] = useState(false);
  // let [bcStateFilter, setBcStateFilter] = useState([]);
  let [showStatus, setShowStatus] = useState(false);
  let [filterVal, setFilterVal] = useState({ value: "all", label: "All Status" });
  let [isActiveBcStatus, setIsActiveBcStatus] = useState(false);
  let [bcStatusData, setBcStatusData] = useState(null);

  let toggleModalBcStatus = () => {
    setIsActiveBcStatus(!isActiveBcStatus);
  }

  let toggleModalDelete = () => {
    setIsShowModalDelete(!isShowModalDelete);
  }

  let toggleTooltipRead = () => {
    setShowTooltipRead(!showTooltipRead)
  }

  let toggleTooltipDelivered = () => {
    setShowTooltipDelivered(!showTooltipDelivered)
  }

  let onSelectListPage = (val) => {
    let data = {
      id: checkID(match),
      pageNumber: 1,
      pageSize: val.value,
      search: searchVal,
      status: filterVal.value
    };
    fetchBroadcastMessageDetail(data, false);
    setListPageVal(val);
    setListPage(val.value);
    setPageDisplay(1);
  };

  let handleChangePage = (page) => {
    let data = {
      id: checkID(match),
      pageNumber: page,
      pageSize: listPage,
      search: searchVal,
      status: filterVal.value
    };
    fetchBroadcastMessageDetail(data, false);
    setPageDisplay(page);
  };

  let convertBody = (text) => {
    return HelperChat.convertFormatTextWhatsapp(text);
  };

  let toCapitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  let removeUnderscoreAndCapitalize = (str) => {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = toCapitalize(frags[i]);
    }
    return frags.join(" ");
  };

  let checkOrgID = (match) => {
    let { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  let checkID = (match) => {
    let { id: param } = match.params;
    if (!param) return false;
    else return Number(param);
  };

  let back = () => {
    history.push({
      pathname: `/o/${orgID}/operational/broadcast-message`,
      state: {
        pageNumber: location.pageNumber ? location.pageNumber : 1,
        pageSize: location.pageSize ? location.pageSize : 20,
        tab: props.history.location.state.tab
      },
    });
  }

  let goToEdit = () => {
    history.push({
      pathname: `/o/${orgID}/operational/broadcast-message/edit/${props.match.params.id}`,
      state: {
        pageNumber: location.pageNumber ? location.pageNumber : 1,
        pageSize: location.pageSize ? location.pageSize : 20,
        tab: props.history.location.state.tab
      },
    });
  }

  let doMount = async () => {
    let paramID = checkOrgID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let fetchBroadcastMessageDetail = (data, loadAll = true) => {
    setBroadcastDetail(false);
    if (loadAll) setIsLoadingGetBroadcastData(true);
    setIsLoadingGetRecipients(true);

    if (data.status === "all") {
      data.status = "";
    }

    BroadcastMessageServices.getBroadcastDetails(orgID, data, (response) => {
      let _retrieveDataError = { ...retrieveDataError };

      if (response.dataResult.error.message === "") {
        setBroadcastDetail(response.dataResult.data);
        setImageSrc(response.dataResult.data.broadcast.contentBody);
        setRetrieveDataError({
          code: false,
          message: false
        });
      } else {
        _retrieveDataError.message = response.dataResult.error.message;
        _retrieveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retrieveDataError);
      }
      setIsLoadingGetBroadcastData(false);
      setIsLoadingGetRecipients(false);
    });
  };

  // let clickMediaPreview = (media, fileURL) => {
  //   let _previewImageOrVideo = { ...props.previewImageOrVideo };

  //   _previewImageOrVideo.fileType = media;
  //   _previewImageOrVideo.fileURL = fileURL;
  //   props.setPreviewImageOrVideo(_previewImageOrVideo);
  // };

  let openURL = (url) => {
    var win = window.open(url, "_blank");
    if (win) {
      //Browser has allowed it to be opened
      win.focus();
    } else {
      //Browser has blocked it
      alert("Please allow popups for this website");
    }
  };

  let renderMessageContent = (body, type, caption = "", filename = "", idx) => {
    if (type === MESSAGE_TYPE.file) {
      return (
        <div className="message-content file-url" onClick={() => openURL(body)}>
          <div className="flexing">
            <img src={IconFile} alt="" />
            <p>{filename}</p>
          </div>
          <p
            className="content-caption"
            dangerouslySetInnerHTML={{
              __html: convertBody(HelperChat.lineBreakWithoutTrim(caption)),
            }}
          ></p>
        </div>
      );
    } else if (type === MESSAGE_TYPE.image) {
      // setImageSrc(body);
      return (
        <div className="message-content">
          <ImageUpload
            imageSrc={body}
            // onClick={() => clickMediaPreview("image", body)}
            onClick={() => {
              toggleModalImage();
            }}
            withoutRemove
          />
          {caption ? (
            <>
              <b>Caption</b>
              <p
                className="content-caption"
                dangerouslySetInnerHTML={{
                  __html: convertBody(HelperChat.lineBreakWithoutTrim(caption)),
                }}
              />
            </>
          ) : (
            ""
          )}
        </div>
      );
    } else
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: convertBody(HelperChat.lineBreakWithoutTrim(body)),
          }}
        ></p>
      );
  };

  let toggleModalImage = () => {
    setIsShowModalImage(!isShowModalImage);
  };

  let generateModalImage = () => {
    let zoomImage = (e) => {
      var zoomer = e.currentTarget;
      zoomer.style.backgroundSize = "200%";
      var offsetX = e.nativeEvent.offsetX;
      var offsetY = e.nativeEvent.offsetY;

      var x = (offsetX / zoomer.offsetWidth) * 100;
      var y = (offsetY / zoomer.offsetHeight) * 100;
      zoomer.style.backgroundPosition = x + "% " + y + "%";
    };

    let zoomImageOut = (e) => {
      var zoomer = e.currentTarget;
      zoomer.style.backgroundSize = "0%";
    };

    return (
      <div>
        <Modal isOpen={isShowModalImage} className={"modal-image"} toggle={toggleModalImage}>
          <ModalBody onClick={() => toggleModalImage()}>
            <IoIosClose
              className="close-modal-image"
              onClick={() => toggleModalImage()}
            />
            <div className="image-wrapper">
              <figure
                className="zoom"
                style={{ backgroundImage: `url(${imageSrc})` }}
                onMouseMove={(e) => zoomImage(e, imageSrc)}
                onMouseLeave={(e) => zoomImageOut(e)}
              >
                <img src={imageSrc} alt="" className="image-preview-val" />
              </figure>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  let downloadReport = (data) => {
    // let id = orgID.split('-');
    // let timestamp = + new Date();
    let url = window.URL.createObjectURL(new Blob([data]));
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${broadcastDetail.broadcast.name.replace(/\[|\]/g, "")}-data.csv`);
    document.body.appendChild(link);
    link.click();
  }

  let generateReport = () => {
    setIsLoadingGenerateReport(true);

    BroadcastMessageServices.exportBroadcastDetail(
      orgID,
      { id: parseInt(props.match.params.id), search: searchVal, status: filterVal.value === "all" ? "" : filterVal.value },
      (response) => {
        setIsLoadingGenerateReport(false);

        if (!response) {
          // setShowModalFailed(true);
        } else {
          downloadReport(response.dataResult)
        }
      }
    )
  }

  let deleteBroadcast = () => {
    setIsLoadingDelete(true);

    BroadcastMessageServices.deleteBroadcastScheduled(orgID, { id: parseInt(props.match.params.id), createdTime: broadcastDetail.broadcast.createdTime }, (response) => {
      if (response.dataResult.error.message === "") {
        if (response.dataResult.data.success) {
          setTimeout(() => {
            back();
          }, 2000)
        } else {
          setIsLoadingDelete(false);
        }

        doToast(response.dataResult.data.message);
      } else {
        doToast(response.dataResult.error.message, "fail");
        setIsLoadingDelete(false);
      }
    }
    )
  }

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    let paramID = checkID(match);
    if (orgID && paramID) {
      let data = {
        id: paramID,
        pageNumber: pageDisplay,
        pageSize: listPage,
        search: searchVal,
        status: filterVal.value
      };
      fetchBroadcastMessageDetail(data);
    } else if (orgID) back();
  }, [orgID]);

  let onChangeSearchBc = (e) => {
    setSearchVal(e.target.value);
  }

  let onSubmitBcSearch = () => {
    let data = {
      id: checkID(match),
      pageNumber: 1,
      pageSize: listPageVal.value,
      search: searchVal,
      status: filterVal.value
    };

    fetchBroadcastMessageDetail(data, false);
  }

  let onClearSearchBc = () => {
    let data = {
      id: checkID(match),
      pageNumber: 1,
      pageSize: listPageVal.value,
      search: "",
      status: filterVal.value
    };

    fetchBroadcastMessageDetail(data, false);
    setSearchVal("");
  }

  let toggleDropdownStatus = () => {
    if (!isLoadingGetRecipients) {
      setShowStatus(!showStatus)
    }
  }

  // let findIndexBcState = (v) => {
  //   let idx = -1;

  //   idx = bcStateFilter.findIndex(_v => _v.value === v);
  //   return idx;
  // }

  let changeBcFilter = (e) => {
    let data = {
      id: checkID(match),
      pageNumber: 1,
      pageSize: listPageVal.value,
      search: searchVal,
      status: e.value
    };
    toggleDropdownStatus();
    fetchBroadcastMessageDetail(data, false);
    setPageDisplay(1);
    setFilterVal(e)
  }

  let modalBcStatus = () => {
    return (
      <div className={`bc-history-wrapper ${isActiveBcStatus ? 'active-bc-history' : ''}`}>
        <div className={`bc-history-main-wrapper ${isActiveBcStatus ? "active-bc-history-main-wrapper" : ""}`}>
          <div className="bc-history-close" onClick={toggleModalBcStatus}>
            <FiX />
          </div>

          <p className={`title-bc-history`}>
            <b>Broadcast Status</b>
          </p>

          <div className="bc-history-list-wrapper">
            <Scrollbars
              autoHideTimeout={500}
              renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
            >
              {bcStatusData &&
                <>
                  {bcStatusData.isReplied &&
                    <div className="bc-history-card">
                      <p className="action-time">
                        <FiClock />
                        {HelperDate.formatToString(new Date(bcStatusData.repliedTime), "dd/MM/yyyy HH:mm")}
                      </p>

                      <p className="action-text">
                        <b>Broadcast replied</b>
                      </p>
                    </div>
                  }

                  {bcStatusData.isRead &&
                    <div className="bc-history-card">
                      <p className="action-time">
                        <FiClock />
                        {HelperDate.formatToString(new Date(bcStatusData.readTime), "dd/MM/yyyy HH:mm")}
                      </p>

                      <p className="action-text">
                        <b>Broadcast read</b>
                      </p>
                    </div>
                  }

                  {bcStatusData.isDelivered &&
                    <div className="bc-history-card">
                      <p className="action-time">
                        <FiClock />
                        {HelperDate.formatToString(new Date(bcStatusData.deliveredTime), "dd/MM/yyyy HH:mm")}
                      </p>

                      <p className="action-text">
                        <b>Broadcast delivered to [{bcStatusData ? `${bcStatusData.alias === "" ? bcStatusData.fullName : `${bcStatusData.alias} (${bcStatusData.fullName})`}` : ""}]</b>
                      </p>
                    </div>
                  }

                  {bcStatusData.isSent &&
                    <div className="bc-history-card">
                      <p className="action-time">
                        <FiClock />
                        {HelperDate.formatToString(new Date(bcStatusData.sentTime), "dd/MM/yyyy HH:mm")}
                      </p>

                      <p className="action-text">
                        <b>Broadcast sent</b>
                      </p>
                    </div>
                  }

                  {bcStatusData.status === "not_sent" &&
                    <div className="bc-history-card">
                      <p className="action-time">
                        <FiClock />
                        {HelperDate.formatToString(new Date((bcStatusData.updatedTime !== 0) ? bcStatusData.updatedTime : bcStatusData.createdTime), "dd/MM/yyyy HH:mm")}
                      </p>

                      <p className="action-text">
                        <b>Broadcast is not sent</b>
                      </p>
                    </div>
                  }

                  <div className="bc-history-card">
                    <p className="action-time">
                      <FiClock />
                      {HelperDate.formatToString(new Date(bcStatusData.createdTime), "dd/MM/yyyy HH:mm")}
                    </p>

                    <p className="action-text">
                      <b>Broadcast pending</b>
                    </p>
                  </div>
                </>
              }
            </Scrollbars>
          </div>

          {/* <div className="bc-history-recipient-name">
            <b>
              Recipient Name
            </b>

            <p>
              {bcStatusData ? `${bcStatusData.alias === "" ? bcStatusData.fullName : `${bcStatusData.alias} (${bcStatusData.fullName})`}` : ""}
            </p>
          </div> */}
        </div>
      </div>
    )
  }

  return (
    <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
      {/* <ModalPreviewImageOrVideo /> */}
      {modalBcStatus()}

      {imageSrc && generateModalImage()}

      <PopupConfirmation
        isOpen={isShowModalDelete}
        toggle={toggleModalDelete}
        title="Delete Scheduled Broadcast?"
        description="Scheduled broadcast message will be canceled and deleted. Are you sure you want to delete this broadcast?"
        className="modal-confirmation-delete-broadcast-scheduled"
      >
        {!isLoadingDelete ?
          <ButtonWithLoadingOrIcon
            text="Delete"
            className="main-button-48 red-button"
            onClickAction={() => deleteBroadcast()}
          />
          :
          <ButtonWithLoadingOrIcon
            text="Delete"
            className="main-button-48 red-button"
            isLoading
            isDisabled
            loadingColor="gray"
            position="left"
          />
        }
      </PopupConfirmation>

      <BackButton text="Back to Broadcast Message" onClick={back} />
      {/* BROADCAST */}
      <div className="view-broadcast-message-detail sectionWrap">
        <div className="view-broadcast-message-detail-top">
          <b>Broadcast Details</b>

          {(
            !isLoadingGetBroadcastData &&
            broadcastDetail &&
            broadcastDetail.broadcast.scheduledInputDate !== "" &&
            broadcastDetail.broadcast.status === TAPLIVE_STATUS.scheduled.value
          ) &&
            <>
              <ButtonWithLoadingOrIcon
                className="orange-button main-button-40 button-edit"
                text="Edit Schedule"
                icon={{
                  type: "svg",
                  src: FiEdit
                }}
                position="left"
                onClickAction={goToEdit}
              />

              <ButtonWithLoadingOrIcon
                className="red-button main-button-40 button-delete"
                text="Delete"
                icon={{
                  type: "svg",
                  src: FiTrash2
                }}
                position="left"
                onClickAction={toggleModalDelete}
              />
            </>
          }
        </div>
        {isLoadingGetBroadcastData || retrieveDataError.code ? (
          <div className="broadcast-message-detail-loading-wrapper">
            <RetrieveDataLoading
              isLoading={isLoadingGetBroadcastData}
              errorMessage={retrieveDataError.message}
            />
          </div>
        ) : broadcastDetail ? (
          <React.Fragment>
            <div className="view-broadcast-message-detail-middle">
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-name">
                <img src={BroadcastBlack} alt="" className="label-icon" />
                <b>Broadcast Name</b>
                <p>{broadcastDetail.broadcast.name}</p>
              </div>
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-channel">
                <img src={IcGroup} alt="" className="label-icon" />
                <b>Channel</b>
                <div className="flexing">
                  <img
                    src={TAPLIVE_MEDIUM_LOGO[broadcastDetail.broadcast.medium]}
                    alt=""
                    className="channel-icon"
                  />
                  <p>{broadcastDetail.channel.accountName}</p>
                </div>
              </div>
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-sender">
                <FiUser className="icon-sender" />
                <b>Sender</b>
                <p>{broadcastDetail.broadcast.senderAccountName}{broadcastDetail.broadcast.senderAccountAlias !== "" ? ` (${broadcastDetail.broadcast.senderAccountAlias})` : ""}</p>
              </div>
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-sender">
                <FiUser className="icon-sender" />
                <b>Recipient Type</b>
                <p>{RECIPIENT_TYPE[broadcastDetail.broadcast.recipientType].text}</p>
              </div>
              {broadcastDetail.broadcast.recipientType === RECIPIENT_TYPE.user_segment.val &&
                <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-sender">
                  <FiUsers className="icon-sender" />
                  <b>Segment Name</b>
                  <p>{broadcastDetail.broadcast.userSegmentName}</p>
                </div>
              }
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-created-time">
                <FiClock className="icon-sender" />
                <b>Started Time</b>
                {broadcastDetail.broadcast.startTime === 0 ?
                  <p>-</p>
                  :
                  <p>
                    {HelperDate.formatToString(new Date(broadcastDetail.broadcast.startTime),
                      "dd MMM yyyy HH:mm"
                    )}
                  </p>
                }
              </div>
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-created-time">
                <FiClock className="icon-sender" />
                <b>Finished Time</b>
                {broadcastDetail.broadcast.endTime === 0 ?
                  <p>-</p>
                  :
                  <p>
                    {HelperDate.formatToString(new Date(broadcastDetail.broadcast.endTime),
                      "dd MMM yyyy HH:mm"
                    )}
                  </p>
                }
              </div>


              {broadcastDetail.broadcast.scheduledInputDate !== "" &&
                <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-created-time">
                  <FiClock className="icon-sender" />
                  <b>Scheduled Time</b>
                  <p>
                    {HelperDate.formatToString(new Date(broadcastDetail.broadcast.scheduledInputDate), "dd MMM yyyy")} {broadcastDetail.broadcast.scheduledInputTime}
                  </p>
                </div>
              }

              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-created-time">
                <FiMessageSquare className="icon-sender" />
                <b>Create Message as Case</b>
                <p className={broadcastDetail.broadcast.withCase ? "yes-color" : "no-color"}>
                  <b className={broadcastDetail.broadcast.withCase ? "green-text" : "red-text"}>{broadcastDetail.broadcast.withCase ? "Yes" : "No"} </b>
                </p>
              </div>

              {broadcastDetail.broadcast.scheduledInputDate === "" &&
                <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-status">
                  <FiActivity className="icon-sender" />
                  <b>Status</b>
                  {TAPLIVE_STATUS[broadcastDetail.broadcast.status] ? (
                    <ColoredInfoBox
                      text={toCapitalize(
                        TAPLIVE_STATUS[broadcastDetail.broadcast.status].value
                      )}
                      color={
                        TAPLIVE_STATUS[broadcastDetail.broadcast.status].color
                      }
                    />
                  ) : (
                    broadcastDetail.broadcast.status
                  )}
                </div>
              }
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>

      <div className="view-broadcast-message-detail sectionWrap">
        <div className="view-broadcast-message-detail-top">
          <b>Broadcast Messages</b>
        </div>
        {isLoadingGetBroadcastData || retrieveDataError.code ? (
          <div className="broadcast-message-detail-loading-wrapper">
            <RetrieveDataLoading
              isLoading={isLoadingGetBroadcastData}
              errorMessage={retrieveDataError.message}
            />
          </div>
        ) : broadcastDetail ? (
          <React.Fragment>
            {broadcastDetail.messages.map((val, idx) => {
              return (
                <div className="view-broadcast-message-detail-middle message-number-wrapper" key={`view-broadcast-message-detail-${idx}`}>
                  <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-content-body">
                    <img src={IconMessage} alt="" />
                    <b>Message {idx > 0 ? `#${idx + 1}` : ""}</b>
                  </div>
                  <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-message-type">
                    <b>Message Type</b>
                    <p>{toCapitalize(val.messageType)}</p>
                  </div>
                  <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-content-body">
                    <b>Content</b>
                    {renderMessageContent(
                      val.contentBody,
                      val.messageType,
                      val.contentCaption,
                      val.contentFileName,
                      idx
                    )}
                  </div>
                </div>
              )
            })}
          </React.Fragment>
        ) : (
          ""
        )}
      </div>

      {/* RECIPIENTS */}
      <div className="view-broadcast-message-detail sectionWrap">
        <div className="view-broadcast-message-detail-top">
          <b>Recipients</b>
          {broadcastDetail ? (
            <span>{" (" + broadcastDetail.broadcast.totalUsers + ")"}</span>
          ) : (
            ""
          )}

          {!isLoadingGenerateReport ?
            <ButtonWithLoadingOrIcon
              position="left"
              icon={{
                type: "svg",
                src: FiDownload
              }}
              text="Export"
              className="main-button-48 orange-button button-export"
              onClickAction={generateReport}
            />
            :
            <ButtonWithLoadingOrIcon
              position="left"
              isLoading
              loadingColor="gray"
              isDisabled
              text="Export"
              className="main-button-48 orange-button button-export"
            // onClickAction={}
            />
          }
        </div>

        <>
          <React.Fragment>
            <div className="view-broadcast-message-detail-middle">
              <div className="view-broadcast-message-detail-middle view-broadcast-message-detail-middle-total-status">
                <div className="total-status-wrapper">
                  <div className="white-space-nowrap">
                    <img src={pendingYellow} alt="" />
                    <b>Pending</b>
                  </div>
                  <div className="total">{!broadcastDetail ? 0 : broadcastDetail.totalPending}</div>
                </div>
                <div className="total-status-wrapper">
                  <div className="white-space-nowrap">
                    <img src={sentOrange} alt="" />
                    <b>Sent</b>
                  </div>
                  <div className="total">{!broadcastDetail ? 0 : broadcastDetail.totalSent}</div>
                </div>
                <div className="total-status-wrapper">
                  <div className="white-space-nowrap">
                    <img src={delivGreen} alt="" />
                    <b>Delivered</b>
                    {(broadcastDetail && RELEASE_DATE.valueOf() > broadcastDetail.broadcast.createdTime) &&
                      <>
                        <span
                          href="#"
                          id="tooltip-delivered-2"
                          onMouseEnter={toggleTooltipDelivered}
                          onMouseLeave={toggleTooltipDelivered}
                        >
                          <FiHelpCircle className="bc-tootlip-toggle" />
                        </span>
                        <Tooltip
                          flip
                          target="tooltip-delivered-2"
                          isOpen={showTooltipDelivered}
                          // toggle={function noRefCheck(){}}
                          placement="right"
                          toogle={toggleTooltipDelivered}
                        >
                          Exclude broadcasts created before 23 Nov 2021
                        </Tooltip>
                      </>
                    }
                  </div>
                  <div className="total">{!broadcastDetail ? 0 : broadcastDetail.totalDelivered}</div>
                </div>
                <div className="total-status-wrapper">
                  <div className="white-space-nowrap">
                    <img src={doubleCheckBlue} alt="" />
                    <b>Read</b>
                    {(broadcastDetail && RELEASE_DATE.valueOf() > broadcastDetail.broadcast.createdTime) &&
                      <>
                        <span
                          href="#"
                          id="tooltip-read-2"
                          onMouseEnter={toggleTooltipRead}
                          onMouseLeave={toggleTooltipRead}
                        >
                          <FiHelpCircle className="bc-tootlip-toggle" />
                        </span>
                        <Tooltip
                          flip
                          target="tooltip-read-2"
                          isOpen={showTooltipRead}
                          // toggle={function noRefCheck(){}}
                          placement="right"
                          toogle={toggleTooltipRead}
                        >
                          Exclude broadcasts created before 23 Nov 2021
                        </Tooltip>
                      </>
                    }
                  </div>
                  <div className="total">{!broadcastDetail ? 0 : broadcastDetail.totalRead}</div>
                </div>
                <div className="total-status-wrapper">
                  <div className="flexing white-space-nowrap">
                    <img src={userPurple} alt="" />
                    <b>Responded</b>
                  </div>
                  <div className="total">{!broadcastDetail ? 0 : broadcastDetail.totalReplied}</div>
                </div>
                <div className="total-status-wrapper">
                  <div className="white-space-nowrap">
                    <img src={xRed} alt="" />
                    <b>Not Sent</b>
                  </div>
                  <div className="total">{!broadcastDetail ? 0 : broadcastDetail.totalNotSent}</div>
                </div>
              </div>

              <div className="bc-list-status-tool-wrapper">
                <CustomSearchBox
                  placeholder={"Search for a full name, alias & phone number"}
                  onChangeSearchSpecial={(e) => onChangeSearchBc(e)}
                  style={{ width: '403px' }}
                  dataToSearch={!broadcastDetail ? [] : broadcastDetail.recipients}
                  onClearSearch={onClearSearchBc}
                  search={searchVal}
                  isDisabled={isLoadingGetRecipients}
                  onSubmitSearch={onSubmitBcSearch}
                />

                <FilterStatusDropdown
                  isOpen={showStatus}
                  toggle={toggleDropdownStatus}
                  isLoading={isLoadingGetRecipients}
                  filterList={FILTER_BC_STATUS}
                  changeFilter={(e) => changeBcFilter(e)}
                  value={filterVal}
                  _className={"dropdownBcFilter"}
                />
              </div>

              <div className="broadcast-recipients-table-wrapper">
                <table className="custom-table-content table-broadcast-recipients">
                  <thead>
                    <tr>
                      <td className="col_name">Full Name</td>
                      <td className="col_alias">Alias</td>
                      <td className="col_phone">Phone Number</td>
                      <td className="col_status">Last Status</td>
                      <td className="col_responded">Responded</td>
                      <td className="col_last_update">Last Updated</td>
                      <td className="col_reason">Reason</td>
                      <td>
                        <CustomSelectOption
                          optionListProps={listPageOption}
                          valueDropdownProps={listPageVal}
                          onClickDropDownListOptionProps={onSelectListPage}
                        />
                      </td>
                    </tr>
                  </thead>

                  {isLoadingGetRecipients || retrieveDataError.code ?
                    <tbody>
                      <tr className="no-data-column">
                        <td colSpan="8" className="no-data-column">
                          <RetrieveDataLoading
                            isLoading={isLoadingGetRecipients}
                            errorMessage={retrieveDataError.message}
                          />
                        </td>
                      </tr>
                    </tbody>
                    :
                    <>
                      {broadcastDetail.recipients.length < 1 ?
                        <tbody>
                          <tr className="no-data-column">
                            <td colSpan="8" className="no-data-column">
                              <b className="no-result-found">
                                No Result found
                              </b>
                            </td>
                          </tr>
                        </tbody>
                        :
                        <tbody>
                          {broadcastDetail.recipients.map((value, index) => {
                            return (
                              <tr key={`broadcast-message-${index}`}>
                                <td className="col_name">{value.fullName}</td>
                                <td className="col_alias">{value.alias}</td>
                                <td className="col_phone">{"+" + HelperInput.phoneBeautify(value.phone)}</td>
                                <td className="col_status">
                                  {TAPLIVE_STATUS[value.status === "" ? "pending" : value.status] ?
                                    <ColoredInfoBox
                                      text={removeUnderscoreAndCapitalize(
                                        TAPLIVE_STATUS[value.status === "" ? "pending" : value.status].value
                                      )}
                                      color={TAPLIVE_STATUS[value.status === "" ? "pending" : value.status].color}
                                    />
                                    :
                                    <>
                                      {value.status === "" ? "pending" : value.status}
                                    </>
                                  }
                                </td>
                                <td className="col_responded">
                                  {value.isReplied ?
                                    <b className="responded-text yes-text">Yes</b>
                                    :
                                    <b className="responded-text no-text">No</b>
                                  }
                                </td>
                                <td className="col_last_updated">
                                  {value.updatedTime === 0 ? "-" : HelperDate.formatToString(new Date(value.updatedTime), "dd/MM/yyyy HH:mm")}
                                </td>
                                <td className="col_reason">
                                  {value.statusReason}
                                </td>
                                <td className="col_history">
                                  <ButtonWithLoadingOrIcon
                                    text="Status History"
                                    className="no-fill-button main-button-26"
                                    onClickAction={() => {
                                      setBcStatusData(value);
                                      toggleModalBcStatus(value);
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      }
                    </>
                  }
                </table>
              </div>

              {(!isLoadingGetRecipients && !retrieveDataError.code) &&
                <div className="pagination-wrapper">
                  {broadcastDetail.recipients.length > 0 && (
                    <Pagination
                      total={broadcastDetail.recipientTotalItems ? broadcastDetail.recipientTotalItems : broadcastDetail.broadcast.totalUsers}
                      page={pageDisplay}
                      listPerPage={listPage}
                      step={1}
                      onChangePage={handleChangePage}
                    ></Pagination>
                  )}
                </div>
              }
            </div>
          </React.Fragment>
        </>
      </div >
    </SectionWrap >
  );
};

let mapStateToProps = (state) => ({
  previewImageOrVideo: state.previewImageOrVideo,
});

let mapDispatchToProps = {
  setPreviewImageOrVideo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BroadcastMessageDetail);
