import React, { useState, useEffect } from "react";
import "../auth/Auth.css";
import "./Topic.css";
import "react-toastify/dist/ReactToastify.css";
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import CustomSearchBox from "../reuseableComponent/customSearchBox/CustomSearchBox";
import topicServices from "../../services/newServices/TopicServices";
import { TopicsList, TopicsHead } from "./TopicsContent";
import TopicsAdd from "./TopicsAdd";
import { checkID } from "../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import mixpanel from "mixpanel-browser";

// let fakeTopics = {
//   status: "OK",
//   data: [
//     {
//       id: 1,
//       name: "Technical",
//       createdTime: 1566455001340,
//       updatedTime: 0,
//       deletedTime: 0
//     },
//     {
//       id: 2,
//       name: "Billing",
//       createdTime: 1566455009213,
//       updatedTime: 0,
//       deletedTime: 0
//     }
//   ]
// };

let Topics = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [listTopic, setListTopic] = useState(false);
  let [listTopicOrigin, setListTopicOrigin] = useState(false);
  let [popupAdd, setPopupAdd] = useState(false);
  let [waitList, setWaitList] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  let [searchVal, setSearchVal] = useState("")

  let getTopic = async (orgID, mode) => {
    setWaitList(true);
    let _retriveDataError = { ...retriveDataError };

    topicServices.getTopic(orgID, "list", null, (response) => {
      let dataResult = response.dataResult;

      let success = response.dataResult.status === 200;
      let hasError = response.dataResult.error && response.dataResult.error.code && response.dataResult.error.message;
      let createList = () => {
        if (success) {
          setListTopic({ status: "OK", data: response.dataResult.data.topics })
          setListTopicOrigin({ status: "OK", data: response.dataResult.data.topics })
        } else if (hasError) {
          setListTopic({ status: "error", data: response.dataResult.error.message })
          setListTopicOrigin({ status: "error", data: response.dataResult.error.message })
        }
        setWaitList(false);
      };

      if (response.dataResult.error.code === "") {
        if (mode === "reload") setTimeout(() => createList(), 500);
        createList();
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        setWaitList(false);
        // doToast(response.dataResult.error.message, "fail");
      }
    });
  };

  let reloadTopic = async () => {
    setWaitList(true);
    // doToast(mode || "Success");
    await getTopic(orgID);
  };

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getTopic(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/topics`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  React.useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (orgID) {
      if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Page] Topics",
          {
            userFullName: myAgentData.account.fullName,
            userID: myAgentData.account.id,
            organizationID: orgID.split("-")[0]
          }
        );
      }
    }
  }, [orgID])

  let togglePopupAdd = () => setPopupAdd(!popupAdd);

  let onChangeSearch = (e) => {
    let val = e.target.value.toLowerCase();
    setSearchVal(val);

    if (val === "") {
      setListTopic(listTopicOrigin)
    } else {
      let result = [];
      for (let i in listTopicOrigin.data) {
        let objectKey = Object.keys(listTopicOrigin.data[i]);

        for (let j in objectKey) {
          let isString = typeof listTopicOrigin.data[i][objectKey[j]] === "string";
          if (listTopicOrigin.data[i][objectKey[j]].toString().toLowerCase().includes(val) && isString) {
            result.push(listTopicOrigin.data[i])
            break;
          }
        }
      }

      let _listTopic = {...listTopic};

      _listTopic.data = result;

      setListTopic(_listTopic);
    }
  }

  let onClearSearch = () => {
    let valSearch = document.querySelector(`#search-box-input-val`);
    valSearch.value = "";
    setListTopic(listTopicOrigin);
    setSearchVal("");
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <div className="topic sectionWrap">
          <TopicsHead setPopupAdd={setPopupAdd} mainProps={props} isError={retriveDataError.code !== false} />

          <CustomSearchBox
            placeholder={"Search for topic"}
            onChangeSearchSpecial={onChangeSearch}
            style={{ width: '343px' }}
            dataToSearch={listTopic}
            onClearSearch={onClearSearch}
            search={searchVal}
          />

          <div className="bodyContent">
            {(waitList || retriveDataError.code) ?
              <RetrieveDataLoading
                isLoading={waitList}
                errorMessage={retriveDataError.message}
              />
              :
              <TopicsList
                orgID={orgID}
                listTopic={listTopic}
                reloadTopic={reloadTopic}
                history={history}
              />
            }
          </div>
        </div>

        <TopicsAdd
          isOpen={popupAdd}
          toggle={togglePopupAdd}
          orgID={orgID}
          success={reloadTopic}
          parentProps={props}
        />
      </SectionWrap>
    </React.Fragment>
  );
};

export default Topics;
