import React, { useState, useEffect } from "react";
import "./IntegrationChatbot.scss";
import "react-toastify/dist/ReactToastify.css";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import NewButton from "../../reuseableComponent/NewButton/NewButton";
import { checkID, doToast } from "../../../helper/HelperGeneral";
// import Pagination from "../reuseableComponent/pagination/Pagination";
import { FiPlus, FiEye, FiAlertCircle, FiInfo } from "react-icons/fi";
import iconXRed from "../../../assets/img/integration/icon-disconnect.svg";
import iconCheckGreen from "../../../assets/img/integration/status-connected.svg";
import iconProvisioning from "../../../assets/img/integration/icon-provisioning-blue.svg";
import FilterStatusDropdown from "../../reuseableComponent/FilterStatusDropdown/FilterStatusDropdown";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import CustomSearchBox from "../../reuseableComponent/customSearchBox/CustomSearchBox";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";
import ChatbotServices from "../../../services/newServices/ChatbotService";
import { NavLink } from "react-router-dom";

let IntegrationChatbot = props => {
  let { match, history, location } = props;
  let [searchVal, setSearchVal] = useState("");
  let [orgID, setOrgID] = useState(false);
  let [chatbotList, setChatbotList] = useState([]);
  let [chatbotListOrigin, setChatbotListOrigin] = useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(true);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [showFilterStatus, setShowFilterStatus] = useState(false);
  let [filterVal, setFilterVal] = useState({ value: "all", label: "All Type" });

  let toggleFilterStatus = () => {
    setShowFilterStatus(!showFilterStatus);
  }

  const FILTER_STATUS = [
    {
      value: "custom",
      label: "Custom"
    },
    {
      value: "chatgpt",
      label: "ChatGPT Chatbot"
    },
    {
      value: "taptalk/scf",
      label: "QnA Chatbot"
    }
  ]

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/chatbots`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let fetchChatbotList = () => {
    let _retriveDataError = { ...retriveDataError };

    ChatbotServices.getChatbot(orgID, (response) => {
      let dataResult = response.dataResult;
      if (dataResult.error.message === "") {
        setChatbotList(dataResult.data.chatbots.slice());
        setChatbotListOrigin(dataResult.data.chatbots.slice());
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  useEffect(() => {
    if (orgID) {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

      fetchChatbotList();

      if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Page] Chatbot",
          {
            userFullName: myAgentData.account.fullName,
            userID: myAgentData.account.id,
            organizationID: orgID.split("-")[0]
          }
        );
      }
    }
  }, [orgID]);

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.message) {
        doToast(props.history.location.state.message);
        window.history.pushState(null, '');
      }
    }
  }, [props.history])

  // let onChangePage = () => {
  //   console.log('change page')
  // }

  let addBtn = () => {
    let fullUrl = `/o/${orgID}/chatbots/create`;

    let onClick = () => history.push(fullUrl);
    let btnProps = { text: "Create Chatbot", icon: FiPlus, onClick };

    return <NewButton {...btnProps} />;
  };

  let goToDetail = (id) => {
    props.history.push({
      pathname: `/o/${orgID}/chatbots/detail/${id}`
    })
  }

  let onChangeSearch = (e) => {
    let val = e.target.value.toLowerCase();
    setSearchVal(val);

    changeSearchAndFilter({
      search: val,
      filter: filterVal
    });
  }

  let changeSearchAndFilter = (data) => {
    let resultSearch = [];
    let resultAll  = [];

    if (data.search === "") {
      resultSearch = chatbotListOrigin.slice();
    } else {
      let result = [];
      for (let i in chatbotListOrigin) {
        let objectKey = Object.keys(chatbotListOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof chatbotListOrigin[i][objectKey[j]] === "string";
          if (chatbotListOrigin[i][objectKey[j]].toString().toLowerCase().includes(data.search.toLowerCase()) && isString) {
            result.push(chatbotListOrigin[i])
            
            break;
          }
        }
      }

      resultSearch = result;
    }

    resultSearch.map(v => {
      if (v.type === data.filter.value || data.filter.value === "all") {
        resultAll.push(v);
      }

      return null;
    })

    setChatbotList(resultAll);
  }

  let onClearSearch = () => {
    setSearchVal("");
   
    changeSearchAndFilter({
      search: "",
      filter: filterVal
    });
  }

  let changeFilterStatus = (v) => {
    setFilterVal(v);
    toggleFilterStatus();
    
    
    changeSearchAndFilter({
      search: searchVal,
      filter: v
    });
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <div className="sectionWrap chatbot-main-wrapper">
          {/* tier blocking */}
          {retriveDataError.code === "49901" &&
            <RetrieveDataLoading
              isLoading={waitForResponseGetList}
              errorMessage={retriveDataError.message}
              errorCode={retriveDataError.code}
            />
          }
          {/* tier blocking */}

          <div className="topContent">
            <div className="title">Chatbots</div>

            {(!waitForResponseGetList && !retriveDataError.code) &&
              <NavLink to={`/o/${orgID}/chatbots/create`}>
                <div
                  className="btn"
                  onClick={() => {
                    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                      mixpanel.track(
                        "[Button] Create Chatbot",
                        {
                          userFullName: myAgentData.account.fullName,
                          umixpserID: myAgentData.account.id,
                          organizationID: orgID.split("-")[0]
                        }
                      )
                    }
                  }}
                >
                  {addBtn()}
                </div>
              </NavLink>
            }
          </div>

          <div className="chatbot-list-info">
            Create automated messages to answer repetitive questions from your customers.
          </div>

          <div className="search-and-filter-wrapper">
            <CustomSearchBox
              placeholder={"Search for chatbot name"}
              onChangeSearchSpecial={onChangeSearch}
              dataToSearch={chatbotList}
              onClearSearch={onClearSearch}
              search={searchVal}
              isDisabled={waitForResponseGetList}
            />

            <FilterStatusDropdown
              isOpen={showFilterStatus}
              toggle={toggleFilterStatus}
              isLoading={waitForResponseGetList}
              filterList={FILTER_STATUS}
              changeFilter={(v) => {
                changeFilterStatus(v);
              }}
              value={filterVal}
              allText="Type"
            />
          </div>

          {waitForResponseGetList || retriveDataError.code ?
            <RetrieveDataLoading
              isLoading={waitForResponseGetList}
              errorMessage={retriveDataError.message}
            />
            :
            <React.Fragment>
              <div className="chatbot-list-table-wrapper">
                <div className="custom-table">
                  <table className="custom-table-content table-chatbot-list">
                    <thead>
                      <tr>
                        <td className="col_chatbot_name">Chatbot Name</td>
                        <td className="col_path">Chatbot Type</td>
                        <td className="col_status">Status</td>
                        <td className="col_action"></td>
                      </tr>
                    </thead>

                    {chatbotList.length < 1 ?
                      <tbody>
                        <tr>
                          <td colSpan="4" className="no-data-column"><b className="no-result-found">No chatbot found, start with "+ Create Chatbot"</b></td>
                        </tr>
                      </tbody>
                      :
                      <tbody>
                        {chatbotList.map((value, index) => {
                          return (
                            <tr
                              key={`chatbot-${index}`}
                              onClick={() => {
                                goToDetail(value.id);
                              }}
                            >
                              <td className="col_chatbot_name">{value.name}</td>
                              <td className="col_path">
                                {value.typeName}
                              </td>
                              <td className="col_status">
                                {
                                  (value.status === "not_paid" || value.status === "suspended") ?
                                  <b className={`status-not-paid`}>
                                    <img src={iconXRed} alt="" />
                                    {value.statusText}
                                  </b>
                                  :
                                  value.status === "waiting_setup" ?
                                  <b className={`status-waiting-setup`}>
                                    <FiAlertCircle />
                                    {value.statusText}
                                  </b>
                                  :
                                  value.status === "provisioning" ?
                                  <b className={`status-provisioning`}>
                                    <img src={iconProvisioning} alt="" />
                                    {value.statusText}
                                  </b>
                                  :
                                  value.status === "active" ?
                                  <b className={`status-active`}>
                                    <img src={iconCheckGreen} alt="" />
                                    {value.statusText}
                                  </b>
                                  :
                                  <b className={`status-others`}>
                                    <FiInfo />
                                    {value.statusText}
                                  </b>
                                }
                              </td>
                              <td className={`col_action`}>
                                <div className="col-action-button-wrapper">
                                  <NavLink to={`/o/${orgID}/chatbots/detail/${value.id}`}>
                                    <b className="orange-link-text">
                                      <FiEye />
                                      View
                                    </b>
                                  </NavLink>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    }
                  </table>
                </div>

                {/* <Pagination total={100} 
                              page={1} 
                              listPerPage={8} 
                              step={3} 
                              onChangePage={onChangePage} 
                  /> */}
              </div>
            </React.Fragment>
          }
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default IntegrationChatbot;
