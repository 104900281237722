import React from "react";
import "./IntegrationChatGPTIntro.scss";
import { connect } from 'react-redux';
import { FiArrowRight } from "react-icons/fi";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import Knowledge from "../../../../assets/img/gpt-intro/knowledge.png";
import Prompt from "../../../../assets/img/gpt-intro/prompt.png";
// import Lead from "../../../../assets/img/gpt-intro/lead.png";
// import Handover from "../../../../assets/img/gpt-intro/handover.png";
import Submission from "../../../../assets/img/gpt-intro/submission.png";

const IntegrationChatGPTIntro = (props) => {
    const INTRO_LIST = [
        {
            title: "Knowledge Base",
            img: Knowledge,
            html: 
                `Knowledge Base for uploading sources to train a chatbot from multiple different types of sources.<br />
                Manage source changes here and will be saved permanently once you submit changes in submission.<br />
                Ensure there are options for uploading different types of sources:
                <ul>
                <li>Documents (e.g., PDFs, Word files, CSV files)</li>
                <li>Text (e.g., directly inputted text)</li>
                </ul>`
        },
        {
            title: "Prompt Settings",
            img: Prompt,
            html: 
                `Prompt Settings are adjustable options that shape how the chatbot interacts.<br />
                Control various aspects of chatbot’s responses by adjusting the chatbot’s tone, length of responses, and level of creativity. By modifying these options, customize the chatbot to better align with your specific requirements of your interaction whether you need clear, straightforward answers or more elaborate, imaginative responses, these settings help ensure the chatbot meets your expectations.`
        },
        // {
        //     title: "Leads Generation",
        //     img: Lead,
        //     html: 
        //         `Leads Generation use chatbot to attract and engage potential customers.<br />
        //         Start conversations, ask qualifying questions, gather contact details, and schedule follow-ups or appointments. By automating these tasks, chatbot can help businesses efficiently identify and nurture leads, making it easier to convert them into customers.`
        // },
        // {
        //     title: "Handover Settings",
        //     img: Handover,
        //     html: 
        //         `Enabling Handover Settings automatically transfer chats to agents when specific criteria are met.<br />
        //         Setting rules for when a conversation should be passed from chatbot to a human agent, example: when the issue is too complex or when the chatbot senses the user is frustrated, triggering the transfer to a human agent who can help further.`
        // },
        {
            title: "Submission",
            img: Submission,
            html: `Use this page to submit all your pending changes for the chatbot settings. Please provide a brief description for each update to help differentiate and ensure that each modification is easily identifiable, description will not affect anything in chatbot’s settings and/or behavior. `
        },
    ];

    const goToSetup = () => {
      props.history.push(`/o/${props.match.params.orgID}/chatbots/detail/${props.match.params.id}/chatgpt-setup`);
    };

    return (
        <>
            <SectionWrap
                {...props}
                orgID={props.match.params.orgID}
                withHeader
                withSideMenu
            >
                <BackButton
                    onClick={() => {
                        props.history.push(`/o/${props.match.params.orgID}/chatbots/detail/${props.match.params.id}`)
                    }}
                    text={"Back to Chatbot Details"}
                />

                <div className="chatgpt-intro-wrapper sectionWrap">
                    <p className="top-title">
                        <b>Introduction</b>
                    </p>

                    <div className="chatgpt-intro-card-wrapper">
                        {INTRO_LIST.map((v, i) => {
                            return (
                                <div key={`intro-gpt-${i}`} className={`chatgpt-intro-card-outer`}>
                                    <div className="intro-title">
                                        <div className="intro-number">
                                            <b>{i + 1}</b>
                                        </div>

                                        <b className="intro-title-text">
                                            {v.title}
                                        </b>
                                    </div>

                                    <div className="intro-card">
                                        <div className="intro-card-image">
                                            <img src={v.img} alt="" />
                                        </div>

                                        <div className="intro-html-wrapper" dangerouslySetInnerHTML={{ __html: v.html }} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="continue-button-wrapper">
                        <ButtonWithLoadingOrIcon
                            className="no-fill-button main-button-40"
                            icon={{
                                type: "svg",
                                src: FiArrowRight
                            }}
                            position="right"
                            text="Start Setup"
                            onClickAction={goToSetup}
                        />
                    </div>
                </div>
            </SectionWrap>
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationChatGPTIntro)