import React, { useState, useEffect } from "react";
import "./IntegrationGoogleCreateTopic.scss";
import { connect } from "react-redux";
import { FiInfo, FiAlertCircle } from "react-icons/fi";
import CustomReactSelect from "../../../../reuseableComponent/CustomReactSelect/CustomReactSelect";
import topicServices from "../../../../../services/newServices/TopicServices";
import { setCreateGoogle } from "../../../../../redux/actions/reduxActionCreateGoogle";
import { doToast } from "../../../../../helper/HelperGeneral";
import ErrorOrInfoComp from '../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp';
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

let IntegrationGoogleCreateTopic = (props) => {
    let [topicList, setTopicList] = useState([]);
    let [errorTopic, setErrorTopic] = useState("");

    useEffect(() => {
        getTopic();
    }, [])

    let getTopic = () => {
        topicServices.getTopic(props.match.params.orgID, "", "", (response) => {
            if (response.dataResult.error.code === "") {
                let dataResult = response.dataResult;
                dataResult.data.topics.map((v, i) => {
                    v.label = v.name;
                    v.value = v.id;

                    return null;
                })

                setTopicList(dataResult.data.topics);
            } else {
                doToast(response.dataResult.error.message, 'fail');
            }
        })
    }

    let onSelectTopic = (e) => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);

        _parse.data.topic = e;
        setErrorTopic("");
        props.setCreateGoogle(_parse);
    }

    let onClickContinue = () => {
        if (props.createGoogle.data.topic.value === "") {
            setErrorTopic("Select your topic first.");
        } else {
            props._setActiveForm(1);
        }
    }

    return (
        <div className="main-integration-google-create">
            <label>
                <b>Topic</b>
            </label>

            <div className="custom-select2-wrapper">
                <CustomReactSelect
                    width="400px"
                    options={topicList}
                    placeholder="Select topic"
                    onChange={(e) => onSelectTopic(e)}
                    classNamePrefix="custom-select-topic-option"
                    isDisabled={false}
                    value={topicList.filter(
                        (obj) => obj.value === props.createGoogle.data.topic.value
                    )}
                    blurInputOnSelect={true}
                    _className={`${errorTopic !== "" ? "border-red input-topic" : "input-topic"}`}
                />
            </div>

            <ErrorOrInfoComp
                text={errorTopic === "" ? `Incoming messages on this channel will be assigned to this topic.` : errorTopic}
                _className={errorTopic === "" ? "" : "font-red"}
                icon={errorTopic === "" ? <FiInfo /> : <FiAlertCircle />}
            />

            <ButtonWithLoadingOrIcon
                className="orange-button main-button-40"
                onClickAction={onClickContinue}
                text="Continue"
            />
        </div>
    )
}

const mapStateToProps = state => ({
    createGoogle: state.createGoogle
});

const mapDispatchToProps = {
    setCreateGoogle
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationGoogleCreateTopic);
