import React, { useEffect, useState } from "react";
import "./IntegrationInstagramAddAccount.scss";
import "react-toastify/dist/ReactToastify.css";
import FacebookIcon from "../../../../assets/img/integration/icon-button-facebook.svg";
// import Integrations from "../../Integrations";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import IntegrationInstagramServices from "../../../../services/integration/IntegrationInstagramServices";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import topicServices from "../../../../services/newServices/TopicServices";
import { setMediumLastRun } from "../../../../redux/actions/reduxActionMediumLastRun";
import { FiAlertCircle, FiInfo } from "react-icons/fi";
import { connect } from 'react-redux';

let CONNECTED = "connected";

const IntegrationInstagramAddAccount = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [isLoadingInstagramData, setIsLoadingInstagramData] = useState(true);
    let [topicList, setTopicList] = useState(false);
    let [fbAccessToken, setFbAccessToken] = useState(false);
    let [fbData, setFbData] = useState(false);
    let [isConnected, setIsConnected] = useState(false);
    let [pageList, setPageList] = useState([]);
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    let [accountTopicVal, setAccountTopicVal] = useState({
        label: "",
        value: ""
    });
    let [pageVal, setPageVal] = useState({
        label: "",
        value: ""
    })
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [errorMessageData, setErrorMessageData] = useState({
        topic: "",
        page: ""
    })

    const back = () => history.push(`/o/${orgID}/integration/instagram`);

    let doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getTopics = async (orgID) => {
        setIsLoadingInstagramData(true);

        topicServices.getTopic(orgID, "listss", null, (response) => {
            let _retriveDataError = {...retriveDataError};
        
            let dataResult = response.dataResult;

            if(response.dataResult.status === 200) {
                const data = response.dataResult.data.topics;
                const createList = () => {
                    let newData = [];
                    data.map(val => {
                        let _data = {};
                        _data.value = val.id;
                        _data.label = val.name;
                        newData.push(_data);
                        return null;
                    });
                    setTopicList(newData);
                };

                createList();
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
                setIsLoadingInstagramData(false);
            }

        });
    }

    let getFbData = async (orgID) => {
        setIsLoadingInstagramData(true);

        IntegrationInstagramServices.getFB(orgID, {platform: "instagram"}, (response) => {
            let _retriveDataError = {...retriveDataError};
            let dataResult = response.dataResult;
            
            if(response.dataResult.status === 200) {
                // setInstagramAccountData(response.dataResult.data.channels);
                setFbData(dataResult.data);
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }
            
            setIsLoadingInstagramData(false);
        });
    }

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        if(orgID) {
            //fb script
            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
            //fb script

            getTopics(orgID);
        }
    }, [orgID])

    useEffect(() => {
        if(topicList) {
            getFbData(orgID)
        }
    }, [topicList])

    useEffect(() => {
        if(isConnected) {
            window.FB.api("/me/accounts/", (r) => {
                if(r.data.length > 0) {
                    let _list = [];
                    r.data.map((val) => {
                        _list.push({
                            value: val.id,
                            label: val.name
                        })
                        return null;
                    })

                    setPageList(_list);
                }
            })
        }
    }, [isConnected])

    useEffect(() => {
        if(fbData && window.FB) {
            window.FB.init({
                appId: fbData.appID,
                cookie: true,
                xfbml: true,
                version: fbData.graphAPIVersion
            });
        }
    }, [fbData])

    let onSelectTopic = (value) => {
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.topic = "";
        setErrorMessageData(_errorMessageData);
        setAccountTopicVal(value)
    }

    let onSelectPage = (value) => {
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.page = "";
        setErrorMessageData(_errorMessageData);
        setPageVal(value)
    }

    // let isFormValid = () => {
    //     return (pageVal.value !== "" && accountTopicVal.value !== "" && isConnected);
    // }

    let connectTo = () => {
        window.FB.login(
            (r) => { 
                setIsConnected(r.status === CONNECTED) 

                if(r.status === CONNECTED) {
                    setFbAccessToken(r.authResponse.accessToken);
                } 
            }, 
            { scope: 'instagram_manage_messages,pages_manage_metadata,instagram_basic,business_management', return_scopes: true }
        )
    }

    let logoutFrom = () => {
        window.FB.logout(function(r) {
            setIsConnected(false)
        });

        setPageVal({
            label: "",
            value: ""
        })

        setAccountTopicVal({
            label: "",
            value: ""
        })
    }

    let onSubmit = () => {
        let errorCount = 0;
        let errorClass = "";
        let _errorMessageData = {...errorMessageData};

        if(pageVal.value === "") {
            _errorMessageData.page = "Please select page";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-page" }
        }

        if(accountTopicVal.value === "") {
            _errorMessageData.topic = "Please select topic";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-topic" }
        }

        if(errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        }else {
            setIsLoadingSubmit(true);

            let data = {
                "topicID": accountTopicVal.value,
                "fbAppID": fbData.appID,
                "fbUserAccessToken": fbAccessToken,
                "fbPageID": pageVal.value
            };

            IntegrationInstagramServices.create(orgID, data, (response) => {
                let dataResult = response.dataResult;

                if(dataResult.status === 200) {
                    if(dataResult.data.success) {
                        doToast(dataResult.data.message);
                        props.setMediumLastRun(new Date());
                        setTimeout(() => {
                            props.history.push(`/o/${orgID}/integration/instagram`);
                        }, 1000);
                    }else {
                        doToast(dataResult.data.message, 'fail');
                    }
                }else {
                    doToast(dataResult.error.message, "fail");
                    setIsLoadingSubmit(false);
                }

            })
        }
    }

    return (
        <div className="integration-outer-wrapper">
            {/* <Helmet>
                <script>
                    (function(d, s, id){
                        var js, fjs = d.getElementsByTagName(s)[0];
                        if (d.getElementById(id)) {return;}
                        js = d.createElement(s); js.id = id;
                        js.src = "https://connect.facebook.net/en_US/sdk.js";
                        fjs.parentNode.insertBefore(js, fjs);
                    }(document, 'script', 'facebook-jssdk'));
                </script>
            </Helmet> */}
            
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >   
                {/* <Integrations activeIntegrationChannel="instagram" parentProps={props} orgIDProps={orgID} /> */}
                <div className="sectionWrap integration-main-container integration-instagram-add-account text-left">
                    {isLoadingInstagramData || retriveDataError.code ?
                        <RetrieveDataLoading
                            isLoading={isLoadingInstagramData}
                            errorMessage={retriveDataError.message}
                        />
                        :
                        <>
                            <BackButton text="Back to Instagram DM" onClick={back} />

                            <div className="integration-instagram-add-account-box">
                                <div className="integration-instagram-add-account-box-top">
                                    <b>Add Channel</b>

                                    {!isLoadingSubmit ?
                                        <ButtonWithLoadingOrIcon 
                                            // isDisabled={!isFormValid()} 
                                            isDisabled={!isConnected}
                                            onClickAction={onSubmit}
                                            className="orange-button main-button-40"
                                            text="Create Channel"
                                            position="left"
                                        />
                                        :
                                        <ButtonWithLoadingOrIcon 
                                            isLoading
                                            loadingColor="gray"
                                            isDisabled
                                            onClickAction={onSubmit}
                                            className="orange-button main-button-40"
                                            text="Create Channel"
                                            position="left"
                                        />
                                    }
                                </div>

                                <div className="top-component">
                                    <div className="alert-component">
                                        <FiAlertCircle />
                                        To learn more regarding Instagram DM Integration, you can check this 
                                        <a target="_blank" rel="noopener noreferrer" href="https://docs.taptalk.io/onetalk-omnichannel-documentation/onetalk-channel-integration/instagram-dm-integration">
                                            <b> Documentation.</b>
                                        </a>
                                    </div>

                                    <div className="facebook-component">
                                        <p>
                                            Ensure that you have requirement below to connect with your Instagram DM channel.
                                        </p>

                                        <ul>
                                            <li>
                                                An <a target="_blank" href="https://help.instagram.com/502981923235522" rel="noopener noreferrer">Instagram Business Account</a> or <a href="https://help.instagram.com/2358103564437429">Instagram Creator Account</a>
                                            </li>
                                            <li>
                                                A <a target="_blank" href="https://help.instagram.com/399237934150902" rel="noopener noreferrer">Facebook Page Connected</a> to your Instagram account
                                            </li>
                                        </ul>

                                        {!isConnected ?
                                            <ButtonWithLoadingOrIcon 
                                                icon={{
                                                    src: FacebookIcon,
                                                    type: "img"
                                                }}
                                                position="left"
                                                text={`Continue with Facebook`}
                                                onClickAction={connectTo}
                                                className="main-button-48 facebook-button"
                                            />
                                            :
                                            <ButtonWithLoadingOrIcon 
                                                icon={{
                                                    src: FacebookIcon,
                                                    type: "img"
                                                }}
                                                position="left"
                                                text={`Logout`}
                                                onClickAction={logoutFrom}
                                                className="main-button-48 facebook-button"
                                            />
                                        }
                                    </div>
                                </div>
                                
                                {isConnected &&
                                    <div className="integration-instagram-add-account-box-form">
                                        <label><b>FB Page</b></label>
                                        <CustomSelectOption
                                            optionListProps={pageList}
                                            valueDropdownProps={pageVal}
                                            placeholderProps={"Select pages"}
                                            onClickDropDownListOptionProps={onSelectPage}
                                            specificClassNameProps="custom-select-instagram-topic"
                                            isDisabled={isLoadingSubmit}
                                            _className={`input-page ${errorMessageData.page !== "" ? "border-red" : ""}`}
                                        />

                                        <ErrorOrInfoComp
                                            text={errorMessageData.page === "" ? "This is the Facebook Page which the Instagram account is connected to." : errorMessageData.page}
                                            _className={errorMessageData.page === "" ? "" : "font-red"}
                                            icon={<FiInfo />}
                                        />
                                        {/* <span className="integration-info-form">
                                            This is the Facebook Page which the Instagram account is connected to.
                                        </span> */}

                                        <label><b>Topic</b></label>
                                        <CustomSelectOption
                                            optionListProps={topicList}
                                            valueDropdownProps={accountTopicVal}
                                            placeholderProps={"Select account topic"}
                                            onClickDropDownListOptionProps={onSelectTopic}
                                            specificClassNameProps="custom-select-instagram-topic"
                                            isDisabled={isLoadingSubmit}
                                            _className={`input-topic ${errorMessageData.topic !== "" ? "border-red" : ""}`}
                                        />

                                        <ErrorOrInfoComp
                                            text={errorMessageData.topic === "" ? "Incoming messages on this channel will be assigned to this topic." : errorMessageData.page}
                                            _className={errorMessageData.topic === "" ? "" : "font-red"}
                                            icon={<FiInfo />}
                                        />
                                        {/* <span className="integration-info-form">
                                            Incoming messages on this channel will be assigned to this topic.
                                        </span> */}
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
            </SectionWrap>
        </div>
    )
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = {
    setMediumLastRun
}
  
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationInstagramAddAccount);