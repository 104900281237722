import React, { useState, useEffect } from 'react';
import './CreateMessageTemplate.scss';
import { FiMessageSquare, FiCheck, FiPlus, FiTrash2, FiInfo, FiFile, FiVideo, FiImage, FiHelpCircle } from "react-icons/fi";
import IntegrationWhatsappBusinessServices from "../../../../../../services/integration/IntegrationWhatsappBusinessServices";
import WhatsappPreview from '../../../../../reuseableComponent/whatsappPreview/WhatsappPreview';
import SectionWrap from '../../../../../reuseableComponent/sectionWrap/SectionWrap';
import CustomSelectOption from '../../../../../reuseableComponent/customSelectOption/CustomSelectOption';
import BackButton from '../../../../../reuseableComponent/BackButton/BackButton';
import RetrieveDataLoading from "../../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from '../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp';
import TemplateUpload from "./templateUpload/TemplateUpload";
import ServiceOrganization from '../../../../../../services/organization/ServiceOrganization';
import { checkID, scrollToClass, doToast } from '../../../../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../../../helper/HelperGetLocalStorage";
import HelperInput from "../../../../../../helper/HelperInput";
import TextPurple from "../../../../../../assets/img/icon-text-purple.svg";
import { FiAlertCircle } from 'react-icons/fi';
import CustomTextEditor from './customTextEditor/CustomTextEditor';
import { Tooltip } from 'reactstrap';
import mixpanel from "mixpanel-browser";

const BUTTON_LIST = [
  {
    value: "none",
    label: "None"
  },
  {
    value: "call_to_action",
    label: "Call to Action"
  },
  {
    value: "quick_reply",
    label: "Quick Reply"
  },
];

const TYPE_OF_ACTION = [
  {
    value: "phone_number",
    label: "Phone Number"
  },
  {
    value: "url",
    label: "Visit Website"
  },
];

const TYPE_OF_URL = [
  {
    value: "static",
    label: "Static"
  },
  {
    value: "dynamic",
    label: "Dynamic"
  },
]

const MAX_CHAR = {
  head: 60,
  body: 1024,
  foot: 60
}

const DEFAULT_AUTHENTICATION = {
  languageCode: "",
  languageVal: {
    value: "",
    label: "",
    error: ""
  },
  body: "",
  footer: "",
  footerTime: "",
  footerTimeError: "",
  buttonText: "",
  textError: ""
};

const DEFAULT_TEMPLATE = {
  file: false,
  disabledChangeHeader: false,
  headerText: "",
  bodyText: "",
  bodyTextError: "",
  footerText: "",
  languageCode: "",
  languageVal: {
    value: "",
    label: "",
    error: ""
  },
  headerFormat: "document",
  sampleParameters: {
    header: [],
    body: [],
    footer: []
  },
  authentication: JSON.parse(JSON.stringify(DEFAULT_AUTHENTICATION)),
  buttonType: "",
  button: BUTTON_LIST[0],
  buttons: undefined
};

const DEFAULT_BUTTON_CALL_TO_ACTION = {
  typeOfActionError: "",
  text: "",
  textError: "",
  lastValueError: "",
  buttonSampleParamError: "",
  subType: "",
  typeOfAction: {
    value: "",
    label: ""
  },
  lastValue: "",
  urlType: {
    value: '',
    label: ''
  },
  urlTypeError: ""
}

const DEFAULT_BUTTON_QUICK_REPLY = {
  text: "",
  textError: "",
  payload: "",
  subType: "quick_reply",
  quickReplyPayload: ""
}

const TEXT_FORMAT = {
  bold: "*",
  italic: "_",
  strikethrough: "~",
  monospace: "```"
}

const TEMPLATE_TYPE_LIST = [
  {
    title: "Text",
    description: "Send messages as plain text",
    value: "text",
    img: {
      type: "img",
      src: TextPurple
    }
  },
  {
    title: "Static Media",
    description: "You can’t change the media (fixed)",
    value: "static-media",
    value2: "media",
    img: {
      type: "svg",
      src: <FiImage />
    }
  },
  {
    title: "Dynamic Media",
    description: "You can change the media  ",
    value: "dynamic-media",
    value2: "media",
    img: {
      type: "svg",
      src: <FiImage />
    }
  }
]

const HEADER_MEDIA_LIST = [
  {
    title: "Document",
    value: "document",
    icon: <FiFile />
  },
  {
    title: "Image",
    value: "image",
    icon: <FiImage />
  },
  {
    title: "Video",
    value: "video",
    icon: <FiVideo />
  }
]

const IntegrationWabaCreateMessageTemplate = (props) => {
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [isLoadingGetOptions, setIsLoadingGetOptioms] = useState(true);
  const [isLoadingCreateMessageTemplate, setIsLoadingCreateMessageTemplate] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [languageCodeList, setLanguageCodeList] = useState([]);
  const [createTemplateMessageVal, setCreateTemplateMessageVal] = useState({
    name: "",
    nameError: "",
    category: {
      value: "",
      label: ""
    },
    categoryError: "",
    template: [JSON.parse(JSON.stringify(DEFAULT_TEMPLATE))],
  });
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [templateTypeVal, setTemplateTypeVal] = useState("text");
  let [showTooltipAddSampleParam, setShowTooltipAddSampleParam] = useState(false);

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getOptionList(orgID);
    }
  }, [orgID]);

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  const getOptionList = async (orgID) => {
    setIsLoadingGetOptioms(true);

    IntegrationWhatsappBusinessServices.categoryOption(orgID, { channelIDoptional: match.params.id }, (response) => {
      const data = response.dataResult.data;

      const createList = (data) => {
        let newData = [];
        data.map((val) => {
          let _data = {};
          _data.value = val.code;
          _data.label = val.name;
          newData.push(_data);
          return null;
        });

        return newData;
      };

      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        setCategoryList(createList(data.categories));
        setLanguageCodeList(createList(data.languages));
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingGetOptioms(false);

      // createList();
    });
  };

  let toogleToottipAddSampeParam = () => {
    setShowTooltipAddSampleParam(!showTooltipAddSampleParam);
  }

  let isFormValid = () => {
    // let _createTemplateMessageVal = {...createTemplateMessageVal};

    let isValid = true;

    // _createTemplateMessageVal.template.map((value) => {
    //   if(value.languageCode === "") {
    //     isValid = false;
    //   }

    //   return null;
    // })

    // if(_createTemplateMessageVal.category.value !== "AUTHENTICATION" && _createTemplateMessageVal.category.value !== "") {
    //   _createTemplateMessageVal.template.map((value) => {
    //     // if(value.bodyText === "") {
    //     //   isValid = false;
    //     // }

    //     if(templateTypeVal !== "text") {
    //       if(value.headerFormat === "") {
    //         isValid = false;
    //       }

    //       if(templateTypeVal === "static-media" && value.headerText === "") {
    //         isValid = false;
    //       }
    //     }

    //     if(value.sampleParameters.header.length > 0) {
    //       value.sampleParameters.header.map(v => {
    //         if(v.value === "") {
    //           isValid = false;
    //         }

    //         return null;
    //       });
    //     }

    //     if(value.sampleParameters.body.length > 0) {
    //       value.sampleParameters.body.map(v => {
    //         if(v.value === "") {
    //           isValid = false;
    //         }

    //         return null;
    //       });
    //     }

    //     return null;
    //   });
    // }

    // if(_createTemplateMessageVal.category.value === "AUTHENTICATION" && _createTemplateMessageVal.category.value !== "") {
    //   _createTemplateMessageVal.template.map((value) => {
    //     if(value.authentication.text === "") {
    //       isValid = false;
    //     }

    //     return null;
    //   })
    // }

    return isValid;
  };

  let back = (id) => {
    history.push({
      pathname: `/o/${orgID}/integration/whatsapp-business-api/detail/${id}`,
      state: {
        whichTab: "requestMessageTemplate"
      }
    })
  }

  let filterLangList = (currentIndex) => {
    let _createTemplateMessageVal = { ...createTemplateMessageVal };
    let _langList = languageCodeList.slice();

    // _langList.map((valLang, indexLang) => {
    //   _createTemplateMessageVal.template.map((valTemp, indexTemp) => {
    //     if (valLang.value === valTemp.languageCode && currentIndex !== indexTemp) {
    //       _langList.splice(indexLang, 1);
    //     }

    //     return null;
    //   })

    //   return null;
    // })

    // console.log(_langList)
    let newArr = [];

    _langList.map(v => {
      let findIdxVal = _createTemplateMessageVal.template.findIndex(_v => _v.languageCode === v.value);

      if (findIdxVal === -1 || findIdxVal === currentIndex) {
        newArr.push(v);
      }

      return null;
    })

    return newArr;
  }

  let filterTypeOfAction = (i, parentIdx) => {
    let _createTemplateMessageVal = { ...createTemplateMessageVal };
    let _typeOfAction = TYPE_OF_ACTION.slice();
    let countVisitWebsite = 0;

    _createTemplateMessageVal.template[parentIdx].buttons.map((valTemp, indexTemp) => {
      if (valTemp.typeOfAction.value === "url") {
        countVisitWebsite = countVisitWebsite + 1;
      }

      return null;
    });

    _createTemplateMessageVal.template[parentIdx].buttons.map((valTemp, indexTemp) => {
      _typeOfAction.map((valType, indexType) => {
        if (valType.value === "url") {
          if (countVisitWebsite === 2 && valTemp.typeOfAction.value !== "url") {
            _typeOfAction.splice(indexType, 1);
          }
        } else {
          if (valType.value === valTemp.typeOfAction.value && i !== indexTemp) {
            _typeOfAction.splice(indexType, 1);
          }
        }

        return null;
      })

      return null;
    })


    return _typeOfAction;
  }

  let changeInput = (e) => {
    let _createTemplateMessageVal = { ...createTemplateMessageVal };
    if (e.target.id === "name" && HelperInput.alphanumericAndUnderscoreOnly(e.target.value.toLowerCase())) {
      _createTemplateMessageVal[e.target.id] = e.target.value.toLowerCase();
      _createTemplateMessageVal["nameError"] = "";
    }

    if (e.target.id !== "name") {
      _createTemplateMessageVal[e.target.id] = e.target.value;
    }

    setCreateTemplateMessageVal(_createTemplateMessageVal);
  }

  let onSelectOption = (e, key) => {
    let _createTemplateMessageVal = { ...createTemplateMessageVal };
    _createTemplateMessageVal[key] = e;
    _createTemplateMessageVal.template = [JSON.parse(JSON.stringify(DEFAULT_TEMPLATE))];

    if (key === "category") {
      _createTemplateMessageVal.categoryError = "";
    }

    setCreateTemplateMessageVal(_createTemplateMessageVal);
  }

  let onSelectButton = (e, ind) => {
    let _createTemplateMessageVal = { ...createTemplateMessageVal };
    _createTemplateMessageVal.template[ind].button = e;
    _createTemplateMessageVal.template[ind].buttonType = e.value;

    if (e.value === "none") {
      _createTemplateMessageVal.template[ind].buttons = undefined;
      delete _createTemplateMessageVal.template[ind].sampleParameters.buttons;
    }

    if (e.value === "call_to_action") {
      _createTemplateMessageVal.template[ind].buttons = [JSON.parse(JSON.stringify(DEFAULT_BUTTON_CALL_TO_ACTION))];
    }

    if (e.value === "quick_reply") {
      _createTemplateMessageVal.template[ind].buttons = [JSON.parse(JSON.stringify(DEFAULT_BUTTON_QUICK_REPLY))];

      delete _createTemplateMessageVal.template[ind].sampleParameters.buttons;
    }

    setCreateTemplateMessageVal(_createTemplateMessageVal);
  }

  let onSelectLanguage = (e, index,) => {
    let _createTemplateMessageVal = { ...createTemplateMessageVal };
    _createTemplateMessageVal.template[index].languageCode = e.value;
    _createTemplateMessageVal.template[index].languageVal = e;
    _createTemplateMessageVal.template[index].languageVal.error = "";
    setCreateTemplateMessageVal(_createTemplateMessageVal);
  }

  let onChangeInputTemplate = (e, index, part) => {
    if (e.target.id === "footerText") {
      if (!HelperInput.withEmoji(e.target.value)) {
        let _createTemplateMessageVal = { ...createTemplateMessageVal };
        _createTemplateMessageVal.template[index][e.target.id] = e.target.value;
        setCreateTemplateMessageVal(_createTemplateMessageVal);
      }
    } else {
      let limitVariable = e.target.id === "headerText" ? 1 : 9999;
      let _createTemplateMessageVal = { ...createTemplateMessageVal };
      _createTemplateMessageVal.template[index][e.target.id] = e.target.value;

      var re = /\{\{([^{}]*)\}\}/g;
      var m;
      var match = [];

      do {
        m = re.exec(e.target.value);
        if (m) {
          if (m[1] !== "") {
            match.push(m[1]);
          }
        }
      } while (m);

      let findVarIndex = (name, i, part) => {
        return _createTemplateMessageVal.template[i].sampleParameters[part].findIndex(v => v.name === name);
      }

      //add var
      if (match.length <= limitVariable) {
        if (onAddVariable) {
          match.map(v => {

            if (findVarIndex(v, index, part) === -1 && !HelperInput.isContainUppercase(v) && HelperInput.alphanumericAndUnderscoreOnly(v)) {
              onAddVariable(part, v, index, e.target.value);
            }

            return null;
          })
        }
      }
      //add var

      //remove var
      if (onRemoveVariable) {
        if (_createTemplateMessageVal.template[index].sampleParameters[part].length > 0) {
          _createTemplateMessageVal.template[index].sampleParameters[part].map((v) => {
            if (match.findIndex(_v => _v === v.name) === -1) {
              onRemoveVariable(part, v.name, index, e.target.value);
            }

            return null;
          })
        }
      }
      //remove var

      _createTemplateMessageVal.template[index].sampleParameters[part].map((v) => {
        v.indexPosition = e.target.value.indexOf(`{{${v.name}}}`);

        return null;
      })

      if (e.target.id === "bodyText") {
        _createTemplateMessageVal.template[index].bodyTextError = "";
      }

      reArrange(_createTemplateMessageVal.template[index].sampleParameters[part]);
      setCreateTemplateMessageVal(_createTemplateMessageVal);
    }
  }

  let onChangeHeaderFormat = (val, index) => {
    if (!isLoadingCreateMessageTemplate) {
      let _createTemplateMessageVal = { ...createTemplateMessageVal };

      if (!_createTemplateMessageVal.template[index].disabledChangeHeader) {
        _createTemplateMessageVal.template[index].headerFormat = val;
        _createTemplateMessageVal.template[index].file = false;
        _createTemplateMessageVal.template[index].headerText = "";
        setCreateTemplateMessageVal(_createTemplateMessageVal);
      }
    }
  }

  let runSetTemplate = (val, index) => {
    let _createTemplateMessageVal = { ...createTemplateMessageVal };
    if (templateTypeVal === "dynamic-media") {
      _createTemplateMessageVal.template[index].sampleParameters.header = [
        {
          name: val.headerFormat,
          value: val.headerText
        }
      ];

      val.headerText = "";
    }

    _createTemplateMessageVal.template[index] = val;
    setCreateTemplateMessageVal(_createTemplateMessageVal);
  }

  let onChangeSampleParam = (part, idx, val, mainIndex) => {
    let _createTemplateMessageVal = { ...createTemplateMessageVal };
    _createTemplateMessageVal.template[mainIndex].sampleParameters[part][idx].value = val;

    _createTemplateMessageVal.template[mainIndex].sampleParameters[part][idx].error = "";

    setCreateTemplateMessageVal(_createTemplateMessageVal);
  }

  let printSampleParam = (part, idx, val, mainIndex) => {
    return (
      <div className={`sample-param-input-wrapper sample-param-${part}-${idx}`} key={`sample-${part}-${idx}`}>
        <p><b>{`{{${val.name}}}`}</b></p>

        <span className={`max-char-variable ${val.error !== "Characters exceed limit" ? 'grey-font' : 'red-text'}`}>
          {val.value.length}/50
        </span>
        <input
          type="text"
          placeholder={`sample content for {{${val.name}}}`}
          value={val.value}
          onChange={(e) => onChangeSampleParam(part, idx, e.target.value, mainIndex)}
          disabled={isLoadingCreateMessageTemplate}
          className={`${val.error !== "" ? 'border-red' : ''}`}
        />

        {val.error !== "" &&
          <ErrorOrInfoComp
            text={val.error}
            icon={<FiInfo />}
            _className="red-text"
          />
        }
      </div>
    )
  }

  let reArrange = (ar) => {
    let orderMethod = () => {
      let run = false;

      if (ar.length > 1) {
        for (let i = 0; i < ar.length; i++) {

          if (i !== (ar.length - 1)) {
            if (ar[i].indexPosition > ar[i + 1].indexPosition) {
              let temp = ar[i + 1];

              ar[i + 1] = ar[i];
              ar[i] = temp;

              run = true;
              break;
            }
          }
        }

        if (run) {
          orderMethod(ar);
        }
      }

      return ar;
    }

    return orderMethod(ar);
  }

  let onAddVariable = (part, val, i, fullText) => {
    let _createTemplateMessageVal = { ...createTemplateMessageVal };

    _createTemplateMessageVal.template[i].sampleParameters[part].push({
      "name": val,
      "value": "",
      "error": ""
    })

    _createTemplateMessageVal.template[i].sampleParameters[part].map((v) => {
      v.indexPosition = fullText.indexOf(`{{${v.name}}}`);

      return null;
    })

    reArrange(_createTemplateMessageVal.template[i].sampleParameters[part]);
    // _createTemplateMessageVal.template[i].sampleParameters[part] = _createTemplateMessageVal.template[i].sampleParameters[part];

    setCreateTemplateMessageVal(_createTemplateMessageVal);
  }

  let onRemoveVariable = (part, val, i, fullText) => {
    let _createTemplateMessageVal = { ...createTemplateMessageVal };
    let _findIndex = _createTemplateMessageVal.template[i].sampleParameters[part].findIndex(v => v.name === val);

    if (_findIndex !== -1) {
      _createTemplateMessageVal.template[i].sampleParameters[part].splice(_findIndex, 1);
    }

    _createTemplateMessageVal.template[i].sampleParameters[part].map((v) => {
      v.indexPosition = fullText.indexOf(`{{${v.name}}}`);

      return null;
    })

    reArrange(_createTemplateMessageVal.template[i].sampleParameters[part]);
    setCreateTemplateMessageVal(_createTemplateMessageVal);
  }

  let onClearVariable = (part, i) => {
    let _createTemplateMessageVal = { ...createTemplateMessageVal };
    _createTemplateMessageVal.template[i].sampleParameters[part] = [];

    setCreateTemplateMessageVal(_createTemplateMessageVal);
  }

  let printTemplate = (val, ind) => {
    //action
    // let runSetHeaderText = (index, text) => {
    //   if(text.length <= MAX_CHAR.head) {
    //     let _createTemplateMessageVal = {...createTemplateMessageVal};
    //     _createTemplateMessageVal.template[index].headerText = text;
    //     setCreateTemplateMessageVal(_createTemplateMessageVal);
    //   }
    // }

    let runSetBodyText = (index, text) => {
      if (text.length <= MAX_CHAR.body) {
        let _createTemplateMessageVal = { ...createTemplateMessageVal };
        _createTemplateMessageVal.template[index].bodyText = text;
        _createTemplateMessageVal.template[index].bodyTextError = "";
        setCreateTemplateMessageVal(_createTemplateMessageVal);
      }
    }

    let onSubmitVariableHeader = (e, index, val) => {
      e.preventDefault();

      let _createTemplateMessageVal = { ...createTemplateMessageVal };
      // _createTemplateMessageVal.template[index].sampleParameters.header.push({
      //     "name": val,
      //     "value": ""
      // })

      // let paramLength =  _createTemplateMessageVal.template[index].sampleParameters.header.length;
      let _headerText = _createTemplateMessageVal.template[index].headerText;
      // let _headerTextIndexed = `${_headerText}{{${paramLength === 1 ? 1 : paramLength + 1}}}`;
      _headerText = `${_headerText}{{${val}}}`;

      onAddVariable("header", val, index, _headerText);

      if (_headerText.length <= MAX_CHAR.head) {
        _createTemplateMessageVal.template[index].headerText = _headerText;
        // _createTemplateMessageVal.template[index].headerTextIndexed = _headerTextIndexed;
        setCreateTemplateMessageVal(_createTemplateMessageVal);
      }
    }

    let onSubmitVariableBody = (e, index, val) => {
      e.preventDefault();
      
      let _createTemplateMessageVal = { ...createTemplateMessageVal };

      if(_createTemplateMessageVal.template[index].sampleParameters.body.length === 0 || _createTemplateMessageVal.template[index].sampleParameters.body.findIndex(v => v.name === val) === -1) {
        // _createTemplateMessageVal.template[index].sampleParameters.body.push({
        //   "name": val,
        //   "value": ""
        // })
  
        // let paramLength =  _createTemplateMessageVal.template[index].sampleParameters.body.length;
        let _bodyText = _createTemplateMessageVal.template[index].bodyText;
        // let _bodyTextIndexed = `${_bodyText}{{${paramLength === 1 ? 1 : paramLength + 1}}}`;
        _bodyText = `${_bodyText}{{${val}}}`;
  
        onAddVariable("body", val, index, _bodyText);
        // _createTemplateMessageVal.template[index].bodyTextIndexed = _bodyTextIndexed;
  
        runSetBodyText(index, _bodyText);
      }else {
        doToast(`Parameter '${val}' is exist`, "fail");
      }
    }

    let runTextFormat = (format, index) => {
      if (!isLoadingCreateMessageTemplate) {
        let bodyTarget = document.querySelectorAll("#bodyText")[index];
        let selectStart = bodyTarget.selectionStart;
        var selectFinish = bodyTarget.selectionEnd;
        let _createTemplateMessageVal = { ...createTemplateMessageVal };
        // let _headerText = _createTemplateMessageVal.template[index].headerText;
        let _bodyText = _createTemplateMessageVal.template[index].bodyText;
        let allTextFormat = _bodyText.substring(selectStart, selectFinish);
        let textFormatTrim = allTextFormat.trim();
        let textFormatSplit = allTextFormat.split(textFormatTrim);


        // if(part === "headerText") {
        //   _headerText = _headerText.substring(0, selectStart)+TEXT_FORMAT[format]+_headerText.substring(selectStart, selectFinish)+TEXT_FORMAT[format]+_headerText.substring(selectFinish, _headerText.length);
        //   runSetHeaderText(index, _headerText);
        // }

        // if(part === "bodyText") {
        // _bodyText = _bodyText.substring(0, selectStart) + TEXT_FORMAT[format] + _bodyText.substring(selectStart, selectFinish) + TEXT_FORMAT[format] + _bodyText.substring(selectFinish, _bodyText.length);
        _bodyText = _bodyText.substring(0, selectStart) + TEXT_FORMAT[format] + textFormatTrim + TEXT_FORMAT[format] + (textFormatSplit[0] === textFormatSplit[1] ? "" : textFormatSplit[1]) + _bodyText.substring(selectFinish, _bodyText.length);
        runSetBodyText(index, _bodyText);
        // }
      }
    }
    //action

    let onChangeCheckboxAuth = (e, part, idx) => {
      let _createTemplateMessageVal = { ...createTemplateMessageVal };
      let text = "";

      if (part === "body") {
        if (e.target.checked) {
          text = " For your security, do not share this code.";
        }
      }

      if (part === "footer") {
        if (e.target.checked) {
          text = "This code expires in <NUM_MINUTES> minutes";
        }
      }

      _createTemplateMessageVal.template[idx].authentication[part] = text;

      setCreateTemplateMessageVal(_createTemplateMessageVal);
    }

    let onChangeFooterTime = (e, idx) => {
      let _createTemplateMessageVal = { ...createTemplateMessageVal };

      if (e.target.value === "" || (HelperInput.numberOnly(e.target.value) && parseInt(e.target.value) < 91)) {
        _createTemplateMessageVal.template[idx].authentication.footerTime = e.target.value;
      }

      if (e.target.value === "0") {
        _createTemplateMessageVal.template[idx].authentication.footerTime = 1;
      }

      _createTemplateMessageVal.template[idx].authentication.footerTimeError = "";

      setCreateTemplateMessageVal(_createTemplateMessageVal);
    }

    let onChangetext = (e, idx) => {
      let _createTemplateMessageVal = { ...createTemplateMessageVal };

      _createTemplateMessageVal.template[idx].authentication.buttonText = e.target.value;
      _createTemplateMessageVal.template[idx].authentication.textError = "";

      setCreateTemplateMessageVal(_createTemplateMessageVal);
    }

    let onSelectTypeOfAction = (e, i, parentIdx) => {
      let _createTemplateMessageVal = { ...createTemplateMessageVal };

      actionRemoveButtonParam(parentIdx, i, _createTemplateMessageVal, (data) => {
        data.template[parentIdx].buttons[i].urlType = { value: '', label: '' }
        data.template[parentIdx].buttons[i].subType = e.value;
        data.template[parentIdx].buttons[i].typeOfAction = e;
        data.template[parentIdx].buttons[i].typeOfActionError = "";
        data.template[parentIdx].buttons[i].lastValue = "";
        data.template[parentIdx].buttons[i].lastValueError = "";
        data.template[parentIdx].buttons[i].buttonSampleParamError = "";

        setCreateTemplateMessageVal(data);
      });
    }

    let onSelectURL = (e, i, parentIdx) => {
      let _createTemplateMessageVal = { ...createTemplateMessageVal };

      _createTemplateMessageVal.template[parentIdx].buttons[i].urlType = e;
      _createTemplateMessageVal.template[parentIdx].buttons[i].urlTypeError = "";

      if (e.value === "dynamic") {
        if (!_createTemplateMessageVal.template[parentIdx].sampleParameters.buttons) {
          _createTemplateMessageVal.template[parentIdx].sampleParameters.buttons = [];
        }

        let idxExist = _createTemplateMessageVal.template[parentIdx].sampleParameters.buttons.findIndex(v => v.name === `url_${i}`);

        if (idxExist === -1) {
          _createTemplateMessageVal.template[parentIdx].sampleParameters.buttons.push(
            {
              name: `url_${i}`,
              value: ""
            }
          );
        }

        setCreateTemplateMessageVal(_createTemplateMessageVal);
      } else {
        actionRemoveButtonParam(parentIdx, i, _createTemplateMessageVal, (data) => {
          setCreateTemplateMessageVal(data);
        });
      }
    }

    let onClickAddAnotherButton = (parentIdx) => {
      if (!isLoadingCreateMessageTemplate) {
        let _createTemplateMessageVal = { ...createTemplateMessageVal };


        _createTemplateMessageVal.template[parentIdx].buttons.push(
          _createTemplateMessageVal.template[parentIdx].button.value === "call_to_action" ?
            JSON.parse(JSON.stringify(DEFAULT_BUTTON_CALL_TO_ACTION))
            :
            JSON.parse(JSON.stringify(DEFAULT_BUTTON_QUICK_REPLY))
        );
        setCreateTemplateMessageVal(_createTemplateMessageVal);
      }
    }

    let onClickRemoveButton = (i, parentIdx) => {
      if (!isLoadingCreateMessageTemplate) {
        let _createTemplateMessageVal = { ...createTemplateMessageVal };

        actionRemoveButtonParam(parentIdx, i, _createTemplateMessageVal, (data) => {
          data.template[parentIdx].buttons.splice(i, 1);
          setCreateTemplateMessageVal(data);
        })
      }
    }

    let onChangeButtonInput = (e, i, parentIdx, type) => {
      let _createTemplateMessageVal = { ...createTemplateMessageVal };

      if (type === "button-text") {
        _createTemplateMessageVal.template[parentIdx].buttons[i].text = e.target.value;
        _createTemplateMessageVal.template[parentIdx].buttons[i].textError = "";
      }

      if (type === "phone-number") {
        if (HelperInput.numberOnly(e.target.value.replaceAll(" ", ""))) {
          _createTemplateMessageVal.template[parentIdx].buttons[i].lastValue = HelperInput.phoneBeautify(e.target.value.replaceAll(" ", ""));
          _createTemplateMessageVal.template[parentIdx].buttons[i].lastValueError = "";
        }
      }

      if (type === "website-url") {
        _createTemplateMessageVal.template[parentIdx].buttons[i].lastValue = e.target.value;
        _createTemplateMessageVal.template[parentIdx].buttons[i].lastValueError = "";
      }

      if (type === "payload") {
        _createTemplateMessageVal.template[parentIdx].buttons[i].quickReplyPayload = e.target.value;
      }

      setCreateTemplateMessageVal(_createTemplateMessageVal);
    }

    let actionRemoveButtonParam = (parentIndex, index, _data, callback) => {
      if (_data.template[parentIndex].sampleParameters.buttons) {
        let idx = _data.template[parentIndex].sampleParameters.buttons.findIndex(v => v.name === `url_${index}`);

        if (idx !== -1) {
          _data.template[parentIndex].sampleParameters.buttons.splice(idx, 1);

          if (_data.template[parentIndex].sampleParameters.buttons.length === 0) {
            delete _data.template[parentIndex].sampleParameters.buttons;
          }
        }
      }

      callback(_data);
    }

    let onChangeButtonSampleParam = (e, i, parentIdx) => {
      let _createTemplateMessageVal = { ...createTemplateMessageVal };
      let _buttons = createTemplateMessageVal.template[parentIdx].sampleParameters.buttons;

      if (_buttons) {
        let _idx = _buttons.findIndex(v => v.name === `url_${i}`);

        if (_idx !== -1) {
          _buttons[_idx].value = e.target.value;
          _createTemplateMessageVal.template[parentIdx].buttons[i].buttonSampleParamError = "";
        }
      }

      setCreateTemplateMessageVal(_createTemplateMessageVal);
    }

    let printButtonsQuickReply = (v, i, parentIdx, length) => {
      return (
        <div key={`buttons-wrap-${i}`} className='buttons-wrapper'>
          <label>
            <b>Button Text{length > 1 ? ` #${i + 1}` : ""}</b>

            <span className={`${i !== 0 ? "right-18" : ""}`}>{v.text.length}/25 character</span>
          </label>
          <input
            type="text"
            placeholder="Type button text here"
            className={`button-text-${parentIdx}-${i} ${v.textError !== "" ? "border-red" : ""}`}
            value={v.text}
            onChange={(e) => onChangeButtonInput(e, i, parentIdx, "button-text")}
            maxLength={25}
            disabled={isLoadingCreateMessageTemplate}
          />
          {v.textError !== "" &&
            <ErrorOrInfoComp
              text={v.textError}
              _className={`${v.textError !== "" ? "red-text" : ""}`}
              icon={<FiInfo />}
            />
          }

          <label>
            <b>Payload{length > 1 ? ` #${i + 1}` : " "} </b>

            <div className='grey-font'>
              (optional)
            </div>

            <span>{v.quickReplyPayload.length}/128 character</span>
          </label>
          <input
            type="text"
            placeholder="Type payload here"
            className={`button-payload-${parentIdx}-${i}`}
            value={v.quickReplyPayload}
            onChange={(e) => onChangeButtonInput(e, i, parentIdx, "payload")}
            maxLength={128}
            disabled={isLoadingCreateMessageTemplate}
          />

          {i !== 0 &&
            <FiTrash2
              className='button-delete'
              onClick={() => onClickRemoveButton(i, parentIdx)}
            />
          }
        </div>
      )
    }

    let printButtonsCallToAction = (v, i, parentIdx, length) => {
      return (
        <div key={`buttons-wrap-${i}`} className='buttons-wrapper'>
          <label>
            <b>Type of Action</b>
          </label>
          <CustomSelectOption
            optionListProps={filterTypeOfAction(i, parentIdx)}
            valueDropdownProps={v.typeOfAction}
            placeholderProps={'Select type of action'}
            onClickDropDownListOptionProps={(e) => onSelectTypeOfAction(e, i, ind)}
            specificClassNameProps="custom-select-waba-topic"
            dropdownError={v.typeOfActionError}
            _className={`type-of-action-${parentIdx}-${i}`}
            isDisabled={isLoadingCreateMessageTemplate}
          />
          {v.typeOfActionError !== "" &&
            <ErrorOrInfoComp
              text={v.typeOfActionError}
              _className={`${v.typeOfActionError !== "" ? "red-text" : ""}`}
              icon={<FiInfo />}
            />
          }

          <label>
            <b>Button Text</b>

            <span>{v.text.length}/25 character</span>
          </label>
          <input
            type="text"
            placeholder="Type button text here"
            className={`button-text-${parentIdx}-${i} ${v.textError !== "" ? "border-red" : ""}`}
            value={v.text}
            onChange={(e) => onChangeButtonInput(e, i, parentIdx, "button-text")}
            maxLength={25}
            disabled={isLoadingCreateMessageTemplate}
          />
          {v.textError !== "" &&
            <ErrorOrInfoComp
              text={v.textError}
              _className={`${v.textError !== "" ? "red-text" : ""}`}
              icon={<FiInfo />}
            />
          }

          {(v.typeOfAction.value === "phone_number") &&
            <>
              <label>
                <b>Phone Number</b>

                <span>{v.lastValue.length}/20 character</span>
              </label>
              <div className='phone-number-wrapper'>
                <FiPlus />
                <input
                  type="text"
                  placeholder="62 8123 456 789"
                  className={`phone-number-${parentIdx}-${i} ${v.lastValueError !== "" ? "border-red" : ""}`}
                  value={v.lastValue}
                  onChange={(e) => onChangeButtonInput(e, i, parentIdx, "phone-number")}
                  maxLength={20}
                  disabled={isLoadingCreateMessageTemplate}
                />
              </div>
              {v.lastValueError !== "" &&
                <ErrorOrInfoComp
                  text={v.lastValueError}
                  _className={`${v.lastValueError !== "" ? "red-text" : ""}`}
                  icon={<FiInfo />}
                />
              }
            </>
          }

          {(v.typeOfAction.value === "url") &&
            <>
              <label>
                <b>URL Type</b>
              </label>
              <CustomSelectOption
                optionListProps={TYPE_OF_URL}
                valueDropdownProps={v.urlType}
                placeholderProps={'Select url type'}
                onClickDropDownListOptionProps={(e) => onSelectURL(e, i, ind)}
                specificClassNameProps="custom-select-waba-topic"
                dropdownError={v.urlTypeError}
                _className={`url-type-${parentIdx}-${i}`}
                isDisabled={isLoadingCreateMessageTemplate}
              />
              {v.urlTypeError !== "" &&
                <ErrorOrInfoComp
                  text={v.urlTypeError}
                  _className={`${v.urlTypeError !== "" ? "red-text" : ""}`}
                  icon={<FiInfo />}
                />
              }

              <label>
                <b>Website URL</b>

                <span>{v.lastValue.length}/1000 character</span>
              </label>

              <div className={`input-param ${v.urlType.value === "dynamic" ? "param-right" : ""}`}>
                {v.urlType.value === "dynamic" &&
                  <span className='span-param'>
                    <b>{`{{1}}`}</b>
                  </span>
                }
                <input
                  type="text"
                  placeholder="https://example.com"
                  className={`website-url-${parentIdx}-${i} ${v.lastValueError !== "" ? "border-red" : ""}`}
                  value={v.lastValue}
                  onChange={(e) => onChangeButtonInput(e, i, parentIdx, "website-url")}
                  maxLength={1000}
                  disabled={isLoadingCreateMessageTemplate}
                />
              </div>
              {v.lastValueError !== "" &&
                <ErrorOrInfoComp
                  text={v.lastValueError}
                  _className={`${v.lastValueError !== "" ? "red-text" : ""}`}
                  icon={<FiInfo />}
                />
              }

              {v.urlType.value === "dynamic" &&
                <>
                  <label>
                    <b>Add Sample URL</b>

                    <div
                      href="#"
                      id="tooltip-sample-param"
                      onMouseEnter={toogleToottipAddSampeParam}
                      onMouseLeave={toogleToottipAddSampeParam}
                    >
                      <FiHelpCircle className="bc-tootlip-toggle" />
                    </div>

                    <Tooltip
                      flip
                      target="tooltip-sample-param"
                      isOpen={showTooltipAddSampleParam}
                      // toggle={function noRefCheck(){}}
                      placement="right"
                      toogle={toogleToottipAddSampeParam}
                    >
                      Adding a variable creates a personalised link for the customer to view their info.
                    </Tooltip>
                  </label>

                  <div className='input-param param-left'>
                    <span className='span-param'>
                      <b>{`{{1}}`}</b>
                    </span>
                    <input
                      type="text"
                      placeholder={`enter URL for ${v.lastValue === "" ? "https://example.com/{{1}}" : (`${v.lastValue}${v.lastValue[v.lastValue.length - 1] !== "/" ? "/{{1}}" : "{{1}}"}`)}`}
                      className={`button-sample-param-${parentIdx}-${i} ${v.buttonSampleParamError !== "" ? "border-red" : ""}`}
                      value={createTemplateMessageVal.template[parentIdx].sampleParameters.buttons[createTemplateMessageVal.template[parentIdx].sampleParameters.buttons.findIndex(v => v.name === `url_${i}`)].value}
                      onChange={(e) => onChangeButtonSampleParam(e, i, parentIdx)}
                      disabled={isLoadingCreateMessageTemplate}
                    />
                  </div>
                  <ErrorOrInfoComp
                    text={v.buttonSampleParamError === "" ? "Only one variable can be added to the end of the URL" : v.buttonSampleParamError}
                    _className={`${v.buttonSampleParamError !== "" ? "red-text" : ""}`}
                    icon={<FiInfo />}
                  />
                </>
              }
            </>
          }

          {i !== 0 &&
            <FiTrash2
              className='button-delete'
              onClick={() => onClickRemoveButton(i, parentIdx)}
            />
          }
        </div>
      )
    }

    return (
      <div className="integration-waba-create-message-template-account-box-form box-bottom" key={`template-${ind}`}>
        {ind > 0 ?
          <p className="template-detail">
            <FiPlus />
            <b>More Language</b>
          </p>
          :
          <p className="template-detail">
            <FiMessageSquare />
            <b>Message Content</b>
          </p>
        }

        {ind > 0 &&
          <ButtonWithLoadingOrIcon
            className="main-button-40 red-button button-remove-template"
            icon={{
              type: "svg",
              src: FiTrash2
            }}
            position="left"
            text="Delete"
            onClickAction={() => onClickDelete(ind)}
          />
        }

        <div className="box-bottom-left">
          <div className="form-topic">
            <label>
              <b>Language Code</b>
            </label>
            <CustomSelectOption
              optionListProps={filterLangList(ind)}
              valueDropdownProps={val.languageVal}
              placeholderProps={'Select language code'}
              onClickDropDownListOptionProps={(e) => onSelectLanguage(e, ind)}
              specificClassNameProps="custom-select-waba-topic"
              dropdownError={val.languageVal.error}
              _className={`language-code-${ind}`}
              isDisabled={isLoadingCreateMessageTemplate}
            />
            {val.languageVal.error !== "" &&
              <ErrorOrInfoComp
                text={val.languageVal.error}
                _className={"red-text margin-top-0"}
                icon={<FiInfo />}
              />
            }

            {(createTemplateMessageVal.category.value !== "AUTHENTICATION" && createTemplateMessageVal.category.value !== "") &&
              <>
                <label>
                  <b>Header</b> <span className="grey-font">{templateTypeVal === "text" ? ' (optional)' : ''}</span>
                </label>

                {templateTypeVal === "text" ?
                  <>
                    <CustomTextEditor
                      placeholder="Place header"
                      id="headerText"
                      value={val.headerText}
                      onChange={onChangeInputTemplate}
                      index={ind}
                      isHeader
                      maxLength={MAX_CHAR.head}
                      onSubmitVariable={onSubmitVariableHeader}
                      runTextFormat={runTextFormat}
                      limitVariable={1}
                      data={createTemplateMessageVal}
                      addVariable={onAddVariable}
                      removeVariable={onRemoveVariable}
                      clearVariable={onClearVariable}
                      isDisabled={isLoadingCreateMessageTemplate}
                    />

                    {val.sampleParameters.header.length === 0 ?
                      <div className="form-content-wrapper">
                        <p className="input-info-wrapper input-info-template-name">
                          <FiInfo />
                          <b>Header can only have one variable.</b>
                        </p>
                      </div>
                      :
                      <>
                        {val.sampleParameters.header.map((v, i) => {
                          return (
                            printSampleParam("header", i, v, ind)
                          )
                        })}

                        {/* <div className="form-content-wrapper">
                          <p className="input-info-wrapper input-info-template-name">
                            <FiInfo />
                            <b>Add sample to your variables and media to help us review your request</b>
                          </p>
                        </div> */}
                        <ErrorOrInfoComp
                          text={`Add sample to your variables and media to help us review your request`}
                          icon={<FiInfo />}
                          _className={``}
                        />
                      </>
                    }
                  </>
                  :
                  <>
                    <div className="header-media-type-selection-wrapper">
                      <label style={{ paddingTop: 0 }}>
                        <span className="grey-text">Media Type</span>
                      </label>

                      {HEADER_MEDIA_LIST.map((_val, _idx) => {
                        return (
                          <div className={`header-media-selection ${isLoadingCreateMessageTemplate ? "grey-box" : ""} ${val.headerFormat === _val.value ? "active-header-media-selection" : ""}`} key={`header-media-${_idx}`} onClick={() => onChangeHeaderFormat(_val.value, ind)}>
                            <div className={`header-media-icon-wrapper header-media-${_val.value}`}>
                              {_val.icon}
                            </div>

                            {_val.title}
                          </div>
                        )
                      })}

                    </div>

                    {(templateTypeVal === "static-media" || templateTypeVal === "dynamic-media") &&
                      <TemplateUpload
                        {...props}
                        createTemplateMessageVal={val}
                        runSetTemplate={runSetTemplate}
                        orgID={orgID}
                        index={ind}
                        templateType={templateTypeVal}
                        fileType={val.headerFormat}
                        isLoading={isLoadingCreateMessageTemplate}
                      />
                    }
                  </>
                }

                <label>
                  <b>Body</b>
                </label>

                <CustomTextEditor
                  placeholder="Enter message..."
                  id="bodyText"
                  value={val.bodyText}
                  onChange={onChangeInputTemplate}
                  index={ind}
                  isTextarea
                  row={7}
                  maxLength={MAX_CHAR.body}
                  onSubmitVariable={onSubmitVariableBody}
                  runTextFormat={runTextFormat}
                  limitVariable={9999}
                  data={createTemplateMessageVal}
                  addVariable={onAddVariable}
                  removeVariable={onRemoveVariable}
                  clearVariable={onClearVariable}
                  _className={`body-text-${ind} ${val.bodyTextError === "" ? "" : "border-red"}`}
                  isDisabled={isLoadingCreateMessageTemplate}
                />

                {val.sampleParameters.body.length > 0 &&
                  <>
                    {val.sampleParameters.body.map((v, i) => {
                      return (
                        printSampleParam("body", i, v, ind)
                      )
                    })}

                    {/* <div className="form-content-wrapper">
                      <p className="input-info-wrapper input-info-template-name">
                        <FiInfo />
                        <b>Add sample to your variables and media to help us review your request</b>
                      </p>
                    </div> */}
                    <ErrorOrInfoComp
                      text={`Add sample to your variables and media to help us review your request`}
                      icon={<FiInfo />}
                      _className={``}
                    />
                  </>
                }

                {val.bodyTextError !== "" &&
                  <ErrorOrInfoComp
                    text={val.bodyTextError === "" ? "" : val.bodyTextError}
                    _className={val.bodyTextError !== "" ? "red-text" : ""}
                    icon={<FiInfo />}
                  />
                }

                <label>
                  <b>Footer</b> <span className="grey-font"> (optional)</span>
                </label>

                <div className="input-with-counter">
                  <span className="grey-font input-counter">
                    {val.footerText.length}/{MAX_CHAR.foot} characters
                  </span>
                  <input disabled={isLoadingCreateMessageTemplate} type="text" id="footerText" placeholder="E.g Regards, Acme" value={val.footerText} onChange={(e) => onChangeInputTemplate(e, ind)} maxLength={MAX_CHAR.foot} />
                </div>

                <label>
                  <b>Buttons</b> <span className="grey-font"> (optional)</span>
                </label>

                <CustomSelectOption
                  optionListProps={BUTTON_LIST}
                  valueDropdownProps={val.button}
                  placeholderProps={'Select button'}
                  onClickDropDownListOptionProps={(e) => onSelectButton(e, ind)}
                  specificClassNameProps="custom-select-waba-topic"
                  _className={`dropdown-category`}
                  isDisabled={isLoadingCreateMessageTemplate}
                />

                {(val.button.value === "call_to_action") &&
                  val.buttons.map((v, i) => {
                    return printButtonsCallToAction(v, i, ind, val.buttons.length);
                  })
                }

                {(val.button.value === "quick_reply") &&
                  val.buttons.map((v, i) => {
                    return printButtonsQuickReply(v, i, ind, val.buttons.length);
                  })
                }

                {(val.button.value === "call_to_action" || val.button.value === "quick_reply") &&
                  (val.button.value === "quick_reply" ?
                    (val.buttons.length > 2 ?
                      ""
                      :
                      <span className='add-another-button' onClick={() => onClickAddAnotherButton(ind)}>
                        <FiPlus />
                        <b>Add Another Button</b>
                      </span>
                    )
                    :
                    (val.buttons.length > 2 ?
                      ""
                      :
                      <span className='add-another-button' onClick={() => onClickAddAnotherButton(ind)}>
                        <FiPlus />
                        <b>Add Another Button</b>
                      </span>
                    )
                  )
                }
              </>
            }

            {(createTemplateMessageVal.category.value === "AUTHENTICATION" && createTemplateMessageVal.category.value !== "") &&
              <>
                <label>
                  <b>Body</b> <span className="grey-font"> (optional)</span>
                </label>
                <div className="custom-checkbox">
                  <input disabled={isLoadingCreateMessageTemplate} type="checkbox" id={`select-body-${ind}`} checked={val.authentication.body !== ""} onClick={(e) => onChangeCheckboxAuth(e, "body", ind)} />
                  <label htmlFor={`select-body-${ind}`}>
                    Security disclaimer
                  </label>
                </div>
                <ErrorOrInfoComp
                  text={"Will add “For your security, do not share this code.”"}
                  _className={""}
                  icon={<FiInfo />}
                />

                <label>
                  <b>Footer</b> <span className="grey-font"> (optional)</span>
                </label>
                <div className="custom-checkbox">
                  <input disabled={isLoadingCreateMessageTemplate} type="checkbox" id={`select-footer-${ind}`} checked={val.authentication.footer !== ""} onClick={(e) => onChangeCheckboxAuth(e, "footer", ind)} />
                  <label htmlFor={`select-footer-${ind}`}>
                    Expiration warning
                  </label>
                </div>
                <ErrorOrInfoComp
                  text={"Will add “This code expires in <NUM_MINUTES> minutes.”"}
                  _className={""}
                  icon={<FiInfo />}
                />

                {val.authentication.footer !== "" &&
                  <>
                    <input disabled={isLoadingCreateMessageTemplate} type="text" placeholder='Input number of minutes' value={val.authentication.footerTime} onChange={(e) => onChangeFooterTime(e, ind)} maxLength={2} className={`input-footer-time-${ind} ${val.authentication.footerTimeError === "" ? "" : "border-red"} margin-top-8`} />
                    <ErrorOrInfoComp
                      text={val.authentication.footerTimeError === "" ? "Minimum time limit range is 1 minute to a maximum of 90 minutes" : val.authentication.footerTimeError}
                      _className={val.authentication.footerTimeError === "" ? "" : "font-red"}
                      icon={<FiInfo />}
                    />
                  </>
                }

                <label>
                  <b>Button</b>
                </label>
                <input disabled={isLoadingCreateMessageTemplate} type="text" className={`input-button-text-${ind} ${val.authentication.textError === "" ? "" : "border-red"}`} placeholder='Type button text here' value={val.authentication.buttonText} onChange={(e) => onChangetext(e, ind)} />

                <ErrorOrInfoComp
                  text={val.authentication.textError === "" ? "Button's function is to copy the OTP code" : val.authentication.textError}
                  _className={val.authentication.textError === "" ? "" : "font-red"}
                  icon={<FiInfo />}
                />
              </>
            }
          </div>
        </div>

        {createTemplateMessageVal.category.value !== "" &&
          <div className="box-bottom-right">
            <label>
              <b>Preview</b>
            </label>

            <WhatsappPreview
              header={val.headerText}
              body={val.bodyText}
              footer={val.footerText}
              template={templateTypeVal}
              language={val}
              data={createTemplateMessageVal}
              index={ind}
              custom={{
                ...createTemplateMessageVal.template[ind].authentication,
                category: createTemplateMessageVal.category.value
              }}
              categoryCode={createTemplateMessageVal.category.value}
            />
          </div>
        }
      </div>
    )
  }

  let submitMessageTemplate = () => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    let _createTemplateMessageVal = { ...createTemplateMessageVal };
    let errorCount = 0;
    let scrollToError = "";
    let _buttonText = "";

    if (_createTemplateMessageVal.name === "") {
      errorCount = errorCount + 1;

      _createTemplateMessageVal.nameError = "This field is required";

      if (scrollToError === "") { scrollToError = `input-template-name` }
    }

    if (_createTemplateMessageVal.name.length > 100) {
      _createTemplateMessageVal.nameError = "Characters exceed limit";
      errorCount = errorCount + 1;

      if (scrollToError === "") { scrollToError = "input-template-name" }
    }

    if (_createTemplateMessageVal.category.value === "") {
      errorCount = errorCount + 1;

      _createTemplateMessageVal.categoryError = "Please select category";

      if (scrollToError === "") { scrollToError = `dropdown-category` }
    }

    _createTemplateMessageVal.template.map((v, i) => {
      if (v.languageVal.value === "") {
        errorCount = errorCount + 1;

        v.languageVal.error = "Please select language code";

        if (scrollToError === "") { scrollToError = `language-code-${i}` }
      }

      if (v.bodyText === "" && _createTemplateMessageVal.category.value !== "AUTHENTICATION") {
        errorCount = errorCount + 1;

        v.bodyTextError = "This field is required";

        if (scrollToError === "") { scrollToError = `body-text-${i}` }
      }

      if (templateTypeVal === "text") {
        if (v.sampleParameters.header.length > 0) {
          v.sampleParameters.header.map((_v, _i) => {
            if (_v.value === "") {
              errorCount = errorCount + 1;

              _v.error = "This field is required";

              if (scrollToError === "") { scrollToError = `sample-param-header-${_i}` }
            }

            if (_v.value.length > 50) {
              errorCount = errorCount + 1;

              _v.error = "Characters exceed limit";

              if (scrollToError === "") { scrollToError = `sample-param-header-${i}` }
            }

            return null;
          })
        }

        if (v.sampleParameters.body.length > 0) {
          v.sampleParameters.body.map((_v, _i) => {
            if (_v.value === "") {
              errorCount = errorCount + 1;

              _v.error = "This field is required";

              if (scrollToError === "") { scrollToError = `sample-param-body-${_i}` }
            }

            if (_v.value.length > 50) {
              errorCount = errorCount + 1;

              _v.error = "Characters exceed limit";

              if (scrollToError === "") { scrollToError = `sample-param-body-${i}` }
            }

            return null;
          })
        }
      }

      return null;
    })

    if (_createTemplateMessageVal.category.value === "AUTHENTICATION" && _createTemplateMessageVal.category.value !== "") {
      _createTemplateMessageVal.template.map((v, i) => {
        if (v.authentication.footer !== "" && v.authentication.footerTime === "") {
          errorCount = errorCount + 1;

          v.authentication.footerTimeError = "This field is required";

          if (scrollToError === "") { scrollToError = `input-footer-time-${i}` }
        }

        if (v.authentication.buttonText === "") {
          errorCount = errorCount + 1;

          v.authentication.textError = "This field is required";

          if (scrollToError === "") { scrollToError = `input-button-text-${i}` }
        }

        return null;
      })
    }

    if (_createTemplateMessageVal.category.value !== "AUTHENTICATION" && _createTemplateMessageVal.category.value !== "") {
      _createTemplateMessageVal.template.map((v, i) => {
        if (v.button.value !== "none" && v.buttons) {
          v.buttons.map((_v, _i) => {
            if (v.button.value === "call_to_action") {
              if (_v.typeOfAction.value === "") {
                errorCount = errorCount + 1;

                _v.typeOfActionError = "Please select type of action";

                if (scrollToError === "") { scrollToError = `type-of-action-${i}-${_i}` }
              }

              if (_v.typeOfAction.value === "url" && _v.urlType.value === "") {
                errorCount = errorCount + 1;

                _v.urlTypeError = "Please select url type";

                if (scrollToError === "") { scrollToError = `url-type-${i}-${_i}` }
              }

              if (_v.typeOfAction.value === "url" && !HelperInput.validURL(_v.lastValue)) {
                errorCount = errorCount + 1;

                _v.lastValueError = "Invalid URL format";

                if (scrollToError === "") { scrollToError = `phone-number-${i}-${_i}` }
              }

              if (_v.lastValue === "") {
                errorCount = errorCount + 1;

                _v.lastValueError = "This field is required";

                if (scrollToError === "") { scrollToError = `phone-number-${i}-${_i}` }
              }

              if (_v.urlType.value === "dynamic") {
                let _idxParam = v.sampleParameters.buttons.findIndex(v => v.name === `url_${_i}`);

                if (_idxParam !== -1) {
                  if (v.sampleParameters.buttons[_idxParam].value === "") {
                    errorCount = errorCount + 1;

                    _v.buttonSampleParamError = "This field is required";

                    if (scrollToError === "") { scrollToError = `button-sample-param-${i}-${_i}` }
                  }
                }
              }
            }

            if (_buttonText === _v.text) {
              errorCount = errorCount + 1;

              _v.textError = "Button text is duplicate";

              if (scrollToError === "") { scrollToError = `button-text-${i}-${_i}` }
            }

            if (_v.text === "") {
              errorCount = errorCount + 1;

              _v.textError = "This field is required";

              if (scrollToError === "") { scrollToError = `button-text-${i}-${_i}` }
            }

            _buttonText = _v.text;

            return null;
          })
        }

        return null;
      });
    }

    setCreateTemplateMessageVal(_createTemplateMessageVal);

    if (scrollToError !== "") {
      scrollToClass(`.${scrollToError}`);
    } else {
      setIsLoadingCreateMessageTemplate(true);

      if (_createTemplateMessageVal.category.value === "AUTHENTICATION") {
        let data = {
          channelID: parseInt(props.match.params.id),
          name: _createTemplateMessageVal.name,
          languages: []
        }

        _createTemplateMessageVal.template.map((v) => {
          data.languages.push({
            "languageCode": v.languageCode,
            "addSecurityRecommendation": v.authentication.body === "" ? false : true,
            "codeExpirationMinutes": v.authentication.footer === "" ? undefined : parseInt(v.authentication.footerTime),
            "buttonText": v.authentication.buttonText,
          })

          return null;
        })

        IntegrationWhatsappBusinessServices.createTemplateAuth(orgID, data, (response) => {
          if (response.dataResult.status === 200) {
            if (!response.dataResult.data.success) {
              doToast(response.dataResult.data.message, "fail");
              setIsLoadingCreateMessageTemplate(false);
            } else {
              if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                  "[Action] Create Message Template",
                  {
                    channel: "WhatsApp Business API",
                    userFullName: myAgentData.account.fullName,
                    userID: myAgentData.account.id,
                    organizationID: orgID.split("-")[0]
                  }
                );
              }

              back(props.match.params.id);
              doToast(response.dataResult.data.message);
            }
          } else {
            doToast(response.dataResult.error.message, 'fail');
            setIsLoadingCreateMessageTemplate(false);
          }
        })
      } else {
        let data = {
          channelID: parseInt(props.match.params.id),
          name: _createTemplateMessageVal.name,
          categoryCode: _createTemplateMessageVal.category.value,
          templateType: templateTypeVal === "text" ? "text" : "media", // or media
          languages: _createTemplateMessageVal.template
        }

        //call to action or quick reply
        data.languages.map((v, i) => {
          if (v.buttonType === "call_to_action") {
            v.buttons.map((_v, _i) => {
              if (_v.subType === "phone_number") {
                _v.phoneNumber = _v.lastValue.replaceAll(" ", "");
              }

              if (_v.subType === "url") {
                _v.url = _v.lastValue;

                if (_v.urlType.value === "dynamic") {
                  _v.url = _v.url + `${_v.url[_v.url.length - 1] === "/" ? `{{url_${_i}}}` : `/{{url_${_i}}}`}`
                }
              }

              return null;
            })
          }
          return null;
        })
        //call to action or quick reply

        IntegrationWhatsappBusinessServices.createTemplate(orgID, data, (response) => {
          if (response.dataResult.status === 200) {
            if (!response.dataResult.data.success) {
              doToast(response.dataResult.data.message, "fail");
              setIsLoadingCreateMessageTemplate(false);
            } else {
              if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                  "[Action] Create Message Template",
                  {
                    channel: "WhatsApp Business API",
                    userFullName: myAgentData.account.fullName,
                    userID: myAgentData.account.id,
                    organizationID: orgID.split("-")[0]
                  }
                );
              }

              back(props.match.params.id);
              doToast(response.dataResult.data.message);
            }
          } else {
            doToast(response.dataResult.error.message, 'fail');
            setIsLoadingCreateMessageTemplate(false);
          }
        })
      }
    }
  }

  let addMoreLang = () => {
    if (!isLoadingCreateMessageTemplate) {
      let _createTemplateMessageVal = { ...createTemplateMessageVal };
      _createTemplateMessageVal.template.push(JSON.parse(JSON.stringify(DEFAULT_TEMPLATE)));
      setCreateTemplateMessageVal(_createTemplateMessageVal);
    }
  }

  let onClickDelete = (index) => {
    if (!isLoadingCreateMessageTemplate) {
      let _createTemplateMessageVal = { ...createTemplateMessageVal };
      _createTemplateMessageVal.template.splice(index, 1);
      setCreateTemplateMessageVal(_createTemplateMessageVal);
    }
  }

  let onChangeTemplateType = (val) => {
    if (!isLoadingCreateMessageTemplate) {
      let _createTemplateMessageVal = { ...createTemplateMessageVal };

      _createTemplateMessageVal.template.map((v) => {
        v.headerText = "";
        v.file = false;
        return null;
      })

      setCreateTemplateMessageVal(_createTemplateMessageVal);
      setTemplateTypeVal(val);
    }
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        <div className="sectionWrap integration-main-container integration-waba-create-message-template-account text-left">
          <>
            <BackButton
              text={`Back to Message Template Requests`}
              onClick={() => back(props.match.params.id)}
            />

            <div className="integration-waba-create-message-template-account-box">
              {isLoadingGetOptions || retriveDataError.code ?
                <div className="integration-detail-loading-wrapper">
                  <RetrieveDataLoading
                    isLoading={isLoadingGetOptions}
                    errorMessage={retriveDataError.message}
                  />
                </div>
                :
                <>
                  <div className="integration-waba-create-message-template-account-box-top">
                    <b>Create Template</b>

                    {/* {!isLoadingEditTopicAccount ? (
                        <ButtonWithLoadingOrIcon 
                            isDisabled={!isFormValid()}
                            onClickAction={editWhatsappAccount}
                            className="orange-button main-button-40"
                            text="Save Changes"
                            position="left"
                        />
                      ) : (
                        <ButtonWithLoadingOrIcon 
                            isLoading
                            loadingWhite
                            className="orange-button main-button-40"
                            text="Save Changes"
                            position="left"
                        />
                      )} */}
                  </div>

                  {/* box-1 */}
                  <div className="integration-waba-create-message-template-account-box-form box-top">
                    <p className="template-detail">
                      <FiAlertCircle />
                      <b>Template Details</b>
                    </p>

                    <div className="form-topic">
                      <div className="form-content-wrapper">
                        <label className='label-550'>
                          <span className={createTemplateMessageVal.nameError === "Characters exceed limit" ? "red-text" : "grey-font"}>{createTemplateMessageVal.name.length}/100</span>
                          <b>Template Name</b>
                        </label>
                        <input disabled={isLoadingCreateMessageTemplate} className={`width-550 input-template-name ${createTemplateMessageVal.nameError === "" ? "" : "border-red"}`} type="text" placeholder="Input template name" value={createTemplateMessageVal.name} onChange={(e) => changeInput(e)} id="name" />

                        <ErrorOrInfoComp
                          text={createTemplateMessageVal.nameError === "" ? "Only alphanumeric, lowercase, and underscore are allowed." : createTemplateMessageVal.nameError}
                          _className={createTemplateMessageVal.nameError === "" ? "" : "red-text"}
                          icon={<FiInfo />}
                        />
                        {/* <p className="input-info-wrapper input-info-template-name">
                              <FiInfo />
                              <b>Only alphanumeric, lowercase, and underscore are allowed.</b>
                            </p> */}
                      </div>

                      <label>
                        <b>Category</b>
                      </label>

                      <div className="width-550">
                        <CustomSelectOption
                          optionListProps={categoryList}
                          valueDropdownProps={createTemplateMessageVal.category}
                          placeholderProps={'Select category'}
                          onClickDropDownListOptionProps={(e) => onSelectOption(e, "category")}
                          specificClassNameProps="custom-select-waba-topic"
                          dropdownError={createTemplateMessageVal.categoryError}
                          _className={`dropdown-category`}
                          isDisabled={isLoadingCreateMessageTemplate}
                        />
                      </div>
                      {createTemplateMessageVal.categoryError !== "" &&
                        <ErrorOrInfoComp
                          text={createTemplateMessageVal.categoryError}
                          _className={`${createTemplateMessageVal.categoryError === "" ? "" : "red-text"} margin-top-0`}
                          icon={<FiInfo />}
                        />
                      }

                      {(createTemplateMessageVal.category.value !== "AUTHENTICATION" && createTemplateMessageVal.category.value !== "") &&
                        <>
                          <label>
                            <b>Template Type</b>
                          </label>

                          <div className="template-type-selection-wrapper custom-radio">
                            {TEMPLATE_TYPE_LIST.map((val, idx) => {
                              return (
                                <div key={`template-type-${idx}`} className={`selection-template-type ${isLoadingCreateMessageTemplate ? "grey-box" : ""} ${val.value === templateTypeVal ? "active-template-type" : ""}`} onClick={() => onChangeTemplateType(val.value)}>
                                  <input
                                    type="radio"
                                    name="template-type"
                                    id={val.value}
                                    value={val.value}
                                    // onChange={(e) => onChangeTemplateType(e)}
                                    checked={val.value === templateTypeVal}
                                    disabled
                                  />
                                  <label htmlFor={val.value} >a</label>

                                  <div className={`template-type-icon-wrapper ${val.value}`}>
                                    {val.img.type === "svg" ?
                                      val.img.src
                                      :
                                      <img src={val.img.src} alt="" />
                                    }
                                  </div>

                                  <p>
                                    <b>{val.title}</b>
                                  </p>

                                  <p>
                                    {val.description}
                                  </p>
                                </div>
                              )
                            })}
                          </div>
                        </>
                      }

                    </div>
                  </div>
                  {/* box-1 */}

                  {/* box-2 */}
                  {createTemplateMessageVal.template.map((value, index) => {
                    return printTemplate(value, index);
                  })}
                  {/* box-2 */}

                  {/* button */}
                  <div className="button-wrapper">
                    {createTemplateMessageVal.template.length < languageCodeList.length &&
                      <ButtonWithLoadingOrIcon
                        className="no-fill-button-no-border main-button-40-no-padding-horizontal"
                        text="Add More Language"
                        icon={{
                          type: "svg",
                          src: FiPlus
                        }}
                        position="left"
                        style={{
                          marginRight: "16px"
                        }}
                        onClickAction={addMoreLang}
                      />
                    }

                    {!isLoadingCreateMessageTemplate ?
                      <ButtonWithLoadingOrIcon
                        className="orange-button main-button-40"
                        text="Submit Request"
                        icon={{
                          type: "svg",
                          src: FiCheck
                        }}
                        position="left"
                        onClickAction={submitMessageTemplate}
                        isDisabled={!isFormValid()}
                      />
                      :
                      <ButtonWithLoadingOrIcon
                        className="orange-button main-button-40"
                        isLoading
                        isDisabled
                        loadingColor="gray"
                        position="left"
                        text="Submit Request"
                      />
                    }
                  </div>
                  {/* button */}
                </>
              }
            </div>
          </>
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationWabaCreateMessageTemplate;
