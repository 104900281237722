import React, { useState, useEffect } from "react";
import "./IntegrationSalestalk.scss";
import "react-toastify/dist/ReactToastify.css";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast } from "../../../helper/HelperGeneral";
// import Pagination from "../reuseableComponent/pagination/Pagination";
import { FiExternalLink, FiEdit } from "react-icons/fi";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupConfirmation from "../../reuseableComponent/popupConfirmation/PopupConfirmation";
import CopyBox from "../../reuseableComponent/copyBox/CopyBox";
import IntegrationSalestalkLoggedIn from "./integrationSalestalkLoggedIn/IntegrationSalestalkLoggedIn";
import IntegrationSalestalkModal from "./integrationSalestalkModal/IntegrationSalestalkModal";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import { STATUS } from "../../../constants/salestalk";
import mixpanel from "mixpanel-browser";
import ServiceSalesTalkDefault from "../../../services/salesTalk/default/SalesTalkDefault";
import SalesTalkIcon from "../../../assets/img/integration/logo-salestalk-side-menu.svg";
import SalesTalkIconWhite from "../../../assets/img/integration/logo-salestalk-side-menu-white.svg";
import SalesTalkConnect from "../../../assets/img/integration/logo-salestalk-connect.svg";
import ExclamationRed from "../../../assets/img/exclamation-red.svg";

let IntegrationSalestalk = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [waitForResponseGet, setWaitForResponseGet] = useState(true);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [showModalDisconnect, setShowModalDisconnect] = useState(false);
  let [isLoadingDisconnect, setIsLoadingDisconnect] = useState(false);
  let [showModalIntegrationSalestalk, setShowModalIntegrationSalestalk] = useState(false);
  let [isLoadingGenerateToken, setIsLoadingGenerateToken] = useState(false);
  let [statusData, setStatusData] = useState({});
  
  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration/salestalk`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let getStatus = () => {
    setWaitForResponseGet(true);
    setStatusData({});
    
    let _retriveDataError = {...retriveDataError};
    
    ServiceSalesTalkDefault.postGetStatus(orgID, {}, (response) => {
      let dataResult = response.dataResult;

      if(dataResult.error.message === "") {
        // setChatbotList(dataResult.data.chatbots);
        setStatusData(dataResult.data);
      }else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGet(false);
    })
  }

  useEffect(() => {
    if(orgID) {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      
      getStatus();
      
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
            "[Page] SalesTalk", 
            {
                userFullName : myAgentData.account.fullName,
                userID : myAgentData.account.id,
                organizationID : orgID.split("-")[0]
            }
        );
      }
    }
  }, [orgID]);

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if(props.history.location.state) {
      if(props.history.location.state.message) {
        doToast(props.history.location.state.message);
        window.history.pushState(null, '');
      }
    }
  }, [props.history])

  // let onChangePage = () => {
  //   console.log('change page')
  // }

  // let addBtn = () => {
  //   let fullUrl = `/o/${orgID}/chatbots/create`;
    
  //   let onClick = () => history.push(fullUrl);
  //   let btnProps = { text: "Create Chatbot", icon: FiPlus, onClick };

  //   return <NewButton {...btnProps} />;
  // };

  let toggleModalDisconnect = () => {
    if(!isLoadingDisconnect) {
      setShowModalDisconnect(!showModalDisconnect);
    }
  }

  let toggleModalIntegrationSalestalk = () => {
    setShowModalIntegrationSalestalk(!showModalIntegrationSalestalk);
  }

  let generateTokenAction = () => {
    setIsLoadingGenerateToken(true);

    ServiceSalesTalkDefault.generateToken((res) => {
      if(res.data.error) {
        doToast(res.data.error.message, "fail");
      }else {
        localStorage.setItem("token_st", res.data);
        toggleModalIntegrationSalestalk();
      }

      setIsLoadingGenerateToken(false);

    });
  }

  let disconnectAction = () => {
    if(!isLoadingDisconnect) {
      setIsLoadingDisconnect(true);
  
      ServiceSalesTalkDefault.postDisconnect(orgID, {id: statusData.salesTalk.id}, (res) => {
        if(res.dataResult.error.message !== "") {
          doToast(res.dataResult.error.message, "fail");
        }else {
          if(res.dataResult.data.success) {
            setShowModalDisconnect(false);
            getStatus();
          }
          
          doToast(res.dataResult.data.message);
        }
  
        setIsLoadingDisconnect(false);
      });
    }
    // toggleModalIntegrationSalestalk();
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      > 
        <IntegrationSalestalkModal 
          orgID={orgID} 
          isOpen={showModalIntegrationSalestalk} 
          toggle={toggleModalIntegrationSalestalk} 
          runGetStatus={getStatus}
        />
        
        {waitForResponseGet || retriveDataError.code ? 
          <RetrieveDataLoading 
            isLoading={waitForResponseGet}
            errorMessage={retriveDataError.message} 
          />
          :
          (statusData.status === STATUS.not_connected ?
            // status not connect
            <>
              {/* box 1 */}
              <div className="sectionWrap salestalk-main-wrapper">
                  <div className="topContent">
                    <div className="title">
                      <img src={SalesTalkIcon} alt="" />
                      SalesTalk
                    </div>
                  </div>

                  <p className="salestalk-main-desc">
                    Enable your own social commerce platform by connecting ordering management system platform with social channel.
                  </p>
              </div>
              {/* box 1 */}

              {/* box 2 */}
              <div className="sectionWrap salestalk-main-wrapper">
                <div className="topContent">
                    <div className="title">
                      Tap2Order
                    </div>
                </div>

                <React.Fragment>
                  <div className="sales-talk-main-view">
                    {statusData.status === STATUS.not_connected &&
                      <>
                        Use our ordering management system to enable your own social commerce. Ensure that you have requirement below to connect with your SalesTalk.
                        <ul>
                          <li>
                            A <a href="https://tap2order.io" target="_blank" rel="noopener noreferrer">Tap2Order Account.</a> 
                          </li>
                        </ul>

                        {!isLoadingGenerateToken ?
                          <ButtonWithLoadingOrIcon 
                            className="orange-button main-button-48 button-connect"
                            text="Log in with Tap2Order"
                            onClickAction={generateTokenAction}
                            position="left"
                            icon={{
                              src: SalesTalkIconWhite,
                              type: "img"
                            }}
                          />
                          :
                          <ButtonWithLoadingOrIcon 
                            className="orange-button main-button-48 button-connect"
                            text="Log in with Tap2Order"
                            // onClickAction={toggleModalIntegrationSalestalk}
                            position="left"
                            isLoading
                            loadingColor="gray"
                            isDisabled
                          />
                        }

                        <p className="dont-have-account">
                          Don't have a Tap2Order account? <a href="https://dashboard.t2o.io/login" target="_blank" rel="noopener noreferrer">Register here.</a>
                        </p>
                      </>
                    }
                  </div>
                </React.Fragment> 
              </div>
              {/* box 2 */}
              
              {/* box 3 */}
              <div className="sectionWrap salestalk-main-wrapper">
                  <div className="topContent">
                      <div className="title">
                        Custom Provider
                      </div>
                  </div>

                  {waitForResponseGet || retriveDataError.code ? 
                    <RetrieveDataLoading 
                      isLoading={waitForResponseGet}
                      errorMessage={retriveDataError.message} 
                    />
                    :
                    <React.Fragment>
                      <div className="sales-talk-main-view">
                        {statusData.status === STATUS.not_connected &&
                          <>
                            If you already have your own ordering management system, you can easily integrate it to SalesTalk. For integration with Custom Provider, business must provide an API URL which SalesTalk will use to communicate with the custom provider via API. The API must be able to serve different response based on a request's parameter. 

                            <p className="for-more-details">
                              For more details, <a href="https://taptalk-io.github.io/onetalk/salestalk-custom-provider-integration.html" target="_blank" rel="noopener noreferrer">please read the documentation here.</a>
                            </p>

                            <ButtonWithLoadingOrIcon 
                              className="orange-button main-button-48 button-connect"
                              text="Connect"
                              onClickAction={() => props.history.push(`/o/${orgID}/integration/salestalk/custom-provider`)}
                              position="left"
                              icon={{
                                src: SalesTalkConnect,
                                type: "img"
                              }}
                            />
                          </>
                        }
                      </div>
                    </React.Fragment>
                  }
              </div>
              {/* box 3 */}
              {/* status not connect */}
            </>
            :
              (statusData.status === STATUS.error ?
                // status error
                <div className="sectionWrap salestalk-main-wrapper">
                  <div className="topContent">
                    <div className="title">
                      <img src={SalesTalkIcon} alt="" />
                      SalesTalk
                    </div>
                  </div>
                  
                  <div className="integration-salestalk-error">
                    <img src={ExclamationRed} alt="" />

                    <p>
                      There’s something wrong, please contact our support for help.
                    </p>

                    <ButtonWithLoadingOrIcon 
                      text="Contact Support"
                      className="orange-button main-button-48"
                      onClickAction={() => {
                          window.open(
                              "https://api.whatsapp.com/send?phone=6282180885808&text=Halo%2C%20saya%20ingin%20berdiskusi%20tentang%20kesalahan%20yang%20terjadi%20pada%20salestalk%20inbox",
                              "_blank"
                          )
                      }}
                    />
                  </div>
                </div>
                // status error
                :
                // status connect
                <>
                  {/* box tap2order */}
                  <div className="sectionWrap salestalk-main-wrapper">
                    <div className="topContent">
                        <div className="title">
                          <img src={SalesTalkIcon} alt="" />
                          SalesTalk
                        </div>

                        {(statusData.status && (statusData.status === STATUS.connected || statusData.status === STATUS.error)) &&
                          <>
                            {statusData.salesTalk.vendor === "tap2order" &&
                              <ButtonWithLoadingOrIcon 
                                text="Manage Store"
                                className="main-button-40 no-fill-button"
                                onClickAction={() => {
                                  window.open(
                                    "https://dashboard.t2o.io/login",
                                    "_blank"
                                  )
                                }}
                                icon={{
                                  type: "svg",
                                  src: FiExternalLink
                                }}
                                position="left"
                              />

                            }
                            
                            <ButtonWithLoadingOrIcon 
                              onClickAction={() => setShowModalDisconnect(true)}
                              text="Disconnect"
                              className="main-button-40 red-button"
                            />
                          </>
                        }

                        <PopupConfirmation
                          isOpen={showModalDisconnect}
                          toggle={toggleModalDisconnect}
                          title={`Disconnect ${(statusData.salesTalk && statusData.salesTalk.vendor === "custom" ? "Custom Provider" : "Tap2Order Account")}`}
                          description={`Are you sure you want to ${(statusData.salesTalk && statusData.salesTalk.vendor === "custom" ? "remove" : "disconnect")} this account from OneTalk?`}
                          className="modal-confirmation-disconnect-salestalk"
                        >
                          {!isLoadingDisconnect ?
                            <ButtonWithLoadingOrIcon
                                text="Disconnect"
                                className="main-button-48 red-button"
                                onClickAction={disconnectAction}
                            />
                            :
                            <ButtonWithLoadingOrIcon
                                text="Disconnect"
                                className="main-button-48 red-button"
                                isLoading
                                loadingColor="gray"
                                isDisabled
                                position="left"
                            />
                          }
                        </PopupConfirmation>
                    </div>

                    <div className="sales-talk-main-view">
                      {statusData.status === STATUS.connected &&
                        <IntegrationSalestalkLoggedIn 
                            data={statusData}
                        />
                      }
                    </div>
                  </div>
                  {/* box tap2order */}

                  {/* box custom provider */}
                  {statusData.salesTalk.vendor === "custom" &&
                    <div className="sectionWrap salestalk-main-wrapper">
                      <div className="topContent-custom-provider">
                        <div className="title">
                          <b>Custom Provider Configs</b>

                          <p className="title-desc">
                            for more details, <a href="https://taptalk-io.github.io/onetalk/salestalk-custom-provider-integration.html" target="_blank" rel="noopener noreferrer">please read this documentation.</a>
                          </p>

                        </div>
                        
                        <ButtonWithLoadingOrIcon
                          className="no-fill-button main-button-40"
                          onClickAction={() => 
                            props.history.push({
                              pathname: `/o/${orgID}/integration/salestalk/custom-provider/edit`,
                              state: {
                                data: statusData
                              }
                            })
                          }
                          text="Edit"
                          icon={{
                            src: FiEdit,
                            type: "svg"
                          }}
                          position="left"
                        />
                      </div>

                      <div className="sales-talk-main-view">
                        <p className="label-title">
                          <b>API URL</b>
                        </p>
                        <CopyBox 
                          val={statusData.custom.apiURL}
                          id="webhook-url" 
                          copyText="Webhook URL Copied"
                        />

                        <p className="label-title">
                          <b>Secret Key</b>
                        </p>
                        <CopyBox 
                          val={statusData.custom.secretKey}
                          id="secrey-key"
                          copyText="Secret Key Copied"
                        />
                      </div>
                    </div>
                  }
                  {/* box custom provider */}
                </>
                // status connect
              )
          )
        }
      </SectionWrap>
    </React.Fragment>
  );
};

export default IntegrationSalestalk;
