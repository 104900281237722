import React, { useEffect, useState } from "react";
import "./IntegrationWhatsappAddAccount.scss";
import "react-toastify/dist/ReactToastify.css";
import { FiInfo } from "react-icons/fi";
// import Integrations from "../../Integrations";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomReactSelect from "../../../reuseableComponent/CustomReactSelect/CustomReactSelect";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import TermsWhatsappModal from "../../../reuseableComponent/termsWhatsappModal/TermsWhatsappModal";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { checkID, scrollToClass } from "../../../../helper/HelperGeneral";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import topicServices from "../../../../services/newServices/TopicServices";

const IntegrationWhatsappAddAccount = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [topicList, setTopicList] = useState([]);
    let [accountNameVal, setAccountNameVal] = useState("");
    let [accountTopicVal, setAccountTopicVal] = useState({
        label: "",
        value: ""
    });
    let [accountNameValOrigin, setAccountNameValOrigin] = useState("");
    let [accountTopicValOrigin, setAccountTopicValOrigin] = useState({
        label: "",
        value: ""
    });
    let [isShowModalTerm, setIsShowModalTerm] = useState(false);
    let [errorMessageData, setErrorMessageData] = useState({
        name: "",
        topic: ""
    })

    useEffect(() => {
        if (props.history.location.state) {
            if (props.history.location.state.whatsappVal) {
                setAccountNameVal(props.history.location.state.whatsappVal.name)
                setAccountTopicVal(props.history.location.state.whatsappVal.topic)
            }
        }
    }, [props.history])

    const back = () => history.push(`/o/${orgID}/integration/whatsapp-sme`);

    let toggleModalTerms = () => setIsShowModalTerm(!isShowModalTerm);

    let goToCheckout = () => {
        props.history.push({
            pathname: `/o/${orgID}/integration/whatsapp-sme/checkout`,
            state: {
                whatsappAccount: {
                    name: accountNameVal,
                    topic: accountTopicVal
                }
            }
        })
    };

    let doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getTopics = async (orgID) => {
        topicServices.getTopic(orgID, "list", null, (response) => {
            const data = response.dataResult.data.topics;
            const createList = () => {
                let newData = [];
                data.map(val => {
                    let _data = {};
                    _data.value = val.id;
                    _data.label = val.name;
                    newData.push(_data);
                    return null;
                });
                setTopicList(newData);
            };

            createList();
        });
    }

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        if (orgID) {
            getTopics(orgID);
        }
    }, [orgID])

    let onSelectTopic = (value) => {
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.topic = "";
        setErrorMessageData(_errorMessageData);
        setAccountTopicVal(value)
    }

    // let isFormValid = () => {
    //     let _accountNameVal = accountNameVal;
    //     let _accountTopicVal = accountTopicVal.value;
    //     return (_accountNameVal !== "" && _accountTopicVal !== "");
    // }

    let onChangeAccountName = (e) => {
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.name = "";
        setErrorMessageData(_errorMessageData);
        setAccountNameVal(e.target.value);
    }

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={{
                    name: accountNameValOrigin,
                    topic: accountTopicValOrigin
                }}
                dataAfterChange={{
                    name: accountNameVal,
                    topic: accountTopicVal
                }}
            >
                {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}
                <TermsWhatsappModal
                    toggleModalTermsProps={toggleModalTerms}
                    isShowModalTermsProps={isShowModalTerm}
                    onClickAcceptProps={goToCheckout}
                    isLoadingAcceptProps={false}
                />
                <div className="sectionWrap integration-main-container integration-whatsapp-add-account text-left">
                    <BackButton text="Back to WhatsApp for SME" onClick={back} />

                    <div className="integration-whatsapp-add-account-box">
                        <div className="integration-whatsapp-add-account-box-top">
                            <b>Add Channel</b>

                            <ButtonWithLoadingOrIcon
                                // isDisabled={!isFormValid()} 
                                onClickAction={() => {
                                    let errorCount = 0;
                                    let errorClass = "";
                                    let _errorMessageData = { ...errorMessageData };

                                    if (accountNameVal === "") {
                                        _errorMessageData.name = "This field is required";
                                        errorCount = errorCount + 1;

                                        if (errorClass === "") { errorClass = "input-name" }
                                    }

                                    if (accountTopicVal.value === "") {
                                        _errorMessageData.topic = "Please select topic";
                                        errorCount = errorCount + 1;

                                        if (errorClass === "") { errorClass = "input-topic" }
                                    }

                                    if (accountNameVal.length > 50) {
                                        _errorMessageData.name = "Characters exceed limit";
                                        errorCount = errorCount + 1;

                                        if (errorClass === "") { errorClass = "input-name" }
                                    }

                                    if (errorCount > 0) {
                                        setErrorMessageData(_errorMessageData);
                                        scrollToClass(`.${errorClass}`);
                                    } else {
                                        setAccountNameValOrigin(accountNameVal);
                                        setAccountTopicValOrigin(accountTopicVal);
                                        toggleModalTerms()
                                    }
                                }}
                                className="orange-button main-button-40"
                                text="Continue To Payment"
                                position="left"
                            />
                        </div>

                        <div className="integration-whatsapp-add-account-box-form">
                            <label>
                                <span className={errorMessageData.name === "Characters exceed limit" ? "red-text" : "grey-font"}>{accountNameVal.length}/50</span>
                                <b>Account Name</b>
                            </label>
                            <input type="text" placeholder="Your WhatsApp account name" onChange={(e) => { onChangeAccountName(e) }} value={accountNameVal} className={`input-name ${errorMessageData.name !== "" ? "border-red" : ""}`} />
                            <ErrorOrInfoComp
                                text={errorMessageData.name === "" ? "Account Name is used to differentiate the various accounts linked to this particular channel." : errorMessageData.name}
                                _className={errorMessageData.name !== "" ? "font-red" : ""}
                                icon={<FiInfo />}
                            />

                            <label><b>Topic</b></label>
                            
                            <CustomReactSelect
                                width="350px"
                                options={topicList}
                                placeholder="Select account topic"
                                onChange={onSelectTopic}
                                classNamePrefix="custom-react-select"
                                isDisabled={false}
                                value={
                                    topicList.filter(
                                        (obj) => obj.value === accountTopicVal.value
                                    )
                                }
                                blurInputOnSelect={true}
                                _className={`input-topic ${errorMessageData.topic !== "" ? "border-red" : ""}`}
                            />
                            <ErrorOrInfoComp
                                text={errorMessageData.topic === "" ? "Incoming messages on this channel will be assigned to this topic." : errorMessageData.topic}
                                _className={errorMessageData.topic !== "" ? "font-red" : ""}
                                icon={<FiInfo />}
                            />
                            {/* <span className="integration-info-form">
                                Incoming messages on this channel will be assigned to this topic.
                            </span> */}
                        </div>
                    </div>
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationWhatsappAddAccount;