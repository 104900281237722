import React, { useState, useEffect } from "react";
import "./BroadcastMessage.scss";
import { Modal, ModalBody, Tooltip } from 'reactstrap';
import { FiPlus, FiInfo, FiUser, FiUsers } from "react-icons/fi";
import { TabContent, Nav, NavItem } from 'reactstrap';
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import BackButton from "../reuseableComponent/BackButton/BackButton";
import CustomReactSelect from "../reuseableComponent/CustomReactSelect/CustomReactSelect";
import { checkID, findMyRole, doToast } from "../../helper/HelperGeneral";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import BroadcastMessageImmediate from "./broadcastMessage/broadcastMessageImmediate/BroadcastMessageImmediate";
import BroadcastMessageScheduled from "./broadcastMessage/broadcastMessageScheduled/BroadcastMessageScheduled";
import BroadcastMessageServices from "../../services/newServices/BroadcastMessageServices";
import MemberServices from "../../services/newServices/MemberServices";
import iconWhatsappSME from "../../assets/img/integration/logo-whatsapp-side-menu.svg";
import iconWhatsappBA from "../../assets/img/integration/logo-whatsapp-business.svg";
import iconImage from "../../assets/img/broadcast-message/icon_image.svg";
import iconFile from "../../assets/img/broadcast-message/icon_file.svg";
import iconText from "../../assets/img/broadcast-message/icon_text.svg";

import { connect } from 'react-redux';

const CHANNEL_LIST = [
    // WhatsApp business API
    {
        img: iconWhatsappSME,
        channel: "whatsappSME",
        value: "whatsapp",
        text: "WhatsApp SME"
    },
    {
        img: iconWhatsappBA,
        channel: "whatsappBA",
        value: "whatsappba",
        text: "WhatsApp BA"
    }
]

const BROADCAST_TYPE = [
    {
        img: <FiUser />,
        value: "single",
        text: "Single Broadcast"
    },
    {
        img: <FiUsers />,
        value: "multi",
        text: "Multiple Broadcast"
    }
]

const BroadcastMessage = (props) => {
    useEffect(() => {
        if (props.membershipData) {
            if ((props.match.params.orgID.split("-")[0] === "5369" || props.match.params.orgID.split("-")[0] === "6919") && findMyRole(props.membershipData.memberships, props.match.params.orgID) !== "owner") {
                props.history.push(`/o/${props.match.params.orgID}/home`)
            }
        }
    }, [props.membershipData])

    const MESSAGE_TYPE = {
        text: "text",
        image: "image",
        file: "file",
        video: "video"
    }

    const BROADCAST = {
        immediate: {
            id: "immediate",
            tab: "Immediate Broadcast",
            component: <BroadcastMessageImmediate {...props} tab={"immediate"} />,
            isHidden: props.memberRole === "member"
        },
        scheduled: {
            id: "scheduled",
            tab: "Scheduled Broadcast",
            component: <BroadcastMessageScheduled {...props} tab={"scheduled"} />
        },
    }

    const NEW_MESSAGE_OPTION_LIST = [
        {
            image: iconText,
            title: "Text",
            desc: "Send messages to people as plain text",
            type: MESSAGE_TYPE.text
        },
        {
            image: iconImage,
            title: "Image",
            desc: "Send an image along with a caption to people",
            type: MESSAGE_TYPE.image
        },
        {
            image: iconFile,
            title: "File",
            desc: "Send a file to people",
            type: MESSAGE_TYPE.file
        },
    ];

    const CONTACT_TYPE = [
        {
            value: "contact",
            title: "Contact"
        },
        {
            value: "segment",
            title: "Contact Segment"
        }
    ];

    const DEFAULT_DATA = {
        // selectedChannel: {
        //   img: "",
        //   channel: "",
        //   text: ""
        // },
        arrayOfChannelRecipient: [
            {
                multiSelectSegment: [],
                channel: {
                    value: "",
                    label: ""
                },
                topic: {
                    value: "",
                    label: ""
                },
                topicID: null
            }
        ],
        bcTimeType: "",
        selectedChannel: {
            img: "",
            channel: "",
            text: ""
        },
        name: "",
        channel: {
            label: "",
            value: ""
        },
        contactType: CONTACT_TYPE[0].value,
        contacts: [],
        customContacts: {},
        segment: "",
        messages: [
            {
                type: "",
                body: "",
                filename: "",
                caption: ""
            }
        ],
        // fileAsset: {
        //   fileURL: "",
        //   fileName: "",
        //   fileData: false,
        //   caption: ""
        // },
        // caption: "",
        // messageType: ""
        messageTemplate: {
            value: "",
            label: "",
            channel: ""
        },
        language: {
            value: "",
            label: "",
            language: ""
        },
        withCase: false,
        scheduleDateSelect: undefined,
        scheduleDate: undefined,
        scheduleTime: "",
        topics: [],
        topic: {
            value: "",
            label: ""
        },
        phoneVal: {
            value: "",
            label: ""
        }
    };

    let [activeTab, setActiveTab] = useState(props.memberRole === "member" ? BROADCAST.scheduled.id : BROADCAST.immediate.id);
    let { match, history, location } = props;
    let [orgID, setOrgID] = useState(false);
    let [tooltipBcType, setTooltipBcType] = useState(false);
    let [activeSelectMessageType, setActiveSelectMessageType] = useState(false);
    let [showModalNewMessage, setShowModalNewMessage] = useState(false);
    let [topicList, setTopicList] = useState(false);
    let [bcType, setBcType] = useState("");
    let [channelNumberList, setChannelNumberList] = useState(false);
    let [broadcastMessageData, setBroadcastMessageData] = useState({ ...DEFAULT_DATA });
    let [errorMessageData, setErrorMessageData] = useState({
        type: "",
        account: "",
        bcType: ""
    })

    let toggleBcType = () => setTooltipBcType(!tooltipBcType);

    let toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    let doMount = async () => {
        const paramID = checkID(match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/organization/members`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    useEffect(() => {
        doMount();
    }, [location.pathname]);

    useEffect(() => {
        if (orgID) {
            getAgentDetailAndStatusAction();
        }
    }, [orgID]);

    useEffect(() => {
        if (topicList) {
            getChannelListWhatsapp();
        }
    }, [topicList])

    useEffect(() => {
        if (props.history.location.state) {
            if (props.history.location.state.tab) {
                setActiveTab(props.history.location.state.tab);
            }
        }
    }, [props.history])

    let getAgentDetailAndStatusAction = () => {
        let orgID = props.match.params.orgID;

        MemberServices.getAssTopic(orgID, (response) => {
            let dataResult = response.dataResult;
            let topicsHashMap = {};

            if (dataResult.error.code === "") {
                for (let i in dataResult.data.topics) {
                    dataResult.data.topics[i].value = dataResult.data.topics[i].id;
                    dataResult.data.topics[i].label = dataResult.data.topics[i].name;

                    topicsHashMap[dataResult.data.topics[i].id] = dataResult.data.topics[i];
                }

                setTopicList(topicsHashMap);
            } else {
                doToast(dataResult.error.message, 'fail');
            }
        })
    }

    let isTopicExist = (chTopics) => {
        let exist = false;

        if (topicList) {
            chTopics.map((v) => {
                if (topicList[v.id]) {
                    exist = true;
                }

                return null;
            })
        }

        return exist;
    }

    let getChannelListWhatsapp = () => {
        BroadcastMessageServices.getAllSmeWabaChannelList(orgID, (response) => {
            let dataResult = response.dataResult;

            if (dataResult.status === 200) {
                let templistSME = [];
                let templistWABA = [];

                let channels = { ...dataResult.data };
                let newListSME = [];
                let newListWABA = [];

                Object.keys(channels).map((_value) => {
                    if (channels[_value].length > 0) {
                        for (let i in channels[_value]) {
                            channels[_value][i].value = channels[_value][i].id;
                            channels[_value][i].label = channels[_value][i].accountName;

                            if (isTopicExist(channels[_value][i].topics)) {
                                if (_value === "whatsapp") {
                                    templistSME.push(channels[_value][i]);
                                }

                                if (_value === "whatsappba") {
                                    templistWABA.push(channels[_value][i]);
                                }
                            }
                        }
                    }

                    return null;
                })

                templistSME.map((v, i) => {
                    if (v.channelStatus === "active" || v.channelStatus === "authenticated") {
                        newListSME.push(v);
                        templistSME.splice(i, 1);
                    }

                    return null;
                })

                templistWABA.map((v, i) => {
                    if (v.channelStatus === "active" || v.channelStatus === "authenticated") {
                        newListWABA.push(v);
                        templistWABA.splice(i, 1);
                    }

                    return null;
                })

                setChannelNumberList({
                    whatsapp: newListSME.concat(templistSME),
                    whatsappba: newListWABA.concat(templistWABA)
                });
            } else {
                doToast(dataResult.error.message, "fail");
            }
        })
    }

    let closeModalNewMessage = () => {
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.account = "";
        _errorMessageData.type = "";
        _errorMessageData.bcType = "";
        setErrorMessageData(_errorMessageData);
        setBcType("");
        setActiveSelectMessageType(false);
        setActiveSelectMessageType(false);
        setBroadcastMessageData(JSON.parse(JSON.stringify(DEFAULT_DATA)));
        setShowModalNewMessage(false);
    }

    let renderModal = () => {
        let onSelectChannel = (val) => {
            let _errorMessageData = { ...errorMessageData };
            _errorMessageData.account = "";
            setErrorMessageData(_errorMessageData);
            let _broadcastMessageData = { ...broadcastMessageData };
            _broadcastMessageData.channel = val;
            setBroadcastMessageData(_broadcastMessageData);
        }

        let onClickSelectChannel = (channel) => {
            let _errorMessageData = { ...errorMessageData };
            _errorMessageData.type = "";
            setErrorMessageData(_errorMessageData);
            let _broadcastMessageData = { ...broadcastMessageData };
            _broadcastMessageData.selectedChannel = channel;
            _broadcastMessageData.channel = {
                value: "",
                label: ""
            };
            setBroadcastMessageData(_broadcastMessageData);
        }

        let disableContinueType = () => {
            let _broadcastMessageData = { ...broadcastMessageData };
            return _broadcastMessageData.messages[0].type === "";
        }

        let onClickMessageType = (type) => {
            let _errorMessageData = { ...errorMessageData };
            _errorMessageData.account = "";
            setErrorMessageData(_errorMessageData);
            let _broadcastMessageData = { ...broadcastMessageData };
            _broadcastMessageData.messages[0].type = type;
            setBroadcastMessageData(_broadcastMessageData);
        }

        return (
            <Modal isOpen={showModalNewMessage} toggle={closeModalNewMessage} className={`new-message-modal ${!activeSelectMessageType ? "new-message-select-channel" : ""}`}>
                {!activeSelectMessageType ?
                    <ModalBody>
                        <div className="new-message-wrapper">
                            <b className="new-message-title">
                                New Broadcast Message
                            </b>

                            <p className="new-message-description">Start your new broadcast message</p>

                            <div className="new-message-channel-option-container">
                                <label>
                                    <b>Broadcast Type</b>
                                    <FiInfo id="TooltipBctype" />
                                    <Tooltip
                                        isOpen={tooltipBcType}
                                        target="TooltipBctype"
                                        toggle={toggleBcType}
                                        className="tooltip-bc-type"
                                        placement="bottom"
                                    >
                                        <p className="tooltip-single-bc">
                                            <b>Single Broadcast</b> lets you send messages to multiple individual contacts or a contact segment.
                                        </p>

                                        <p className="tooltip-multi-bc">
                                            <b>Multiple Broadcast</b> allows you to reach several contact segments at once, and with WSME, you can send broadcasts through multiple channels.
                                        </p>
                                    </Tooltip>
                                </label>

                                <div className="new-message-channel-option-wrapper input-type">
                                    {BROADCAST_TYPE.map((value, index) => {
                                        return (
                                            <div
                                                className={`new-message-channel-option ${value.value === bcType ? "active-channel" : ""}`}
                                                key={`channel-option-${index}`}
                                                onClick={() => {
                                                    let _errorMessageData = { ...errorMessageData };
                                                    _errorMessageData.bcType = "";
                                                    setErrorMessageData(_errorMessageData);
                                                    setBcType(value.value);
                                                }}
                                            >
                                                {value.img}
                                                {value.text}
                                            </div>
                                        )
                                    })}
                                </div>
                                {errorMessageData.bcType !== "" &&
                                    <ErrorOrInfoComp
                                        text={errorMessageData.bcType}
                                        _className={"font-red"}
                                        icon={<FiInfo />}
                                    />
                                }

                                <label>
                                    <b>Channel Type</b>
                                </label>

                                <div className="new-message-channel-option-wrapper input-type">
                                    {CHANNEL_LIST.map((value, index) => {
                                        return (
                                            <div
                                                className={`new-message-channel-option ${broadcastMessageData.selectedChannel.channel === value.channel ? "active-channel" : ""}`}
                                                key={`channel-option-${index}`}
                                                onClick={() => onClickSelectChannel(value)}
                                            >
                                                <img src={value.img} alt="" />
                                                {value.text}
                                            </div>
                                        )
                                    })}
                                </div>
                                {errorMessageData.type !== "" &&
                                    <ErrorOrInfoComp
                                        text={errorMessageData.type}
                                        _className={"font-red"}
                                        icon={<FiInfo />}
                                    />
                                }

                                {(bcType === "multi" && broadcastMessageData.selectedChannel.channel === "whatsappSME") ?
                                    ""
                                    :
                                    <>
                                        {broadcastMessageData.selectedChannel.channel === CHANNEL_LIST[0].channel &&
                                            <>
                                                <label>
                                                    <b>Channel Account</b>
                                                </label>

                                               <CustomReactSelect
                                                    width={"100%"}
                                                    isWabaOrSmeChannel
                                                    options={channelNumberList.whatsapp}
                                                    placeholder="Select Channel"
                                                    onChange={onSelectChannel}
                                                    classNamePrefix="react-select-broadcast"
                                                    isDisabled={!channelNumberList}
                                                    value={channelNumberList.whatsapp.filter(
                                                        (obj) => obj.value === broadcastMessageData.channel.value
                                                    )}
                                                    blurInputOnSelect={true}
                                                    _className={`input-account ${errorMessageData.account === "" ? "" : "border-red"}`}
                                                />

                                                {errorMessageData.account !== "" &&
                                                    <ErrorOrInfoComp
                                                        text={errorMessageData.account}
                                                        _className={"font-red"}
                                                        icon={<FiInfo />}
                                                    />
                                                }
                                            </>
                                        }

                                        {broadcastMessageData.selectedChannel.channel === CHANNEL_LIST[1].channel &&
                                            <>
                                                <label>
                                                    <b>Channel Account</b>
                                                </label>

                                                <CustomReactSelect
                                                    width={"100%"}
                                                    isWabaOrSmeChannel
                                                    options={channelNumberList.whatsappba}
                                                    placeholder="Select Channel"
                                                    onChange={onSelectChannel}
                                                    classNamePrefix="react-select-broadcast"
                                                    isDisabled={!channelNumberList}
                                                    value={channelNumberList.whatsappba.filter(
                                                        (obj) => obj.value === broadcastMessageData.channel.value
                                                    )}
                                                    blurInputOnSelect={true}
                                                    _className={`input-account ${errorMessageData.account === "" ? "" : "border-red"}`}
                                                />

                                                {errorMessageData.account !== "" &&
                                                    <ErrorOrInfoComp
                                                        text={errorMessageData.account}
                                                        _className={"font-red"}
                                                        icon={<FiInfo />}
                                                    />
                                                }
                                            </>
                                        }
                                    </>
                                }

                                <ButtonWithLoadingOrIcon
                                    className="no-fill-button main-button-40 cancel-broadcast"
                                    text="Cancel"
                                    onClickAction={closeModalNewMessage}
                                />

                                <ButtonWithLoadingOrIcon
                                    className="orange-button main-button-40 continue-broadcast"
                                    text="Continue"
                                    onClickAction={() => {
                                        // if(broadcastMessageData.selectedChannel.value === "whatsappba") {
                                        //   goToCreate()
                                        // }else {
                                        //   setActiveSelectMessageType(true)
                                        // }
                                        goToCreate();
                                    }}
                                // isDisabled={disableContinueChannel()}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    :
                    <ModalBody>
                        <div className="new-message-wrapper">
                            <BackButton text="Back to Select Channel" onClick={() => setActiveSelectMessageType(false)} />

                            <br />

                            {/* <FiX onClick={closeModalNewMessage} className="close-modal-new-message" /> */}

                            <div className="new-message-option-container">
                                {NEW_MESSAGE_OPTION_LIST.map((value, index) => {
                                    return (
                                        <div
                                            className={`new-message-option-list ${broadcastMessageData.messages[0].type === value.type ? "active-message-type" : ""}`}
                                            // className={`new-message-option-list ${broadcastMessageData.messages[0].type === value.type ? "active-message-type" : ""}`}
                                            key={`new-message-option-${index}`}
                                            onClick={() => onClickMessageType(value.type)}
                                        >
                                            <img src={value.image} alt="" />
                                            <br />
                                            <b>{value.title}</b>

                                            <p>
                                                {value.desc}
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>

                            <ButtonWithLoadingOrIcon
                                className="no-fill-button main-button-40 cancel-broadcast"
                                text="Cancel"
                                onClickAction={closeModalNewMessage}
                            />

                            <ButtonWithLoadingOrIcon
                                className="orange-button main-button-40 continue-broadcast"
                                text="Continue"
                                onClickAction={goToCreate}
                                isDisabled={disableContinueType()}
                            />
                        </div>
                    </ModalBody>
                }
            </Modal>
        )
    }

    let goToCreate = () => {
        let errorCount = 0;
        let errorClass = "";
        let _errorMessageData = { ...errorMessageData };

        if (bcType === "") {
            _errorMessageData.bcType = "Please select broadcast type";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-bc-type" }
        }

        if (broadcastMessageData.selectedChannel.channel === "") {
            _errorMessageData.type = "Please select channel type";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-channel" }
        }

        if (bcType !== "multi" && broadcastMessageData.channel.value !== "whatsappSME") {
            if (broadcastMessageData.selectedChannel.channel !== "" && broadcastMessageData.channel.value === "") {
                _errorMessageData.account = "Please select account";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-account" }
            }
        }

        if (broadcastMessageData.selectedChannel.value === "whatsappba") {
            if (broadcastMessageData.channel.value === "") {
                _errorMessageData.account = "Please select account";
                errorCount = errorCount + 1;

                if (errorClass === "") { errorClass = "input-account" }
            }
        }

        if (errorCount > 0) {
            setErrorMessageData(_errorMessageData);
        } else {
            history.push({
                pathname: `/o/${orgID}/operational/broadcast-message/create`,
                state: {
                    tab: props.tab,
                    broadcastMessageData: broadcastMessageData,
                    topicListAgent: topicList,
                    isNew: true,
                    isMultiple: bcType === "multi",
                    whatsappsmeList: channelNumberList.whatsapp
                }
            })
        }
    }

    return (
        <>
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                {renderModal()}

                <div className="broadcast-page-wrapper">
                    <Nav tabs>
                        {Object.keys(BROADCAST).map((value, index) => {
                            return (
                                !BROADCAST[value].isHidden &&
                                <NavItem key={`tab-setup-${index}`} className={`${activeTab === BROADCAST[value].id ? 'active-setup-tab' : ''}`}>
                                    <div className="setup-tab-wrapper"
                                        onClick={() => { toggle(BROADCAST[value].id); }}
                                    >
                                        <b>{BROADCAST[value].tab}</b>
                                    </div>
                                </NavItem>
                            )
                        })}
                    </Nav>

                    <ButtonWithLoadingOrIcon
                        text="Create Broadcast"
                        icon={{
                            type: "svg",
                            src: FiPlus
                        }}
                        position="left"
                        className="orange-button main-button-40 button-create-broadcast"
                        onClickAction={() => {
                            setShowModalNewMessage(true);
                        }}
                    />

                    <TabContent>
                        {BROADCAST[activeTab].component}
                    </TabContent>
                </div>
            </SectionWrap>
        </>
    )
}

const mapStateToProps = state => ({
    membershipData: state.membershipData
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastMessage)