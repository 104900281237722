import React from 'react';
import './AssistanceAI.scss';
import 'react-toastify/dist/ReactToastify.css';
import SectionWrap from '../reuseableComponent/sectionWrap/SectionWrap';
import { connect } from 'react-redux';

let AssistanceAI = (props) => {
  // useEffect(() => {
  //   if(props.match.params.orgID.split("-")[0] !== "5819") {
  //     props.history.push(`/o/${props.match.params.orgID}/home`)
  //   }
  // }, [])

  return (
    <React.Fragment>
      <SectionWrap {...props} withHeader withSideMenu>
        <div className='sectionWrap assist-ai'>
          <iframe src={process.env.REACT_APP_ONPREMISE_ASISTENAI_REPORT_URL} title="TapTalk.io Assistence AI" />
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

let mapStateToProps = (state) => ({

});

let mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(AssistanceAI);
