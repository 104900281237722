import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './SharedMediaTab2.scss';
import { connect } from 'react-redux';
import { FiDownload, FiFile, FiImage, FiVideo } from 'react-icons/fi';
import TripleDotIcon from '../../../../../../assets/img/message-action/triple-dot.svg';
import ViewInChat from '../../../../../../assets/img/view-in-chat.svg';
import NoSharedMedia from '../../../../../../assets/img/no-shared-media-data.svg';
import { CHAT_TYPE } from '../../../../../../helper/HelperConst';
import Helper from '../../../../../../helper/HelperChat';
import HelperDate from '../../../../../../helper/HelperDate';
import { setGoToChat } from '../../../../../../redux/actions/reduxActionGoToChat';
import { setActiveSharedMedia } from "../../../../../../redux/actions/reduxActionActiveSharedMedia";

var SharedMediaTab2 = (props) => {
  let [showDropdownShowInChat, setShowDropdownShowInChat] = useState({});
  let [dataToPrint, setDataToPrint] = useState({});

  useEffect(() => {
    generateData();
  }, [JSON.stringify(props.sharedMediaData)]);

  let toggleDropdownShowInChat = (parent, idx) => {
    let _showDropdownShowInChat = { ...showDropdownShowInChat };

    _showDropdownShowInChat[parent][idx] = !_showDropdownShowInChat[parent][idx];

    setShowDropdownShowInChat(_showDropdownShowInChat);
  }

  let generateData = () => {
    let toggleViewChat = {};

    let _data = {};

    if (props.sharedMediaData.length > 0) {
      props.sharedMediaData.map((v) => {
        let dateString = HelperDate.formatToString(new Date(v.created), "MMMM yyyy");

        if (v.messageType === CHAT_TYPE.TAPChatMessageTypeFile) {
          if (!_data[dateString]) {
            _data[dateString] = [];
          }

          if (!toggleViewChat[dateString]) {
            toggleViewChat[dateString] = [];
          }

          _data[dateString].push(v);
          toggleViewChat[dateString].push(false);
        }

        return null;
      })

      setShowDropdownShowInChat(toggleViewChat);
    }

    setDataToPrint(_data);
  }

  let downloadFile = (data) => {
    const url = data.data.url;
    const link = document.createElement('a');
    link.href = url;
    link.target = "_blank";
    link.setAttribute('download', data.data.fileName);
    document.body.appendChild(link);
    link.click();
  }

  return (
    <div className={`shared-media-main-tab-wrapper`}>
      {JSON.stringify(dataToPrint) === "{}" ?
        <div className='no-shared-media'>
          <img src={NoSharedMedia} alt="" />

          <p>
            <b>No Shared Media</b>
          </p>

          <p>
            You can see all your shared media from this chat here once you start sharing them.
          </p>
        </div>
        :
        <>
          {Object.keys(dataToPrint).map((_v, _i) => {
            return (
              <div className='share-media-list-wrapper' key={`date-sep-${_i}`}>
                <div className='share-media-date'>
                  {_v}
                </div>

                <div className='share-media-list-inner'>
                  {dataToPrint[_v].map((v, i) => {
                    return (
                      <div
                        className='share-media-card'
                        key={`shard-media-${i}`}
                      >
                        <div className='share-media-card-doc'>
                          <Dropdown isOpen={showDropdownShowInChat[_v][i]} toggle={() => toggleDropdownShowInChat(_v, i)}>
                            <DropdownToggle className="dropdown-view-in-chat">
                              <div className="triple-dot-wrap">
                                <img src={TripleDotIcon} alt="" />
                              </div>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  props.setGoToChat({
                                    lastUpdate: new Date().valueOf(),
                                    chat: dataToPrint[_v][i]
                                  });

                                  props.setActiveSharedMedia(false);
                                }}
                              >
                                <img src={ViewInChat} alt="" />
                                View in chat
                              </DropdownItem>

                              {/* <DropdownItem
                                onClick={() => {
                                  downloadFile(v)
                                }}
                              >
                                <FiDownload />
                                Download
                              </DropdownItem> */}
                            </DropdownMenu>
                          </Dropdown>

                          <div 
                            className='share-media-doc-box'
                            onClick={() => {
                              downloadFile(v);
                            }}
                          >
                            {v.data.mediaType.split("/")[0] === "image" ?
                              <FiImage className='icon-image-video' />
                              :
                              (v.data.mediaType.split("/")[0] === "video" ?
                                <FiVideo className='icon-image-video'/>
                                :
                                <FiFile className='icon-image-video' />
                              )
                            }

                            <FiDownload className='icon-download' />
                          </div>

                          <div className='share-media-doc-info'>
                            <p className='file-name'>
                              <b>{v.data.fileName}</b>
                            </p>

                            {v.data.size !== 0 ?
                              <p className='file-info'>
                                {Helper.bytesToSize(v.data.size)} <div className='centered-dot'></div> {HelperDate.formatToString(new Date(v.created), "dd/MM/yyyy")} <div className='centered-dot'></div> {HelperDate.formatToString(new Date(v.created), "HH:mm")}
                              </p>
                              :
                              <p className='file-info'>
                                {HelperDate.formatToString(new Date(v.created), "dd/MM/yyyy")} <div className='centered-dot'></div> {HelperDate.formatToString(new Date(v.created), "HH:mm")}
                              </p>
                            }

                          </div>
                        </div>

                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </>
      }
    </div>
  );
}

const mapStateToProps = state => ({
  goToChat: state.goToChat
});

const mapDispatchToProps = {
  setGoToChat,
  setActiveSharedMedia
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedMediaTab2);
