import React, { useEffect, useState } from "react";
import "./IntegrationEmailEditDetail.scss";
import "react-toastify/dist/ReactToastify.css";
import { FiInfo } from "react-icons/fi";
// import Integrations from "../../Integrations";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import CustomReactSelect from "../../../reuseableComponent/CustomReactSelect/CustomReactSelect";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import IntegrationEmailServices from '../../../../services/integration/IntegrationEmailServices';
import topicServices from "../../../../services/newServices/TopicServices";

const IntegrationEmailEditDetail = props => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [loadingSubmit, setLoadingSubmit] = useState(false);
  let [topicList, setTopicList] = useState([]);
  let [data, setData] = useState({
    sender: "",
    topic: {
      value: "",
      label: ""
    }
  });
  let [dataOrigin, setDataOrigin] = useState({
    sender: "",
    topic: {
      value: "",
      label: ""
    }
  });
  let [errorMessageData, setErrorMessageData] = useState({
    sender: "",
    topic: ""
  });
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });

  const EMAIL_PROVIDER = [
    {
      value: "custom",
      label: "Custom",
    },
    {
      value: "1",
      label: "Gmail (TLS)",
      "smtpServerHost": "smtp.gmail.com",
      "smtpServerPort": 587,
      "smtpServerProtocol": "starttls",
      "imapServerHost": "imap.gmail.com",
      "imapServerPort": 993,
      "imapServerProtocol": "ssl"
    },
    {
      value: "2",
      label: "Gmail (SSL)",
      "smtpServerHost": "smtp.gmail.com",
      "smtpServerPort": 465,
      "smtpServerProtocol": "ssl",
      "imapServerHost": "imap.gmail.com",
      "imapServerPort": 993,
      "imapServerProtocol": "ssl"
    },
    {
      value: "3",
      label: "Yahoo Mail (TLS)",
      "smtpServerHost": "smtp.gmail.com",
      "smtpServerPort": 587,
      "smtpServerProtocol": "starttls",
      "imapServerHost": "imap.mail.yahoo.com",
      "imapServerPort": 993,
      "imapServerProtocol": "ssl"
    },
    {
      value: "4",
      label: "Yahoo Mail (SSL)",
      "smtpServerHost": "smtp.mail.yahoo.com",
      "smtpServerPort": 465,
      "smtpServerProtocol": "ssl",
      "imapServerHost": "imap.mail.yahoo.com",
      "imapServerPort": 993,
      "imapServerProtocol": "ssl"
    },
    {
      value: "5",
      label: "Outlook.com/Hotmail (TLS)",
      "smtpServerHost": "smtp-mail.outlook.com",
      "smtpServerPort": 587,
      "smtpServerProtocol": "starttls",
      "imapServerHost": "outlook.office365.com",
      "imapServerPort": 993,
      "imapServerProtocol": "ssl",
    }
  ];

  let back = () => {
    if (!loadingSubmit) {
      history.push(`/o/${orgID}/integration/email/detail/${props.match.params.accountID}`);
    }
  }

  let doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };


  let getTopics = () => {
    setIsLoadingGetDetailData(true);

    topicServices.getTopic(orgID, "list", null, (response) => {
      const topics = response.dataResult.data.topics;

      const createList = () => {
        let newData = [];

        if (topics) {
          topics.map(val => {
            let _data = {};
            _data.value = val.id;
            _data.label = val.name;
            newData.push(_data);
            return null;
          });

          setTopicList(newData);
        }


        getAccountDetail();
      };

      createList();
    });
  }

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    doMount();
    getTopics();
  }, [orgID]);

  let onSelectDropdown = (id, value) => {
    let _errorMessageData = { ...errorMessageData };
    let _data = { ...data };
    _errorMessageData[id] = "";
    _data[id] = value;

    if (id === "provider") {
      _data.outgoing = value.smtpServerHost;
      _data.portOut = value.smtpServerPort;
      _data.protocolOut = {
        value: value.smtpServerProtocol,
        label: value.smtpServerProtocol === "ssl" ? "SSL" : "StartTLS"
      };
      _data.incoming = value.imapServerHost;
      _data.portIn = value.imapServerPort;
    }

    if (id === "protocolOut") {
      _data.provider = EMAIL_PROVIDER[0];
    }

    setErrorMessageData(_errorMessageData);
    setData(_data);
  }

  let onChangeText = (id, e) => {
    let _errorMessageData = { ...errorMessageData };
    let _data = { ...data };

    _errorMessageData[id] = "";

    if (id === "sender" && e.target.value.length > 50) {
      _errorMessageData[id] = "Characters exceed limit";
    }

    if (id !== "sender" && id !== "email" && id !== "password") {
      _data.provider = EMAIL_PROVIDER[0];
    }

    _data[id] = e.target.value;
    setErrorMessageData(_errorMessageData);
    setData(_data);
  }

  let onSubmit = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = { ...errorMessageData };

    if (data.topic.value === "") {
      _errorMessageData.topic = "Please select topic";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-topic" }
    }

    // if (data.sender === "") {
    //   _errorMessageData.sender = "This field is required";
    //   errorCount = errorCount + 1;

    //   if (errorClass === "") { errorClass = "input-sender" }
    // }

    if (errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    } else {
      setLoadingSubmit(true);

      let _data = {
        id: parseInt(props.match.params.accountID),
        topicID: data.topic.value,
        senderName: data.sender
      }

      IntegrationEmailServices.edit(props.match.params.orgID, _data, (response) => {
        let _retriveDataError = { ...retriveDataError };
        if (response.dataResult.error.message === "") {
          if (response.dataResult.data.success) {
            setDataOrigin({ ...data });

            setTimeout(() => {
              back();
            }, 3000)
          } else {
            doToast(response.dataResult.data.message, "fail");
            setLoadingSubmit(false);
          }
        } else {
          _retriveDataError.message = response.dataResult.error.message;
          _retriveDataError.code = response.dataResult.error.code;
          setRetrieveDataError(_retriveDataError);

          doToast(response.dataResult.error.message, 'fail');
          setLoadingSubmit(false);
        }
      });
    }
  }

  let getAccountDetail = () => {
    setIsLoadingGetDetailData(true);

    let _data = {
      id: parseInt(props.match.params.accountID)
    }

    IntegrationEmailServices.getDetail(props.match.params.orgID, _data, (response) => {
      let _retriveDataError = { ...retriveDataError };
      if (response.dataResult.error.message === "") {
        let newData = {};

        newData = {
          sender: response.dataResult.data.channel.senderName,
          topic: {
            value: response.dataResult.data.channel.topicID,
            label: response.dataResult.data.channel.topicName
          }
        }

        setData({ ...newData });
        setDataOrigin({ ...newData });
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);

        // doToast(response.dataResult.error.message, 'fail');
        // setIsLoadingGetDetailData(false);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataOrigin={JSON.stringify(dataOrigin)}
        dataAfterChange={JSON.stringify(data)}
      >
        {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}

        {isLoadingGetDetailData ?
          <RetrieveDataLoading
            isLoading={isLoadingGetDetailData}
            errorMessage={retriveDataError.message}
          />
          :
          <div className="sectionWrap integration-main-container integration-email-add-account text-left">
            <BackButton text="Back to Channel Details" onClick={back} />
            <div className="integration-email-add-account-box">
              <div className="integration-email-add-account-box-top">
                <b>Edit Channel Details</b>

                {loadingSubmit ?
                  <ButtonWithLoadingOrIcon
                    // isDisabled={!isFormValid()} 
                    isDisabled
                    loadingColor={"gray"}
                    isLoading
                    className="orange-button main-button-40"
                    text="Save Changes"
                    position="left"
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    // isDisabled={!isFormValid()} 
                    onClickAction={onSubmit}
                    className="orange-button main-button-40"
                    text="Save Changes"
                    position="left"
                  />
                }
              </div>

              <div className="integration-email-add-account-box-form">
                <div className="integration-email-add-account-box-form-inner">
                  <label><b>Topic</b></label>
                  
                  <CustomReactSelect
                    width="350px"
                    options={topicList}
                    placeholder="Select topic"
                    onChange={(e) => onSelectDropdown("topic", e)}
                    classNamePrefix="custom-select-email-topic"
                    isDisabled={loadingSubmit}
                    value={
                      topicList.filter(
                        (obj) => obj.value === data.topic.value
                      )
                    }
                    blurInputOnSelect={true}
                    _className={`input-topic ${errorMessageData.topic !== "" ? "border-red" : ""}`}
                  />
                  <ErrorOrInfoComp
                    text={errorMessageData.topic === "" ? "Incoming messages on this channel will be assigned to this topic." : errorMessageData.topic}
                    _className={errorMessageData.topic !== "" ? "font-red " : ""}
                    icon={<FiInfo />}
                  />

                  <label>
                    <span className={errorMessageData.sender === "Characters exceed limit" ? "red-text" : "grey-font"}>({data.sender.length}/50 character)</span>
                    <b>Sender Name</b>
                    <span className="grey-font optional-text"> (Optional)</span>
                  </label>
                  <input
                    type="text"
                    placeholder="e.g. TapTalk.io Support"
                    onChange={(e) => { onChangeText("sender", e) }}
                    value={data.sender}
                    className={`input-sender ${errorMessageData.sender !== "" ? "border-red" : ""}`}
                    disabled={loadingSubmit}
                  />
                  {errorMessageData.sender !== "" &&
                    <ErrorOrInfoComp
                      text={errorMessageData.sender}
                      _className={errorMessageData.sender !== "" ? "font-red" : ""}
                      icon={<FiInfo />}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </SectionWrap>
    </div>
  )
}

export default IntegrationEmailEditDetail;