import React, { useState, useEffect } from "react";
import "./QuickReply.scss";
import { TabContent, Nav, NavItem } from 'reactstrap';
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { checkID } from "../../helper/HelperGeneral";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import QuickReplyPersonal from "./personal/QuickReply";
import QuickReplyGeneral from "./general/QuickReply";
import ServiceQuickReply from "../../services/newServices/QuickReplyService";
import { connect } from 'react-redux';

const QuickReply = (props) => {
    let { match, history, location } = props;
    let [orgID, setOrgID] = useState(false);
    let [retriveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    })
    let [quickReplyData, setQuickReplyData] = useState(false);
    let [waitForResponseGetList, setWaitForResponseGetList] = useState(true);

    let toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const QUICK_REPLY = {
        generalQuickReply: {
            id: "generalQuickReply",
            tab: "General Quick Reply",
            component: <QuickReplyGeneral {...props} quickReply={quickReplyData} retriveDataError={retriveDataError} />,
            isHidden: props.memberRole === "member"
        },
        personalQuickReply: {
            id: "personalQuickReply",
            tab: "Personal Quick Reply",
            component: <QuickReplyPersonal {...props} quickReply={quickReplyData} retriveDataError={retriveDataError} />
        },
    }

    let [activeTab, setActiveTab] = useState(props.memberRole === "member" ? QUICK_REPLY.personalQuickReply.id : QUICK_REPLY.generalQuickReply.id);

    let doMount = async () => {
        const paramID = checkID(match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/organization/members`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let fetchQuickReplyList = () => {
        let _retriveDataError = { ...retriveDataError };

        ServiceQuickReply.getQuickReplyList(orgID, { channelType: "all" }, (response) => {
            let dataResult = response.dataResult;

            if (dataResult.error.message === "") {
                setQuickReplyData(dataResult.data);
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setWaitForResponseGetList(false);
        })
    }

    useEffect(() => {
        doMount();
    }, [location.pathname]);

    useEffect(() => {
        if (props.history.location.state) {
            if (props.history.location.state.tab) {
                setActiveTab(props.history.location.state.tab);
            }
        }
    }, [props.history])

    useEffect(() => {
        if (orgID) {
            fetchQuickReplyList();
        }
    }, [orgID])

    let showTab = (tab) => {
        let _show = true;

        // if (quickReplyData && tab === "generalQuickReply" && !quickReplyData.canManageGeneral) {
        //     _show = false;
        //     // setActiveTab(QUICK_REPLY.personalQuickReply.id);
        // }

        return _show;
    }

    return (
        <>
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                <div className="quick-reply-page-wrapper">
                    {waitForResponseGetList || retriveDataError.code ?
                        <RetrieveDataLoading
                            isLoading={waitForResponseGetList}
                            errorMessage={retriveDataError.message}
                        />
                        :
                        <>
                            <Nav tabs>
                                {Object.keys(QUICK_REPLY).map((value, index) => {
                                    return (
                                        !QUICK_REPLY[value].isHidden &&
                                        (showTab(value) &&
                                            <NavItem
                                                key={`tab-setup-${index}`}
                                                className={`
                                                        ${activeTab === QUICK_REPLY[value].id ?
                                                            ((value === QUICK_REPLY.generalQuickReply.id && !quickReplyData.canManageGeneral) ? '' : 'active-setup-tab')
                                                            :
                                                            ''
                                                        }

                                                        ${value === QUICK_REPLY.personalQuickReply.id && !quickReplyData.canManageGeneral ? "active-setup-tab" : ""}
                                                    `}
                                            >
                                                <div className="setup-tab-wrapper"
                                                    onClick={() => { toggle(QUICK_REPLY[value].id); }}
                                                >
                                                    <b>{QUICK_REPLY[value].tab}</b>
                                                </div>
                                            </NavItem>
                                        )
                                    )
                                })}
                            </Nav>

                            <TabContent>
                                {QUICK_REPLY[!quickReplyData.canManageGeneral ? QUICK_REPLY.personalQuickReply.id : activeTab].component}
                            </TabContent>
                        </>
                    }
                </div>
            </SectionWrap>
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(QuickReply)