import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../auth/Auth.css";
import "./Roles.scss";
import { checkID, doToast } from "../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import iconEyeOrange from "../../assets/img/icon-eye-orange.svg";
import PopupRemove from "../reuseableComponent/popupRemove/PopupRemove";
import CustomSearchBox from "../reuseableComponent/customSearchBox/CustomSearchBox";
import ServiceGroupRole from "../../services/organization/ServiceGroupRole";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import mixpanel from "mixpanel-browser";

let Roles = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [waitForResponseDelete, setWaitForResponseDelete] = useState(false);
  let [showPopupRemove, setShowPopupRemove] = useState(false);
  let [searchVal, setSearchVal] = useState("");
  let [roles, setRoles] = useState([]);
  let [rolesOrigin, setRolesOrigin] = useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(true);
  let [dataToBeRemoved, setDataToBeRemoved] = useState([]);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.roleships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.roleships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/roles`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (orgID) {
      if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Page] Group Roles",
          {
            userFullName: myAgentData.account.fullName,
            userID: myAgentData.account.id,
            organizationID: orgID.split("-")[0]
          }
        );
      }
    }
  }, [orgID])

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.success) {
        doToast(props.history.location.state.inviteMessage);
      } else {
        doToast(props.history.location.state.inviteMessage, 'fail');
      }

      window.history.pushState(null, '');
    }
  }, [props.history])

  // let onSelectListPage = (val) => {
  // let data = {
  //   pageDisplay: 1,
  //   listPage: val.value,
  //   submitQuery: submitQuery
  // }
  // let paramID = checkID(match);
  // getUserContactList(paramID, false, data);
  // setListPageVal(val)
  // setListPage(val.value)
  // setPageDisplay(1)
  // }

  let onChangeSearch = (e) => {
    let val = e.target.value.toLowerCase();
    setSearchVal(val);

    if (val === "") {
      setRoles(rolesOrigin)
    } else {
      let result = [];
      for (let i in rolesOrigin) {
        let objectKey = Object.keys(rolesOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof rolesOrigin[i][objectKey[j]] === "string";
          if (rolesOrigin[i][objectKey[j]].toString().toLowerCase().includes(val) && isString) {
            result.push(rolesOrigin[i])
            break;
          }
        }
      }

      setRoles(result);
    }
  }

  let onClearSearch = () => {
    let valSearch = document.querySelector(`#search-box-input-val`);
    valSearch.value = "";
    setRoles(rolesOrigin);
    setSearchVal("");
  }

  let toggleModalRemoveMember = () => {
    if (!waitForResponseDelete) {
      setShowPopupRemove(!showPopupRemove);
    }
  }

  let initialState = () => {
    setDataToBeRemoved([]);
    setShowPopupRemove(false);
    setWaitForResponseGetList(true);
    setWaitForResponseDelete(false);
  }

  let deleteRole = () => {
    setWaitForResponseDelete(true);

    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    let _body = null;

    _body = {
      createdTime: "",
      id: dataToBeRemoved
    }

    ServiceGroupRole.postDeleteRole(orgID, _body, (response) => {
      if (response.dataResult.status === 200) {
        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
          mixpanel.track(
            "[Action] Remove Group Roles",
            {
              userFullName: myAgentData.account.fullName,
              userID: myAgentData.account.id,
              organizationID: orgID.split("-")[0]
            }
          );
        }

        doToast(response.dataResult.data.message);
        initialState();
        getList();
      } else {
        doToast(response.dataResult.error.message, "fail");
        setWaitForResponseDelete(false);
      }
    })
  }

  let getList = () => {
    let _retriveDataError = { ...retriveDataError };

    ServiceGroupRole.postGetGroupRole(orgID, (response) => {
      let dataResult = response.dataResult;

      if (response.dataResult.status === 200) {
        // setMemberOriginals(response.dataResult.data.roles);
        setRoles(response.dataResult.data.roles);
        setRolesOrigin(response.dataResult.data.roles);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  useEffect(() => {
    if (orgID) getList(orgID);
  }, [orgID]);

  // let onClickCheckAll = (e) => {
  //   let checked = e.target.checked;
  //   let data = [];

  //   if(checked) {
  //     roles.map((v) => {
  //       data.push(v.id);

  //       return null;
  //     })
  //   }

  //   setDataToBeRemoved(data);
  // }

  // let onClickSingleCheck = (e, id) => {
  //   let checked = e.target.checked;
  //   let _dataToBeRemoved = dataToBeRemoved.slice();

  //   let _findIndex = _dataToBeRemoved.findIndex(v => v === id);

  //   if(checked) {
  //     _dataToBeRemoved.push(id);
  //   }else {
  //     _dataToBeRemoved.splice(_findIndex, 1);
  //   }

  //   setDataToBeRemoved(_dataToBeRemoved);
  // }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <div className="sectionWrap">
          <div className="roles-list-title">
            <b>Roles</b>

            {!retriveDataError.code &&
              <>
                {(dataToBeRemoved.length === 0) ?
                  <Link
                    to={`/o/${orgID}/organization/roles/create`}
                    onClick={() => {
                      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                      if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                        mixpanel.track(
                          "[Button] Create Group Roles",
                          {
                            userFullName: myAgentData.account.fullName,
                            userID: myAgentData.account.id,
                            organizationID: orgID.split("-")[0]
                          }
                        );
                      }
                    }}
                  >
                    <ButtonWithLoadingOrIcon
                      className="orange-button main-button-40 button-create-role"
                      text="Create Role"
                      icon={{
                        type: "svg",
                        src: FiPlus
                      }}
                      position="left"
                    />
                  </Link>
                  :
                  <ButtonWithLoadingOrIcon
                    onClickAction={() => toggleModalRemoveMember(false)}
                    className="red-button main-button-40 button-create-role"
                    text={`Delete ${dataToBeRemoved.length} Role${dataToBeRemoved.length > 1 ? "s" : ""}`}
                    icon={{
                      type: "svg",
                      src: FiTrash2
                    }}
                    position="left"
                  />
                }
              </>
            }
          </div>

          {waitForResponseGetList || retriveDataError.code ?
            <RetrieveDataLoading
              isLoading={waitForResponseGetList}
              errorMessage={retriveDataError.message}
            />
            :
            <React.Fragment>
              <div className="roles-list-tool-wrapper">
                <CustomSearchBox
                  placeholder={"Search for role"}
                  onChangeSearchSpecial={onChangeSearch}
                  style={{ width: '343px' }}
                  dataToSearch={roles}
                  onClearSearch={onClearSearch}
                  search={searchVal}
                />

                {/* {dataToBeRemoved.length === 0 ?
                  ""
                  :
                  <div className="manage-project-tool-wrapper roles-tool-wrapper">
                    <div id="button-box-wrapper">
                      <ButtonWithLoadingOrIcon
                        onClickAction={() => toggleModalRemoveMember(false)}
                        className="red-button main-button-48"
                        text={`Remove ${dataToBeRemoved.length} Member${dataToBeRemoved.length > 1 ? "s" : ""}`}
                        icon={{
                          type: "svg",
                          src: FiTrash2
                        }}
                        position="right"
                      />

                      <ButtonWithLoadingOrIcon
                        className="no-fill-button main-button-48"
                        text={`Cancel`}
                        onClickAction={() => onCancelRemove()}
                      />
                    </div>
                  </div>
                } */}
              </div>

              <div className="roles-list-table-wrapper">
                <div className="custom-table">
                  <table className="custom-table-content table-roles-list">
                    <thead>
                      <tr>
                        <td className="col_name">
                          {/* <div className={dataToBeRemoved.length === roles.length ? "custom-checkbox" : "custom-checkbox-stripe"}>
                            <input
                              type="checkbox"
                              id="select-all"
                              onClick={(e) => onClickCheckAll(e)}
                              checked={dataToBeRemoved.length > 0}
                            />
                            <label htmlFor="select-all"></label>
                          </div> */}
                          Name
                        </td>
                        <td className="col_desc">Description</td>
                        <td className="col_total">Total Members</td>
                        <td className="col_role">
                          {/* <CustomSelectOption
                            optionListProps={listPageOption}
                            valueDropdownProps={listPageVal}
                            onClickDropDownListOptionProps={onSelectListPage}
                            isDisabled={false}
                            isBold
                          /> */}
                        </td>
                      </tr>
                    </thead>

                    {roles.length < 1 ?
                      <tbody>
                        <tr className="no-data-tr">
                          <td colSpan="4" className="no-data-column"><b className="no-result-found">{searchVal !== "" ? "No roles found" : "You have no roles yet, start with ‘+ Create Role’"}</b></td>

                        </tr>
                      </tbody>
                      :
                      <tbody>
                        {roles.map((value, index) => {
                          return (
                            <tr key={`roles-${index}`}>
                              <td className="col_name">
                                {/* <div className="custom-checkbox">
                                  <input type="checkbox"
                                    id={`select-roles-${value.id}`}
                                    onClick ={(e) => onClickSingleCheck(e, value.id)}
                                    checked={dataToBeRemoved.findIndex(v => v === value.id) !== -1}
                                  />
                                  <label htmlFor={`select-roles-${value.id}`}></label>
                                </div> */}
                                {value.name}
                              </td>
                              <td className="col_desc">{value.description}</td>
                              <td className="col_total">{value.totalMembers}</td>
                              <td className={`col_role agent-${value.rowID}`}>
                                <div className="col-action-button-wrapper">
                                  {value.accountID !== 0 &&
                                    <Link to={{
                                      pathname: `/o/${orgID}/organization/roles/detail/${value.id}`,
                                      state: {
                                        data: null
                                      }
                                    }}
                                      onClick={() => {
                                        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                                        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                                          mixpanel.track(
                                            "[Button] Member Details",
                                            {
                                              userFullName: myAgentData.account.fullName,
                                              userID: myAgentData.account.id,
                                              organizationID: orgID.split("-")[0]
                                            }
                                          );
                                        }
                                      }}
                                    >
                                      <img className="view-agent" src={iconEyeOrange} alt="" />
                                      <b className="view-agent">View</b>
                                    </Link>
                                  }
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    }
                  </table>
                </div>


                <PopupRemove 
                  removeTitle={`Remove ${dataToBeRemoved.length} role${dataToBeRemoved.length > 1 ? "s" : ""}?`}
                  removeDescription={`You will be deleting ${dataToBeRemoved.length} role${dataToBeRemoved.length > 1 ? "s" : ""} and this action cannot be undone. Are you sure you want to continue?`}
                  onClickCancel={() => toggleModalRemoveMember(false)}
                  onClickRemove={deleteRole}
                  waitForResponseDelete={waitForResponseDelete}
                  showModalProps={showPopupRemove}
                  colorButton="red-button"
                  submitText={`Delete Role${dataToBeRemoved.length > 1 ? "s" : ""}`}
                />
              </div>
            </React.Fragment>
          }
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default Roles;
