import React, { useEffect, useState, useRef } from 'react';
import './DailyOverviewReport.scss';
import { Tooltip } from "reactstrap";
import ReportService from '../../../services/newServices/ReportService';
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import { doToast, checkID } from '../../../helper/HelperGeneral';
import HelperDate from '../../../helper/HelperDate';
import NoReportFound from "../../../assets/img/no-report-found.svg";
import IconChatList from '../../../assets/img/chatroom/user-info-tab/chat-list.svg';
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import CalendarPickerSingle from '../../reuseableComponent/calendarPickerSingle/CalendarPickerSingle';
import OverviewTopicReportNoData from '../overview/overviewTopicReportNoData/OverviewTopicReportNoData';
import mixpanel from "mixpanel-browser";
import CaseReport1 from "../../../assets/img/report/overview/case-report-1.svg";
import CaseReport2 from "../../../assets/img/report/overview/case-report-2.svg";
import CaseReport6 from "../../../assets/img/report/overview/case-report-6.svg";
import CaseReport8 from "../../../assets/img/report/overview/case-report-8.svg";
import CaseReport7 from "../../../assets/img/report/overview/case-report-7.svg";
import IconUsers from '../../../assets/img/fi-user-circle.svg';
import IconQuestion from '../../../assets/img/fi-question.svg';
import IconBot from '../../../assets/img/fi-bot.svg';
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import { FiRefreshCw, FiHelpCircle, FiCalendar, FiGlobe } from 'react-icons/fi';
import { TAPLIVE_MEDIUM_LOGO } from '../../../constants/taplive';
import Chart from 'chart.js';
import ApexCharts from 'apexcharts';

let DailyOverviewReport = (props) => {
  let [orgID, setOrgID] = React.useState(false);
  let [isWaitingFetchOverview, setIsWaitingFetchOverview] = useState(false);
  let [printedOverviewData, setPrintedOverviewData] = useState(null);
  let [retriveDataError, setRetrieveDataError] = React.useState({
    code: false,
    message: false
  });
  let [lastUpdate, setLastUpdate] = useState(new Date().valueOf());
  let [showTooltipCasesCreated, setShowTooltipCasesCreated] = useState(false);
  let [showTooltipTotalCase, setShowTooltipTotalCase] = useState(false);
  let [showTooltipAuto, setShowTooltipAuto] = useState(false);
  let [isOpenModalCalendar, setOpenModalCalendar] = useState(false);
  let [filterDate, setFilterDate] = useState(new Date());
  let [filterDateForCalender, setFilterDateForCalender] = useState(new Date());
  let [autoRefresh, setAutoRefresh] = useState(false);
  let autoRefreshRef = useRef(null);
  
  let [showTooltipChannelTrafficUnassigned, setShowTooltipChannelTrafficUnassigned] = useState(false);
  let [showTooltipChannelTrafficAgent, setShowTooltipChannelTrafficAgent] = useState(false);
  let [showTooltipChannelTrafficBot, setShowTooltipChannelTrafficBot] = useState(false);
  let [showTooltipTotalCasesUnassigned, setShowTooltipTotalCasesUnassigned] = useState(false);
  let [showTooltipTotalCasesCreated, setShowTooltipTotalCasesCreated] = useState(false);
  let [showTooltipTotalCasesResolved, setShowTooltipTotalCasesResolved] = useState(false);

  let channelTrafficChart = null;

  let timerAutoRefresh = () => {
    autoRefreshRef.current = setInterval(() => {
      getOverview(new Date());
    }, 600000)
  }

  let toggleTooltipChannelTrafficUnassigned = () => {
    setShowTooltipChannelTrafficUnassigned(!showTooltipChannelTrafficUnassigned);
  }

  let toggleTooltipChannelTrafficAgent = () => {
    setShowTooltipChannelTrafficAgent(!showTooltipChannelTrafficAgent);
  }

  let toggleTooltipChannelTrafficBot = () => {
    setShowTooltipChannelTrafficBot(!showTooltipChannelTrafficBot);
  }

  let toggleTooltipTotalCasesUnassigned = () => {
    setShowTooltipTotalCasesUnassigned(!showTooltipTotalCasesUnassigned);
  }

  let toggleTooltipTotalCasesCreated = () => {
    setShowTooltipTotalCasesCreated(!showTooltipTotalCasesCreated);
  }

  let toggleTooltipTotalCasesResolved = () => {
    setShowTooltipTotalCasesResolved(!showTooltipTotalCasesResolved);
  }

  let toggleModalCalendar = () => {
    setOpenModalCalendar(!isOpenModalCalendar);
    setFilterDateForCalender(filterDate);
  }

  let handleDateFilterClick = (date) => {
    setFilterDateForCalender(date);
  }

  let handleDateFilterCancel = () => {
    setFilterDateForCalender(filterDate)
  }

  let toggleTooltipCasesCreated = () => {
    setShowTooltipCasesCreated(!showTooltipCasesCreated);
  }

  let toggleTooltipTotalCase = () => {
    setShowTooltipTotalCase(!showTooltipTotalCase);
  }

  let toggleTooltipAuto = () => {
    setShowTooltipAuto(!showTooltipAuto);
  }

  useEffect(() => {
    if (orgID) {
      getOverview();

      let _autoRefreshOverview = localStorage.getItem("autoRefreshOverview");
      let _orgID = orgID.split("-")[0];

      if (_autoRefreshOverview !== null && _autoRefreshOverview !== "") {
        setAutoRefresh(JSON.parse(_autoRefreshOverview)[_orgID]);

        if (JSON.parse(_autoRefreshOverview)[_orgID]) {
          timerAutoRefresh();
        }
      }
    }
  }, [orgID])

  useEffect(() => {
    doMount();
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Page] Daily Overview Report",
        {
          userFullName: myAgentData.account.fullName,
          userID: myAgentData.account.id,
          organizationID: props.match.params.orgID.split("-")[0]
        }
      );
    }
  }, []);

  let doMount = async () => {
    let paramID = checkID(props.match);

    if (paramID) {
      setOrgID(paramID);
    } 
    else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/organization/members`);
        } 
        else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let getOverview = (date = false) => {
    if (date) {
      setFilterDate(date);
    }

    setIsWaitingFetchOverview(true);

    if (channelTrafficChart) {
      channelTrafficChart.destroy();
    }

    let _retriveDataError = { ...retriveDataError };
    let data = {
      date: HelperDate.formatToString(date ? date : filterDate, "yyyy-MM-dd")
    }

    ReportService.getOverviewDaily(props.match.params.orgID, data, (response) => {
      let dataResult = response.dataResult;

      if (dataResult.error.message === '') {
        setPrintedOverviewData(dataResult.data);
        setLastUpdate(new Date().valueOf());
      }
      else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsWaitingFetchOverview(false);
    });
  };

  let printTime = (val) => {
    let time = "";
    let split = val.split(":");
    let h = split[0];
    let m = split[1];
    let s = split[2];

    if (m === "00") {
      time = s + "s";
    }
    else if (h === "00") {
      time = m + "m " + s + "s";
    }
    else {
      time = h + "h " + m + "m " + s + "s";
    }

    return time;
  }

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.success) {
        doToast(props.history.location.state.message);
      }
      else {
        doToast(props.history.location.state.message, 'fail');
      }

      window.history.pushState(null, '');
    }
  }, [props.history]);

  useEffect(() => {
    //chart plugin
    Chart.plugins.register({
      beforeRender: function(chart) {
        if (chart.config.options.showAllTooltips) {
          // create an array of tooltips, 
          // we can't use the chart tooltip because there is only one tooltip per chart
          chart.pluginTooltips = [];
          chart.config.data.datasets.forEach(function(dataset, i) {
            chart.getDatasetMeta(i).data.forEach(function(sector, j) {
              chart.pluginTooltips.push(new Chart.Tooltip({
                _chart: chart.chart,
                _chartInstance: chart,
                _data: chart.data,
                _options: chart.options.tooltips,
                _active: [sector]
              }, chart));
            });
          });      
          chart.options.tooltips.enabled = false; // turn off normal tooltips
        }
      },
      afterDraw: function(chart, easing) {
        if (chart.config.options.showAllTooltips) {
          if (!chart.allTooltipsOnce) {
            if (easing !== 1) {
              return;
            }
            chart.allTooltipsOnce = true;
          }
          chart.options.tooltips.enabled = true;
          Chart.helpers.each(chart.pluginTooltips, function(tooltip) {
            tooltip.initialize();
            tooltip.update();
            tooltip.pivot();
            tooltip.transition(easing).draw();
          });
          chart.options.tooltips.enabled = false;
        }
      }
    });
    //chart plugin

    if (printedOverviewData &&
        printedOverviewData.casesPerHour &&
        printedOverviewData.casesPerHour.length > 0
    ) {
      const xValues = [];
      const yValues = [];
      const createdValues = [];
      const unassignedValues = [];
      const resolvedValues = [];

      printedOverviewData.casesPerHour.map((v) => {
        xValues.push(v.label);
        yValues.push(v.totalCases);
        createdValues.push(v.totalCasesCreated);
        unassignedValues.push(v.totalCasesUnassigned);
        resolvedValues.push(v.totalCasesClosed);

        return null;
      })

      setTimeout(() => {
        new Chart("myChartTotalCasePerHour", {
          type: "line",
          data: {
            labels: xValues,
            datasets: [
              {
                label: "Created",
                data: createdValues,
                borderWidth: 1,
                borderColor: "#3BAEFF",
                pointBackgroundColor: "#3BAEFF",
                backgroundColor: "#3BAEFF1A",  
                lineTension: 0,
                pointRadius: 4,
                fill: true,
              },
              {
                label: "Unassigned",
                data: unassignedValues,
                borderWidth: 1,
                borderColor: "#F99080",
                pointBackgroundColor: "#F99080",
                backgroundColor: "#F990801A",
                lineTension: 0,
                pointRadius: 4,
                fill: true,
              },
              {
                label: "Resolved",
                data: resolvedValues,
                borderWidth: 1,
                borderColor: "#191919",
                pointBackgroundColor: "#191919",
                backgroundColor: "#1919191A",
                lineTension: 0,
                pointRadius: 4,
                fill: true,
              },
            ]
          },
          options: {
            legend: { display: false },
            // events: ['click'],
            tooltips: {
              intersect: false,
              callbacks: {
                beforeFooter: function () {
                  return ""
                },
                title: function (tooltipItems, data) {
                  return (tooltipItems && tooltipItems.length > 0) ? `🕔 ${tooltipItems[0].xLabel}:00` : "";
                },
                label: function (tooltipItems, data) {
                  // return (tooltipItems && data.datasets && data.datasets.length > tooltipItems.datasetIndex) ? `${data.datasets[tooltipItems.datasetIndex].label}: ${tooltipItems.yLabel} case${Number(tooltipItems.yLabel) > 1 ? "s" : ""}` : "";
                  return "";
                },
                afterBody: function(data) {
                  let tooltip = [];
                  if (data && data.length > 0) {
                    let index = data[0].index;
                    tooltip.push(`Created: ${createdValues[index]} case${createdValues[index] > 1 ? "s" : ""}`);
                    tooltip.push(`Unassigned: ${unassignedValues[index]} case${unassignedValues[index] > 1 ? "s" : ""}`);
                    tooltip.push(`Resolved: ${resolvedValues[index]} case${resolvedValues[index] > 1 ? "s" : ""}`);
                  }
                  return tooltip;
                }
              },
              filter: function (tooltipItem, data) {
                var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                if (value === 0) {
                    return false;
                }
                else {
                    return true;
                }
              },
              titleFontFamily: "PTRootBold",
              titleFontSize: 12,
              bodyFontFamily: "PTRootUI",
              bodyFontSize: 12,
              bodySpacing: 4,
              backgroundColor: '#6B6B6B',
              displayColors: false,
              whiteSpace: "pre-line"
            },
            scales: {
              xAxes: [{
                gridLines: {
                  borderDash: [4, 4],
                  color: "#19191966"
                },
                ticks: {
                  fontFamily: "PTRootBold",
                  fontColor: "#19191966",
                  fontSize: 12,
                }
              }],
              yAxes: [{
                gridLines: {
                  borderDash: [4, 4],
                  color: "#19191966"
                },
                ticks: {
                  beginAtZero: true,
                  fontFamily: "PTRootUI",
                  fontColor: "#19191966",
                  fontSize: 12,
                }
              }]
            },
            interaction: {
              mode: 'nearest',
              axis: 'x',
              intersect: false
            }
          }
        })

        if (props.match.params.orgID &&
            props.match.params.orgID.split("-").length > 0 &&
            props.match.params.orgID.split("-")[0] === "5419" // FIXME: HARD-CODED CHART
        ) {
          new Chart("chartCreatedCasePerHour", {
            type: "line",
            data: {
              labels: xValues,
              datasets: [{
                data: createdValues,
                borderColor: "#4138BC",
                fill: "#4138BC"
              }]
            },
            options: {
              showAllTooltips: true,
              legend: { display: false },
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                  },
                }],
              },
              tooltips: {
                callbacks: {
                  beforeFooter: function () {
                    return ""
                  },
                  title: function () {
                    return ""
                  },
                  label: function (tooltipItems, data) {
                    return `${tooltipItems.yLabel}`;
                  },
                },
                filter: function (tooltipItem, data) {
                  var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                  if (value === 0) {
                      return false;
                  }
                  else {
                      return true;
                  }
              },
                backgroundColor: '#6B6B6B',
                displayColors: false,
              },
            }
          })
        }
      }, 0);
    }

    if (printedOverviewData &&
        printedOverviewData.casesPerChannel &&
        printedOverviewData.casesPerChannel.length > 0
    ) {
      const xValues = [];
      const xValuesTrimmed = [];
      const unassignedValues = [];
      const agentValues = [];
      const botValues = [];
      const mediums = [];

      printedOverviewData.casesPerChannel.map((v) => {
        xValues.push(v.channelName);
        if (v.channelName.length > 12) {
          let first = v.channelName.substr(0, 12);
          if (first.lastIndexOf(" ") > 0) {
            first = first.substr(0, first.lastIndexOf(" "));
          }
          let second = v.channelName.substr(first.length);
          if (second.startsWith(" ")) {
            second = second.substr(1);
          }
          if (second.length > 12) {
            second = second.substr(0, 12) + "…";
          }
          xValuesTrimmed.push([first, second]);
        }
        else {
          xValuesTrimmed.push(v.channelName);
        }
        unassignedValues.push(v.totalUnassigned);
        agentValues.push(v.totalAssignedToAgent);
        botValues.push(v.totalAssignedToChatbot);
        mediums.push(v.channelType);
        return null;
      })

      const channelTrafficSeries = [
        {
          name: "Assigned to Agent",
          data: agentValues,
        },
        {
          name: "Unassigned",
          data: unassignedValues,
        },
        {
          name: "Assigned to Chatbot",
          data: botValues,
        },
      ];

      setTimeout(() => {
        let channelTrafficChartOptions = {
          chart: {
            type: "bar",
            stacked: true,
            height: 350,
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false,
            },
          },
          colors: ["#51E683", "#F99080", "#9C88F1"],
          stroke: {
            width: 1,
            colors: ["transparent"],
          },
          series: channelTrafficSeries,
          dataLabels: {
            style: {
              fontFamily: "PTRootUI",
              fontSize: "12px",
              fontWeight: "400",
            },
          },
          legend: {
            show: false,
            // position: 'top',
            // horizontalAlign: 'right',
            // fontFamily: "PTRootUI",
            // fontSize: "12px",
            // fontWeight: "400",
            // labels: {
            //   colors: ["#191919", "#191919", "#191919", "#191919", "#191919"],
            // },
            // onItemClick: {
            //     toggleDataSeries: false,
            // },
          },
          xaxis: {
            categories: xValuesTrimmed,
            labels: {
              style: {
                fontFamily: "PTRootUI",
                fontSize: "12px",
              },
              rotate: 0,
            },
            crosshairs: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            seriesName: "Number of Cases",
            title: {
              text: "Number of Cases",
              style: {
                fontFamily: "PTRootUI",
                fontSize: "12px",
                fontWeight: "400",
                color: "#19191966",
              }
            },
            labels: {
              style: {
                fontFamily: "PTRootUI",
                fontSize: "12px",
                colors: ["#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966", "#19191966"]
              }
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              // borderRadiusApplication: "around",
              // borderRadiusWhenStacked: "all",
            },
          },
          states: {
            active: {
              filter: {
                type: "none"
              }
            },
            hover: {
              filter: {
                type: "none"
              }
            },
          },
          tooltip: {
            theme: "dark",
            style: {
              fontFamily: "PTRootUI",
              fontSize: "12px",
            },
            intersect: false,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              return (
                `<div className="channel-traffic-tooltip">` +
                  `<span>` +
                    `<img src=${TAPLIVE_MEDIUM_LOGO[mediums[dataPointIndex]]} />` +
                    `<b>${xValues[dataPointIndex]}</b>` +
                  `</span>` +
                  `<p>${channelTrafficSeries[2].name}: ${channelTrafficSeries[2].data[dataPointIndex]} case${channelTrafficSeries[2].data[dataPointIndex] > 1 ? "s" : ""}</p>` +
                  `<p>${channelTrafficSeries[1].name}: ${channelTrafficSeries[1].data[dataPointIndex]} case${channelTrafficSeries[1].data[dataPointIndex] > 1 ? "s" : ""}</p>` +
                  `<p>${channelTrafficSeries[0].name}: ${channelTrafficSeries[0].data[dataPointIndex]} case${channelTrafficSeries[0].data[dataPointIndex] > 1 ? "s" : ""}</p>` +
                `</div>`
              )
            },
          }
        }
        let elChart = document.querySelector("#channel-traffic-chart");
        if (elChart) {
          channelTrafficChart = new ApexCharts(elChart, channelTrafficChartOptions);
          channelTrafficChart.render();
        }
      }, 0);
    }
  }, [printedOverviewData]);

  let submitDateFilter = () => {
    setFilterDate(filterDateForCalender);
    toggleModalCalendar();
    getOverview(filterDateForCalender);

    if (HelperDate.formatToString(new Date(), "yyyy-MM-dd") !== HelperDate.formatToString(new Date(filterDateForCalender), "yyyy-MM-dd")) {
      clearInterval(autoRefreshRef.current);
    }
    else {
      if (autoRefresh) {
        timerAutoRefresh();
      }
    }
  }

  let onClearDatePicker = () => {
    let _date = {
      from: undefined,
      to: undefined
    }

    setFilterDateForCalender(_date);
  }

  let isToday = () => {
    let yesToday = true;

    if (HelperDate.formatToString(new Date(), "yyyy-MM-dd") !== HelperDate.formatToString(new Date(filterDate), "yyyy-MM-dd")) {
      yesToday = false;
    }

    return yesToday;
  }

  let printDate = () => {
    let text = "";
    let today = HelperDate.formatToString(new Date(), "yyyy-MM-dd");

    if (HelperDate.formatToString(filterDate, "yyyy-MM-dd") === today) {
      text = `Today (${HelperDate.formatToString(filterDate, "d MMM yyyy")})`;
    }
    else {
      text = `${HelperDate.formatToString(filterDate, "d MMM yyyy")}`;
    }

    return text;
  }

  let onClickToggleAutoRefresh = (e) => {
    let _autoRefreshOverview = localStorage.getItem("autoRefreshOverview");
    let _orgID = orgID.split("-")[0];

    if (e.target.checked) {
      if (_autoRefreshOverview === null || _autoRefreshOverview === "") {
        localStorage.setItem("autoRefreshOverview", JSON.stringify({ [_orgID]: true }));
      }
      else {
        localStorage.setItem("autoRefreshOverview", JSON.stringify(Object.assign(JSON.parse(_autoRefreshOverview), { [_orgID]: true })));
      }

      timerAutoRefresh();
    }
    else {
      if (JSON.parse(_autoRefreshOverview)[_orgID]) {
        let _obj = JSON.parse(_autoRefreshOverview);

        delete _obj[_orgID];
        localStorage.setItem("autoRefreshOverview", JSON.stringify(_obj));

        clearInterval(autoRefreshRef.current);
      }
    }
    setAutoRefresh(!autoRefresh);
  }

  let isDataTotalCasePerHourEmpty = (data) => {
    if (!data) {
      return true;
    }
    
    let isEmpty = true;

    data.map((v, i) => {
      if (v.totalCases !== 0 || v.totalUnassigned !== 0 || v.totalAssignedToAgent !== 0 || v.totalAssignedToChatbot !== 0) {
        isEmpty = false;
      }

      return null;
    })

    return isEmpty;
  }

  const isChannelTrafficChartScrollable = () => {
    return printedOverviewData && printedOverviewData.casesPerChannel && (printedOverviewData.casesPerChannel.length * 120 + 60) > window.innerWidth - 240;
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="overview-daily-report-wrapper sectionWrap">
        {/* tier blocking */}
        {retriveDataError.code === "49901" &&
          <RetrieveDataLoading
            isLoading={isWaitingFetchOverview}
            errorMessage={retriveDataError.message}
            errorCode={retriveDataError.code}
          />
        }
        {/* tier blocking */}

        <React.Fragment>
          <p className="overview-daily-report-title">
            <b>Daily Overview</b>

            {isToday() &&
              <div className="daily-overview-top-right">
                {!retriveDataError.code &&
                  <>
                    <span className="grey-text">
                      Last updated {HelperDate.formatToString(new Date(lastUpdate), "dd/MM/yyyy, HH:mm")}
                    </span>
                    
                    <ButtonWithLoadingOrIcon
                      text="Refresh"
                      className="main-button-40 no-fill-button"
                      icon={{
                        type: "svg",
                        src: FiRefreshCw
                      }}
                      position="left"
                      onClickAction={() => getOverview()}
                      isDisabled={isWaitingFetchOverview}
                    />
                  </>
                }

              </div>
            }
          </p>

          <div className='daily-overview-date-wrapper-top'>
            {!retriveDataError.code &&
              <>
                <CalendarPickerSingle
                  {...props}
                  hideClear
                  isOpen={isOpenModalCalendar}
                  toggle={toggleModalCalendar}
                  date={filterDateForCalender}
                  handleDateFilterClick={handleDateFilterClick}
                  submitDateFilter={submitDateFilter}
                  cancelDatePicker={handleDateFilterCancel}
                  clearDatePicker={onClearDatePicker}
                  disabledDays={{
                    after: new Date()
                  }}
                />
                <div
                  className={`date-wrapper ${isWaitingFetchOverview ? "grey-box" : ""}`}
                  onClick={() => {
                    if (!isWaitingFetchOverview) {
                      toggleModalCalendar();
                    }
                  }}
                >
                  <span>{printDate()}</span>
                  <FiCalendar />
                </div>
              </>
            }


            {/* <div className="daily-overview-top-right">
              <span className="grey-text">
                Last updated {HelperDate.formatToString(new Date(lastUpdate), "dd/MM/yyyy, HH:mm")}
              </span>

              <ButtonWithLoadingOrIcon 
                text="Refresh"
                className="main-button-40 no-fill-button"
                icon={{
                  type: "svg",
                  src: FiRefreshCw
                }}
                position="left"
                onClickAction={getOverview}
                isDisabled={isWaitingFetchOverview}
              />
            </div> */}

            {isToday() &&
              (!retriveDataError.code &&
                <div className='toggle-auto-refresh'>
                  <label className="custom-switch-wrapper">
                    <input disabled={isWaitingFetchOverview} type="checkbox" checked={autoRefresh} onChange={(e) => onClickToggleAutoRefresh(e)} />
                    <span className="custom-switch round"></span>
                  </label>
                  <b>
                    Auto Refresh
                  </b>
                  <FiHelpCircle id="TooltipAuto" />

                  <Tooltip
                    isOpen={showTooltipAuto}
                    target="TooltipAuto"
                    toggle={toggleTooltipAuto}
                    placement="bottom"
                  >
                    Turning on toggle will refresh Daily Overview page every 10 minutes.
                  </Tooltip>
                </div>
              )
            }
          </div>

          {(isWaitingFetchOverview || (retriveDataError.code && retriveDataError.code !== "49901")) ?
            <RetrieveDataLoading
              isLoading={isWaitingFetchOverview}
              errorMessage={retriveDataError.message}
              errorCode={retriveDataError.code}
            />
            :
            <div className="overview-daily-report-data-outer-wrapper">
              <div className="overview-daily-report-data-wrapper">

                {
                  !printedOverviewData ?
                  <div className="no-overview-daily-report">
                    <img src={NoReportFound} alt="No Report Found" />

                    <p>
                      <b>No report found.</b>
                    </p>
                  </div>
                  :
                  <>
                    {
                      printedOverviewData.caseStatus.isRealTime &&
                      <div className="overview-daily-box-wrapper">
                        <p className="overview-daily-box-title">
                          <b>Real-time Status</b>
                        </p>

                        <div className="real-time-status-wrapper">
                          <div className="real-time-status-left-wrapper">
                            <p className="real-time-status-title">
                              <img src={IconUsers} alt="" className="value-title-icon" />
                              <b>Agent Status</b>
                            </p>

                            <div className="status-value-wrapper">
                              <p>
                                <div className="activity-circle online" />
                                Online (Connected)

                                <span>{printedOverviewData.agentStatus.totalOnlineConnected}</span>
                              </p>

                              <p>
                                <div className="activity-circle online-not" />
                                Online (Not Connected)

                                <span>{printedOverviewData.agentStatus.totalOnlineNotConnected}</span>
                              </p>

                              <p>
                                <div className="activity-circle away" />
                                Away

                                <span>{printedOverviewData.agentStatus.totalAway}</span>
                              </p>

                              <p>
                                <div className="activity-circle offline" />
                                Offline

                                <span>{printedOverviewData.agentStatus.totalOffline}</span>
                              </p>
                            </div>
                          </div>

                          <div className={`real-time-status-right-wrapper`}>
                            <p className="real-time-status-title">
                              <img src={IconChatList} alt="" className="value-title-icon" />
                              <b>Case Status</b>
                            </p>

                            <div className="status-value-wrapper">
                              <p>
                                <img src={IconQuestion} alt="" className="value-icon" />
                                Unassigned

                                <span>{printedOverviewData.caseStatus.totalUnassigned}</span>
                              </p>

                              <p>
                                <img src={IconUsers} alt="" className="value-icon" />
                                Assigned to agent

                                <span>{printedOverviewData.caseStatus.totalAssignedToAgent}</span>
                              </p>

                              <p>
                                <img src={IconBot} alt="" className="value-icon" />
                                Assigned to chatbot

                                <span>{printedOverviewData.caseStatus.totalAssignedToChatbot}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                
                <div className='overview-daily-box-wrapper total-cases-per-hour'>
                  <p className="real-time-status-title">
                    <FiGlobe className="value-title-icon" />
                    <b>Channel Traffic</b>
                  </p>

                  {
                    (printedOverviewData && !isDataTotalCasePerHourEmpty(printedOverviewData.casesPerChannel)) &&
                    <div className="chart-legend-wrapper">
                      <div className="chart-legend-list">
                        <div className="chart-legend assigned-to-bot"/>
                        <p>Assigned to Chatbot</p>
                        <FiHelpCircle id="tooltip-channel-traffic-bot" className="chart-legend-tooltip-icon" alt="" />
                        <Tooltip 
                          className="tooltip-chart-legend"
                          placement="bottom"
                          isOpen={showTooltipChannelTrafficBot}
                          target="tooltip-channel-traffic-bot"
                          toggle={toggleTooltipChannelTrafficBot}
                          opacity={1.0}
                        >
                          Number of cases<br/>assigned to Chatbot
                        </Tooltip>
                      </div>
                      <div className="chart-legend-list">
                        <div className="chart-legend channel-unassigned"/>
                        <p>Unassigned</p>
                        <FiHelpCircle id="tooltip-channel-traffic-unassigned" className="chart-legend-tooltip-icon" alt="" />
                        <Tooltip 
                          className="tooltip-chart-legend"
                          placement="bottom"
                          isOpen={showTooltipChannelTrafficUnassigned}
                          target="tooltip-channel-traffic-unassigned"
                          toggle={toggleTooltipChannelTrafficUnassigned}
                          opacity={1.0}
                        >
                          Number of<br/>unassigned cases
                        </Tooltip>
                      </div>
                      <div className="chart-legend-list">
                        <div className="chart-legend assigned-to-agent"/>
                        <p>Assigned to Agent</p>
                        <FiHelpCircle id="tooltip-channel-traffic-agent" className="chart-legend-tooltip-icon" alt="" />
                        <Tooltip 
                          className="tooltip-chart-legend"
                          placement="bottom"
                          isOpen={showTooltipChannelTrafficAgent}
                          target="tooltip-channel-traffic-agent"
                          toggle={toggleTooltipChannelTrafficAgent}
                          opacity={1.0}
                        >
                          Number of cases<br/>assigned to Agent
                        </Tooltip>
                      </div>
                    </div>
                  }
                  <div className="overview-box-wrapper overview-topic-report-wrapper">
                    <div id="channel-traffic-chart-outer-wrapper" className="overview-topic-report-inner-wrapper">
                      {
                        !printedOverviewData ?
                        ""
                        :
                        <>
                          {
                            isDataTotalCasePerHourEmpty(printedOverviewData.casesPerChannel) ?
                            <OverviewTopicReportNoData noTitle _className="overview-topic-report-wrapper-no-margin" />
                            :
                            <div
                              id="channel-traffic-chart-wrapper"
                              style={{
                                overflowX: 
                                  isChannelTrafficChartScrollable()
                                  ?
                                  "scroll"
                                  :
                                  "hidden",
                                overflowY: "hidden",
                                // transform: "translateY(-24px)",
                              }}
                              // onScroll={handleMessagesUsersScroll}
                              // ref={messagesUsersChartRef}
                            >
                              <div
                                style={{
                                  width: 
                                    isChannelTrafficChartScrollable()
                                    ?
                                    `${printedOverviewData.casesPerChannel.length * 120 + 60}px`
                                    :
                                    `100%`
                                }}
                              >
                                <div id="channel-traffic-chart" />
                              </div>
                            </div>
                          }
                        </>
                      }
                    </div>
                  </div>
                </div>
                      </div>
                    }

                    <div className="section-separator" />

                    <div className="overview-daily-box-wrapper">
                      <p className="overview-daily-box-title">
                        <b>Daily Report</b>
                      </p>

                      {
                        (printedOverviewData.caseStatus.showReport === true && printedOverviewData.caseStatus.isRealTime === false) &&
                        <div className="real-time-status-wrapper real-time-status-wrapper-bottom">
                          <div className={`real-time-status-right-wrapper ${!printedOverviewData.caseStatus.isRealTime ? "no-margin real-time-status-wrapper-60" : ""}`}>
                            <p className="real-time-status-title">
                              <img src={IconChatList} alt="" className="value-title-icon" />
                              <b>Case Status</b>
                            </p>

                            <div className="status-value-wrapper">
                              <p>
                                <img src={IconQuestion} alt="" className="value-icon" />
                                Unassigned

                                <span>{printedOverviewData.caseStatus.totalUnassigned}</span>
                              </p>

                              <p>
                                <img src={IconUsers} alt="" className="value-icon" />
                                Assigned to agent

                                <span>{printedOverviewData.caseStatus.totalAssignedToAgent}</span>
                              </p>

                              <p>
                                <img src={IconBot} alt="" className="value-icon" />
                                Assigned to chatbot

                                <span>{printedOverviewData.caseStatus.totalAssignedToChatbot}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      }

                      {/* daily report */}
                      <div>
                        <div className="case-report-box">
                          <img src={CaseReport1} alt="" />
                          <p className="case-report-value"><b>{printedOverviewData.caseSummary.totalCasesCreated}</b></p>
                          <p className="case-report-title">
                            <b>
                              Cases Created
                              <FiHelpCircle id="TooltipCasesCreated" />

                              <Tooltip
                                isOpen={showTooltipCasesCreated}
                                target="TooltipCasesCreated"
                                toggle={toggleTooltipCasesCreated}
                                className="tooltip-daily-report"
                                placement="bottom"
                              >
                                Total of new cases
                              </Tooltip>
                            </b>
                          </p>
                        </div>

                        <div className="case-report-box">
                          <img src={CaseReport2} alt="" />
                          <p className="case-report-value"><b>{printedOverviewData.caseSummary.totalCasesClosed}</b></p>
                          <p className="case-report-title"><b>Cases Being Resolved</b></p>
                        </div>

                        <div className="case-report-box">
                          <img src={CaseReport8} alt="" />
                          <p className="case-report-value">
                            <b>{
                              printedOverviewData.caseSummary.totalCasesClosed +
                              printedOverviewData.caseStatus.totalAssignedToAgent +
                              printedOverviewData.caseStatus.totalAssignedToChatbot +
                              printedOverviewData.caseStatus.totalUnassigned
                            }</b>
                          </p>
                          <p className="case-report-title">
                            <b>
                              Total Cases
                              <FiHelpCircle id="TooltipTotalCase" />

                              <Tooltip
                                isOpen={showTooltipTotalCase}
                                target="TooltipTotalCase"
                                toggle={toggleTooltipTotalCase}
                                className="tooltip-daily-report"
                                placement="bottom"
                              >
                                Total of unassigned, assigned, and resolved cases on selected date
                              </Tooltip>
                            </b>
                          </p>
                        </div>

                        <div className="case-report-box">
                          <img src={CaseReport6} alt="" />
                          <p className="case-report-value"><b>{printTime(printedOverviewData.caseSummary.averageFirstResponseWaitDuration)}</b></p>
                          <p className="case-report-title"><b>Average First Response Wait Duration</b></p>
                        </div>

                        <div className="case-report-box">
                          <img src={CaseReport7} alt="" />
                          <p className="case-report-value"><b>{printTime(printedOverviewData.caseSummary.averageCaseDuration)}</b></p>
                          <p className="case-report-title"><b>Average Case Duration</b></p>
                        </div>
                      </div>
                      {/* daily report */}
                    </div>

                    {/* <div>
                      <p className="overview-daily-box-title">
                        <b>Total Cases per Hour</b>
                      </p>

                      <canvas className={isDataTotalCasePerHourEmpty() ? "force-hide" : ""} id="myChartTotalCasePerHour" width="100" height="35"></canvas>
                    </div> */}
                  </>
                }

                <div className='overview-daily-box-wrapper total-cases-per-hour'>
                  <p className="overview-daily-box-title">
                  <b>Total Cases per Hour</b>
                  </p>

                  {
                    (printedOverviewData && !isDataTotalCasePerHourEmpty(printedOverviewData.casesPerHour)) &&
                    <div className="chart-legend-wrapper">
                      <div className="chart-legend-list">
                        <div className="chart-legend created"/>
                        <p>Created</p>
                        <FiHelpCircle id="tooltip-total-cases-created" className="chart-legend-tooltip-icon" alt="" />
                        <Tooltip 
                          className="tooltip-chart-legend"
                          placement="bottom"
                          isOpen={showTooltipTotalCasesCreated}
                          target="tooltip-total-cases-created"
                          toggle={toggleTooltipTotalCasesCreated}
                          opacity={1.0}
                        >
                          Number of <br/>new cases
                        </Tooltip>
                      </div>
                      <div className="chart-legend-list">
                        <div className="chart-legend unassigned"/>
                        <p>Unassigned</p>
                        <FiHelpCircle id="tooltip-total-cases-unassigned" className="chart-legend-tooltip-icon" alt="" />
                        <Tooltip 
                          className="tooltip-chart-legend"
                          placement="bottom"
                          isOpen={showTooltipTotalCasesUnassigned}
                          target="tooltip-total-cases-unassigned"
                          toggle={toggleTooltipTotalCasesUnassigned}
                          opacity={1.0}
                        >
                          Number of<br/>unassigned cases
                        </Tooltip>
                      </div>
                      <div className="chart-legend-list">
                        <div className="chart-legend resolved"/>
                        <p>Resolved</p>
                        <FiHelpCircle id="tooltip-total-cases-resolved" className="chart-legend-tooltip-icon" alt="" />
                        <Tooltip 
                          className="tooltip-chart-legend"
                          placement="bottom"
                          isOpen={showTooltipTotalCasesResolved}
                          target="tooltip-total-cases-resolved"
                          toggle={toggleTooltipTotalCasesResolved}
                          opacity={1.0}
                        >
                          Number of<br/>cases resolved
                        </Tooltip>
                      </div>
                    </div>
                  }

                  <div className="overview-box-wrapper overview-topic-report-wrapper">
                    <div className='overview-topic-report-inner-wrapper'>
                      {
                        !printedOverviewData ?
                        ""
                        :
                        <>
                          {
                            isDataTotalCasePerHourEmpty(printedOverviewData.casesPerHour) ?
                            <OverviewTopicReportNoData noTitle _className="overview-topic-report-wrapper-no-margin" />
                            :
                            <>
                              <canvas id="myChartTotalCasePerHour" width="100" height="35"></canvas>
                              <div className="chart-y-label">Number of Cases</div>
                              <div className="chart-x-label">Hour (24-Hour Time)</div>
                            </>
                          }
                        </>
                      }
                    </div>
                  </div>
                </div>

                {
                  (
                   props.match.params.orgID &&
                   props.match.params.orgID.split("-").length > 0 &&
                   props.match.params.orgID.split("-")[0] === "5419" // FIXME: HARD-CODED CHART
                  ) &&
                  <div className='overview-daily-box-wrapper total-cases-per-hour'>
                    <p className="overview-daily-box-title">
                    <b>Created Cases per Hour</b>
                    </p>

                    <div className="overview-box-wrapper overview-topic-report-wrapper">
                      <div className='overview-topic-report-inner-wrapper'>
                        {
                          !printedOverviewData ?
                          ""
                          :
                          <>
                            {
                              isDataTotalCasePerHourEmpty(printedOverviewData.casesPerHour) ?
                              <OverviewTopicReportNoData noTitle _className="overview-topic-report-wrapper-no-margin" />
                              :
                              <>
                                <canvas id="chartCreatedCasePerHour" width="100" height="35"></canvas>
                                <div className="chart-y-label">Number of Cases</div>
                                <div className="chart-x-label">Hour (24-Hour Time)</div>
                              </>
                            }
                          </>
                        }
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        </React.Fragment>
      </div>
    </SectionWrap>
  );
};

export default DailyOverviewReport;
