import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './SharedMediaTab1.scss';
import { connect } from 'react-redux';
import { FiCamera, FiVideo, FiX } from 'react-icons/fi';
import TripleDotIcon from '../../../../../../assets/img/message-action/triple-dot.svg';
import ViewInChat from '../../../../../../assets/img/view-in-chat.svg';
import NoSharedMedia from '../../../../../../assets/img/no-shared-media-data.svg';
import { CHAT_TYPE } from '../../../../../../helper/HelperConst';
import Helper from '../../../../../../helper/HelperChat';
import HelperDate from '../../../../../../helper/HelperDate';
import { setGoToChat } from '../../../../../../redux/actions/reduxActionGoToChat';
import { setActiveSharedMedia } from "../../../../../../redux/actions/reduxActionActiveSharedMedia";

var SharedMediaTab1 = (props) => {
  let [showModalImage, setShowModalImage] = useState(false);
  let [imageSrc, setImageSrc] = useState("");
  let [showModalVideo, setShowModalVideo] = useState(false);
  let [videoSrc, setVideoSrc] = useState("");
  let [showDropdownShowInChat, setShowDropdownShowInChat] = useState({});
  let [dataToPrint, setDataToPrint] = useState({});

  useEffect(() => {
    generateData();
  }, [JSON.stringify(props.sharedMediaData)]);

  let toggleDropdownShowInChat = (parent, idx) => {
    let _showDropdownShowInChat = {...showDropdownShowInChat};

    _showDropdownShowInChat[parent][idx] = !_showDropdownShowInChat[parent][idx];

    setShowDropdownShowInChat(_showDropdownShowInChat);
  }

  let toggleModalImage = () => {
    setShowModalImage(!showModalImage);
  }

  let toggleModalVideo = () => {
    setShowModalVideo(!showModalVideo);
  }

  let generateModalImage = () => {
    let zoomImage = (e) => {
      var zoomer = e.currentTarget;
      zoomer.style.backgroundSize = '200%';
      var offsetX = e.nativeEvent.offsetX;
      var offsetY = e.nativeEvent.offsetY;

      var x = offsetX / zoomer.offsetWidth * 100
      var y = offsetY / zoomer.offsetHeight * 100
      zoomer.style.backgroundPosition = x + '% ' + y + '%';
    }

    let zoomImageOut = (e) => {
      var zoomer = e.currentTarget;
      zoomer.style.backgroundSize = '0%';
    }

    return (
      <div>
        <Modal isOpen={showModalImage} className={'modal-image'} toggle={toggleModalImage}>
          <ModalBody onClick={() => toggleModalImage()}>
            <FiX className="close-modal-image" onClick={() => toggleModalImage()} />

            <div className="image-wrapper">
              <figure
                className="zoom"
                style={{ "backgroundImage": `url(${imageSrc})` }}
                onMouseMove={(e) => zoomImage(e, imageSrc)}
                onMouseLeave={(e) => zoomImageOut(e)}
              >
                <img
                  src={imageSrc}
                  alt=""
                  className="image-preview-val"
                />
              </figure>
            </div>

          </ModalBody>
        </Modal>
      </div>
    );
  }

  let generateModalVideo = () => {
    return (
      <div>
        <Modal isOpen={showModalVideo} className={'modal-video'} toggle={toggleModalVideo}>
          <ModalBody onClick={() => toggleModalVideo()}>
            <FiX className="close-modal-video" onClick={() => toggleModalVideo()} />
            <div className="video-wrapper">
              <video controls>
                <source src={videoSrc} type="video/mp4" />
                <source src={videoSrc} type="video/ogg" />
              </video>
            </div>

          </ModalBody>
        </Modal>
      </div>
    );
  }

  let generateData = () => {
    let toggleViewChat = {};

    let _data = {};

    if (props.sharedMediaData.length > 0) {
      props.sharedMediaData.map((v) => {
        let dateString = HelperDate.formatToString(new Date(v.created), "MMMM yyyy");

        if (v.messageType === CHAT_TYPE.TAPChatMessageTypeImage || v.messageType === CHAT_TYPE.TAPChatMessageTypeVideo) {
          if (!_data[dateString]) {
            _data[dateString] = [];
          }

          if (!toggleViewChat[dateString]) {
            toggleViewChat[dateString] = [];
          }

          _data[dateString].push(v);
          toggleViewChat[dateString].push(false);
        }

        return null;
      })
      
      setShowDropdownShowInChat(toggleViewChat);
    }

    setDataToPrint(_data);
  }

  return (
    <>
      {generateModalImage()}
      {generateModalVideo()}

      <div className={`shared-media-main-tab-wrapper-1`}>
        {JSON.stringify(dataToPrint) === "{}" ?
          <div className='no-shared-media'>
            <img src={NoSharedMedia} alt="" />

            <p>
              <b>No Shared Media</b>
            </p>

            <p>
              You can see all your shared media from this chat here once you start sharing them.
            </p>
          </div>
          :
          <>
            {Object.keys(dataToPrint).map((_v, _i) => {
              return (
                <div className='share-media-list-wrapper' key={`date-sep-${_i}`}>
                  <div className='share-media-date'>
                    {_v}
                  </div>

                  <div className='share-media-list-inner'>
                    {dataToPrint[_v].map((v, i) => {
                      return (
                        <div
                          className='share-media-card'
                          key={`shard-media-${i}`}
                        >
                          {JSON.stringify(showDropdownShowInChat) !== "{}" &&
                            <Dropdown isOpen={showDropdownShowInChat[_v][i]} toggle={() => toggleDropdownShowInChat(_v, i)}>
                              <DropdownToggle className="dropdown-view-in-chat">
                                <div className="triple-dot-wrap">
                                  <img src={TripleDotIcon} alt="" />
                                </div>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    props.setGoToChat({
                                      lastUpdate: new Date().valueOf(),
                                      chat: dataToPrint[_v][i]
                                    });

                                    props.setActiveSharedMedia(false);
                                  }}
                                >
                                  <img src={ViewInChat} alt="" />
                                  View in chat
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          }

                          {v.messageType === CHAT_TYPE.TAPChatMessageTypeVideo &&
                            <>
                              <video
                                className='share-media-vid'
                                src={v.data.url}
                                onClick={() => {
                                  setVideoSrc(v.data.url);
                                  toggleModalVideo();
                                }}
                              />

                              <FiVideo className='media-camera-image' />
                            </>
                          }

                          {v.messageType === CHAT_TYPE.TAPChatMessageTypeImage &&
                            <>
                              <img
                                alt=""
                                className='share-media-img'
                                src={v.data.url}
                                onClick={() => {
                                  setImageSrc(v.data.url);
                                  toggleModalImage();
                                }}
                              />

                              <FiCamera className='media-camera-image' />
                            </>
                          }

                          {v.data.size !== 0 &&
                            <span className='media-size-text'>
                              {Helper.byteToSize(v.size)}
                            </span>
                          }
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </>
        }
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  goToChat: state.goToChat
});

const mapDispatchToProps = {
  setGoToChat,
  setActiveSharedMedia
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedMediaTab1);
