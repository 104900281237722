import React from "react";
import "./FilterStatusDropdown.scss";
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { FiChevronDown, FiCheck } from "react-icons/fi";
import iconFilterBlack from "../../../assets/img/icon-filter-inbox-black.svg";
import iconFilterGrey from "../../../assets/img/icon-filter-inbox-grey.svg";

let FilterStatusDropdown = (props) => {
    let {
        isOpen,
        toggle,
        isLoading,
        filterList,
        changeFilter,
        value,
        _className = "",
        allText = ""
    } = props;

    return (
        <Dropdown isOpen={isOpen} toggle={toggle} className={`dropdown-filter-status ${isLoading ? "dropdown-isdisabled" : ""} ${_className}`} isDisabled={true}>
            <DropdownToggle className="">
                <img src={isLoading ? iconFilterGrey : iconFilterBlack} alt="" className="filter-black" />
                
                {value.label}

                <FiChevronDown className={"chevron-up"} />
            </DropdownToggle>
            <DropdownMenu className="">
                <div className={isLoading ? "disabled-clock-filter dropdown-all-status" : "dropdown-all-status"} onClick={() => changeFilter({value: "all", label: "All Status" })}>
                    {value.value === "all" ?
                        <FiCheck />
                        :
                        ""
                    }

                    <label>
                        {allText === "" ?
                            "All Status"
                            :
                            `All ${allText}`
                        }
                    </label>
                </div>
                {filterList.map((v, i) => {
                    return (
                        <div key={`filter-clock-${i}`} className={isLoading ? "disabled-clock-filter" : ""} onClick={() => changeFilter(v)}>
                            {value.value === v.value ?
                                <FiCheck />
                                :
                                ""
                            }
                            <label>
                                {v.label}
                            </label>
                        </div>
                    )
                })}
            </DropdownMenu>
        </Dropdown>
    )
}

export default FilterStatusDropdown;