import React, { useState, useEffect } from 'react';
import { FiInfo } from "react-icons/fi";
import './IntegrationGoogleProfileEditDetail.scss';
import IntegrationGoogleBusinessProfile from '../../../../services/integration/integrationGoogleBusinessProfile';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import CustomReactSelect from "../../../reuseableComponent/CustomReactSelect/CustomReactSelect";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import topicServices from '../../../../services/newServices/TopicServices';
import { doToast, checkID, scrollToClass } from '../../../../helper/HelperGeneral';
// import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
// import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
// import mixpanel from "mixpanel-browser";

let IntegrationGoogleProfileEditDetail = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [accountDetail, setAccountDetail] = useState(null);
  let [accountDetailOrigin, setAccountDetailOrigin] = useState(null);
  let [isLoadingEditAccount, setIsLoadingEditAccount] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [topicList, setTopicList] = useState([]);
  // let [topicListOrigin, setTopicListOrigin] = useState([]);

  useEffect(() => {
    doMount();
  }, []);

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getTopics(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let getGoogleAccountDetail = (orgID, accountID) => {
    setIsLoadingGetDetailData(true);

    IntegrationGoogleBusinessProfile.getDetail(orgID, { id: parseInt(accountID) }, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        response.dataResult.data.channel.topic = {
          value: response.dataResult.data.channel.topics[0].id,
          label: response.dataResult.data.channel.topics[0].name
        };

        setAccountDetail(response.dataResult.data.channel);
        setAccountDetailOrigin(response.dataResult.data.channel);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);

        // doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let editGoogleAccount = () => {
    let errorCount = 0;
    let scrollToError = "";
    let _formVal = { ...accountDetail };

    if (_formVal.channelName === "") {
      errorCount = errorCount + 1;

      _formVal.channelNameError = "This field is required";

      if (scrollToError === "") { scrollToError = `input-channel-name` }
    }

    if (_formVal.channelName.length > 30) {
      errorCount = errorCount + 1;

      _formVal.channelNameError = "Characters exceed limit";

      if (scrollToError === "") { scrollToError = `input-channel-name` }
    }

    setAccountDetail(_formVal);

    if (scrollToError !== "" || errorCount > 0) {
      scrollToClass(`.${scrollToError}`);
    } else {
      setIsLoadingEditAccount(true);

      let data = {
        "id": accountDetail.id,
        "channelName": accountDetail.channelName,
        "topicID": accountDetail.topic.value
      };

      IntegrationGoogleBusinessProfile.editAccount(orgID, data, (response) => {
        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.success) {
            doToast(response.dataResult.data.message);
            setIsLoadingEditAccount(false);
          } else {
            setAccountDetailOrigin(accountDetail);
            setTimeout(() => {
              history.push(
                `/o/${orgID}/integration/google-business-profile/detail/${match.params.id}`,
              );
            }, 3000)
            doToast(response.dataResult.data.message);
          }
        } else {
          doToast(response.dataResult.error.message, 'fail');
          setIsLoadingEditAccount(false);
        }
      });
    }
  };

  let getTopics = async (orgID) => {
    setIsLoadingGetDetailData(true);

    topicServices.getTopic(orgID, 'list', null, (response) => {
      let data = response.dataResult.data.topics;
      let createList = () => {
        let newData = [];
        data.map((val) => {
          let _data = {};
          _data.value = val.id;
          _data.label = val.name;
          newData.push(_data);
          return null;
        });
        setTopicList(newData);
        // setTopicListOrigin(newData);
      };

      createList();

      getGoogleAccountDetail(orgID, match.params.id);
    });
  };

  // let isFormValid = () => {
  //   let valid = true;

  //   if(JSON.stringify(accountDetail) === JSON.stringify(accountDetailOrigin)) {
  //     valid = false;
  //   }

  //   return valid;
  // };

  let back = (id) =>
    history.push(`/o/${orgID}/integration/google-business-profile/detail/${id}`);

  let onChange = (e) => {
    let _account = { ...accountDetail };
    _account.channelName = e.target.value;
    _account.channelNameError = "";
    setAccountDetail(_account);
  }

  let onSelectTopic = (e) => {
    let _account = { ...accountDetail };
    _account.topic = e;
    setAccountDetail(_account);
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataOrigin={JSON.stringify(accountDetailOrigin)}
        dataAfterChange={JSON.stringify(accountDetail)}
      >
        <div className="sectionWrap integration-main-container integration-fb-edit-name-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
          ) : (
            <>
              <BackButton
                text={`Back to Google Business Profile`}
                onClick={() => back(accountDetail.id)}
              />

              <div className="integration-fb-edit-name-account-box">
                <div className="integration-fb-edit-name-account-box-top">
                  <b>Edit Channel Details </b>

                  {!isLoadingEditAccount ? (
                    <ButtonWithLoadingOrIcon
                      // isDisabled={!isFormValid()}
                      onClickAction={editGoogleAccount}
                      className="orange-button main-button-40"
                      text="Save Changes"
                      position="left"
                    />
                  ) : (
                    <ButtonWithLoadingOrIcon
                      isLoading
                      loadingColor="gray"
                      isDisabled
                      className="orange-button main-button-40"
                      text="Save Changes"
                      position="left"
                    />
                  )}
                </div>
                <div className="integration-fb-edit-name-account-box-form">
                  <div className="form-name">
                    <label>
                      <b>Channel Name</b>
                    </label>

                    <div className="input-wrap">
                      <span className={`font-grey ${accountDetail.channelNameError === "Characters exceed limit" ? "red-text" : ""}`}>
                        {accountDetail.channelName.length}/30 characters
                      </span>
                      <input
                        type="text"
                        placeholder="Input channel name"
                        className={`
                            input-channel-name 
                            ${!accountDetail.channelNameError ?
                            ""
                            :
                            accountDetail.channelNameError === "" ? "" : "border-red"
                          }

                            ${accountDetail.channelNameError === "Characters exceed limit"}
                          `}
                        value={accountDetail.channelName}
                        onChange={(e) => onChange(e)}
                        disabled={isLoadingEditAccount}
                      />
                    </div>

                    {(accountDetail.channelNameError && accountDetail.channelNameError !== "") &&
                      <ErrorOrInfoComp
                        text={accountDetail.channelNameError}
                        _className={"font-red"}
                        icon={<FiInfo />}
                      />
                    }
                  </div>

                  <div className="form-name">
                    <label>
                      <b>Assigned Topic</b>
                    </label>

                    <CustomReactSelect
                      width="350px"
                      options={topicList}
                      placeholder="Select topic"
                      onChange={onSelectTopic}
                      classNamePrefix="custom-select-topic"
                      isDisabled={isLoadingEditAccount}
                      value={
                        topicList.filter(
                          (obj) => obj.value === accountDetail.topic.value
                        )
                      }
                      blurInputOnSelect={true}
                      _className={``}
                    />

                    <p className="integration-info-form margin-top-10">
                      <FiInfo />
                      <b>
                        Incoming messages on this channel will be assigned to this topic.
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationGoogleProfileEditDetail;
