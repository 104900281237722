const INITIAL_STATE = {
  lastUpdate: false,
  chat: null
};

let reduxReducerGoToChat = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_GO_TO_CHAT':
        return action.goToChatData;
      case 'CLEAR_GO_TO_CHAT':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerGoToChat;