import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import "./CustomSelectOption.scss";
import { FiChevronDown } from "react-icons/fi";
import Loading from "../loading/Loading";

const CustomSelectOption = props => {
    let [isDropdownActive, setIsDropdownActive] = useState(false);
    let isSelectingMultiple = false;

    let toggleDropdownOption = () => {
        if (isSelectingMultiple) {
            isSelectingMultiple = false;
            return;
        }
        setIsDropdownActive(!isDropdownActive);
    }

    let onClickOptionList = (value) => {
        if (!props.isMultipleSelect) {
            toggleDropdownOption();
        }
        else {
            isSelectingMultiple = true;
        }
        props.onClickDropDownListOptionProps(value);
    }

    return (
        <div
            className={`
                ${props.specificClassNameProps} 
                main-custom-select-option-wrapper 
                ${isDropdownActive ? "active-dropdown" : ""}
                ${props._className}
            `}
        >
            <Dropdown
                isOpen={isDropdownActive}
                toggle={() => {
                    !props.isLoading && toggleDropdownOption();
                }}
                className={isDropdownActive ? "active-dropdown-toggle" : ""}
            >
                <DropdownToggle
                    className={`${props.dropdownError && props.dropdownError !== "" ? "border-red" : ""} custom-select-option-value-wrapper`} disabled={props.isDisabled || props.isLoading}
                    onClick={() => props.isMultipleSelect && isDropdownActive && setIsDropdownActive(false)}
                >
                    {props.valueDropdownProps.label === "" ?
                        <p className="custom-select-option-placeholder">
                            {props.placeholderBold ?
                                <b>{props.placeholderProps}</b>
                                :
                                props.placeholderProps
                            }
                        </p>
                        :
                        <p className="custom-select-option-value">
                            {props.isBold ?
                                <>
                                    {props.valueDropdownProps.logo ?
                                        <img src={props.valueDropdownProps.logo} className="dropdown-logo-val" alt="" />
                                        :
                                        ""
                                    }

                                    {props.iconLeft ?
                                        props.iconLeft
                                        :
                                        ""
                                    }

                                    <b>{props.valueDropdownProps.label}</b>
                                </>
                                :
                                <>
                                    {props.valueDropdownProps.logo ?
                                        <img src={props.valueDropdownProps.logo} className="dropdown-logo-val" alt="" />
                                        :
                                        ""
                                    }

                                    {props.iconLeft ?
                                        props.iconLeft
                                        :
                                        ""
                                    }

                                    {props.valueDropdownProps.label}
                                </>
                            }
                        </p>
                    }

                    {props.isLoading ?
                        <Loading className="loading-custom-select" />
                        :
                        <FiChevronDown className={`icon-arrow`} />
                    }
                </DropdownToggle>

                <DropdownMenu className="dropdown-option-wrapper">
                    {props.optionListProps && props.optionListProps.length > 0 ?
                        props.optionListProps.map((val, key) => {
                            if (!props.condition || props.condition(val.id)) {
                                return (
                                    (val.channelStatusText ?
                                        <DropdownItem
                                            className={`
                                                ${((props.valueDropdownProps.array && props.valueDropdownProps.array.includes(val.label)) ||
                                                    props.valueDropdownProps.value === val.value) ?
                                                    props.isMultipleSelect ? "active-multiple" : "active-value"
                                                    :
                                                    props.isMultipleSelect ? "inactive-multiple" : ""
                                                }

                                                channel-option-with-badge
                                            `}
                                            onClick={() => {
                                                if (val.channelStatus === "active" || val.channelStatus === "authenticated") {
                                                    onClickOptionList(val)
                                                }
                                            }}
                                            key={`option-list-${props.placeholderProps}-${key}`}
                                            disabled={val.channelStatus === "active" || val.channelStatus === "authenticated" ? false : true}
                                        >
                                            {val.logo ?
                                                <img src={val.logo} className="dropdown-logo-option" alt="" />
                                                :
                                                ""
                                            }

                                            <p className={`${(val.channelStatus === "active" || val.channelStatus === "authenticated") ? "" : "grey-text"}`}>
                                                {val.label}
                                            </p>

                                            <div className={`option-badge option-${val.channelStatus}`}>
                                                {val.channelStatusText}
                                            </div>
                                        </DropdownItem>
                                        :
                                        <DropdownItem
                                            className={`${((props.valueDropdownProps.array && props.valueDropdownProps.array.includes(val.label)) ||
                                                props.valueDropdownProps.value === val.value)
                                                ?
                                                props.isMultipleSelect ? "active-multiple" : "active-value" :
                                                props.isMultipleSelect ? "inactive-multiple" : ""
                                                }`}
                                            onClick={() => onClickOptionList(val)}
                                            key={`option-list-${props.placeholderProps}-${key}`}
                                        >
                                            {val.logo ?
                                                <img src={val.logo} className="dropdown-logo-option" alt="" />
                                                :
                                                ""
                                            }

                                            {val.label}

                                            {
                                                val.description &&
                                                <div className="dropdown-description-label">
                                                    <p>{val.description}</p>
                                                </div>
                                            }

                                        </DropdownItem>
                                    )
                                )
                            }

                            return null;
                        })
                        :
                        <DropdownItem className="no-data-option">
                            No Options
                        </DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default CustomSelectOption;
