import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./IntegrationChatbotDetail.scss";
import iconXRed from "../../../../assets/img/integration/icon-disconnect.svg";
import iconCheckGreen from "../../../../assets/img/integration/status-connected.svg";
import iconProvisioning from "../../../../assets/img/integration/icon-provisioning-blue.svg";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ChatbotService from '../../../../services/newServices/ChatbotService';
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupRemove from "../../../reuseableComponent/popupRemove/PopupRemove";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";
import { doToast, numberWithCommas } from "../../../../helper/HelperGeneral";
import { FiEdit, FiTrash2, FiCopy, FiInfo, FiEye, FiAlertCircle } from "react-icons/fi";
import { CHATBOT } from "../../../../constants/chatbot";
import { NavLink } from "react-router-dom";
import PopupPassword from "../../../reuseableComponent/popupPassword/PopupPassword";
import PopupConfirmation from "../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import PopupTextField from "../../../reuseableComponent/popupTextField/PopupTextField";
import ChatbotServices from "../../../../services/newServices/ChatbotService";


const IntegrationChatbotDetail = (props) => {
  let { match } = props;
  let [orgID, setOrgID] = useState(false);
  let [chatbotDetail, setIntegrationChatbotDetail] = useState(false);
  let [chatbotName, setChatbotName] = useState("");
  let [chatbotNameError, setChatbotNameError] = useState("");
  let [periods, setPeriods] = useState([]);
  let [chatbotUsage, setChatbotUsage] = useState({
    chatSessionUsages: [],
    messageUsages: [],
    totalTokenUsage: -1,
    totalTokenQuota: -1,
    totalChatSession: -1,
    totalMessages: -1,
  });
  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isLoadingGetDetail, setIsLoadingGetDetail] = useState(true);
  let [isLoadingGetUsage, setIsLoadingGetUsage] = useState(true);
  let [showPopupSingleRemove, setShowPopupSingleRemove] = useState(false);
  let [waitForResponseDelete, setWaitForResponseDelete] = useState(false);
  let [isShowEditNameModal, setIsShowEditNameModal] = useState(false);
  let [isLoadingEditInfo, setIsLoadingEditInfo] = useState(false);
  
  useEffect(() => {
    if(orgID) {
      setRetrieveDataError({
        code: false,
        message: false,
      })
      getDetail();
    }
  }, [orgID, match])

  // const goToEditDetail = (id) => {
  //   props.history.push(`/o/${orgID}/chatbots/edit-detail/${id}`);
  // }

  // const goToEditConfig = (id) => {
  //   props.history.push(`/o/${orgID}/chatbots/edit-config/${id}`);
  // }

  const getDetail = () => {
    let _retrieveDataError = { ...retrieveDataError };
    setIsLoadingGetDetail(true);
    
    let data = {
      id: Number(match.params.id),
      chatbotID: Number(match.params.id)
    }

    ChatbotService.detailChatbot(orgID, data, (response) => {
      if (response.dataResult.status === 200) {
        let item = response.dataResult.data.chatbot;
        setIntegrationChatbotDetail(item);
        setChatbotName(item.name);
      } else {
        _retrieveDataError.message = response.dataResult.error.message;
        _retrieveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retrieveDataError);
        doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingGetDetail(false);
    });

    setIsLoadingGetUsage(true);
    ChatbotService.getChatGptPeriods(orgID, data, (response) => {
      let _periods = response.dataResult.data.periods;
      if (response.dataResult.status === 200 && _periods && _periods.length > 0) {
        setPeriods(_periods);
        let currentTime = Date.now();
        let hasPeriod = false;
        _periods.map((period) => {
          if (period.startTime &&
              period.startTime < currentTime &&
              period.endTime &&
              period.endTime > currentTime
          ) {
            hasPeriod = true;
            data.startTime = period.startTime;
            data.endTime = period.endTime;
            ChatbotService.getChatGptUsage(orgID, data, (response) => {
              if (response.dataResult.status === 200 && response.dataResult.data) {
                setChatbotUsage(response.dataResult.data);
              }
            });
          }
          if (!hasPeriod) {
            setIsLoadingGetUsage(false);
          }
          return null;
        });
      }
      else {
        setIsLoadingGetUsage(false);
      }
    });
  };

  const checkOrgID = (match) => {
    const { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  const back = () => {
    props.history.push(`/o/${orgID}/chatbots`);
  };

  const doMount = async () => {
    const paramID = checkOrgID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  const copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    setIsLoadingGetUsage(false);
  }, [chatbotUsage]);

  let toggleModalRemoveChatbot = () => {
    setShowPopupSingleRemove(!showPopupSingleRemove);
  }

  let toggleEditNameModal = () => {
    if (isLoadingEditInfo) {
      return;
    }
    if (isShowEditNameModal) {
      setTimeout(() => {
        setChatbotName(chatbotDetail.name);
        setChatbotNameError("");
      }, 500);
    }
    setIsShowEditNameModal(!isShowEditNameModal);
  }

  let deleteChatbot = () => {
    setWaitForResponseDelete(true);
    let data = {
      id: chatbotDetail.id,
      createdTime: chatbotDetail.createdTime
    };

    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    ChatbotService.deleteChatbot(orgID, data, (response) => {
      let dataResult = response.dataResult;

      if(dataResult.error.message === "") {
        if(!response.dataResult.data.success) {
          doToast(response.dataResult.data.message, "fail");
          toggleModalRemoveChatbot();
          setWaitForResponseDelete(false);
        }else {
          if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
              "[Action] Delete Chatbot", 
              {
                  userFullName : myAgentData.account.fullName,
                  userID : myAgentData.account.id,
                  organizationID : orgID.split("-")[0]
              }
            );
          }

          doToast(response.dataResult.data.message);

          setTimeout(() => {
            back();
          }, 3000)
        }
      }else {
        doToast(response.dataResult.message, 'fail');
        toggleModalRemoveChatbot();
        setWaitForResponseDelete(false);
      }

    })
  }

  const validateChangeName = () => {
    if (!chatbotName) {
      setChatbotNameError("This field is required");
    }
    else if (chatbotName.length > 30) {
      setChatbotNameError("Characters exceed limit");
    }
    else if (chatbotName === chatbotDetail.name) {
      doToast("No changes found", "fail");
    }
    else {
      editChatbotInfo();
    }
  }

  const editChatbotInfo = () => {
    setIsLoadingEditInfo(true);

    let data = {
      id: Number(match.params.id),
      name: chatbotName
    }

    ChatbotServices.editChatbot(orgID, data, (response) => {
      let dataResult = response.dataResult;
      if (dataResult.data.success) { 
        getDetail();
        doToast(dataResult.data.message);
        setIsShowEditNameModal(false);
      }
      else if (dataResult.error.message) {
        doToast(dataResult.error.message, "fail");
      }
      else if (dataResult.data.message) {
        doToast(dataResult.data.message, "fail");
      }
      setIsLoadingEditInfo(false);
    })
  }

  return (
    <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
      <BackButton text="Back to Chatbot" onClick={back} />

      <div className="view-chatbot-detail sectionWrap">
        <div className="view-chatbot-detail-top">
          <b>Chatbot Details</b>
          
          {!isLoadingGetDetail &&
            <>
              {
                (chatbotDetail.type !== "chatgpt" || chatbotDetail.status === "not_paid") &&
                <ButtonWithLoadingOrIcon 
                  text="Delete"
                  position="left"
                  icon={{
                    type: "svg",
                    src: FiTrash2
                  }}
                  onClickAction={toggleModalRemoveChatbot}
                  className="main-button-40 red-button"
                />
              }
              {
                (chatbotDetail.type === "chatgpt" && 
                 (chatbotDetail.status === "waiting_setup" || chatbotDetail.status === "active")
                ) &&
                // <NavLink to={`/o/${orgID}/chatbots/detail/${chatbotDetail.id}/chatgpt-introduction`}>
                <NavLink 
                  to={{
                    pathname: `/o/${orgID}/chatbots/detail/${chatbotDetail.id}/chatgpt-setup`,
                    state: {
                      lastUpdated: chatbotDetail.updatedTime
                    }
                  }}
                >
                  <ButtonWithLoadingOrIcon 
                    text="Setup"
                    position="left"
                    icon={{
                      type: "svg",
                      src: FiEdit
                    }}
                    className={`main-button-40 edit-top no-fill-button`}
                  />
                </NavLink>
              }
            </>
          }
        </div>
        
        {isLoadingGetDetail || retrieveDataError.code ? (
          <div className="chatbot-detail-loading-wrapper">
            <RetrieveDataLoading
              isLoading={isLoadingGetDetail}
              errorMessage={retrieveDataError.message}
            />
          </div>
        ) : chatbotDetail ? 
          (
            <React.Fragment>
              <div className="view-chatbot-detail-middle view-chatbot-detail-middle-top">
                <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                  <b className="detail-label">Chatbot Name</b>
                  <FiEdit onClick={toggleEditNameModal} />
                  <p className="detail-value">{chatbotDetail.name}</p>
                </div>

                <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                  <b className="detail-label">Chatbot Type</b>
                  <p className="detail-value">{chatbotDetail.typeName}</p>
                </div>

                <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                  <b className="detail-label">Status</b>
                  {
                    (chatbotDetail.status === "not_paid" || chatbotDetail.status === "suspended") ?
                    <b className={`detail-value detail-status status-not-paid`}>
                      <img src={iconXRed} alt="" />
                      {chatbotDetail.statusText}
                    </b>
                    :
                    chatbotDetail.status === "waiting_setup" ?
                    <b className={`detail-value detail-status status-waiting-setup`}>
                      <FiAlertCircle />
                      {chatbotDetail.statusText}
                    </b>
                    :
                    chatbotDetail.status === "provisioning" ?
                    <p className={`detail-value detail-status status-provisioning`}>
                      <img src={iconProvisioning} alt="" />
                      <div className="provisioning-wrapper">
                        <b>{chatbotDetail.statusText}</b>
                        <p>We are preparing instance for your chatbot.</p>
                      </div>
                    </p>
                    :
                    chatbotDetail.status === "active" ?
                    <b className={`detail-value detail-status status-active`}>
                      <img src={iconCheckGreen} alt="" />
                      {chatbotDetail.statusText}
                    </b>
                    :
                    <b className={`detail-value detail-status status-others`}>
                      <FiInfo />
                      {chatbotDetail.statusText}
                    </b>
                  }
                </div>

                {
                  (chatbotDetail.chatgpt && chatbotDetail.chatgpt.tierID && chatbotDetail.status !== "not_paid") ?
                  <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                    <b className="detail-label">Tier</b>
                    <p className="detail-value">{chatbotDetail.chatgpt.tierName}<span className="grey-font-6">{` (${numberWithCommas(chatbotDetail.chatgpt.tokenQuota)} tokens)`}</span></p>
                    {/* <ErrorOrInfoComp 
                      icon={<FiInfo />}
                      text="Approximately 6000 conversation/month"
                    /> */}
                  </div>
                  :
                  <></>
                }

                {chatbotDetail.type === CHATBOT["taptalk/scf"].value &&
                  <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                    <b className="detail-label">QnA Path</b>
                    <p className="detail-value">{chatbotDetail.scf.scfPathTitle}</p>
                  </div>
                }
              </div>
            </React.Fragment>
          ) 
          : 
          (
            ""
          )
        }

        <PopupRemove removeTitle={`Delete Chatbot?`}
          removeDescription={`This chatbot will be removed from the organization.`}
          onClickCancel={() => toggleModalRemoveChatbot()}
          onClickRemove={deleteChatbot}
          waitForResponse={waitForResponseDelete}
          showModalProps={showPopupSingleRemove}
          submitText="Delete Chatbot"
        />

        <PopupTextField
          isOpen={isShowEditNameModal}
          toggle={toggleEditNameModal}
          title="Chatbot Name"
          placeholder="Your chatbot name"
          text={chatbotName}
          setText={setChatbotName}
          textError={chatbotNameError}
          setTextError={setChatbotNameError}
          buttonAction={validateChangeName}
          isLoading={isLoadingEditInfo}
          characterLimit={30}
          leftButtonText="Cancel"
          rightButtonText="Save Changes"
        />
      </div>

      {
        chatbotDetail.type === "chatgpt" &&
        <div className="view-chatbot-detail sectionWrap">
          <div className="view-chatbot-detail-top">
            <b>Chatbot Usage</b>
            
            {
              (!isLoadingGetDetail && periods && periods.length > 0) &&
              <>
                <NavLink to={`/o/${orgID}/chatbots/detail/${chatbotDetail.id}/view-chatbot-usage`}>
                  <ButtonWithLoadingOrIcon 
                    text="View Details"
                    position="left"
                    icon={{
                      type: "svg",
                      src: FiEye
                    }}
                    className="main-button-40 view-detail-top no-fill-button"
                  />
                </NavLink>
              </>
            }
          </div>
          
          {isLoadingGetUsage || retrieveDataError.code ? (
            <div className="chatbot-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetUsage}
                errorMessage={retrieveDataError.message}
              />
            </div>
          ) : chatbotDetail ? 
            (
              <React.Fragment>
                <div className="view-chatbot-detail-middle view-chatbot-detail-middle-top">
                  <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                    <b className="detail-label">Token Usage</b>
                    {
                      (chatbotUsage.totalTokenUsage >= 0)
                      ?
                      <p className="detail-value detail-value-token">{numberWithCommas(chatbotUsage.totalTokenUsage)}</p>
                      :
                      <p className="detail-value detail-value-no-data">No Data</p>
                    }
                  </div>

                  <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                    <b className="detail-label">Remaining Token</b>
                    {
                      (chatbotUsage.totalTokenUsage >= 0 && chatbotUsage.totalTokenQuota >= 0)
                      ?
                      <p className="detail-value detail-value-token">{numberWithCommas(Math.max(0, chatbotUsage.totalTokenQuota - chatbotUsage.totalTokenUsage))}</p>
                      :
                      <p className="detail-value detail-value-no-data">No Data</p>
                    }
                  </div>
                </div>
              </React.Fragment>
            ) 
            : 
            (
              ""
            )
          }
        </div>
      }

      {chatbotDetail.type === "custom" &&
        <div className="view-chatbot-detail sectionWrap">
          <div className="view-chatbot-detail-top">
            <b>Chatbot Configs</b>
            <p className="subtitle-chatbot text-left">
              For more details, <a href="https://docs.taptalk.io/onetalk-omnichannel-documentation/chatbot-integration" target="_blank" rel="noopener noreferrer">please read the documentation.</a>
            </p>

            <NavLink to={`/o/${orgID}/chatbots/edit-config/${chatbotDetail.id}`}> 
              <ButtonWithLoadingOrIcon 
                text="Edit"
                position="left"
                icon={{
                  type: "svg",
                  src: FiEdit
                }}
                className="main-button-40 no-fill-button edit-button"
              />
            </NavLink>
          </div>

          <React.Fragment>
            <div className="view-chatbot-detail-middle view-chatbot-detail-middle-top">
              <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                <b className="detail-label">Chatbot URL</b>
                <div className="detail-value-wrapper">
                  <input 
                    type="text"
                    value={chatbotDetail.custom.chatbotURL}
                    id="chatboturl"
                    readOnly
                  />
                  <FiCopy onClick={() => copyToClip("chatboturl", "Chatbot URL Copied")} />
                </div>
              </div>

              <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                <b className="detail-label">OneTalk URL</b>
                <div className="detail-value-wrapper">
                  <input 
                    type="text"
                    value={chatbotDetail.custom.webhookURL}
                    id="webhookurl"
                    readOnly
                  />
                  <FiCopy onClick={() => copyToClip("webhookurl", "OneTalk URL Copied")} />
                </div>
              </div>

              <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                <b className="detail-label">Secret Key</b>
                <div className="detail-value-wrapper">
                  <input 
                    type="text"
                    value={chatbotDetail.custom.secretKey}
                    id="secretkey"
                    readOnly
                  />
                  <FiCopy onClick={() => copyToClip("secretkey", "Secret Key Copied")} />
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      }
    </SectionWrap>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationChatbotDetail);
