import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./IntegrationChatGPTUsage.scss";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ChatbotService from '../../../../services/newServices/ChatbotService';
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import TokenRed from "../../../../assets/img/token-red.svg";
import ChatOrange from "../../../../assets/img/chat-text-orange.svg";
import MailBlue from "../../../../assets/img/mail-blue.svg";
import NoReportFound from "../../../../assets/img/no-report-found.svg";
import { doToast, numberWithCommas } from "../../../../helper/HelperGeneral";
import { FiInfo, FiCalendar } from "react-icons/fi";
import HelperDate from "../../../../helper/HelperDate";
import ApexCharts from 'apexcharts';

const IntegrationChatGPTUsage = (props) => {
  let { match } = props;
  let [orgID, setOrgID] = useState(false);
  let [chatbotDetail, setIntegrationChatbotDetail] = useState(false);
  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isLoadingGetDetail, setIsLoadingGetDetail] = useState(true);
  let [selectedPeriod, setSelectedPeriod] = useState({
    value: "",
    label: "",
    period: {
      id: 0,
      startTime: 0,
      endTime: 0,
      tokenQuota: 0,
      tierID: 0,
      tierName: ""
    },
  });
  let [mappedPeriods, setMappedPeriods] = useState({
    periods: []
  });
  let [chatbotUsage, setChatbotUsage] = useState({
    chatSessionUsages: [],
    messageUsages: [],
    totalTokenUsage: 0,
    totalTokenQuota: 0,
    totalChatSessions: 0,
    totalMessages: 0,
  });

  let dailyChatSessionsChart = null;
  let dailyMessagesChart = null;

  useEffect(() => {
    if (orgID) {
      setRetrieveDataError({
        code: false,
        message: false,
      })
      getDetail();
      getPeriods();
    }
  }, [orgID, match]);

  useEffect(() => {
    if (selectedPeriod &&
        selectedPeriod.period.startTime > 0 &&
        selectedPeriod.period.endTime > 0
    ) {
      getUsage();
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (chatbotUsage.chatSessionUsages &&
        chatbotUsage.chatSessionUsages.length > 0
    ) {
      const xValues = [];
      const xValuesTrimmed = [];
      const chatSessionValues = [];
      const labelColors = [];
      const borderColors = [];

      chatbotUsage.chatSessionUsages.map((v) => {
        let label = HelperDate.formatToString(new Date(Date.parse(v.date, "yyyy-MM-dd")), "d MMM");
        xValues.push(label);
        xValuesTrimmed.push(label.split(" "));
        chatSessionValues.push(v.totalSessions);
        labelColors.push("#19191966");
        borderColors.push("#FF7E00");
        return null;
      })

      const dailyChatSessionSeries = [
        {
          name: "Chat Sessions",
          data: chatSessionValues,
        },
      ];

      setTimeout(() => {
        let dailyChatSessionsChartOptions = getChartOptions(dailyChatSessionSeries, xValuesTrimmed, labelColors, borderColors);
        let elChart = document.querySelector("#daily-chat-sessions-chart");
        if (elChart) {
          dailyChatSessionsChart = new ApexCharts(elChart, dailyChatSessionsChartOptions);
          dailyChatSessionsChart.render();
        }
        setIsLoadingGetDetail(false);
      }, 1000);
    }
    
    if (chatbotUsage.messageUsages &&
        chatbotUsage.messageUsages.length > 0
    ) {
      const xValues = [];
      const xValuesTrimmed = [];
      const dailyMessagesValues = [];
      const labelColors = [];
      const borderColors = [];

      chatbotUsage.messageUsages.map((v) => {
        let label = HelperDate.formatToString(new Date(Date.parse(v.date, "yyyy-MM-dd")), "d MMM");
        xValues.push(label);
        xValuesTrimmed.push(label.split(" "));
        dailyMessagesValues.push(v.totalMessages);
        labelColors.push("#19191966");
        borderColors.push("#FF7E00");
        return null;
      })

      const dailyMessageSeries = [
        {
          name: "Chat Messages",
          data: dailyMessagesValues,
        },
      ];

      setTimeout(() => {
        let dailyMessagesChartOptions = getChartOptions(dailyMessageSeries, xValuesTrimmed, labelColors, borderColors);
        let elChart = document.querySelector("#daily-messages-chart");
        if (elChart) {
          dailyMessagesChart = new ApexCharts(elChart, dailyMessagesChartOptions);
          dailyMessagesChart.render();
        }
        setIsLoadingGetDetail(false);
      }, 1000);
    }
  }, [chatbotUsage]);

  const getChartOptions = (series, xValuesTrimmed, labelColors, borderColors) => {
    return {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#FFCB99"],
      fill: {
        opacity: 1,
      },
      series: series,
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        curve: "smooth",
        colors: borderColors,
        width: 1,
        dashArray: 2, 
      },
      xaxis: {
        categories: xValuesTrimmed,
        labels: {
          style: {
            fontFamily: "PTRootBold",
            fontSize: "12px",
            colors: labelColors,
          },
          rotate: 0,
        },
        // axisBorder: {
        //   color: "#19191966",
        //   offsetY: 4,
        // },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        seriesName: "Number of Cases",
        tickAmount: 10,
        labels: {
          style: {
            fontFamily: "PTRootUI",
            fontSize: "12px",
            colors: labelColors,
          }
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      states: {
        active: {
          filter: {
            type: "none"
          }
        },
        hover: {
          filter: {
            type: "none"
          }
        },
      },
      grid: {
        borderColor: "#19191966",
        strokeDashArray: 4, 
      },
      tooltip: {
        theme: "dark",
        style: {
          fontFamily: "PTRootUI",
          fontSize: "12px",
        },
      }
    }
  }
  
  const getDetail = () => {
    setIsLoadingGetDetail(true);
    
    let data = {
      id: Number(match.params.id),
    }
    ChatbotService.detailChatbot(orgID, data, (response) => {
      if (response.dataResult.status === 200 && response.dataResult.data.chatbot) {
        setIntegrationChatbotDetail(response.dataResult.data.chatbot);
      }
      else {
        if (response.dataResult.error && response.dataResult.error.message) {
          let _retrieveDataError = { ...retrieveDataError };
          _retrieveDataError.message = response.dataResult.error.message;
          _retrieveDataError.code = response.dataResult.error.code;
          setRetrieveDataError(_retrieveDataError);
          doToast(response.dataResult.error.message, 'fail');
        }
      }
    });
  }

  const getPeriods = () => {
    let _retrieveDataError = { ...retrieveDataError };
    setIsLoadingGetDetail(true);

    let data = {
      id: Number(match.params.id),
      chatbotID: Number(match.params.id)
    }
    ChatbotService.getChatGptPeriods(orgID, data, (response) => {
      if (response.dataResult.status === 200 && response.dataResult.data.periods) {
        let _mappedPeriods = {
          periods: []
        };

        response.dataResult.data.periods.map((period) => {
          let label = HelperDate.formatToString(new Date(period.startTime), "d MMM yyyy") + " - " + HelperDate.formatToString(new Date(period.endTime), "d MMM yyyy")
          _mappedPeriods.periods.push({
            value: period.id,
            label: label,
            period: period,
          });
          return null;
        });
        if (_mappedPeriods.periods.length === 0) {
          setIsLoadingGetDetail(false);
        }
        else {
          setMappedPeriods(_mappedPeriods);
          setSelectedPeriod(_mappedPeriods.periods[0]);
        }
      }
      else {
        if (response.dataResult.error && response.dataResult.error.message) {
          _retrieveDataError.message = response.dataResult.error.message;
          _retrieveDataError.code = response.dataResult.error.code;
          setRetrieveDataError(_retrieveDataError);
          doToast(response.dataResult.error.message, 'fail');
        }
        setIsLoadingGetDetail(false);
      }
    });
  };
  
  const getUsage = () => {
    let _retrieveDataError = { ...retrieveDataError };
    setIsLoadingGetDetail(true);

    let data = {
      chatbotID: Number(match.params.id),
      startTime: selectedPeriod.period.startTime,
      endTime: selectedPeriod.period.endTime,
    }

    ChatbotService.getChatGptUsage(orgID, data, (response) => {
      if (response.dataResult.status === 200 && response.dataResult.data) {
        setChatbotUsage(response.dataResult.data);
      }
      else {
        if (response.dataResult.error && response.dataResult.error.message) {
          _retrieveDataError.message = response.dataResult.error.message;
          _retrieveDataError.code = response.dataResult.error.code;
          setRetrieveDataError(_retrieveDataError);
          setIsLoadingGetDetail(false);
          doToast(response.dataResult.error.message, 'fail');
        }
      }
      setIsLoadingGetDetail(false);
    });
  };

  const checkOrgID = (match) => {
    const { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  const back = () => {
    props.history.push(`/o/${orgID}/chatbots/detail/${props.match.params.id}`);
  };

  const doMount = async () => {
    const paramID = checkOrgID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  const onSelectPeriod = (e) => {
    if (e) {
      setSelectedPeriod(e);
    }
  }

  return (
    <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
      <BackButton text="Back to Chatbot Details" onClick={back} />

      <div className="view-chatgpt-usage sectionWrap">
        <div className="view-chatgpt-usage-top">
          <b><FiInfo />Chatbot Usage</b>
        </div>

        {
          (isLoadingGetDetail || retrieveDataError.code) ?
          <div className="chatbot-detail-loading-wrapper">
            <RetrieveDataLoading
              isLoading={isLoadingGetDetail}
              errorMessage={retrieveDataError.message}
            />
          </div>
          :
          (chatbotDetail && chatbotUsage && mappedPeriods.periods && mappedPeriods.periods.length > 0) ?
          <React.Fragment>
            <div className="view-chatgpt-usage-bottom">
              <div className="view-chatgpt-usage-filter-wrapper">
                <CustomSelectOption
                  iconLeft={<FiCalendar className="icon-left" />}
                  isDisabled={false}
                  optionListProps={mappedPeriods.periods}
                  valueDropdownProps={selectedPeriod}
                  placeholderProps={"Select period"}
                  onClickDropDownListOptionProps={onSelectPeriod}
                  specificClassNameProps="custom-select-usage-period"
                  _className={``}
                />
              </div>

              <div className="view-chatgpt-usage-top-usage-wrapper">
                <div className="package-information-left">
                  <p className="package-information-top">
                    <FiInfo />
                    <b>Package Information</b>
                  </p>

                  <p className="package-information-list">
                    <b>Chatbot Name: </b>
                    {chatbotDetail.name}
                  </p>

                  <p className="package-information-list">
                    <b>Tier: </b>
                    {selectedPeriod.period.tierName ? selectedPeriod.period.tierName : "-"}
                  </p>

                  <p className="package-information-list">
                    <b>Token Limit: </b>
                    {chatbotUsage.totalTokenQuota ? numberWithCommas(chatbotUsage.totalTokenQuota) : "0"}
                  </p>
                </div>

                <div className="package-information-right">
                  <div className="package-information-right-list">
                    <img src={TokenRed} alt="" />

                    <p className="package-information-right-text-1">
                      <b>Token Usage</b>
                    </p>

                    <p className="package-information-right-text-2">
                      <b>{chatbotUsage.totalTokenUsage ? numberWithCommas(chatbotUsage.totalTokenUsage) : "0"}</b>
                    </p>

                    <p className="package-information-right-text-3">
                      <b className="grey-text">/ {chatbotUsage.totalTokenQuota ? numberWithCommas(chatbotUsage.totalTokenQuota) : "0"}</b>
                    </p>
                  </div>

                  <div className="package-information-right-list">
                    <img src={ChatOrange} alt="" />

                    <p className="package-information-right-text-1">
                      <b>Total Chat Sessions</b>
                    </p>

                    <p className="package-information-right-text-2">
                      <b>{chatbotUsage.totalChatSessions ? numberWithCommas(chatbotUsage.totalChatSessions) : "0"}</b>
                    </p>
                  </div>

                  <div className="package-information-right-list">
                    <img src={MailBlue} alt="" />

                    <p className="package-information-right-text-1">
                      <b>Total Messages</b>
                    </p>

                    <p className="package-information-right-text-2">
                    <b>{chatbotUsage.totalMessages ? numberWithCommas(chatbotUsage.totalMessages) : "0"}</b>
                    </p>

                    {/* <p className="package-information-right-text-3">
                      <b className="grey-text">/ billing cycle</b>
                    </p> */}
                  </div>
                </div>
              </div>

              <div className="view-chatgpt-usage-bar-chart-wrapper">
                <div className="title-wrapper">
                  <b>Daily Chat Sessions</b>
                  {
                    (chatbotUsage.chatSessionUsages && chatbotUsage.chatSessionUsages.length > 0) &&
                    <div className="bar-chart-legend-wrapper">
                      <div className="bar-chart-legend-box" />
                      # of Chat Sessions
                    </div>
                  }
                </div>

                {
                  (chatbotUsage.chatSessionUsages && chatbotUsage.chatSessionUsages.length > 0)
                  ?
                  <div id="daily-chat-sessions-chart" />
                  :
                  <div className="box-no-data">
                    <img src={NoReportFound} alt="" />
                    <b>There is no data for the selected period.</b>
                  </div>
                }
              </div>

              <div className="view-chatgpt-usage-bar-chart-wrapper">
                <div className="title-wrapper">
                  <b>Daily Messages</b>
                  {
                    (chatbotUsage.messageUsages && chatbotUsage.messageUsages.length > 0) &&
                    <div className="bar-chart-legend-wrapper">
                      <div className="bar-chart-legend-box" />
                      # of Chat Messages
                    </div>
                  }
                </div>
                {
                  (chatbotUsage.messageUsages && chatbotUsage.messageUsages.length > 0)
                  ?
                  <div id="daily-messages-chart" />
                  :
                  <div className="box-no-data">
                    <img src={NoReportFound} alt="" />
                    <b>There is no  data for the selected period.</b>
                  </div>
                }
              </div>
            </div>
          </React.Fragment>
          :
          <div className="box-no-data">
            <img src={NoReportFound} alt="" />
            <b>There is no usage period found for this chatbot.</b>
          </div>
        }
      </div>
    </SectionWrap>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationChatGPTUsage);
