import { taptalk } from "@taptalk.io/web-sdk";
import { validEmail } from "./HelperGeneral";
import MessageSending from "../assets/img/icon-airplane-dark.svg";
import MessageSendingWhite from "../assets/img/icon-airplane-white.svg";
import MessageSent from "../assets/img/icon-checkmark-grey-1.svg";
import MessageSentWhite from "../assets/img/icon-checkmark-grey-1-white-room-list.svg";
import MessageReceive from "../assets/img/icon-checkmark-grey-2.svg";
import MessageReceiveWhite from "../assets/img/icon-checkmark-grey-2-white.svg";
import MessageRead from "../assets/img/icon-checkmark-green-2.svg";
import MessageDelete from "../assets/img/icon-notallowed.svg";
import MessageFail from "../assets/img/icon-alertexclamation.svg";
import MessageFailWhite from "../assets/img/icon-alertexclamation-white.svg";
import { TAPLIVE_MEDIUM, TAPLIVE_MEDIUM_LOGO } from "../constants/taplive";
import { printLastMessageName, generateElipsis } from "./HelperGeneral";
import HelperDate from "./HelperDate";

var Helper = {
    renderUserAvatarWord: (text, isGroup) => {
        let userAvatarWord = "";
        let nameSplit = text.split(" ");
        let secondName = nameSplit.length > 1 ? nameSplit[1] : '';

        if(isGroup) {
            userAvatarWord = nameSplit[0].substr(0, 1);
        }else {
            userAvatarWord = nameSplit[0].substr(0, 1);
            userAvatarWord += secondName.substr(0, 1);
        }

        return userAvatarWord.toUpperCase();
    },

    getHourMinute: (timestamp) => {
        var time = new Date(timestamp);
        // return time.getHours()+":"+(time.getMinutes() < 10 ? ("0" + time.getMinutes()) : time.getMinutes())
        return HelperDate.formatToString(time, "HH:mm");
    },

    getDateMonthYear: (timestamp) => {
        var time = new Date(timestamp);
        let date = time.getDate();
        let month = time.getMonth() + 1;

        return (date < 10 ? "0"+date : date)+"/"+(month < 10 ? "0"+month : month)+"/"+time.getFullYear();
    },

    copyToClipBoard: (str) => {
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
    },

    bytesToSize : (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return (bytes / Math.pow(1024, i)).toFixed(2).replace('.00', '') + ' ' + sizes[i];
    },

    msToTime(duration) {
        // var milliseconds = parseInt((duration % 1000) / 100),
        var seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds;
    },

    lineBreakRoomList(message) {
        let _message = message;

        if(_message !== null) {
            let lineBreakSplit = _message.trim().split("\n");

            if(lineBreakSplit.length > 1) {
                return lineBreakSplit[0] + "...";
            }
        }

        return _message;
    },

    replaceTagHTML(str) {
        var tagsToReplace = {
            '<': '&lt;',
            '>': '&gt;'
        };
        
        let replaceTag = (tag) => {
            return tagsToReplace[tag] || tag;
        }
        
        return str.replace(/[&<>]/g, replaceTag); 
    },

    removeHTMLTag(str) {
        let _str = str;
        
        var div = document.createElement("div");
        div.innerHTML = _str;
        
        return div.innerText;
    },

    convertUrl(inputText) {
        let regQuestingMark = /(\?)+$/;
        // let takeOutQuestionMark = "";
        // takeOutQuestionMark = inputText.match(regQuestingMark) !== null ? inputText.match(regQuestingMark) : "";
        // inputText = inputText.replace(regQuestingMark, "");

        let isHttp = (text) => {
            return text.substring(0, 7) === "http://";
        }

        let isHttps = (text) => {
            return text.substring(0, 8) === "https://";
        }

        let isW3 = (text) => {
            return text.substring(0, 4) === "www.";
        }

        let urlify = (text) => {
            // let urlRegex = /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;
            let urlRegex = /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\"\,\{\}\\|\\\^\[\]`]+)?)/gi;
            let startingWithoutSpecialChar = /^[A-Za-z0-9]/;
            
            return text.replace(urlRegex, function(url) {
                let returnValue = url;
                let _url = url;
                let rep = "";
                let c = (t) => {
                    if(startingWithoutSpecialChar.test(t) && !t.includes("{{contact.")) {
                        runConvert();
                    }
                }

                let runConvert = () => {
                    if(!url.includes("contact.")) {
                        if(!isHttps(url)) {
                            if(!isHttp(url)) {
                                _url = "http://"+url;
                            }
                        }
    
                        let _urlQuestionMark = _url.match(regQuestingMark) !== null ? url.match(regQuestingMark) : "";
    
                        url = url.replace(regQuestingMark, "");
                        _url = _url.replace(regQuestingMark, "");
                        // returnValue = `<a class="tap-hyperlink" target="_blank" href="${_url}">${url}</a>${takeOutQuestionMark !== "" ? takeOutQuestionMark[0] : ""}`;
                        returnValue = `<a class="tap-hyperlink" target="_blank" href="${_url}">${url}</a>${_urlQuestionMark === "" ? "" : _urlQuestionMark[0] }`;
                    }
                }

                if(startingWithoutSpecialChar.test(url) && !validEmail(url)) {
                    if(isW3(url)) {
                        rep = url.replace("www.", "");
                        c(rep);
                    }else if(isHttp(url)) {
                        rep = url.replace("http://", "");
                        c(rep);
                    }else if (isHttps(url)) {
                        rep = url.replace("https://", "");
                        c(rep);
                    }else {
                        runConvert();
                    }
                }

                return returnValue;
            })
        }

        return urlify(inputText)
    },

    lineBreak(message) {
        let _message = this.replaceTagHTML(message);
        return _message !== null ? _message.trim().replace(new RegExp("\n", "g"), "<br />"): _message;
    },

    lineBreakChatRoom(message) {
        let _message = message ? this.replaceTagHTML(message) : "";
        let result = "";

        result = _message;
        
        // result = _message !== null ? this.convertUrl(_message.trim().replace(new RegExp("\n", "g"), "<br />")) : _message;

        // return this.convertFormatTextWhatsapp(result);
        return result === null || result === "" ? "" : this.convertUrl(this.convertFormatTextWhatsapp(result).trim().replace(new RegExp("\n", "g"), "<br />"))
    },

    lineBreakWithoutTrim(message) {
        let _message = this.replaceTagHTML(message);
        return _message !== null ? _message.replace(new RegExp("\n", "g"), "<br />") : _message;
    },

    generateLastMessage(message, caseData, myAgentData) {
        let myAgentAccount = myAgentData.account;

        let _message;

        // let lastChatFrom = myAgentAccount.fullName === message.user.fullname ? 'You: ' : message.user.fullname.split(" ")[0] + ": ";
        
        let lastChatFrom = 
            myAgentAccount.fullName === message.user.fullname ? 
                'You: ' 
                : 
                (printLastMessageName(message, caseData, true).length > 13 ?
                    generateElipsis(printLastMessageName(message, caseData, true), 10) + ": "
                    :
                    printLastMessageName(message, caseData, true) + ": "
                )
            ;

        if(message.isDeleted) {
            if(taptalk.getTaptalkActiveUser().userID === message.user.userID) {
                _message = "You deleted this message.";
            }else {
                _message = "This message was deleted.";
            }
        }else {
            switch(message.type) {
                case 1002: //type image
                    _message = lastChatFrom + Helper.lineBreakRoomList(message.body);
                    break;
                case 1003: //type video
                    _message = lastChatFrom + Helper.lineBreakRoomList(message.body);
                    break;
                case 1004: //type file
                    _message = lastChatFrom + Helper.lineBreakRoomList(message.body);
                    break;
                case 9001: //type system message
                    let sender, target;

                    if(message.user.userID === taptalk.getTaptalkActiveUser().userID) {
                        sender = "You";
                    }else {
                        sender = message.user.fullname;
                    }

                    if(message.target.targetID === taptalk.getTaptalkActiveUser().userID) {
                        target = "you";
                    }else {
                        target = message.target.targetName;
                    }

                    _message =  this.lineBreakRoomList(message.body).replace("{{sender}}", sender).replace("{{target}}", target);
                    break;
                case 3021: //type waba template
                    _message = lastChatFrom + this.convertFormatTextWhatsapp(this.lineBreakRoomList(message.body));
                    break;
                default:
                    _message = lastChatFrom + this.lineBreakRoomList(message.body);
            }
        }

        return this.replaceTagHTML(_message);
    },

    generateLastMessageSpanRoomName(message, caseDetail, showPhone, myAgentData) {
        let myAgentAccount = myAgentData.account;

        let _message;

        // let lastChatFrom = myAgentAccount.fullName === message.user.fullname ? 'You: ' : message.user.fullname.split(" ")[0] + ": ";
        let lastChatFrom = myAgentAccount.fullName === message.user.fullName ? 'You: ' : printLastMessageName(message, caseDetail, true, false, showPhone) + ": ";
        let withOutSender = true;
        
        lastChatFrom = `<span class="chat-last-message-room">${lastChatFrom}</span>`;

        if(message.isDeleted) {
            if(taptalk.getTaptalkActiveUser().userID === message.user.userID) {
                _message = "You deleted this message.";
            }else {
                _message = "This message was deleted.";
            }
        }else {
            switch(message.type) {
                case 1002: //type image
                    _message = Helper.lineBreakRoomList(message.body);
                    withOutSender = false;
                    break;
                case 1003: //type video
                    _message = Helper.lineBreakRoomList(message.body);
                    withOutSender = false;
                    break;
                case 1004: //type file
                    _message = Helper.lineBreakRoomList(message.body);
                    withOutSender = false;
                    break;
                case 9001: //type system message
                    let sender, target;

                    if(message.user.userID === taptalk.getTaptalkActiveUser().userID) {
                        sender = "You";
                    }else {
                        sender = message.user.fullname;
                    }

                    if(message.target.targetID === taptalk.getTaptalkActiveUser().userID) {
                        target = "you";
                    }else {
                        target = message.target.targetName;
                    }

                    _message =  this.lineBreakRoomList(message.body).replace("{{sender}}", sender).replace("{{target}}", target);
                    break;
                case 3021: //type waba template
                    _message = this.convertFormatTextWhatsapp(this.lineBreakRoomList(message.body));
                    withOutSender = false;
                    break;
                default:
                    _message = this.lineBreakRoomList(message.body);
                    withOutSender = false;
            }
        }

        return {
            message: this.replaceTagHTML(_message),
            lastChatFrom: lastChatFrom,
            withOutSender: withOutSender
        }
    },

    generateIconOmnichannel(caseDetail) {
        return TAPLIVE_MEDIUM_LOGO[caseDetail.medium] ? TAPLIVE_MEDIUM_LOGO[caseDetail.medium] : "";
    },

    renderChatStatus(message, activeRoom) {
        let messageStatus;
        let isWhite = false;

        if((activeRoom !== null) && message.room.roomID === activeRoom.roomID) {
            isWhite = true;
        }

        if(message.type !== 9001) {
            if(message.isSending) {
                messageStatus = "sending";
            }

            if(!message.isSending && !message.isDelivered) {
                messageStatus = "sent";
            }

            if(!message.isSending && message.isDelivered && !message.isRead) {
                messageStatus = "receive";
            }

            if(message.isRead) {
                messageStatus = "receive";
            }

            if(message.isDeleted) {
                messageStatus = "deleted";
            }

            switch(messageStatus) {
                case "sending":
                    return isWhite ? MessageSendingWhite : MessageSending;
                case "sent":
                    return isWhite ? MessageSentWhite : MessageSent;
                case "receive":
                    return isWhite ? MessageReceiveWhite : MessageReceive;
                case "deleted":
                    return MessageDelete;
                case "read":
                    return MessageRead;
                case "not delivered":
                    return isWhite ? MessageSentWhite : MessageSent;
                default:
                    return isWhite ? MessageFailWhite : MessageFail;
            }
        }

        return MessageFail;
    },

    resetChatRoomHeightAndInputText() {
        let elTextInput = document.getElementsByClassName("main-textarea-input")[0];
        let elChatRoomMain = document.getElementsByClassName("chat-room-main-wrapper")[0];

        if(elChatRoomMain) {
            elChatRoomMain.removeAttribute("style");
        }

        if(elTextInput) {
            elTextInput.value = "";
            elTextInput.removeAttribute("style");
        }
    },

    actionSetRoomListLastMessage(message, hashmapOfMessages, sortBy, callback, action = null) {
        const SORT_MESSAGE_BY = {
            LAST_MESSAGE: "last_message",
            CREATED_TIME: "created_time"
        }

        var user = taptalk.getTaptalkActiveUser().userID;

        let unreadCounter = () => {
            if(hashmapOfMessages[message.room.roomID]) {
                let count = hashmapOfMessages[message.room.roomID].tapTalkRoom.unreadCount;

                if(!message.isRead) {
                    if((user !== message.user.userID)) {
                        // if(!tapTalkRoom[message.room.roomID][message.localID]) {
                            count = count + 1;

                            hashmapOfMessages[message.room.roomID].tapTalkRoom.unreadCount = count;
                        // }
                    }
                }else {
                    if(count !== 0) {
                        // if(!tapTalkRooms[message.room.roomID][message.localID]) {
                            count = 0;
                            hashmapOfMessages[message.room.roomID].tapTalkRoom.unreadCount = count;
                        // }
                    }
                }
            }
        }

        //new emit action
        if(action === 'new emit') {
            unreadCounter();
            let temporaryRoomList = {};
            temporaryRoomList[message.room.roomID] = hashmapOfMessages[message.room.roomID];

            if((temporaryRoomList[message.room.roomID].tapTalkRoom.lastMessage.created !== message.created)) {
                temporaryRoomList[message.room.roomID].tapTalkRoom.lastMessage = message;
            }

            if(sortBy === SORT_MESSAGE_BY.LAST_MESSAGE) {
                delete hashmapOfMessages[message.room.roomID];

                hashmapOfMessages = Object.assign(temporaryRoomList, hashmapOfMessages);
            }
        }
        //new emit action

        //update emit action
        if(action === 'update emit') {
            if((hashmapOfMessages[message.room.roomID].tapTalkRoom.lastMessage.localID === message.localID)) {
                hashmapOfMessages[message.room.roomID].tapTalkRoom.lastMessage = message;
            }else {
                if(hashmapOfMessages[message.room.roomID].tapTalkRoom.lastMessage.created < message.created) {
                    hashmapOfMessages[message.room.roomID].tapTalkRoom.lastMessage = message;
                }
            }

            if(message.isRead) {
                unreadCounter();
            }
        }
        //update emit action

        callback(hashmapOfMessages);
    },

    setRoomListLastMessage(message, hashmapOfMessage, sortBy, isUpdate, callback) {
        let _hashmapOfMessage = {...hashmapOfMessage};

        if(!message.isHidden) {
            this.actionSetRoomListLastMessage(message, _hashmapOfMessage, sortBy, callback, isUpdate ? "update emit" : "new emit");
        }
    },

    showNotificationMessage(message) {
        try {
            const notification = new Notification(message.user.fullname, {
                body: message.body,
                icon: 'https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/taptalk-icon.jpg'
            });
            notification.onclick = function () {
                window.focus();
            };
        }catch(e) {
            console.log(e)
        }
    },

    resetHeightClearReply(replyMessage) {
        let elChatRoomMain = document.getElementsByClassName("chat-room-main-wrapper")[0];

        if(elChatRoomMain && replyMessage.message) {
            let chatRoomContainerHeight = elChatRoomMain.style.maxHeight === "" ? 158 : parseInt(elChatRoomMain.style.maxHeight.split("-")[1].replace("px", ""));
            let heightNew = 0;
            
            let setNewHeight = (height, isSet) => {
                heightNew = height;
                elChatRoomMain.style.setProperty("max-height", "calc(100vh - " + heightNew + "px)", "important");
            }

            setNewHeight(chatRoomContainerHeight - 68)
        }
    },
    
    is_aplhanumeric(c) {
        var x = c.charCodeAt();
        return ((x>=65&&x<=90)||(x>=97&&x<=122)||(x>=48&&x<=57))?true:false;
    },
    
    whatsappStyles(format,wildcard, opTag, clTag) {
        var indices = [];
        let a = "";
        for(var i = 0; i < format.length; i++) {
            if (format[i] === wildcard) {
                if(indices.length%2) {
                    if(format[i-1] === " ") {
                        a = null;
                    }else if(typeof(format[i+1])=="undefined") {
                        indices.push(i);
                    } else if(this.is_aplhanumeric(format[i+1])) {
                        a = null;
                    } else {
                        indices.push(i);
                    }
                } else {
                    if(typeof(format[i+1]) === "undefined") {
                        a = null;
                    } else if(format[i+1] === " ") {
                        a = null;
                    } else if(typeof(format[i-1]) === "undefined") {
                        indices.push(i);
                    } else if(this.is_aplhanumeric(format[i-1])) {
                        a = null;
                    } else {
                        indices.push(i);
                    }
                }
            }
            else{
                if(format[i].charCodeAt()===10 && indices.length % 2) {
                    indices.pop()
                }
            }
        }

        if(indices.length % 2) {
            indices.pop()
        }
            
        var e=0;
        
        indices.forEach(function(v,i){
            var t=(i%2)?clTag:opTag;
            v+=e;
            format=format.substr(0,v)+t+format.substr(v+1);
            e+=(t.length-1);
        });
        return format;
    },

    convertFormatTextWhatsapp(message) {
        var html = message;
        html = this.whatsappStyles(html,'_', '<i>', '</i>');
        html = this.whatsappStyles(html,'*', '<b>', '</b>');
        html = this.whatsappStyles(html,'~', '<s>', '</s>');
        html = this.whatsappStyles(html,'```', '<mono>', '</mono>');
        html = html.replace(/\n/gi,"<br>");
        // var bold = /\*(.*?)\*/gm;
        // var italic = /\_(.*?)\_/gm;
        // var strikethrough = /\~(.*?)\~/gm;
        var monospace = /\```(.*?)\```/gm;
        html = html.replace(monospace, '<mono>$1</mono>');
        return html;
    },

    boldTextSalestalkProduct(_text, medium) {
        let text = _text;

        if(
            medium === TAPLIVE_MEDIUM.whatsapp || 
            medium === TAPLIVE_MEDIUM.whatsappba || 
            medium === TAPLIVE_MEDIUM.facebook ||
            medium === TAPLIVE_MEDIUM.line ||
            medium === TAPLIVE_MEDIUM.telegram
        ) {
            text = `*${_text}*`;
        }else {
            text = `${_text}`;
        }

        return text;
    },

    needReplyDefaultColor(data) {
        let color = "#ff3f57";

        if(data) {
            color = data.firstHexColor;
        }

        return color;
    },

    needReplyLevelColor(data, level) {
        let color = "#ff3f57";

        if(data.levels) {
            color = data.levels[level].hexColor;
        }

        return color;
    },

    needReplyLevelCount(dataNeedReplyColor, caseData) {
        let nowTimeStamp = new Date().valueOf();
        let dif_createdTime = nowTimeStamp - caseData.tapTalkRoom.lastMessage.created;  
        // let _data = JSON.parse(JSON.stringify(caseData));
        let _data = {...caseData};
        
        if(dataNeedReplyColor.isCustom) {
            _data["needReplyLevel"] = 0;
            _data["needReplyHexColor"] = this.needReplyDefaultColor(dataNeedReplyColor);
            
            if(dif_createdTime > dataNeedReplyColor.levels[0].afterSeconds * 1000) {
                _data["needReplyLevel"] = 1;
                _data["needReplyHexColor"] = dataNeedReplyColor.levels[0].hexColor;
            }

            if(dif_createdTime > (dataNeedReplyColor.levels[1].afterSeconds * 1000)) {
                _data["needReplyLevel"] = 2;
                _data["needReplyHexColor"] = dataNeedReplyColor.levels[1].hexColor;
            }
        }else {
            _data["needReplyLevel"] = 0;
            _data["needReplyHexColor"] = "#ff3f57";
        }

        return _data;
    }
}

export default Helper;
