import React, { useState, useEffect } from "react";
import "./SourceText.scss";
import { connect } from 'react-redux';
import { FiAlertCircle, FiEdit, FiInfo, FiRotateCcw } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { doToast } from "../../../../../helper/HelperGeneral";
import ErrorOrInfoComp from "../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ChatbotServices from "../../../../../services/newServices/ChatbotService";
import PopupLoading from "../../../../reuseableComponent/popupLoading/PopUpLoading";
import PopUpErrorUpload from "../../../../reuseableComponent/popUpErrorUpload/PopUpErrorUpload";
import SectionWrap from "../../../../reuseableComponent/sectionWrap/SectionWrap";

const SourceText = (props) => {

    const TEXT_NAME_MAX_LENGTH = 100;
    const TEXT_NAME_MAX_LENGTH_ERROR = `Text name may not exceed ${TEXT_NAME_MAX_LENGTH} characters.`;

    let [pendingUploadFile, setPendingUploadFile] = useState({
        name: "",
        content: "",
    });
    let [errorMessageData, setErrorMessageData] = useState({
      name: "",
      content: "",
    });
    let [isShowModalUploading, setIsShowModalUploading] = useState(false);
    let [isShowModalError, setIsShowModalError] = useState(false);
    let [isShowModalDuplicate, setIsShowModalDuplicate] = useState(false);
    let [duplicateMessage, setDuplicateMessage] = useState("Text source name already exists");

    useEffect(() => {
        resizeTextArea();
    }, []);

    const toggleModalLoading = () => {
        setIsShowModalUploading(!isShowModalUploading);
    }

    const toggleModalError = () => {
        if (isShowModalError) {
            setPendingUploadFile({
                name: "",
                content: "",
            });
        }
        setIsShowModalError(!isShowModalError);
    }

    const toggleModalDuplicate = () => {
        if (isShowModalDuplicate) {
            setPendingUploadFile({
                name: "",
                content: "",
            });
        }
        setIsShowModalDuplicate(!isShowModalDuplicate);
    }

    const onChangeName = (e) => {
        let _valName = e.target.value;
        let _data = { ...props.sourceTextData }
        _data.name = _valName;
        props.setSourceTextData(_data);
        if (_valName) {
            let _errorMessageData = {...errorMessageData};
            if (props.checkFileNameExists(_valName)) {
                _errorMessageData.name = duplicateMessage;
            }
            else if (_valName.length > TEXT_NAME_MAX_LENGTH) {
                _errorMessageData.name = TEXT_NAME_MAX_LENGTH_ERROR;
            }
            else {
                _errorMessageData.name = "";
            }
            setErrorMessageData(_errorMessageData);
        }
    }

    const onChangeContent = (e) => {
        let _valContent = e.target.value;
        let _data = { ...props.sourceTextData }
        _data.content = _valContent;
        props.setSourceTextData(_data);
        if (e.target.value) {
            let _errorMessageData = {...errorMessageData};
            _errorMessageData.content = "";
            setErrorMessageData(_errorMessageData);
        }
        resizeTextArea();
    }

    const resizeTextArea = () => {
        const elements = document.getElementsByClassName("input-content");
        if (!elements) {
            return;
        }
        const textarea = elements[0];
        if (!textarea) {
            return;
        }
        if (textarea.offsetHeight === textarea.scrollHeight) {
            return;
        }
        textarea.style.height = "1px"; // Reset height
        if (textarea.scrollHeight <= 168) {
            textarea.style.height = "168px";
        }
        else {
            textarea.style.height = "" + textarea.scrollHeight + "px";
            // textarea.style.overflow = "hidden";
        }
    }

    const validateText = () => {
        let _errorMessageData = {...errorMessageData};
        let hasError = false;
        if (!props.sourceTextData.name) {
            _errorMessageData.name = "This field is required";
            hasError = true;
        }
        else if (props.checkFileNameExists(props.sourceTextData.name)) {
            _errorMessageData.name = "Text source name already exists";
            hasError = true;
        }
        else if (props.sourceTextData.name.length > TEXT_NAME_MAX_LENGTH) {
            _errorMessageData.name = TEXT_NAME_MAX_LENGTH_ERROR;
            hasError = true;
        }
        if (!props.sourceTextData.content) {
            _errorMessageData.content = "This field is required";
            hasError = true;
        }
        setErrorMessageData(_errorMessageData);
        if (!hasError) {
            uploadTextSource();
        }
    }

    const uploadTextSource = () => {
        let item = {
            "name": props.sourceTextData.name,
            "content": props.sourceTextData.content
        };
        let data = {
            "chatbotID": Number(props.match.params.id),
            "type": "text",
            "text": item
        };
        setIsShowModalUploading(true);
        setPendingUploadFile(item);
        ChatbotServices.addKnowledgeBaseItem(props.match.params.orgID, data, (response) => {
            setIsShowModalUploading(false);
            let dataResult = response.dataResult;
            if (dataResult.data.success) {
                doToast(`Source has been successfully uploaded`);
                setPendingUploadFile({
                    name: "",
                    content: "",
                });
                let _data = { ...props.sourceTextData }
                _data.name = "";
                _data.content = "";
                props.setSourceTextData(_data);
                resizeTextArea();
                props.fetchPendingChanges(true);
            }
            else if (dataResult.error.message === "") {
                setDuplicateMessage(dataResult.data.message);
                setIsShowModalDuplicate(true);
            }
            else {
                setIsShowModalError(true);
            }
        });
    }

    return (
        <SectionWrap
            {...props}
            orgID={props.match.params.orgID}
            noWrap
            dataOrigin={JSON.stringify({
                name: "",
                content: "",
            })}
            dataAfterChange={JSON.stringify(props.sourceTextData)}
        >
            <div className="source-text-wrapper">
                <div className="form-content-wrapper width-100">
                    <label>
                        <b>Name</b>
                        <span className={`input-counter grey-font ${props.sourceTextData.name.length > TEXT_NAME_MAX_LENGTH ? "red-text" : ""}`}>
                            {props.sourceTextData.name.length}/{TEXT_NAME_MAX_LENGTH} characters
                        </span>
                    </label>
                    <input
                        className={`input-name ${errorMessageData.name ? "border-red" : ""}`}
                        type="text"
                        id="source-text-name"
                        onChange={(e) => onChangeName(e)}
                        value={props.sourceTextData.name}
                        placeholder="Insert name here"
                    />
                    {
                        errorMessageData.name ?
                        <ErrorOrInfoComp
                            text={errorMessageData.name}
                            _className={"font-red"}
                            icon={<FiAlertCircle />}
                        />
                        :
                        <ErrorOrInfoComp
                            text="Name will only be used for labeling and does not affect chatbot"
                            icon={<FiInfo />}
                        />
                    }
                </div>

                <div className="form-content-wrapper width-100">
                    <label>
                        <b>Content</b>
                    </label>
                    <textarea
                        className={`input-content ${errorMessageData.content ? "border-red" : ""}`}
                        type="text"
                        id="source-text-content"
                        onChange={(e) => onChangeContent(e)}
                        value={props.sourceTextData.content}
                        placeholder="Insert content here"
                    />
                    {
                        errorMessageData.content &&
                        <ErrorOrInfoComp
                            text={errorMessageData.content}
                            _className={"font-red"}
                            icon={<FiAlertCircle />}
                        />
                    }
                </div>

                <ButtonWithLoadingOrIcon
                    className="no-fill-button main-button-40"
                    position="left"
                    text="Upload Source"
                    onClickAction={validateText}
                />
            </div>

            <PopupLoading
                isOpen={isShowModalUploading}
                toggle={toggleModalLoading}
                title="Uploading..."
                description="This may take a while, please wait"
                // buttonText={uploadQueue.files.length > 1 ? "Cancel" : ""}
                // buttonText="Cancel"
                // buttonAction={toggleModalLoading}
            />

            <PopUpErrorUpload
                isOpen={isShowModalError && !isShowModalUploading && pendingUploadFile}
                toggle={toggleModalError}
                title="Something Went Wrong"
                description="File upload was interrupted, please check your network and try again."
                errorFiles={[pendingUploadFile]}
                buttonText="Try Again"
                buttonIcon={FiRotateCcw}
                buttonAction={uploadTextSource}
            />

            <PopUpErrorUpload
                isOpen={isShowModalDuplicate && !isShowModalUploading && !isShowModalError && pendingUploadFile}
                toggle={toggleModalDuplicate}
                title="Something Went Wrong"
                description={duplicateMessage}
                errorFiles={[pendingUploadFile]}
                buttonText="Edit"
                buttonIcon={FiEdit}
                buttonAction={() => {
                    toggleModalDuplicate();
                    if (duplicateMessage.includes("Knowledge base with the same content already exists")) {
                        document.getElementById("source-text-content").focus();
                    }
                    else {
                        document.getElementById("source-text-name").focus();
                    }
                }}
                buttonClass="no-fill-button main-button-48"
            />
        </SectionWrap>
    )
}

const mapStateToProps = state => ({
    // allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SourceText)
